import * as React from "react";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../context/AppContext";
import { getOfficesList, syncOfficeList } from "../../services/Office";
import { OFFICE_MODIFY_PERM, OFFICE_CREATE_PERM, NOTICE_CONFIGURATION_PERM, DEVICE_TYPE_CONF_PERM, DEVICE_TYPE_DO_ASIGN_PERM } from "../../const/Permission";
import i18n from "../../i18n";
import { OFFICES_ROUTE } from "../../const/Routes";
import { ResponseError, apiHandleErrorCode } from "../../utils/ApiBaseConfig";
import { BranchOffice } from "../../interfaces/Office";
import { MESSAGE_SUCCESS } from "../../const/Message";
import { OfficeList } from "./List";
import { FormOffice } from "./Form";
import { NoticeForm } from "./NoticeForm";
import { DeviceAssign } from "./DeviceAssign";

interface OfficeProps extends RouteComponentProps, AppContextProps { }
interface OfficeState {
    showModify: boolean;
    showHistory: boolean;
    showCreateNew: boolean;
    officeList: BranchOffice[];
    selected: BranchOffice | null;
    code: string | null;
    deleteDialog: boolean;
    step: number;
}

export class OfficeMain extends React.Component<OfficeProps, OfficeState> {
    bootstrapTableRef: any;
    constructor(props: OfficeProps) {
        super(props);
        this.toggleDelete = this.toggleDelete.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.syncAction = this.syncAction.bind(this);
        this.setStep = this.setStep.bind(this);
        this.state = {
            showCreateNew: false,
            showHistory: false,
            showModify: false,
            officeList: [],
            selected: null,
            code: null,
            deleteDialog: false,
            step: this.props.havePermission(OFFICE_CREATE_PERM) ? 0 : 1,
        }
    }

    componentDidMount() {
        this.props.showLoading(true);
        getOfficesList()
            .then((officeList: BranchOffice[]) => {
                this.setState({ officeList }, () => this.props.showLoading(false));
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    toggleDelete() {
        this.setState(state => ({ ...state, deleteDialog: !state.deleteDialog }));
    }

    syncAction() {
        this.props.showLoading(true);

        syncOfficeList()
            .then(() => {
                this.props.showLoading(false);
                this.props.history.push(OFFICES_ROUTE,
                    this.props.setMessage({ message: i18n.t('branch-office-synced'), type: MESSAGE_SUCCESS }));
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }


    onSelect(selected: BranchOffice) {
        this.setState({ selected, code: selected.code });
    }

    setStep(step: number): any {
        this.setState({ step });
    }


    render() {
        return (
            <div className="card">
                <div className="panel-tabs">
                    <div className="panel-heading">
                        <ul className="nav nav-tabs">
                            {this.props.havePermission(OFFICE_CREATE_PERM) && <li className={this.state.step === 0 ? "active" : ""}><a onClick={() => this.setStep(0)} href="#" className="nav-link" >{i18n.t('branch-offices-manage')}</a></li>}
                            {this.props.havePermission(OFFICE_MODIFY_PERM) && <li className={this.state.step === 1 ? "active" : ""}><a onClick={() => this.setStep(1)} href="#" className="nav-link" >{i18n.t('branch-office-config')}</a></li>}
                            {this.props.havePermission(OFFICE_MODIFY_PERM) && <li className={this.state.step === 2 ? "active" : ""}><a onClick={() => this.setStep(2)} href="#" className="nav-link" >{i18n.t('branch-office-config-notices')}</a></li>}                            
                            {this.props.havePermission(DEVICE_TYPE_DO_ASIGN_PERM) && <li className={this.state.step === 3 ? "active" : ""}><a onClick={() => this.setStep(3)} href="#" className="nav-link" >{i18n.t('branch-office-config-devices')}</a></li>}
                        </ul>
                    </div>
                    {this.state.step === 0 && this.props.havePermission(OFFICE_CREATE_PERM) &&
                        <OfficeList props={this.props} />
                    }
                    {this.state.step === 1 && this.props.havePermission(OFFICE_MODIFY_PERM) &&
                        <FormOffice props={this.props} />
                    }
                    {this.state.step === 2 && this.props.havePermission(DEVICE_TYPE_CONF_PERM) &&
                        <NoticeForm props={this.props} />
                    }
                    {this.state.step === 3 && this.props.havePermission(DEVICE_TYPE_DO_ASIGN_PERM) &&
                        <DeviceAssign props={this.props} />
                    }
                </div>
            </div>
        )
    }
}

export const Office = withAppContext(OfficeMain);