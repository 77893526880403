import * as React from "react";
import i18n from "../../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../../context/AppContext";
import { BootstrapTable, TableHeaderColumn, ExportCSVButton } from "react-bootstrap-table";
import { Region } from "../../../interfaces/Movement";
import { BranchOffice } from "../../../interfaces/Office";
import { getRegionList, getBranchOfficeList } from "../../../services/Stock";
import { ResponseError, TIMEOUT_RESPONSE_ERROR, apiHandleErrorCode } from "../../../utils/ApiBaseConfig";
import { Balance, ProposalBalance } from "../../../interfaces/Balance";
import { findPendingBalances, rejectBalances, sendApprovalClose, sendCloseProposal } from "../../../services/Balance";
import Modal from 'react-modal';
import { MESSAGE_SUCCESS, MESSAGE_WARNING } from "../../../const/Message";

export interface PendingProps extends RouteComponentProps, AppContextProps { }
export interface PendingState {
    region: string;
    regionName: string;
    regions: Region[];
    office: string;
    officeName: string;
    offices: BranchOffice[];
    stock: string;
    blockRegionAndLocation: boolean;
    allBalances: Balance[];
    data: Balance[];
    dataPending: Balance[];
    dataModified: any[];
    dataNotModified: any[];
    tableOptions: any;
    comment: string;
    detailModalIsOpen: boolean;
    selectedBalance: Balance | any;
    isTableVisible: boolean;
    approveBalancesButtonDisabled: boolean;
    rejectBalancesButtonDisabled: boolean;
}

export class PendingMain extends React.Component<PendingProps, PendingState> {
    reachTheLastPage: boolean = false;
    constructor(props: PendingProps) {
        super(props);
        this.handleRegion = this.handleRegion.bind(this);
        this.handleBranchOffice = this.handleBranchOffice.bind(this);
        this.handleStock = this.handleStock.bind(this);
        this.getRegionsFromAPI = this.getRegionsFromAPI.bind(this);
        this.getOfficesFromAPI = this.getOfficesFromAPI.bind(this);
        this.findBalancesFromAPI = this.findBalancesFromAPI.bind(this);
        this.openDetailModal = this.openDetailModal.bind(this);
        this.closeDetailModal = this.closeDetailModal.bind(this);
        this.sendBalancesToAPI = this.sendBalancesToAPI.bind(this);
        this.rejectBalance = this.rejectBalance.bind(this);
        this.state = {
            region: "",
            regionName: "",
            regions: [],
            office: "",
            officeName: "",
            offices: [],
            stock: "",
            blockRegionAndLocation: true,
            allBalances: [],
            data: [],
            dataPending: [],
            dataModified: [],
            dataNotModified: [],
            comment: "",
            tableOptions: {
                noDataText: i18n.t('table-empty'),
                sizePerPageList: [{
                    text: '10', value: 10
                }, {
                    text: '20', value: 20
                }, {
                    text: '50', value: 50
                }],
                sizePerPage: 10,
                paginationSize: 3,
                pageStartIndex: 1
            },
            detailModalIsOpen: false,
            selectedBalance: {},
            isTableVisible: false,
            approveBalancesButtonDisabled: false,
            rejectBalancesButtonDisabled: false
        };
    }

    componentDidMount() {
        if (this.props.authUser && this.props.authUser.branchOffice) {
            //setear la fecha de  busqueda por defecto
            var curr = new Date();
            var date = curr.toISOString().substr(0, 10);
            curr.setDate(curr.getDate() + 1);
            var tomorrow = curr.toISOString().substr(0, 10);

            if (this.props.authUser.branchOffice.code === "1") {
                this.setState({ blockRegionAndLocation: false }, () => {
                    this.getRegionsFromAPI();
                });
            }
            else {
                this.setState({
                    office: this.props.authUser.branchOffice.code,
                    officeName: this.props.authUser.branchOffice.name,
                    regionName: this.props.authUser.branchOffice.region.regionName,
                }, () => {
                    this.findBalancesFromAPI();
                });
            }
        }

    }

    handleRegion(e: any) {
        e.preventDefault();
        const regionSelected = e.target.value;
        this.setState({ region: regionSelected }, () => { 
            this.getOfficesFromAPI();
        });
    }

    handleBranchOffice(e: any) {
        e.preventDefault();
        const officeSelected = e.target.value;
        this.setState({ office: officeSelected }, () => {
            this.findBalancesFromAPI();
        });
    }

    handleStock(stockType: any) {
        this.setState({ stock: stockType.value });

    }


    getRegionsFromAPI(): void {
        this.props.showLoading(true);
        getRegionList().then((regionArray: Region[]) => {
            this.setState({ regions: regionArray }, this.props.showLoading(false));
        })
            .catch((response: ResponseError) => {
                this.props.showLoading(false);
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
    }

    getOfficesFromAPI(): void {
        this.props.showLoading(true);
        if (this.state.region !== "" && this.state.region !== "NONE") {
            getBranchOfficeList(this.state.region).then((officeArray: BranchOffice[]) => {
                this.setState({ offices: officeArray }, this.props.showLoading(false));
            })
                .catch((response: ResponseError) => {
                    this.props.showLoading(false);
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                });
        }
    }

    separeBalances(balances: Balance[], callback: () => void): void {
        var pending: Balance[] = [];
        var notPending: Balance[] = [];
        var allBalances: Balance[] = [];
        balances.map((balance: Balance) => {
            allBalances.push(balance);
            if (balance.pending) {
                pending.push(balance); // Agregar a la lista de pendientes
            } else {
                notPending.push(balance); // Agregar a la lista de no pendientes
            }
        });
        this.setState({
            data: notPending,
            dataPending: pending,
            allBalances: allBalances
        });
        callback();

    }

    findBalancesFromAPI(): void {
        this.props.showLoading(true);
        findPendingBalances(this.state.office)
            .then((balance: Balance[]) => {
                this.separeBalances(balance, () => {
                    this.props.showLoading(false);
                });
            })
            .catch((response: ResponseError) => {
                this.props.showLoading(false);
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
    }


    sendBalancesToAPI() {
        this.props.showLoading(true);
        this.setState({ approveBalancesButtonDisabled: true });
        let balancesToSend: ProposalBalance[] = [];
        for (const rawBalance of this.state.allBalances) {
            const balance: ProposalBalance = {
                correlative: rawBalance.correlative,
                branchOffice: { code: this.state.office, name: this.state.officeName },
                deviceType: { code: rawBalance.deviceTypeCode, name: rawBalance.deviceTypeName, available: true },
                user: { group: "identity", userId: "", password: "" },
                userSupervisor: { group: "identity", userId: "", password: "" },
                description: rawBalance.description,
                vaultToMachine: rawBalance.vaultToMachine,
                expectedMachineToVault: rawBalance.expectedMachineToVault,
                expectedQuantityInVault: rawBalance.expectedQuantityInVault,
                expectedQuantityRejectedMachine: rawBalance.expectedQuantityRejectedMachine,
                expectedQuantityRejectedQA: rawBalance.expectedQuantityRejectedQA,
                expectedQuantitySuccessful: rawBalance.expectedQuantitySuccessful,
                expectedTotalQuantityOutVault: rawBalance.expectedTotalQuantityOutVault,
                realMachineToVault: rawBalance.realMachineToVault,
                realQuantityInMachine: rawBalance.realQuantityInMachine,
                realQuantityInOther: rawBalance.realQuantityInOther,
                realQuantityInVault: rawBalance.realQuantityInVault,
                realQuantityRejectedMachine: rawBalance.realQuantityRejectedMachine,
                realQuantityRejectedQA: rawBalance.realQuantityRejectedQA,
                realQuantitySuccessful: rawBalance.realQuantitySuccessful,
                realTotalQuantityOutVault: rawBalance.realTotalQuantityOutVault,
                pending: rawBalance.pending
            };
            balancesToSend.push(balance);
        }

        sendApprovalClose(balancesToSend).then(() => {
            this.props.setMessage({ message: i18n.t('balance-sent-close-ok'), type: MESSAGE_SUCCESS });
            this.setState({
                allBalances: [],
                selectedBalance: {},
                approveBalancesButtonDisabled: false,
                dataPending: [],
                data: [],
                dataModified: [],
                dataNotModified: [],
            });
            this.props.showLoading(false);
        }).catch((response: ResponseError) => {
            if (response === TIMEOUT_RESPONSE_ERROR) {
                // Error de timeout
                this.props.setMessage({ message: i18n.t('request-timeout'), type: MESSAGE_WARNING });
                this.setState({
                    allBalances: [],
                    selectedBalance: {},
                    approveBalancesButtonDisabled: false,
                    dataPending: [],
                    data: [],
                    dataModified: [],
                    dataNotModified: [],
                });
            } else {
                // Otros errores
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.setState({
                    allBalances: [],
                    selectedBalance: {},
                    approveBalancesButtonDisabled: false,
                    dataPending: [],
                    data: [],
                    dataModified: [],
                    dataNotModified: [],
                });
            }
            this.props.showLoading(false);
        });
    }

    rejectBalance() {
        this.props.showLoading(true);
        this.setState({ rejectBalancesButtonDisabled: true });
        let balancesToSend: ProposalBalance[] = [];
        for (const rawBalance of this.state.allBalances) {
            const balance: ProposalBalance = {
                correlative: rawBalance.correlative,
                branchOffice: { code: this.state.office, name: this.state.officeName },
                deviceType: { code: rawBalance.deviceTypeCode, name: rawBalance.deviceTypeName, available: true },
                user: { group: "identity", userId: "", password: "" },
                userSupervisor: { group: "identity", userId: "", password: "" },
                description: rawBalance.description,
                vaultToMachine: rawBalance.vaultToMachine,
                expectedMachineToVault: rawBalance.expectedMachineToVault,
                expectedQuantityInVault: rawBalance.expectedQuantityInVault,
                expectedQuantityRejectedMachine: rawBalance.expectedQuantityRejectedMachine,
                expectedQuantityRejectedQA: rawBalance.expectedQuantityRejectedQA,
                expectedQuantitySuccessful: rawBalance.expectedQuantitySuccessful,
                expectedTotalQuantityOutVault: rawBalance.expectedTotalQuantityOutVault,
                realMachineToVault: rawBalance.realMachineToVault,
                realQuantityInMachine: rawBalance.realQuantityInMachine,
                realQuantityInOther: rawBalance.realQuantityInOther,
                realQuantityInVault: rawBalance.realQuantityInVault,
                realQuantityRejectedMachine: rawBalance.realQuantityRejectedMachine,
                realQuantityRejectedQA: rawBalance.realQuantityRejectedQA,
                realQuantitySuccessful: rawBalance.realQuantitySuccessful,
                realTotalQuantityOutVault: rawBalance.realTotalQuantityOutVault,
                pending: rawBalance.pending
            };
            balancesToSend.push(balance);
        }

        rejectBalances(balancesToSend).then(() => {
            this.props.setMessage({ message: i18n.t('balance-reject-ok'), type: MESSAGE_SUCCESS });
            this.setState({
                allBalances: [],
                selectedBalance: {},
                approveBalancesButtonDisabled: false,
                dataPending: [],
                data: [],
                dataModified: [],
                dataNotModified: [],
            });
            this.props.showLoading(false);
        }).catch((response: ResponseError) => {
            if (response === TIMEOUT_RESPONSE_ERROR) {
                // Error de timeout
                this.props.setMessage({ message: i18n.t('request-timeout'), type: MESSAGE_WARNING });
                this.setState({
                    allBalances: [],
                    selectedBalance: {},
                    dataPending: [],
                    data: [],
                    dataModified: [],
                    dataNotModified: [],
                });
            } else {
                // Otros errores
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.setState({
                    allBalances: [],
                    selectedBalance: {},
                    dataPending: [],
                    data: [],
                    dataModified: [],
                    dataNotModified: [],
                });
            }
            this.props.showLoading(false);
            this.setState({ rejectBalancesButtonDisabled: false });
        });
    }

    openDetailModal(record: any) {
        this.setState({ selectedBalance: record }, () => this.separateBalanceModified());
    }

    separateBalanceModified() {
        this.props.showLoading(true);
        const { selectedBalance } = this.state;
        const modified = [];
        const notModified = [];

        const createData = (title: string, input1: number, input2: number) => ({ title, input1, input2 });
        const createData2 = (title: string, input1: number) => ({ title, input1 });

        if (selectedBalance.expectedQuantitySuccessful !== selectedBalance.realQuantitySuccessful) {
            modified.push(createData(i18n.t('balance-successful-emission'), selectedBalance.expectedQuantitySuccessful, selectedBalance.realQuantitySuccessful));
        } else {
            notModified.push(createData2(i18n.t('balance-successful-emission'), selectedBalance.realQuantitySuccessful));
        }

        if (selectedBalance.expectedQuantityRejectedQA !== selectedBalance.realQuantityRejectedQA) {
            modified.push(createData(i18n.t('balance-reject-qa'), selectedBalance.expectedQuantityRejectedQA, selectedBalance.realQuantityRejectedQA));
        } else {
            notModified.push(createData2(i18n.t('balance-reject-qa'), selectedBalance.realQuantityRejectedQA));
        }

        if (selectedBalance.expectedQuantityRejectedMachine !== selectedBalance.realQuantityRejectedMachine) {
            modified.push(createData(i18n.t('balance-reject-in-machine'), selectedBalance.expectedQuantityRejectedMachine, selectedBalance.realQuantityRejectedMachine));
        } else {
            notModified.push(createData2(i18n.t('balance-reject-in-machine'), selectedBalance.realQuantityRejectedMachine));
        }

        if (selectedBalance.expectedMachineToVault !== selectedBalance.realMachineToVault) {
            modified.push(createData(i18n.t('balance-expected-to-vault'), selectedBalance.expectedMachineToVault, selectedBalance.realMachineToVault));
        } else {
            notModified.push(createData2(i18n.t('balance-expected-to-vault'), selectedBalance.realMachineToVault));
        }

        this.setState({
            dataModified: modified,
            dataNotModified: notModified,
            detailModalIsOpen: true
        }, () => this.props.showLoading(false));
    }

    closeDetailModal() {
        this.setState({ detailModalIsOpen: false, selectedBalance: {} });
    }

    toggleTableVisibility = () => {
        this.setState(prevState => ({ isTableVisible: !prevState.isTableVisible }));
    }

    render() {
        const customStyles: any = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            }
        };

        const REG: { [key: string]: string } = this.state.regions.reduce((map: { [key: string]: string }, region: Region) => {
            map[region.codeRegion] = region.regionName;
            return map;
        }, {});

        const regions: JSX.Element[] = Object.keys(REG).map((codeRegion: string) => {
            return <option key={codeRegion} value={codeRegion}>{i18n.t(REG[codeRegion])}</option>;
        });

        const OFFI: { [key: string]: string } = this.state.offices.reduce((map: { [key: string]: string }, office: BranchOffice) => {
            map[office.code] = office.name;
            return map;
        }, {});

        const branchOffices: JSX.Element[] = Object.keys(OFFI).map((codeOffice: string) => {
            return <option key={codeOffice} value={codeOffice}>{i18n.t(OFFI[codeOffice])}</option>;
        });

        const tableStyle = {
            display: 'none'
        };

        const formGroupStyle: React.CSSProperties = {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '15px'
        };

        const formGroupContainerStyle: React.CSSProperties = {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center'
        };
        const labelStyle: React.CSSProperties = {
            flex: '1',
            textAlign: 'right',
            marginRight: '10px'
        };
        const valueStyle: React.CSSProperties = {
            flex: '1',
            textAlign: 'left'
        };
        const fullWidthLineStyle: React.CSSProperties = {
            display: 'block',
            textAlign: 'left',
            width: '100%',
            borderBottom: '1px solid #ccc',
            lineHeight: '0.1em',
            margin: '10px 0 20px'
        };

        const linkStyle: React.CSSProperties = {
            background: '#fff',
            padding: '0 10px',
        };
        const buttonContainerStyle: React.CSSProperties = {
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px', // Espacio entre los botones
            marginTop: '20px'
        };

        return (
            <div className="panel-body">
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('pending-closures')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="form-group col-sm-12">
                            {!this.state.blockRegionAndLocation &&
                                <div>
                                    <div className="form-group col-sm-4">
                                        <label>{i18n.t('region')}:</label>
                                        <select className="form-control" onChange={this.handleRegion} value={this.state.region}>
                                            <option value="NONE">{i18n.t('option-select')}</option>
                                            {regions}
                                        </select>
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label>{i18n.t('branch-office')}:</label>
                                        <select className="form-control" onChange={this.handleBranchOffice} value={this.state.office}>
                                            <option value="NONE">{i18n.t('option-select')}</option>
                                            {branchOffices}
                                        </select>
                                    </div>
                                </div>
                            }
                            {this.state.blockRegionAndLocation &&
                                <div>
                                    <div className="form-group col-sm-4">
                                        <label>{i18n.t('region')}:</label>
                                        <p className="form-control">{this.state.regionName}</p>
                                    </div>

                                    <div className="form-group col-sm-4">
                                        <label>{i18n.t('branch-office')}:</label>
                                        <p className="form-control">{this.state.officeName}</p>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="form-group col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-title">
                                        <div className="title center">{i18n.t('pending-approval')}</div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="form-group col-sm-12">
                                        <BootstrapTable
                                            data={this.state.dataPending}
                                            options={{
                                                ...this.state.tableOptions,
                                            }}
                                            bordered hover pagination={this.state.data.length > 0}
                                        >
                                            <TableHeaderColumn dataField='correlative' isKey={true} hidden={true}></TableHeaderColumn>
                                            <TableHeaderColumn dataField='deviceTypeCode' tdStyle={{ whiteSpace: 'normal' }} dataSort={true} dataAlign="center" filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} width="130">{i18n.t('device-code')}</TableHeaderColumn>
                                            <TableHeaderColumn dataField='deviceTypeName' tdStyle={{ whiteSpace: 'normal' }} dataSort={true} dataAlign="center" filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} width="130">{i18n.t('movement-card-name')}</TableHeaderColumn>
                                            <TableHeaderColumn dataField='' tdStyle={{ textAlign: 'center' }} width="80" dataFormat={(cell, row) => (
                                                <button onClick={() => this.openDetailModal(row)}>
                                                    <span><i className='glyphicon glyphicon-search'></i></span>
                                                </button>
                                            )}>{i18n.t('details')}</TableHeaderColumn>
                                        </BootstrapTable>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <span style={fullWidthLineStyle}>
                                    <a href="#" style={linkStyle} onClick={this.toggleTableVisibility}>
                                        {this.state.isTableVisible ? i18n.t('see-less') : i18n.t('see-more')}
                                    </a>
                                </span>
                            </div>
                            {this.state.isTableVisible &&
                                <div className="card">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <div className="title center">{i18n.t('pending-not-modified')}</div>
                                        </div>
                                    </div>
                                    <div className="form-group col-sm-12">
                                        <BootstrapTable
                                            data={this.state.data}
                                            options={{
                                                ...this.state.tableOptions,
                                            }}
                                            bordered hover pagination={this.state.data.length > 0}
                                        >
                                            <TableHeaderColumn dataField='correlative' isKey={true} hidden={true}></TableHeaderColumn>
                                            <TableHeaderColumn dataField='deviceTypeCode' tdStyle={{ whiteSpace: 'normal' }} dataSort={true} dataAlign="center" filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} width="130">{i18n.t('device-code')}</TableHeaderColumn>
                                            <TableHeaderColumn dataField='deviceTypeName' tdStyle={{ whiteSpace: 'normal' }} dataSort={true} dataAlign="center" filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} width="130">{i18n.t('movement-card-name')}</TableHeaderColumn>
                                            <TableHeaderColumn dataField='vaultToMachine' thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} dataAlign="center" width="100">{i18n.t('balance-out-vault')} <span data-toggle="tooltip" data-placement="top" title={i18n.t('balance-out-vault-explanation')}><i className='glyphicon glyphicon-info-sign'></i></span></TableHeaderColumn>
                                            <TableHeaderColumn dataField='realQuantitySuccessful' thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} dataAlign="center" width="100">{i18n.t('balance-successful-emission')} <span data-toggle="tooltip" data-placement="top" title={i18n.t('balance-successful-emission-explanation')}><i className='glyphicon glyphicon-info-sign'></i></span></TableHeaderColumn>
                                            <TableHeaderColumn dataField='realQuantityRejectedMachine' thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} dataAlign="center" width="100">{i18n.t('balance-expected-to-vault')} <span data-toggle="tooltip" data-placement="top" title={i18n.t('expected-printer-rejection')}><i className='glyphicon glyphicon-info-sign'></i></span></TableHeaderColumn>
                                            <TableHeaderColumn dataField='realQuantityRejectedQA' thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} dataAlign="center" width="100">{i18n.t('balance-expected-to-vault')} <span data-toggle="tooltip" data-placement="top" title={i18n.t('executive-rejection-expected')}><i className='glyphicon glyphicon-info-sign'></i></span></TableHeaderColumn>
                                            <TableHeaderColumn dataField='realMachineToVault' thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }} dataAlign="center" width="100">{i18n.t('balance-expected-to-vault')} <span data-toggle="tooltip" data-placement="top" title={i18n.t('balance-expected-to-vault-explanation')}><i className='glyphicon glyphicon-info-sign'></i></span></TableHeaderColumn>
                                            <TableHeaderColumn dataField='' tdStyle={{ textAlign: 'center' }} width="80" dataFormat={(cell, row) => (
                                                <button onClick={() => this.openDetailModal(row)}>
                                                    <span><i className='glyphicon glyphicon-search'></i></span>
                                                </button>
                                            )}>{i18n.t('details')}</TableHeaderColumn>
                                        </BootstrapTable>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={this.state.detailModalIsOpen}
                    onRequestClose={this.closeDetailModal}
                    style={customStyles}
                    ariaHideApp={false}
                >
                    <h2>{i18n.t('balance-pending-details-title')}</h2>
                    <div className="card-body">
                        <div className="form-group col-sm-12" style={formGroupContainerStyle}>
                            <div className="form-group col-sm-6" style={formGroupStyle}>
                                <label style={labelStyle}>{i18n.t('device')}:</label>
                                <div className="value" style={valueStyle}>{this.state.selectedBalance.deviceTypeName}</div>
                            </div>
                            <div className="form-group col-sm-6" style={formGroupStyle}>
                                <label style={labelStyle}>{i18n.t('balance-out-vault')}:</label>
                                <div className="value" style={valueStyle}>{this.state.selectedBalance.vaultToMachine}</div>
                            </div>
                            <div className="form-group col-sm-6" style={formGroupStyle}>
                                <label style={labelStyle}>{i18n.t('comment')}:</label>
                                <textarea
                                    name="comment"
                                    id="comment"
                                    maxLength={250}
                                    value={this.state.selectedBalance.description}
                                    disabled={true}
                                    style={{ width: '50%' }} // Ajusta el ancho del textarea si es necesario
                                />
                            </div>
                        </div>


                        <label>{i18n.t('modified')}</label>
                        <div className="form-group" style={{ maxWidth: '1000px' }}>
                            <BootstrapTable
                                data={this.state.dataModified}
                                bordered hover  >
                                <TableHeaderColumn dataField='title' isKey={true} dataAlign="center" width="130" dataFormat={(cell) => <strong>{cell}</strong>}></TableHeaderColumn>
                                <TableHeaderColumn dataField='input1' dataAlign="center" width="130">{i18n.t('balance-info-system')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='input2' dataAlign="center" width="130">{i18n.t('balance-info-operator')}</TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                        <label>{i18n.t('modified-not')}</label>
                        <div className="form-group" style={{ maxWidth: '1000px' }}>
                            <BootstrapTable
                                data={this.state.dataNotModified}
                                bordered hover
                                headerStyle={tableStyle}
                            >
                                <TableHeaderColumn dataField='title' isKey={true} dataAlign="center" width="130" headerTitle={false} dataFormat={(cell) => <strong>{cell}</strong>}></TableHeaderColumn>
                                <TableHeaderColumn dataField='input1' dataAlign="center" width="130" ></TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                        <button className="btn btn-info pull-right" onClick={this.closeDetailModal}>{i18n.t('close')}</button>
                    </div>
                </Modal>

                <div style={buttonContainerStyle}>
                    <button
                        disabled={this.state.rejectBalancesButtonDisabled}
                        type="button"
                        className="btn btn-danger"
                        onClick={this.rejectBalance}
                    >
                        {i18n.t('reject')}
                    </button>
                    <button
                        disabled={this.state.approveBalancesButtonDisabled}
                        type="button"
                        className="btn btn-success"
                        onClick={this.sendBalancesToAPI}
                    >
                        {i18n.t('approve')}
                    </button>
                </div>
            </div>
        )
    }
}

export const Pending = withAppContext(PendingMain);
