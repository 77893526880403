import * as React from "react";
import i18n from "../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../context/AppContext";
import { ResponseError, apiHandleErrorCode } from "../../utils/ApiBaseConfig";
import { RouteOfficeParams, OFFICES_ROUTE, OFFICE_SHOW_ROUTE, OFFICE_CREATE_ROUTE, OFFICE_MODIFY_ROUTE } from "../../const/Routes";
import { getBranchOffice, updateOffice, createOffice } from "../../services/Office";
import { BranchOfficeAPI, BranchOffice } from "../../interfaces/Office";
import { MESSAGE_SUCCESS, MESSAGE_WARNING } from "../../const/Message";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { activeFormatter } from "../../utils/Formatter";
import { Region } from "../../interfaces/Movement";
import { getBranchOfficeList, getRegionList } from "../../services/Stock";
import { DEVICE_TYPE_CONF_PERM, OFFICE_CHANGE_PERM, OFFICE_MODIFY_PERM, OFFICE_CREATE_PERM } from "../../const/Permission";


interface FormOfficeProps extends RouteComponentProps<RouteOfficeParams>, AppContextProps { }
interface FormmOfficeState {
    branchOffice: BranchOffice;
    tableOptions: any;
    regions: Region[];
    region: string;
    saveBtnDisabled: boolean;
    showSelectRegionAndLocation: boolean;
    offices: BranchOffice[];
    office: string;
    regionName: string;
    officeName: string;
}

export class FormOfficeMain extends React.Component<FormOfficeProps, FormmOfficeState> {
    constructor(props: FormOfficeProps) {
        super(props);
        this.back = this.back.bind(this);
        this.update = this.update.bind(this);
        this.create = this.create.bind(this);
        this.handleRegion = this.handleRegion.bind(this);
        this.getRegionsFromAPI = this.getRegionsFromAPI.bind(this);
        this.handleInputCode = this.handleInputCode.bind(this);
        this.handleInputName = this.handleInputName.bind(this);
        this.handleInputAddress = this.handleInputAddress.bind(this);
        this.handleInputManagerUser = this.handleInputManagerUser.bind(this);
        this.handleInputNotificationMail = this.handleInputNotificationMail.bind(this);
        this.printDeviceType = this.printDeviceType.bind(this);
        this.enableSaveBtn = this.enableSaveBtn.bind(this);
        this.handleBranchOffice = this.handleBranchOffice.bind(this);
        this.state = {
            regions: [],
            region: "",
            branchOffice: {
                code: '',
                name: '',
                enabled: true,
                address: '',
                managerUserName: '',
                notificationMail: '',
                updateDate: new Date(),
                stockConfig: [],
                region: { codeRegion: '', regionName: '', regionActive: true },
                defaultNotifyConf: true
            },
            tableOptions: {
                noDataText: i18n.t('table-empty'),
            },
            saveBtnDisabled: true,
            showSelectRegionAndLocation: false,
            offices: [],
            office: "",
            regionName: "",
            officeName: "",
        }
    }

    componentDidMount() {
        if (this.props.authUser && this.props.authUser.branchOffice) {
            if (this.props.authUser.branchOffice.code === "1") {
                this.setState({ showSelectRegionAndLocation: true }, this.getRegionsFromAPI);
            }
            else {
                this.setState({
                    office: this.props.authUser.branchOffice.code,
                    officeName: this.props.authUser.branchOffice.name,
                    regionName: this.props.authUser.branchOffice.region.regionName
                }, this.fetchOffice);
                this.getRegionsFromAPI();
            }
        }
    }

    fetchOffice() {
        this.props.showLoading(true);
        getBranchOffice(this.state.office)
            .then((branchOffice: BranchOffice) => {
                this.setState({ branchOffice });
                this.props.showLoading(false);
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
                this.props.history.goBack();
            });
    }

    getRegionsFromAPI(): void {
        this.props.showLoading(true);
        getRegionList().then((regionArray: Region[]) => {
            this.setState({ regions: regionArray }, this.props.showLoading(false));
        })
            .catch((response: ResponseError) => {
                this.props.showLoading(false);
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
    }

    handleRegion(e: any) {
        e.preventDefault();
        this.setState({
            branchOffice: {
                ...this.state.branchOffice,
                region: {
                    codeRegion: e.target.value,
                    regionName: e.target.options[e.target.selectedIndex].text,
                    regionActive: true
                }
            }
            , region: e.target.value
        }, this.getOfficesFromAPI);
    }

    handleBranchOffice(e: any) {
        e.preventDefault();
        const officeSelected = e.target.value;
        if (officeSelected !== "" && officeSelected !== "NONE") {
            this.setState({ office: officeSelected }, this.fetchOffice);
        } else {
            this.setState({ office: officeSelected });
        }
    }

    getOfficesFromAPI(): void {
        if (this.state.region !== "" && this.state.region !== "NONE") {
            this.props.showLoading(true);
            getBranchOfficeList(this.state.region).then((officeArray: BranchOffice[]) => {
                this.setState({ offices: officeArray }, () => {
                    this.props.showLoading(false);
                });
            })
                .catch((response: ResponseError) => {
                    this.props.showLoading(false);
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                });
        }
    }

    back() {
        this.props.history.push(OFFICES_ROUTE);
    }

    handleInputCode(e: any): void {
        e.preventDefault();
        const code = e.target.value;
        this.setState(state => ({ ...state, branchOffice: { ...state.branchOffice, code } }));
    }

    handleInputName(e: any): void {
        e.preventDefault();
        const name = e.target.value;
        this.setState(state => ({ ...state, branchOffice: { ...state.branchOffice, name } }), this.enableSaveBtn);
    }

    handleInputAddress(e: any): void {
        e.preventDefault();
        const address = e.target.value;
        this.setState(state => ({ ...state, branchOffice: { ...state.branchOffice, address } }), this.enableSaveBtn);
    }

    handleInputManagerUser(e: any): void {
        e.preventDefault();
        const managerUserName = e.target.value;
        this.setState(state => ({ ...state, branchOffice: { ...state.branchOffice, managerUserName } }), this.enableSaveBtn);
    }

    handleInputNotificationMail(e: any): void {
        e.preventDefault();
        const notificationMail = e.target.value;
        this.setState(state => ({ ...state, branchOffice: { ...state.branchOffice, notificationMail } }), this.enableSaveBtn);
    }

    update() {
        const office: BranchOfficeAPI = {
            ...this.state.branchOffice,
            stockConfig: this.state.branchOffice.stockConfig,
            updateDate: 0,
            region: this.state.branchOffice.region
        };

        this.props.showLoading(true);
        updateOffice(office)
            .then(() => {
                this.props.history.push(OFFICES_ROUTE);
                this.props.setMessage({ message: i18n.t('branch-office-updated'), type: MESSAGE_SUCCESS });
                this.props.showLoading(false);
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    create() {
        const office: BranchOfficeAPI = { ...this.state.branchOffice, stockConfig: undefined, updateDate: 0, region: this.state.branchOffice.region };
        if (office.code.length === 0 || office.name.length === 0) {
            this.props.setMessage({ message: i18n.t('branch-office-form'), type: MESSAGE_WARNING });
            return;
        }
        if (this.state.branchOffice.region.codeRegion === "" || this.state.branchOffice.region.codeRegion === "NONE") {
            this.props.setMessage({ message: i18n.t('region-must-be-selected'), type: MESSAGE_WARNING });
            return;
        }

        this.props.showLoading(true);
        createOffice(office)
            .then(() => {
                this.props.history.push(OFFICES_ROUTE);
                this.props.setMessage({ message: i18n.t('branch-office-created'), type: MESSAGE_SUCCESS });
                this.props.showLoading(false);
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    printDeviceType(row: any) {
        return row.name;
    }

    enableSaveBtn() {
        if (this.state.saveBtnDisabled)
            this.setState({ saveBtnDisabled: false });
    }

    render() {
        const REG: { [key: string]: string } = this.state.regions.reduce((map: { [key: string]: string }, region: Region) => {
            map[region.codeRegion] = region.regionName;
            return map;
        }, {});

        const regions: JSX.Element[] = Object.keys(REG).map((codeRegion: string) => {
            return <option key={codeRegion} value={codeRegion}>{i18n.t(REG[codeRegion])}</option>;
        });

        const OFFI: { [key: string]: string } = this.state.offices.reduce((map: { [key: string]: string }, office: BranchOffice) => {
            map[office.code] = office.name;
            return map;
        }, {});

        const branchOffices: JSX.Element[] = Object.keys(OFFI).map((codeOffice: string) => {
            return <option key={codeOffice} value={codeOffice}>{i18n.t(OFFI[codeOffice])}</option>;
        });

        return (
            <div>
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('branch-office-config')}</div>
                        </div>
                    </div>
                    <div className="card-body">

                        {this.state.showSelectRegionAndLocation && this.props.match.path !== OFFICE_CREATE_ROUTE &&
                            <div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('region')}:</label>
                                    <select className="form-control" onChange={this.handleRegion} value={this.state.region}>
                                        <option value="NONE">{i18n.t('option-select')}</option>
                                        {regions}
                                    </select>
                                </div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('branch-office')}:</label>
                                    <select className="form-control" onChange={this.handleBranchOffice} value={this.state.office}>
                                        <option value="NONE">{i18n.t('option-select')}</option>
                                        {branchOffices}
                                    </select>
                                </div>
                            </div>
                        }
                        {!this.state.showSelectRegionAndLocation &&
                            <div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('region')}:</label>
                                    <p className="form-control">{this.state.regionName}</p>
                                </div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('branch-office')}:</label>
                                    <p className="form-control">{this.state.officeName}</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('branch-office-info')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <form className="form-horizontal">
                            <div className="form-group">
                                <label className="col-sm-2 control-label">{i18n.t('code')}: </label>
                                <div className="col-sm-4">
                                    <input type="text" maxLength={4} disabled={this.props.match.path !== OFFICE_CREATE_ROUTE} name="code" className="form-control" id="code" onChange={this.handleInputCode} value={this.state.branchOffice.code} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-2 control-label">{i18n.t('name')}: </label>
                                <div className="col-sm-4">
                                    <input type="text" disabled={!this.props.havePermission(OFFICE_CHANGE_PERM)} name="name" className="form-control" id="name" onChange={this.handleInputName} value={this.state.branchOffice.name} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-2 control-label">{i18n.t('address')}: </label>
                                <div className="col-sm-4">
                                    <input type="text" disabled={!this.props.havePermission(OFFICE_CHANGE_PERM)} name="address" className="form-control" id="address" onChange={this.handleInputAddress} value={this.state.branchOffice.address} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-2 control-label">{i18n.t('region')}:</label>
                                <div className="col-sm-4">
                                    <select disabled={!this.props.havePermission(OFFICE_CHANGE_PERM)} className="form-control" onChange={this.handleRegion} value={this.state.branchOffice.region.codeRegion}>
                                        <option value="NONE">{i18n.t('option-select')}</option>
                                        {regions}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group" style={{ display: "none" }}>
                                <label className="col-sm-2 control-label">{i18n.t('status')}: </label>
                                <div className="col-sm-4">
                                    <label className="control-label">
                                        {this.state.branchOffice.enabled &&
                                            <span className="label label-success">{i18n.t('enabled')}</span>}
                                        {!this.state.branchOffice.enabled &&
                                            <span className="label label-danger">{i18n.t('disabled')}</span>}
                                    </label>
                                </div>
                            </div>
                            <div className="form-group" style={{ display: "none" }}>
                                <label className="col-sm-2 control-label">{i18n.t('manager')}: </label>
                                <div className="col-sm-4">
                                    <input type="text" disabled={!this.props.havePermission(OFFICE_CHANGE_PERM) || this.props.match.path === OFFICE_SHOW_ROUTE} onChange={this.handleInputManagerUser} name="managerUserName" className="form-control" id="managerUserName" value={this.state.branchOffice.managerUserName} />
                                </div>
                            </div>
                            <div className="form-group" style={{ display: "none" }}>
                                <label className="col-sm-2 control-label">{i18n.t('email')}: </label>
                                <div className="col-sm-4">
                                    <input type="text" disabled={!this.props.havePermission(OFFICE_MODIFY_PERM) || this.props.match.path === OFFICE_SHOW_ROUTE} onChange={this.handleInputNotificationMail} name="notificationMail" className="form-control" id="notificationMail" value={this.state.branchOffice.notificationMail} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {
                    this.state.branchOffice.stockConfig && <div className="card margin-card-top">
                        <div className="card-header">
                            <div className="card-title">
                                <div className="title">{i18n.t('stock-config')}</div>
                            </div>
                        </div>
                        <div className="card-body">
                            <BootstrapTable data={this.state.branchOffice.stockConfig}
                                bordered={true}
                                cellEdit={this.props.havePermission(DEVICE_TYPE_CONF_PERM) ? {
                                    mode: 'click',
                                    blurToSave: true,
                                    beforeSaveCell: (row, cellName, cellValue) => {
                                        const originalValue = row[cellName];
                                        const number = parseFloat(cellValue);
                                        if (isNaN(number) || number < 0) {
                                            this.props.setMessage({ message: i18n.t('positive-number-required'), type: MESSAGE_WARNING });
                                            return false;
                                        }
                                        if (number === parseFloat(originalValue)) {
                                            return false;
                                        }
                                        this.enableSaveBtn();
                                        return true;
                                    }
                                } : undefined}
                                options={this.state.tableOptions}>
                                <TableHeaderColumn dataField='deviceType' dataFormat={this.printDeviceType} dataSort={true} isKey={true}>{i18n.t('device')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='minStock' dataAlign={"center"} dataSort={true}>{i18n.t('stock-min')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='defAperture' dataAlign={"center"} dataSort={true}>{i18n.t('stock-config-aperture')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='available' dataAlign={"center"} dataSort={true} dataFormat={activeFormatter} editable={false}>{i18n.t('active')}</TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                    </div>
                }
                <div className="card margin-card-top">
                    <div className="card-body">
                        <div className="col-sm-12">
                            {this.props.match.path === OFFICE_CREATE_ROUTE &&
                                <button type="button" onClick={this.create} className="btn btn-info pull-right" >
                                    {i18n.t('create')}
                                </button>}
                            {this.props.havePermission(OFFICE_MODIFY_PERM) && this.props.match.path !== OFFICE_CREATE_ROUTE &&
                                <button type="button" onClick={this.update} className="btn btn-info pull-right" disabled={this.state.saveBtnDisabled}>
                                    {i18n.t('save')}
                                </button>}
                        </div>
                        <br /><br />
                    </div>
                </div>
            </div >
        )
    }
}

export const FormOffice = withAppContext(FormOfficeMain);