import * as React from "react";
import i18n from "../../i18n";
import { RouteComponentProps } from "react-router";
import Modal from 'react-modal';
import { AppContextProps, withAppContext } from "../../context/AppContext";
import { BootstrapTable, TableHeaderColumn, ExportCSVButton } from "react-bootstrap-table";
import { Region } from "../../interfaces/Movement";
import { BranchOffice } from "../../interfaces/Office";
import { getBranchOfficeList, getRegionList } from "../../services/Stock";
import { ResponseError, apiHandleErrorCode } from "../../utils/ApiBaseConfig";
import { StockSummary } from "../../interfaces/Stock";
import { addStock, getCurrentStock, getFileLoadStock, reduceStock, sendStock, uploadStockFile } from "../../services/StockManagement";
import { STOCK_MANAGEMENT_ADD_PERM, STOCK_MANAGEMENT_LOAD_PERM, STOCK_MANAGEMENT_REDUCE_PERM, STOCK_MANAGEMENT_SEND_PERM } from "../../const/Permission";
import { MESSAGE_SUCCESS } from "../../const/Message";
import { ExcelExportButton } from "../utils/ExportExcelButton";

export interface StockManagementProps extends RouteComponentProps, AppContextProps{ }
export interface StockManagementState {
    region: string;
    sendRegion: string;
    regionName: string;
    regions: Region[];
    sendRegions: Region[];
    office: string;
    sendOffice: string;
    officeName: string;
    offices: BranchOffice[];
    sendOffices: BranchOffice[];
    showSelectRegionAndLocation: boolean;
    stock: StockSummary[];
    selected: StockSummary | null;
    selectedCode: string;
    selectedQuantity: number;
    selectedName: string;
    addModalIsOpen: boolean;
    reduceModalIsOpen: boolean;
    sendModalIsOpen: boolean;
    loadModalIsOpen: boolean;
    quantity: number;
    description: string;
    source: string;
}

export class StockManagementMain extends React.Component<StockManagementProps, StockManagementState>{
    constructor(props: StockManagementProps) {
        super(props);

        this.handleRegion = this.handleRegion.bind(this);
        this.handleBranchOffice = this.handleBranchOffice.bind(this);
        this.handleSendRegion = this.handleSendRegion.bind(this);
        this.handleSendBranchOffice = this.handleSendBranchOffice.bind(this);
        this.getRegionsFromAPI = this.getRegionsFromAPI.bind(this);
        this.getOfficesFromAPI = this.getOfficesFromAPI.bind(this);
        this.getOfficesFromAPISend = this.getOfficesFromAPISend.bind(this);
        this.getStockFromAPI = this.getStockFromAPI.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.openAddModal = this.openAddModal.bind(this);
        this.openReduceModal = this.openReduceModal.bind(this);
        this.openSendModal = this.openSendModal.bind(this);
        this.openLoadModal = this.openLoadModal.bind(this);
        this.closeAddModal = this.closeAddModal.bind(this);
        this.closeReduceModal = this.closeReduceModal.bind(this);
        this.closeSendModal = this.closeSendModal.bind(this);
        this.closeLoadModal = this.closeLoadModal.bind(this);
        this.preventInvalidChars = this.preventInvalidChars.bind(this);
        this.handleQuantityChange = this.handleQuantityChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleFile = this.handleFile.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.addStockDeviceType = this.addStockDeviceType.bind(this);
        this.reduceStockDeviceType = this.reduceStockDeviceType.bind(this);
        this.sendStockDeviceType = this.sendStockDeviceType.bind(this);

        this.state = {
            region: "",
            sendRegion: "",
            regionName: "",
            regions: [],
            sendRegions: [],
            office: "",
            sendOffice: "",
            officeName: "",
            offices: [],
            sendOffices: [],
            showSelectRegionAndLocation: false,
            stock: [],
            selected: null,
            selectedQuantity: 0,
            selectedCode: "",
            selectedName: "",
            addModalIsOpen:false,
            reduceModalIsOpen: false,
            sendModalIsOpen: false,
            loadModalIsOpen: false,
            quantity: 0,
            description: "",
            source: ""
        };
    }

    componentDidMount() {
        if (this.props.authUser && this.props.authUser.branchOffice) {
            if (this.props.authUser.role.codeRole === "SUADM") {
                this.setState({ showSelectRegionAndLocation: true }, this.getRegionsFromAPI );
            }
            else {
                this.setState({
                    office: this.props.authUser.branchOffice.code,
                    officeName: this.props.authUser.branchOffice.name,
                    regionName: this.props.authUser.branchOffice.region.regionName
                }, this.getStockFromAPI );

                if(this.props.havePermission(STOCK_MANAGEMENT_SEND_PERM)){
                    this.getRegionsFromAPI();
                }
            }
        }
    }

    getRegionsFromAPI(): void {
        this.props.showLoading(true);
        getRegionList().then((regionArray: Region[]) => {
            this.setState({ regions: regionArray, sendRegions: regionArray }, () => {
                this.props.showLoading(false);
            });
        })

            .catch((response: ResponseError) => {
                this.props.showLoading(false);
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
    }

    getOfficesFromAPI(): void {
        if (this.state.region !== "" && this.state.region !== "NONE") {
            this.props.showLoading(true);
            getBranchOfficeList(this.state.region).then((officeArray: BranchOffice[]) => {
                this.setState({ offices: officeArray }, () => {
                    this.props.showLoading(false);
                });
            })
                .catch((response: ResponseError) => {
                    this.props.showLoading(false);
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                });
        }
    }

    getOfficesFromAPISend(): void {
        if (this.state.sendRegion !== "" && this.state.sendRegion !== "NONE") {
            this.props.showLoading(true);
            getBranchOfficeList(this.state.sendRegion).then((officeArray: BranchOffice[]) => {
                if (this.state.office){
                    let id = this.state.office;
                    const index = officeArray.findIndex(item => item.code === id);
                    if (index !== -1) {
                        officeArray.splice(index, 1);
                    }
                }
                this.setState({ sendOffices: officeArray }, () => {
                    this.props.showLoading(false);
                });
            })
                .catch((response: ResponseError) => {
                    this.props.showLoading(false);
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                });
        }
    }

    getStockFromAPI(): void {
        this.props.showLoading(true);
        getCurrentStock(this.state.office).then((stock: StockSummary[]) => {
            this.setState({ stock: stock  }, () => {
                this.props.showLoading(false);
            });
        })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    handleRegion(e: any) {
        e.preventDefault();
        const regionSelected = e.target.value;
        this.setState({ region: regionSelected }, this.getOfficesFromAPI );
    }

    handleBranchOffice(e: any) {
        e.preventDefault();
        const officeSelected = e.target.value;
        if (officeSelected !== "" && officeSelected !== "NONE") {
            this.setState({ office: officeSelected, stock: [] }, this.getStockFromAPI);
        } else {
            this.setState({ office: officeSelected, stock: [] });
        }
        if(this.props.havePermission(STOCK_MANAGEMENT_SEND_PERM)){
            this.getOfficesFromAPISend();
        }
    }

    handleSendRegion(e: any) {
        e.preventDefault();
        const regionSelected = e.target.value;
        this.setState({ sendRegion: regionSelected }, this.getOfficesFromAPISend );
    }

    handleSendBranchOffice(e: any) {
        e.preventDefault();
        const officeSelected = e.target.value;
        this.setState({ sendOffice: officeSelected });
    }

    onSelect(selected: StockSummary) {
        this.setState({ selected: selected, selectedName: selected.deviceTypeName, selectedCode: selected.deviceTypeCode, selectedQuantity: selected.currentStock });
    }

    downloadFile(){
        this.props.showLoading(true);
        getFileLoadStock(this.state.office).then(() => {
            this.props.showLoading(false);
        })
        .catch((response: ResponseError) => {
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
            this.props.showLoading(false);
        });
    }

    uploadFile(){
        this.closeLoadModal();
        this.props.showLoading(true);
        uploadStockFile(this.state.source, this.state.office).then(() => {
            this.props.showLoading(false);
            this.props.setMessage({ message: i18n.t('stock-management-success-load'), type: MESSAGE_SUCCESS });
            this.getStockFromAPI();
        })
        .catch((response: ResponseError) => {
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
            this.props.showLoading(false);
        });
    }

    addStockDeviceType(){
        this.closeAddModal();
        this.props.showLoading(true);
        addStock(this.state.quantity, this.state.office, this.state.selectedCode).then(() => {
            this.props.showLoading(false);
            this.props.setMessage({ message: i18n.t('stock-management-success-add'), type: MESSAGE_SUCCESS });
            this.getStockFromAPI();
        })
        .catch((response: ResponseError) => {
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
            this.props.showLoading(false);
        });
    }

    reduceStockDeviceType(){
        this.closeReduceModal();
        this.props.showLoading(true);
        reduceStock(this.state.quantity, this.state.office, this.state.selectedCode, this.state.description).then(() => {
            this.props.showLoading(false);
            if (this.props.authUser) {
                if (this.props.authUser.role.codeRole != "SUCSP" && this.props.authUser.role.codeRole != "CENSP" && this.props.authUser.role.codeRole != "SUADM") {

                    this.props.setMessage({ message: i18n.t('stock-management-success-reduce-aprove'), type: MESSAGE_SUCCESS });
                }
                else {
                    this.props.setMessage({ message: i18n.t('stock-management-success-reduce'), type: MESSAGE_SUCCESS });
                }
            }
            this.getStockFromAPI();
        })
        .catch((response: ResponseError) => {
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
            this.props.showLoading(false);
        });
    }

    sendStockDeviceType(){
        this.closeSendModal();
        this.props.showLoading(true);
        sendStock(this.state.quantity, this.state.office, this.state.sendOffice, this.state.selectedCode).then(() => {
            this.props.showLoading(false);
            this.props.setMessage({ message: i18n.t('stock-management-success-send'), type: MESSAGE_SUCCESS });
            this.getStockFromAPI();
        })
        .catch((response: ResponseError) => {
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
            this.props.showLoading(false);
        });
    }

    openAddModal(){
        if(this.state.selected !== null)
            this.setState({ addModalIsOpen: true, quantity: 0 });
    }

    openReduceModal(){
        if(this.state.selected !== null)
            this.setState({ reduceModalIsOpen: true, quantity: 0, description: "" });
    }

    openSendModal(){
        if(this.state.selected !== null)
            this.setState({ sendModalIsOpen: true, quantity: 0 });
    }

    openLoadModal(){
        if(this.state.office !== "" && this.state.office !== "NONE")
            this.setState({ loadModalIsOpen: true, source: "" });
    }

    closeAddModal(){
        this.setState({ addModalIsOpen: false });
    }

    closeReduceModal(){
        this.setState({ reduceModalIsOpen: false });
    }

    closeSendModal(){
        this.setState({ sendModalIsOpen: false });
    }

    closeLoadModal(){
        this.setState({ loadModalIsOpen: false });
    }

    preventInvalidChars(e: any) {
        // Prevenir la entrada de "e" y "E"
        if (e.key === 'e' || e.key === 'E' || e.key === '-') {
            e.preventDefault();
        }
    }

    handleQuantityChange(e: any){
        e.preventDefault();
        this.setState({ quantity: e.target.value });
    }

    handleDescriptionChange(e: any){
        e.preventDefault();
        this.setState({ description: e.target.value });
    }

    handleFile(e: any) {
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64String = reader.result as string;
                const base64 = base64String.split(',')[1];
                this.setState({ source: base64 });
            };
        } else {
            this.setState({ source: "" });
        }
    }

    render() {
        const customStyles: any = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '40%'
            }
        };

        const REG: { [key: string]: string } = this.state.regions.reduce((map: { [key: string]: string }, region: Region) => {
            map[region.codeRegion] = region.regionName;
            return map;
        }, {});

        const REG_SEND: { [key: string]: string } = this.state.sendRegions.reduce((map: { [key: string]: string }, region: Region) => {
            map[region.codeRegion] = region.regionName;
            return map;
        }, {});

        const regions: JSX.Element[] = Object.keys(REG).map((codeRegion: string) => {
            return <option key={codeRegion} value={codeRegion}>{i18n.t(REG[codeRegion])}</option>;
        });

        const sendRegions: JSX.Element[] = Object.keys(REG_SEND).map((codeRegion: string) => {
            return <option key={codeRegion} value={codeRegion}>{i18n.t(REG_SEND[codeRegion])}</option>;
        });

        const OFFI: { [key: string]: string } = this.state.offices.reduce((map: { [key: string]: string }, office: BranchOffice) => {
            map[office.code] = office.name;
            return map;
        }, {});

        const OFFI_SEND: { [key: string]: string } = this.state.sendOffices.reduce((map: { [key: string]: string }, office: BranchOffice) => {
            map[office.code] = office.name;
            return map;
        }, {});

        const branchOffices: JSX.Element[] = Object.keys(OFFI).map((codeOffice: string) => {
            return <option key={codeOffice} value={codeOffice}>{i18n.t(OFFI[codeOffice])}</option>;
        });

        const sendBranchOffices: JSX.Element[] = Object.keys(OFFI_SEND).map((codeOffice: string) => {
            return <option key={codeOffice} value={codeOffice}>{i18n.t(OFFI_SEND[codeOffice])}</option>;
        });

        const options = {
            noDataText: i18n.t('table-empty'),
        };

        const columns = [
            { dataField: 'deviceTypeCode', text: i18n.t('code') },
            { dataField: 'deviceTypeName', text: i18n.t('device-type') },
            { dataField: 'currentStock', text: i18n.t('quantity') }
        ];

        return (
            <>
            <div className="panel-body">
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('stock-management-title')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        {this.state.showSelectRegionAndLocation &&
                            <div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('region')}:</label>
                                    <select className="form-control" onChange={this.handleRegion} value={this.state.region}>
                                        <option value="NONE">{i18n.t('option-select')}</option>
                                        {regions}
                                    </select>
                                </div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('branch-office')}:</label>
                                    <select className="form-control" onChange={this.handleBranchOffice} value={this.state.office}>
                                        <option value="NONE">{i18n.t('option-select')}</option>
                                        {branchOffices}
                                    </select>
                                </div>
                            </div>
                        }
                        {!this.state.showSelectRegionAndLocation &&
                            <div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('region')}:</label>
                                    <p className="form-control">{this.state.regionName}</p>
                                </div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('branch-office')}:</label>
                                    <p className="form-control">{this.state.officeName}</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="form-group col-sm-12">
                            {this.props.havePermission(STOCK_MANAGEMENT_ADD_PERM) && 
                                <button className="btn btn-info" onClick={() => this.openAddModal()} disabled={this.state.selected === null}>
                                    {i18n.t('stock-management-button-add')} <span data-toggle="tooltip" data-placement="top" title={i18n.t('stock-management-tooltip-add')}><i className='glyphicon glyphicon-info-sign'></i></span>
                                </button>
                            }
                            {this.props.havePermission(STOCK_MANAGEMENT_REDUCE_PERM) && 
                                <button className="btn btn-warning" onClick={() => this.openReduceModal()} disabled={this.state.selected === null}>
                                    {i18n.t('stock-management-button-reduce')} <span data-toggle="tooltip" data-placement="top" title={i18n.t('stock-management-tooltip-reduce')}><i className='glyphicon glyphicon-info-sign'></i></span>
                                </button>
                            }
                            {this.props.havePermission(STOCK_MANAGEMENT_SEND_PERM) && 
                                <button className="btn btn-success" onClick={() => this.openSendModal()} disabled={this.state.selected === null}>
                                    {i18n.t('stock-management-button-send')} <span data-toggle="tooltip" data-placement="top" title={i18n.t('stock-management-tooltip-send')}><i className='glyphicon glyphicon-info-sign'></i></span>
                                </button>
                            }
                            {this.props.havePermission(STOCK_MANAGEMENT_LOAD_PERM) && 
                                <button className="btn btn-default pull-right" onClick={() => this.openLoadModal()} disabled={this.state.office === "" || this.state.office === "NONE"}>
                                    {i18n.t('stock-management-button-load')} <span data-toggle="tooltip" data-placement="top" title={i18n.t('stock-management-tooltip-load')}><i className='glyphicon glyphicon-info-sign'></i></span>
                                </button>
                            }
                            <ExcelExportButton data={this.state.stock} columns={columns} filename="current_stock.xlsx" clazz="btn btn-success pull-right" />

                            <BootstrapTable 
                                data={this.state.stock}
                                selectRow={{ mode: 'radio', onSelect: this.onSelect }}
                                options={options}
                                bordered hover pagination={this.state.stock.length > 0}
                                >
                                <TableHeaderColumn dataField='id' isKey={true} hidden={true}></TableHeaderColumn>
                                <TableHeaderColumn dataField='deviceTypeCode' dataSort={true} filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} width="180" >{i18n.t('code')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='deviceTypeName' dataSort={true} filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} >{i18n.t('device-type')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='currentStock' dataAlign="right" width="300" >{i18n.t('quantity')}</TableHeaderColumn>             
                            </BootstrapTable>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={this.state.addModalIsOpen}
                onRequestClose={this.closeAddModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <h2>{i18n.t('stock-management-modal-title-add')}</h2>
                <div className="card-body">
                    <div className="form-group">
                        <label>{i18n.t('device')}:</label>
                        <input className="form-control" value={this.state.selectedName} disabled></input>
                    </div>
                    <div className="form-group">
                        <label>{i18n.t('quantity')}:</label>
                        <input autoComplete="new-password" 
                               type="number" 
                               className="form-control" 
                               value={ this.state.quantity } 
                               onChange={this.handleQuantityChange}
                               onKeyDown={this.preventInvalidChars} />
                    </div>
                    <button className="btn btn-default pull-left" onClick={this.closeAddModal}>{i18n.t('close')}</button>
                    <button className="btn btn-info pull-right" onClick={this.addStockDeviceType} disabled={this.state.quantity <= 0}>{i18n.t('stock-management-button-add')}</button>
                </div>
            </Modal>
            <Modal
                isOpen={this.state.reduceModalIsOpen}
                onRequestClose={this.closeReduceModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <h2>{i18n.t('stock-management-modal-title-reduce')}</h2>
                <div className="card-body">
                    <div className="form-group">
                        <label>{i18n.t('device')}:</label>
                        <input className="form-control" value={this.state.selectedName} disabled></input>
                    </div>
                    <div className="form-group">
                        <label>{i18n.t('quantity')}:</label>
                        <input autoComplete="new-password" 
                               type="number" 
                               className="form-control" 
                               value={ this.state.quantity } 
                               onChange={this.handleQuantityChange}
                               onKeyDown={this.preventInvalidChars} />
                    </div>
                    <div className="form-group">
                            <label>{i18n.t('comment-required')}:</label>
                            <textarea
                                name="description"
                                maxLength={250}
                                value={this.state.description}
                                onChange={this.handleDescriptionChange}
                                className="form-control"
                            />
                        </div>
                    <button className="btn btn-default pull-left" onClick={this.closeReduceModal}>{i18n.t('close')}</button>
                    <button className="btn btn-info pull-right" onClick={this.reduceStockDeviceType} disabled={this.state.quantity <= 0 || this.state.quantity > this.state.selectedQuantity || this.state.description.trim().length === 0}>{i18n.t('stock-management-button-reduce')}</button>
                </div>
            </Modal>
            <Modal
                isOpen={this.state.sendModalIsOpen}
                onRequestClose={this.closeSendModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <h2>{i18n.t('stock-management-modal-title-send')}</h2>
                <div className="card-body">
                    <div className="form-group">
                        <label>{i18n.t('device')}:</label>
                        <input className="form-control" value={this.state.selectedName} disabled></input>
                    </div>
                    <div className="form-group">
                        <label>{i18n.t('quantity')}:</label>
                        <input autoComplete="new-password" 
                               type="number" 
                               className="form-control" 
                               value={ this.state.quantity } 
                               onChange={this.handleQuantityChange}
                               onKeyDown={this.preventInvalidChars} />
                    </div>
                    <div className="form-group">
                        <label>{i18n.t('region')}:</label>
                        <select className="form-control" onChange={this.handleSendRegion} value={this.state.sendRegion}>
                            <option value="NONE">{i18n.t('option-select')}</option>
                            {sendRegions}
                        </select>
                    </div>
                    <div className="form-group">
                        <label>{i18n.t('branch-office')}:</label>
                        <select className="form-control" onChange={this.handleSendBranchOffice} value={this.state.sendOffice}>
                            <option value="NONE">{i18n.t('option-select')}</option>
                            {sendBranchOffices}
                        </select>
                    </div>
                    <button className="btn btn-default pull-left" onClick={this.closeSendModal}>{i18n.t('close')}</button>
                    <button className="btn btn-info pull-right" onClick={this.sendStockDeviceType} disabled={this.state.quantity <= 0 || this.state.quantity > this.state.selectedQuantity || this.state.sendOffice === "NONE"}>{i18n.t('stock-management-button-send')}</button>
                </div>
            </Modal>
            <Modal
                isOpen={this.state.loadModalIsOpen}
                onRequestClose={this.closeLoadModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <h2>{i18n.t('stock-management-modal-title-load')}</h2>
                <div className="card-body">
                    <p>{i18n.t('stock-management-load-desc')}<button className="btn-link" onClick={this.downloadFile}>{i18n.t('stock-management-load-desc-clic')}</button>.</p>
                    <div className="form-group">
                        <label className="control-label">{i18n.t('file-select')}: </label>
                        <input accept=".xlsx" type="file" className="form-control-file" onChange={this.handleFile} />
                    </div>
                    <button className="btn btn-default pull-left" onClick={this.closeLoadModal}>{i18n.t('close')}</button>
                    <button className="btn btn-info pull-right" onClick={this.uploadFile} disabled={this.state.source === ""}>{i18n.t('stock-management-button-load')}</button>
                </div>
            </Modal>
            </>
        )    
    }
}

export const StockManagement = withAppContext(StockManagementMain);