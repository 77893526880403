import * as React from "react";
import i18n from "../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../context/AppContext";
import Select from "react-select";
import Modal from 'react-modal';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { MovementType, Region } from "../../interfaces/Movement";
import { BranchOffice } from "../../interfaces/Office";
import { StockManagementAction } from "../../interfaces/Stock";
import { getBranchOfficeList, getRegionList } from "../../services/Stock";
import { apiHandleErrorCode, ResponseError } from "../../utils/ApiBaseConfig";
import { pendingActions, resolveAction } from "../../services/StockManagement";
import { dateFormatter } from "../../utils/Formatter";
import { MESSAGE_SUCCESS } from "../../const/Message";

export interface PendingStockManagementProps extends RouteComponentProps, AppContextProps{ }
export interface PendingStockManagementState {
    region: string;
    regionName: string;
    regions: Region[];
    office: string;
    officeName: string;
    offices: BranchOffice[];
    blockRegionAndLocation: boolean;
    actions: StockManagementAction[];
}

export class PendingStockManagementMain extends React.Component<PendingStockManagementProps, PendingStockManagementState>{
    constructor(props: PendingStockManagementProps) {
        super(props);
        this.handleRegion = this.handleRegion.bind(this);
        this.handleBranchOffice = this.handleBranchOffice.bind(this);
        this.getRegionsFromAPI = this.getRegionsFromAPI.bind(this);
        this.getOfficesFromAPI = this.getOfficesFromAPI.bind(this);
        this.findPendingsFromAPI = this.findPendingsFromAPI.bind(this);
        this.resolvePendingInAPI = this.resolvePendingInAPI.bind(this);
        this.state = {
            region: "",
            regionName: "",
            regions: [],
            office: "",
            officeName: "",
            offices: [],
            blockRegionAndLocation: true,
            actions: [],
        }
    }

    componentDidMount() {
        if(this.props.authUser && this.props.authUser.branchOffice) {
            if (this.props.authUser.role.codeRole === "SUADM") {
                this.setState({ blockRegionAndLocation: false }, () => { 
                    this.getRegionsFromAPI() 
                });
            } else { 
                this.setState({
                    office: this.props.authUser.branchOffice.code, 
                    officeName: this.props.authUser.branchOffice.name, 
                    regionName: this.props.authUser.branchOffice.region.regionName
                }, () => { 
                    this.findPendingsFromAPI();
                });
            }
        }
    }

    getRegionsFromAPI(): void {
        this.props.showLoading(true);
        getRegionList().then((regionArray: Region[]) =>{
            this.setState({ regions: regionArray }, () => {
                this.props.showLoading(false);
            });
        })
        .catch((response: ResponseError) => {
            this.props.showLoading(false);
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
        });
    }

    getOfficesFromAPI(): void {
        this.props.showLoading(true);
        if(this.state.region !== "" && this.state.region !== "NONE") {
            getBranchOfficeList(this.state.region).then((officeArray: BranchOffice[]) =>{
                this.setState({ offices: officeArray }, () => {
                    this.props.showLoading(false);
                });
            })
            .catch((response: ResponseError) => {
                this.props.showLoading(false);
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
        }
    }

    findPendingsFromAPI(): void {
        this.props.showLoading(true);
        pendingActions(this.state.office)
        .then((actions: StockManagementAction[]) => {
            this.setState({ actions }, () => {
                this.props.showLoading(false);
            });
        })
        .catch((response: ResponseError) => {
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
            this.props.showLoading(false);
        });
    }

    handleRegion(e: any) {
        e.preventDefault();
        const regionSelected = e.target.value;
        this.setState({ region: regionSelected }, this.getOfficesFromAPI);
    }

    handleBranchOffice(e: any) {
        e.preventDefault();
        const officeSelected = e.target.value;
        this.setState({ office: officeSelected }, this.findPendingsFromAPI);
    }

    resolvePendingInAPI(record: any, option: boolean) {
        this.props.showLoading(true);
        resolveAction(record.correlative, option)
        .then( () => {
            if(option) { 
                this.props.setMessage({ message: i18n.t('stock-management-pending-approve-success'), type: MESSAGE_SUCCESS });
            } else { 
                this.props.setMessage({ message: i18n.t('stock-management-pending-reject-success'), type: MESSAGE_SUCCESS });
            }
            this.props.showLoading(false);
            this.findPendingsFromAPI();
        })
        .catch((response: ResponseError) => {
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
            this.props.showLoading(false);
        });
    }

    render() {
        const REG : { [key: string]: string } = this.state.regions.reduce((map: { [key: string]: string }, region: Region) => {
            map[region.codeRegion] = region.regionName;
            return map;
        }, {});

        const regions: JSX.Element[] = Object.keys(REG).map((codeRegion: string) => {
            return <option key={codeRegion} value={codeRegion}>{i18n.t(REG[codeRegion])}</option>;
        });
        
        const OFFI : { [key: string]: string } = this.state.offices.reduce((map: { [key: string]: string }, office: BranchOffice) => {
            map[office.code] = office.name;
            return map;
        }, {});

        const branchOffices: JSX.Element[] = Object.keys(OFFI).map((codeOffice: string) => {
            return <option key={codeOffice} value={codeOffice}>{i18n.t(OFFI[codeOffice])}</option>;
        });

        const options = {
            noDataText: i18n.t('table-empty'),
        };

        return (
            <>
                <div className="panel-body">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <div className="title">{i18n.t('stock-management-pending')}</div>
                            </div>
                        </div>
                        <div className="card-body">
                            {!this.state.blockRegionAndLocation &&
                            <div className="col-sm-12">
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('region')}:</label>
                                    <select className="form-control" onChange={this.handleRegion} value={this.state.region}>
                                        <option value="NONE">{i18n.t('option-select')}</option>
                                        {regions}
                                    </select>
                                </div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('branch-office')}:</label>
                                    <select className="form-control" onChange={this.handleBranchOffice} value={this.state.office}>
                                        <option value="NONE">{i18n.t('option-select')}</option>
                                        {branchOffices}
                                    </select>
                                </div>
                            </div>
                            }
                            {this.state.blockRegionAndLocation &&
                            <div className="col-sm-12">
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('region')}:</label>
                                    <p className="form-control">{this.state.regionName}</p>
                                </div>

                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('branch-office')}:</label>
                                    <p className="form-control">{this.state.officeName}</p>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <div className="title">{i18n.t('stock-management-pending-subtitle')}</div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="form-group col-sm-12">
                                <BootstrapTable 
                                    data={this.state.actions}
                                    options={options}
                                    bordered hover pagination={this.state.actions.length > 0}
                                    >
                                    <TableHeaderColumn dataField='correlative' isKey={true} hidden={true}></TableHeaderColumn>
                                    <TableHeaderColumn dataField='deviceTypeCode' dataSort={true} filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} width="180" >{i18n.t('code')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='deviceTypeName' dataSort={true} filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} >{i18n.t('device-type')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='quantity' dataAlign="right" width="100">{i18n.t('quantity')}</TableHeaderColumn>             
                                    <TableHeaderColumn dataField='userName' dataSort={true} filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} >{i18n.t('user')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='actionDate' dataFormat={dateFormatter} dataSort={true} dataAlign={'right'} width="150">{i18n.t('date')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='message' tdStyle={ { whiteSpace: 'normal', wordWrap: 'break-word'} }>{i18n.t('comment')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='' width="150" tdStyle={{ textAlign: 'center' }} dataFormat={(cell, row) => (
                                        <>
                                        <button className="btn btn-success" onClick={() => this.resolvePendingInAPI(row, true)}>
                                            <span data-toggle="tooltip" data-placement="top" title={i18n.t('stock-management-pending-approve')}><i className='glyphicon glyphicon-ok'></i></span>
                                        </button>
                                        <button className="btn btn-danger" onClick={() => this.resolvePendingInAPI(row, false)}>
                                            <span data-toggle="tooltip" data-placement="top" title={i18n.t('stock-management-pending-reject')}><i className='glyphicon glyphicon-remove'></i></span>
                                        </button>
                                        </>
                                    )}>{i18n.t('actions')}</TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export const PendingStockManagement = withAppContext(PendingStockManagementMain);