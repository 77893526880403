import { MovementsFromAPI, Movements, SearchMovement, Movement, MovementToAPI, MovementType, MovementToAPICW } from "../interfaces/Movement";
import i18n from "../i18n";
import { helperAxios, apiErrorCallback, helperAxiosCW, WIZARD_USER, WIZARD_PASS, CARD_STOCK_ID, GROUP_ID } from "../utils/ApiBaseConfig";
import { API_PREFIX, CW_API_PREFIX } from "../const/Routes";
import { AxiosResponse } from "axios";

export const MOVEMENTS_AMOUNT_PER_REQUEST = 300;

export function parseMovements(movementsFromAPI: MovementsFromAPI): Movements {
    return {
        regionCode: movementsFromAPI.regionCode,
        regionName: movementsFromAPI.regionName,
        officeCode: movementsFromAPI.branchOfficeCode,
        officeName: movementsFromAPI.branchOfficeName,
        movementTypeCode: movementsFromAPI.movementTypeCode,
        movementTypeDescription: movementsFromAPI.movementTypeDescription,
        movementDate: movementsFromAPI.movementDate ? new Date(movementsFromAPI.movementDate) : i18n.t('no-data'),
        movementQuantity: movementsFromAPI.movementQuantity,
        movementDescription: movementsFromAPI.movementDescription,
        deviceTypeCode: movementsFromAPI.deviceTypeCode,
        deviceTypeName: movementsFromAPI.deviceTypeName,
        userCode: movementsFromAPI.userCode,
        userName: movementsFromAPI.userName,
    }
}

export function findMovements(searchMovement: SearchMovement): Promise<Movements[]> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/movements/find',
        data: {
            ...searchMovement,
            maxResults: searchMovement.maxResults || MOVEMENTS_AMOUNT_PER_REQUEST
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] findMovements');
            return response.data.map(parseMovements);
        })
        .catch(apiErrorCallback);
}

export function sendMovementList(movementList: Movement[]): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/movements/create',
        data: movementList.map(parseMovemenToAPI)
    })
        .then(() => {
            console.log('[OK] sendMovementList');
            return true;
        })
        .catch(apiErrorCallback);
}

export function sendMovementListCW(movementList: Movement[]): Promise<boolean> {
    console.log('[INIT] sendMovementListCW');
    //TODO: cambiar datos en duro, conexión dummy con card wizard
    return helperAxiosCW({
        method: 'POST', url: CW_API_PREFIX + '/OAuthTokens/', data: { 'userName': WIZARD_USER, 'password': WIZARD_PASS, 'iscwUser': true }
    }).then((response: AxiosResponse) => {
        console.log('[OK] LOG IN !');
        return helperAxiosCW({
            method: 'POST', url: CW_API_PREFIX + '/CardStocks/Activities/',
            data: movementList.map(parseMovemenToAPICW)
        })
            .then((response: AxiosResponse) => {
                console.log('[OK] sendMovementListCW');
                return true;
            })
            .catch(apiErrorCallback);
    })
        .catch(apiErrorCallback);
}

export function parseMovemenToAPI(movement: Movement): MovementToAPI {
    return {
        branchOffice: { code: movement.office },
        stockMovementType: { stockMovementCode: movement.movement, stockMovementDescription: "" },
        movementDate: Date.now().toString(),
        movementQuantity: movement.cards,
        movementDescription: movement.comment,
        deviceType: { code: movement.stockId, name: "", available: true }
    }
}

export function parseMovemenToAPICW(movement: Movement): MovementToAPICW {
    //CAMBIAR LOS DATOS EN DURO, 2 == VTOD, 3 == DTOV
    var activity: number = -1;
    switch(movement.movement) {
        case "VTOD":
            activity = 2;
            break;
        case "DTOV":
            activity = 3;
            break;
        case "OTOV":
            activity = 0;
            break;
    }
    return {
        ID: CARD_STOCK_ID,
        PCGroupID: GROUP_ID,
        InventoryActivity: activity,
        NumberOfCards: "" + movement.cards,
        Comment: movement.comment
    }
}

export function getMovementTypeList(): Promise<MovementType[]> {
    return helperAxios(API_PREFIX + '/movements/movementTypes')
        .then((response: AxiosResponse) => {
            console.log('[OK] getMovementTypeList');
            return response.data;
        })
        .catch(apiErrorCallback);
}

export function findMovementsOfBalance(balanceCorrelative: number) : Promise<Movements[]>{
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/balance/movements/' + balanceCorrelative,
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] findMovementsOfBalance');
            return response.data.map(parseMovements);
        })
        .catch(apiErrorCallback);
}
