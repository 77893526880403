import * as React from "react";
import i18n from "../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../context/AppContext";
import { MovementType, Region } from "../../interfaces/Movement";
import { BranchOffice } from "../../interfaces/Office";
import { DeviceType } from "../../interfaces/DeviceType";
import { getDeviceTypeList } from "../../services/DeviceType";
import { getBranchOfficeList, getRegionList } from "../../services/Stock";
import { apiHandleErrorCode, ResponseError } from "../../utils/ApiBaseConfig";
import { findActions } from "../../services/StockManagement";
import { StockManagementAction } from "../../interfaces/Stock";
import Select from "react-select";
import Modal from 'react-modal';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { dateFormatter, stockManagementActionFormatter } from "../../utils/Formatter";
import { ExcelExportButton } from "../utils/ExportExcelButton";

export interface FindStockManagementProps extends RouteComponentProps, AppContextProps{ }
export interface FindStockManagementState {
    region: string;
    regionName: string;
    regions: Region[];
    office: string;
    officeName: string;
    offices: BranchOffice[];
    deviceType: string;
    deviceTypes: DeviceType[];
    actionType: string;
    actionTypes: MovementType[];
    dateFrom: string;
    dateTo: string;
    blockRegionAndLocation: boolean;
    actions: StockManagementAction[];
    detailModalIsOpen: boolean;
    detailUser: string;
    detailMessage: string;
}

export class FindStockManagementMain extends React.Component<FindStockManagementProps, FindStockManagementState>{
    constructor(props: FindStockManagementProps) {
        super(props);
        this.handleRegion = this.handleRegion.bind(this);
        this.handleBranchOffice = this.handleBranchOffice.bind(this);
        this.handleStock = this.handleStock.bind(this);
        this.handleFrom = this.handleFrom.bind(this);
        this.handleTo = this.handleTo.bind(this);
        this.handleActionType = this.handleActionType.bind(this);
        this.getRegionsFromAPI = this.getRegionsFromAPI.bind(this);
        this.getOfficesFromAPI = this.getOfficesFromAPI.bind(this);
        this.getDeviceTypesFromAPI = this.getDeviceTypesFromAPI.bind(this);
        this.findActionsFromAPI = this.findActionsFromAPI.bind(this);
        this.openDetailModal = this.openDetailModal.bind(this);
        this.closeDetailModal = this.closeDetailModal.bind(this);
        this.state = {
            region: "",
            regionName: "",
            regions: [],
            office: "",
            officeName: "",
            offices: [],
            deviceType: "",
            deviceTypes: [],
            actionType: "",
            actionTypes: [
                { stockMovementCode: "ADD", stockMovementDescription: i18n.t('stock-management-type-add') },
                { stockMovementCode: "REDUCE", stockMovementDescription: i18n.t('stock-management-type-reduce') },
                { stockMovementCode: "SEND", stockMovementDescription: i18n.t('stock-management-type-send') },
                { stockMovementCode: "CANCEL", stockMovementDescription: i18n.t('stock-management-type-cancel') },
            ],
            dateFrom: "",
            dateTo: "",
            blockRegionAndLocation: true,
            actions: [],
            detailModalIsOpen: false,
            detailUser: "",
            detailMessage: ""
        }
    }

    componentDidMount() {
        if(this.props.authUser && this.props.authUser.branchOffice) {
            //setear la fecha de  busqueda por defecto
            var curr = new Date();
            var date = curr.toISOString().substr(0, 10);
            curr.setDate(curr.getDate()+1);
            var tomorrow = curr.toISOString().substr(0, 10);

            if (this.props.authUser.role.codeRole === "SUADM") {
                this.setState({ blockRegionAndLocation: false, dateFrom: date, dateTo: tomorrow }, () => { 
                    this.getRegionsFromAPI() 
                    this.getDeviceTypesFromAPI();
                    this.findActionsFromAPI();
                });
            }
            else {
                this.setState({
                    office: this.props.authUser.branchOffice.code, 
                    officeName: this.props.authUser.branchOffice.name, 
                    regionName: this.props.authUser.branchOffice.region.regionName,
                    dateFrom: date, 
                    dateTo: tomorrow
                }, () => { 
                    this.getDeviceTypesFromAPI();
                    this.findActionsFromAPI();
                });
            }            
        }
    }

    openDetailModal(record: any) {
        this.setState({ 
            detailModalIsOpen: true, 
            detailUser: record.userName,
            detailMessage: record.message
         });
    }

    closeDetailModal() {
        this.setState({ detailModalIsOpen: false });
    }

    getRegionsFromAPI(): void {
        this.props.showLoading(true);
        getRegionList().then((regionArray: Region[]) =>{
            this.setState({ regions: regionArray }, () => {
                this.props.showLoading(false);
            });
        })
        .catch((response: ResponseError) => {
            this.props.showLoading(false);
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
        });
    }

    getOfficesFromAPI(): void {
        this.props.showLoading(true);
        if(this.state.region !== "" && this.state.region !== "NONE") {
            getBranchOfficeList(this.state.region).then((officeArray: BranchOffice[]) =>{
                this.setState({ offices: officeArray }, () => {
                    this.props.showLoading(false);
                });
            })
            .catch((response: ResponseError) => {
                this.props.showLoading(false);
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
        }
    }

    getDeviceTypesFromAPI(): void {
        this.props.showLoading(true);
        getDeviceTypeList().then((deviceTypeArray: DeviceType[]) =>{
            this.setState({ deviceTypes: deviceTypeArray }, () => {
                this.props.showLoading(false);
            });
        })
        .catch((response: ResponseError) => {
            this.props.showLoading(false);
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
        });
    }

    findActionsFromAPI(): void {
        this.props.showLoading(true);
        findActions({ 
            branchOfficeCode : this.state.office !== "NONE" ? this.state.office : undefined, 
            actionTypeCode: this.state.actionType !== "NONE" ? this.state.actionType : undefined,
            deviceTypeCode: this.state.deviceType !== "NONE" ? this.state.deviceType : undefined,
            dateFrom: this.state.dateFrom !== "" ? this.state.dateFrom : undefined,
            dateTo: this.state.dateTo !== "" ? this.state.dateTo : undefined
        })
        .then((actions: StockManagementAction[]) => {
            this.setState({ actions }, () => {
                this.props.showLoading(false);
            });
        })
        .catch((response: ResponseError) => {
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
            this.props.showLoading(false);
        });
    }

    handleRegion(e: any) {
        e.preventDefault();
        const regionSelected = e.target.value;
        this.setState({ region: regionSelected }, this.getOfficesFromAPI);
    }

    handleBranchOffice(e: any) {
        e.preventDefault();
        const officeSelected = e.target.value;
        this.setState({ office: officeSelected });
    }

    handleStock(deviceType: any) {
        this.setState({ deviceType: deviceType.value });
    }

    handleFrom(e: any) {
        e.preventDefault();
        const startDate = e.target.value;
        this.setState({ dateFrom :startDate });
    }

    handleTo(e: any) {
        e.preventDefault();
        const endDate = e.target.value;
        this.setState({ dateTo :endDate });
    }

    handleActionType(e: any) {
        e.preventDefault();
        const actionSelected = e.target.value;
        this.setState({ actionType: actionSelected });
    }

    render() {
        const REG : { [key: string]: string } = this.state.regions.reduce((map: { [key: string]: string }, region: Region) => {
            map[region.codeRegion] = region.regionName;
            return map;
        }, {});

        const regions: JSX.Element[] = Object.keys(REG).map((codeRegion: string) => {
            return <option key={codeRegion} value={codeRegion}>{i18n.t(REG[codeRegion])}</option>;
        });
        
        const OFFI : { [key: string]: string } = this.state.offices.reduce((map: { [key: string]: string }, office: BranchOffice) => {
            map[office.code] = office.name;
            return map;
        }, {});

        const branchOffices: JSX.Element[] = Object.keys(OFFI).map((codeOffice: string) => {
            return <option key={codeOffice} value={codeOffice}>{i18n.t(OFFI[codeOffice])}</option>;
        });

        const STOCK : { [key: string]: string } = this.state.deviceTypes.reduce((map: { [key: string]: string }, deviceType: DeviceType) => {
            map[deviceType.codeDeviceType] = deviceType.nameDeviceType;
            return map;
        }, {});

        const stocks : { label: string, value: string }[] = Object.keys(STOCK).map((codeStock: string) => { 
            return { label: i18n.t(STOCK[codeStock]), value: codeStock }
        });

        const MOVE: { [key: string]: string } = this.state.actionTypes.reduce((map: { [key: string]: string }, actionType: MovementType) => {
            map[actionType.stockMovementCode] = actionType.stockMovementDescription;
            return map;
        }, {})

        const movements: JSX.Element[] = Object.keys(MOVE).map((codeMovement: string) => {
            return <option key={codeMovement} value={codeMovement}>{i18n.t(MOVE[codeMovement])}</option>;
        });

        const options = {
            noDataText: i18n.t('table-empty'),
        };

        const customStyles: any = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '30%'
            }
        };

        const columns = [
            { dataField: 'deviceTypeCode', text: i18n.t('code') },
            { dataField: 'deviceTypeName', text: i18n.t('device-type') },
            { dataField: 'actionTypeDesc', text: i18n.t('stock-management-type')  },
            { dataField: 'quantity', text: i18n.t('quantity') },
            { dataField: 'originName', text: i18n.t('shipment-origin') },
            { dataField: 'destinyName', text: i18n.t('shipment-destiny') },
            { dataField: 'userName', text: i18n.t('user') },
            { dataField: 'actionDate', text: i18n.t('date') },
        ];

        const formatters = {
            actionTypeDesc: (cell: any, row: any) => stockManagementActionFormatter(cell, row),
            actionDate: (cell: any, row: any) => dateFormatter(cell, row)
          };

        return (
            <>
                <div className="panel-body">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <div className="title">{i18n.t('stock-management-changes')}</div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="form-group col-sm-12">
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('device-type')}:</label>
                                    <Select 
                                        options={stocks}
                                        isSearchable={true}
                                        onChange={this.handleStock}
                                        placeholder={i18n.t('option-select')}
                                    />
                                </div>
                                {!this.state.blockRegionAndLocation &&
                                <div>
                                    <div className="form-group col-sm-4">
                                        <label>{i18n.t('region')}:</label>
                                        <select className="form-control" onChange={this.handleRegion} value={this.state.region}>
                                            <option value="NONE">{i18n.t('option-select')}</option>
                                            {regions}
                                        </select>
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label>{i18n.t('branch-office')}:</label>
                                        <select className="form-control" onChange={this.handleBranchOffice} value={this.state.office}>
                                            <option value="NONE">{i18n.t('option-select')}</option>
                                            {branchOffices}
                                        </select>
                                    </div>
                                </div>
                                }
                                {this.state.blockRegionAndLocation &&
                                <div>
                                    <div className="form-group col-sm-4">
                                        <label>{i18n.t('region')}:</label>
                                        <p className="form-control">{this.state.regionName}</p>
                                    </div>

                                    <div className="form-group col-sm-4">
                                        <label>{i18n.t('branch-office')}:</label>
                                        <p className="form-control">{this.state.officeName}</p>
                                    </div>
                                </div>
                                }
                            </div>
                            <div className="form-group col-sm-12">
                                <div className="form-group col-sm-3">
                                    <label>{i18n.t('stock-management-type')}:</label>
                                    <select className="form-control" onChange={this.handleActionType} value={this.state.actionType}>
                                        <option value="NONE">{i18n.t('option-select')}</option>
                                        {movements}
                                    </select>
                                </div>
                                <div className="form-group col-sm-3">
                                    <label>{i18n.t('from')}:</label>
                                    <input type="date" name="from" className="form-control" id="from" value={this.state.dateFrom} onChange={this.handleFrom} />
                                </div>
                                <div className="form-group col-sm-3">
                                    <label>{i18n.t('to')}:</label>
                                    <input type="date" name="to" className="form-control" id="to" value={this.state.dateTo} onChange={this.handleTo} />
                                </div>
                                <div className="form-group col-sm-3">
                                    <br></br>
                                    <button type="button" className="btn btn-info" onClick={this.findActionsFromAPI}>{i18n.t('search')}</button>
                                </div>
                            </div>
                            <div className="form-group col-sm-12">
                                <ExcelExportButton 
                                    data={this.state.actions} 
                                    columns={columns} 
                                    filename="stock_actions.xlsx" 
                                    clazz="btn btn-success pull-right" 
                                    formatters={formatters}
                                    />
                            </div>
                            <div className="form-group col-sm-12">
                                <BootstrapTable 
                                    data={this.state.actions}
                                    options={options}
                                    bordered hover pagination={this.state.actions.length > 0}
                                    >
                                    <TableHeaderColumn dataField='correlative' isKey={true} hidden={true}></TableHeaderColumn>
                                    <TableHeaderColumn dataField='deviceTypeCode' dataSort={true} filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} width="150" >{i18n.t('code')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='deviceTypeName' dataSort={true} filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} >{i18n.t('device-type')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='actionTypeDesc' dataFormat={stockManagementActionFormatter} dataSort={true} filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} >{i18n.t('stock-management-type')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='quantity' dataAlign="right" width="100">{i18n.t('quantity')}</TableHeaderColumn>             
                                    <TableHeaderColumn dataField='originName' dataSort={true} filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} >{i18n.t('shipment-origin')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='destinyName' dataSort={true} filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} >{i18n.t('shipment-destiny')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='userName' dataSort={true} filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} >{i18n.t('user')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='actionDate' dataFormat={dateFormatter} dataSort={true} dataAlign={'right'}>{i18n.t('date')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='' tdStyle={{ textAlign: 'center' }} dataFormat={(cell, row) => (
                                        row.message && row.message.length > 0 ?
                                        <button onClick={() => this.openDetailModal(row)}>
                                            <span><i className='glyphicon glyphicon-search'></i></span>
                                        </button> : <></>
                                    )}>{i18n.t('details')}</TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.detailModalIsOpen}
                    onRequestClose={this.closeDetailModal}
                    style={customStyles}
                    ariaHideApp={false}
                >
                    <h2>{i18n.t('comment')}</h2>
                    <div className="card-body">
                        <div className="form-group">
                            <label>{i18n.t('user')}:</label>
                            <input disabled
                                type="text"
                                value={this.state.detailUser}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <label>{i18n.t('comment')}:</label>
                            <textarea disabled
                                name="description"
                                maxLength={250}
                                value={this.state.detailMessage}
                                className="form-control"
                            />
                        </div>
                        <button className="btn btn-default pull-right" onClick={this.closeDetailModal}>{i18n.t('close')}</button>
                    </div>

                </Modal>
            </>
        )
    }
}

export const FindStockManagement = withAppContext(FindStockManagementMain);