import * as React from "react";
import i18n from "../../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../../context/AppContext";
import { STATE_OFFICE_LAST_REPORT_PERM, BASE_PERM, MOVEMENT_CREATE_PERM, MOVEMENT_FIND_PERM, STOCK_DEVICE_STOCK_OPENING_ACCESS } from "../../../const/Permission";
import { MovementAdd } from "./Add";
import { MovementList } from "./List";
import { MovementOpening } from "./Opening";

export interface MovementDeviceProps extends RouteComponentProps, AppContextProps { }
export interface MovementDeviceState {
    step: number;
}

export class MovementDeviceMain extends React.Component<MovementDeviceProps, MovementDeviceState> {
    reachTheLastPage: boolean = false;
    constructor(props: MovementDeviceProps) {
        super(props);
        this.setStep = this.setStep.bind(this);
        this.state = {
            step: this.props.havePermission(STOCK_DEVICE_STOCK_OPENING_ACCESS) ? 0 : 2,
        };
    }

    setStep(step: number): any {
        this.setState({ step });
    }

    render() {
        return (
            <div className="panel-tabs">
                <div className="panel-heading">
                    <ul className="nav nav-tabs">
                        {this.props.havePermission(STOCK_DEVICE_STOCK_OPENING_ACCESS) && <li className={this.state.step === 0 ? "active" : ""}><a onClick={() => this.setStep(0)} href="#" className="nav-link" >{i18n.t('movement-open')}</a></li>}
                        {this.props.havePermission(MOVEMENT_CREATE_PERM, STATE_OFFICE_LAST_REPORT_PERM) && <li className={this.state.step === 1 ? "active" : ""}><a onClick={() => this.setStep(1)} href="#" className="nav-link" >{i18n.t('make-movements')}</a></li>}
                        {this.props.havePermission(MOVEMENT_FIND_PERM) && <li className={this.state.step === 2 ? "active" : ""}><a onClick={() => this.setStep(2)} href="#" className="nav-link" >{i18n.t('see-movements')}</a></li>}
                    </ul>
                </div>
                {this.state.step === 0 && this.props.havePermission(STOCK_DEVICE_STOCK_OPENING_ACCESS) &&
                    <MovementOpening />
                }
                {this.state.step === 1 && this.props.havePermission(MOVEMENT_CREATE_PERM,STATE_OFFICE_LAST_REPORT_PERM) &&
                    <MovementAdd />
                }
                {this.state.step === 2 && this.props.havePermission(MOVEMENT_FIND_PERM) &&
                    <MovementList />
                }
            </div>
        )
    }
}

export const MovementDevice = withAppContext(MovementDeviceMain);
