import * as React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RouteComponentProps } from "react-router";
import { Link } from 'react-router-dom'
import { AppContextProps, withAppContext } from "../../context/AppContext";
import { Role } from "../../interfaces/Role";
import { getRolesList } from "../../services/Role";
import { ROLE_MODIFY_PERM, ROLE_CREATE_PERM, ROLE_SHOW_PERM, ROLE_LIST_PERM } from "../../const/Permission";
import i18n from "../../i18n";
import { ROLE_CREATE_ROUTE, ROLE_MODIFY_ROUTE, ROLE_ID_PARAM, ROLE_SHOW_ROUTE } from "../../const/Routes";
import { ToggleLink } from "../../utils/ToggleLink";
import { activeFormatter } from "../../utils/Formatter";
import { ResponseError, apiHandleErrorCode } from "../../utils/ApiBaseConfig";

interface RoleListProps extends RouteComponentProps, AppContextProps { }
interface RoleListState {
    showModify: boolean;
    showHistory: boolean;
    showCreateNew: boolean;
    roleList: Role[];
    selected: Role | null;
}

export class RoleListMain extends React.Component<RoleListProps, RoleListState>{
    bootstrapTableRef: any;
    constructor(props: RoleListProps) {
        super(props);
        this.onSelect = this.onSelect.bind(this);

        this.state = {
            showCreateNew: false,
            showHistory: false,
            showModify: false,
            roleList: [],
            selected: null,
        }
    }

    componentDidMount() {
        this.props.showLoading(true);
        getRolesList()
            .then((roleList: Role[]) => {
                this.setState({ roleList }, () => this.props.showLoading(false));
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    onSelect(selected: Role) {
        this.setState({ selected });
    }

    render() {
        return (
            <div className="card">
                <div className="card-header">
                    <div className="card-title">
                        <div className="title">{i18n.t('roles')}</div>
                    </div>
                </div>
                <div className="card-body">
                    {this.props.havePermission(ROLE_SHOW_PERM) && <ToggleLink to={ROLE_SHOW_ROUTE.replace(ROLE_ID_PARAM, this.state.selected ? this.state.selected.codeRole : '')} className="btn btn-primary" disabled={this.state.selected === null}>{i18n.t('show')}</ToggleLink>}
                    {this.props.havePermission(ROLE_MODIFY_PERM) && <ToggleLink to={ROLE_MODIFY_ROUTE.replace(ROLE_ID_PARAM, this.state.selected ? this.state.selected.codeRole : '')} className="btn btn-primary" disabled={this.state.selected === null}>{i18n.t('modify')}</ToggleLink>}
                    {this.props.havePermission(ROLE_CREATE_PERM) && <Link to={ROLE_CREATE_ROUTE} className="btn btn-info" >{i18n.t('create')}</Link>}

                    {this.props.havePermission(ROLE_LIST_PERM) &&
                        <div className="panel fresh-color panel-info">
                            <div className="panel-heading">{i18n.t('role-select')}</div>
                            <BootstrapTable
                                data={this.state.roleList}
                                selectRow={{ mode: 'radio', onSelect: this.onSelect }}
                                bordered={false} >
                                <TableHeaderColumn dataField='codeRole' isKey={true} dataSort={true} dataAlign="center" width="120">{i18n.t('code')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='roleName' dataSort={true}>{i18n.t('name')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='externalRoleName' dataSort={true} dataAlign={'center'} hidden={true}>{i18n.t('role-external')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='needTwoFactorAuth' dataFormat={activeFormatter} dataSort={true} dataAlign="center" width="140" hidden={true}>{i18n.t('two-factor')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='creationDate' dataSort={true} dataAlign={'center'}>{i18n.t('date-creation')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='roleActive' dataFormat={activeFormatter} width="80" dataAlign="center">{i18n.t('active')}</TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export const RoleList = withAppContext(RoleListMain);