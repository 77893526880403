import * as React from "react";
import i18n from "../../../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps } from "../../../../context/AppContext";
import { BootstrapTable, TableHeaderColumn, ExportCSVButton } from "react-bootstrap-table";
import { ShipmentGenerated, ShipmentAPI } from "../../../../interfaces/Shipment";
import { SHIPMENT_REQUEST_PERM } from "../../../../const/Permission";
import { requestShipments } from "../../../../services/Shipment";
import { DocumentSource } from "../../../../interfaces/Document";
import { ResponseError, apiHandleErrorCode } from "../../../../utils/ApiBaseConfig";
import { getTimestamp } from "../../../../utils/Formatter";
import { DEVICES_ROUTE } from "../../../../const/Routes";
import { MESSAGE_SUCCESS } from "../../../../const/Message";
import { CURUS_SHIPMENT_STATE_ASSIGNED } from "../../../../const/Identity";
import { BranchOffice } from "../../../../interfaces/Office";
import { DeviceContextProps, withAppDeviceContext } from "../../../../context/DeviceContext";

export interface ShipmentsAssignedProps extends RouteComponentProps, AppContextProps, DeviceContextProps {
    assigned: ShipmentGenerated[];
}

export interface ShipmentsAssignedState {
    selected: ShipmentGenerated | null;
    tableOptions: any;
}

export class ShipmentsAssignedMain extends React.Component<ShipmentsAssignedProps, ShipmentsAssignedState> {

    constructor(props: ShipmentsAssignedProps) {
        super(props);
        this.onSelect = this.onSelect.bind(this);
        this.cleanShipments = this.cleanShipments.bind(this);
        this.request = this.request.bind(this);
        this.finish = this.finish.bind(this);
        this.csvFileName = this.csvFileName.bind(this);
        this.formatOfficeCode = this.formatOfficeCode.bind(this);

        this.state = {
            selected: null,
            tableOptions: {
                noDataText: i18n.t('table-empty'),
                exportCSVBtn: (onClick: any) => { return <ExportCSVButton btnText={i18n.t('shipment-summary-export')} btnContextual='btn-info' onClick={onClick} /> }
            }
        }
    }

    onSelect(selected: ShipmentGenerated) {
        this.setState({ selected });
    }

    cleanShipments(): ShipmentAPI[] {
        const selected: ShipmentGenerated[] = this.props.assigned.slice();
        const clean: ShipmentAPI[] = [];
        for (let i = 0; i < selected.length; i++) {
            const shipment: ShipmentGenerated = Object.assign({}, selected[i]);
            clean.push({ 
                shipmentState: CURUS_SHIPMENT_STATE_ASSIGNED,
                origin: { code: this.props.authUser!.branchOffice!.code, name: this.props.authUser!.branchOffice!.name },
                receiver: { correlative: 0, name: "" },
                destination: { code: shipment.destinationCode!, name: ""},
                sender: { correlative: shipment.senderCorrelative, name: ""},
                deviceQuantity: shipment.deviceQuantity,
                comment: shipment.comment,
                shipmentCorrelative: shipment.shipmentCorrelative,
                sendDate: null,
                receptionDate: null,
                updateDate: 0,
                deviceTypeCode: "",
                deviceTypeName: ""
            });
        }

        return clean;
    }

    request() {
        this.props.showLoading(true);
        requestShipments(this.cleanShipments())
            .then(() => {
                this.props.setMessage({ message: i18n.t('shipments-requested'), type: MESSAGE_SUCCESS });
                this.props.showLoading(false);
                this.finish();
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    finish() {
        this.props.history.push(DEVICES_ROUTE);
    }

    csvFileName() {
        const shipment: ShipmentGenerated = this.props.assigned[0];
        return 'RESUMEN_ENVIO_' + shipment.originCode + '_' + shipment.destinationCode + '_' + getTimestamp() + '.csv';
    }

    formatOfficeCode(code: string) {
        const office: BranchOffice | undefined = this.props.branchOffices.find((office: BranchOffice) => office.code === code);
        return office ? office.code + ' - ' + office.name : '';
    }

    render() {
        return (
            <div className="card">
                <div className="card-header">
                    <div className="card-title">
                        <div className="title">{i18n.t('step')} 2: {i18n.t('shipments-assigned-summary')}</div>
                    </div>
                </div>
                <div className="card-body">
                    <BootstrapTable
                        data={this.props.assigned}
                        bordered={true}
                        options={this.state.tableOptions}
                        exportCSV csvFileName={this.csvFileName()}
                        selectRow={{ mode: 'radio', onSelect: this.onSelect }}>
                        <TableHeaderColumn dataField='boxCode' isKey={true} dataSort={true} csvHeader={i18n.t('box-code')}>{i18n.t('box-code')}</TableHeaderColumn>
                        <TableHeaderColumn dataField='deviceQuantity' dataSort={true} dataAlign={'center'} csvHeader={i18n.t('devices-quantity')}>{i18n.t('devices-quantity')}</TableHeaderColumn>
                        <TableHeaderColumn dataField='destinationCode' csvHeader={i18n.t('destination')} dataFormat={this.formatOfficeCode}>{i18n.t('destination')}</TableHeaderColumn>
                        <TableHeaderColumn dataField='comment' dataSort={true} csvHeader={i18n.t('observation')}>{i18n.t('observation')}</TableHeaderColumn>
                    </BootstrapTable>
                    <br />
                    {this.props.havePermission(SHIPMENT_REQUEST_PERM) && <button className="btn btn-info" onClick={this.request}>{i18n.t('request')}</button>}
                    <button className="btn btn-info" onClick={this.finish}>{i18n.t('finish')}</button>
                </div>
            </div>
        )
    }
}

export const ShipmentsAssigned = withAppDeviceContext(ShipmentsAssignedMain);