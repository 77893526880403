import * as React from "react";
import i18n from "../../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../../context/AppContext";
import { Region, SearchBalance, Balance, ExpectedBalance, BalanceToSend, CreateBalanceToSend } from "../../../interfaces/Balance";
import { BranchOffice } from "../../../interfaces/Office";
import { getRegionList, getBranchOfficeList } from "../../../services/Stock";
import { ResponseError, apiHandleErrorCode } from "../../../utils/ApiBaseConfig";
import { MESSAGE_ERROR, MESSAGE_SUCCESS, MESSAGE_WARNING } from "../../../const/Message";
import Modal from 'react-modal';
import { getLastBalance, getExpectedBalance, sendStocktaking } from "../../../services/Balance";
import { getDeviceTypeList } from "../../../services/DeviceType";
import { DeviceType } from "../../../interfaces/DeviceType";
import { getAuthenticateIntelliTrust, completeAuthenticateIntelliTrust, getAuthenticatorsIntelliTrust } from "../../../services/Session";
import { IntelliTrustChallengeRequest, IntelliTrustAuthenticators } from "../../../interfaces/IntelliTrust";
import { Authorization } from "../../../interfaces/System";
import { cleanProperty } from "../../../utils/Formatter";
import { isCardWizardActive } from "../../../services/System";
import Select from "react-select";

export interface BalanceAddProps extends RouteComponentProps, AppContextProps {
    onlyStockSetter: boolean;
}
export interface BalanceAddState {
    region: string;
    regionName: string;
    regions: Region[];
    office: string;
    officeName: string;
    offices: BranchOffice[];
    deviceTypes: DeviceType[];
    stock: string;
    step: number;
    showSelectRegionAndLocation: boolean;
    dateOfLastBalance: string;
    expectedBalance: ExpectedBalance;
    quantityInVault: string;
    quantityInMachine: string;
    quantityInOther: string;
    quantityRejectedMachine: string;
    quantityRejectedQA: string;
    comment: string;
    modalIsOpen: boolean;
    supervisorId: string;
    supervisorPwd: string;
    insideVaultDifference: string;
    outsideVaultDifference: string;
    rejectMachineDifference: string;
    rejectQADifference: string;
    showPhase: number;
    supervisorUsername: string;
    challengeTypes: string[];
    challengeType: string;
    otpDeliveryTypes: string[];
    otpDeliveryType: string;
    challengeResponse: string;
    challengeToken: string;
    showOtpType: boolean;
    backendActive: boolean;
}

export class BalanceAddMain extends React.Component<BalanceAddProps, BalanceAddState>{
    reachTheLastPage: boolean = false;
    myRef: React.RefObject<HTMLDivElement>;
    subtitle: HTMLHeadingElement | null | undefined;

    constructor(props: BalanceAddProps) {
        super(props);
        this.myRef = React.createRef();
        this.handleRegion = this.handleRegion.bind(this);
        this.handleBranchOffice = this.handleBranchOffice.bind(this);
        this.handleStock = this.handleStock.bind(this);
        this.getRegionsFromAPI = this.getRegionsFromAPI.bind(this);
        this.getOfficesFromAPI = this.getOfficesFromAPI.bind(this);
        this.getDeviceTypesFromAPI = this.getDeviceTypesFromAPI.bind(this);
        this.getLastInventoryBalanceFromAPI = this.getLastInventoryBalanceFromAPI.bind(this);
        this.getExpectedInventoryBalanceFromAPI = this.getExpectedInventoryBalanceFromAPI.bind(this);
        this.newExpectedBalance = this.newExpectedBalance.bind(this);
        this.callGetLastAndExpectedBalanceFromAPI = this.callGetLastAndExpectedBalanceFromAPI.bind(this);
        this.sendButtonShouldDisabled = this.sendButtonShouldDisabled.bind(this);
        this.handleInsideVault = this.handleInsideVault.bind(this);
        this.handleInMachine = this.handleInMachine.bind(this);
        this.handleInOther = this.handleInOther.bind(this);
        this.handleRejectedMachine = this.handleRejectedMachine.bind(this);
        this.handleRejectedQA = this.handleRejectedQA.bind(this);
        this.handleComment = this.handleComment.bind(this);
        this.sendStocktakingToAPI = this.sendStocktakingToAPI.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.sendDataAndCloseModal = this.sendDataAndCloseModal.bind(this);
        this.handleSupervisorId = this.handleSupervisorId.bind(this);
        this.handleSupervisorPwd = this.handleSupervisorPwd.bind(this);
        this.tryToSendBalance = this.tryToSendBalance.bind(this);
        this.isBackendActive = this.isBackendActive.bind(this);
        this.showError = this.showError.bind(this);
        this.getDifference = this.getDifference.bind(this);
        this.refreshDifference = this.refreshDifference.bind(this);
        this.getClassNameForTextbox = this.getClassNameForTextbox.bind(this);

        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handleSelectChallengeType = this.handleSelectChallengeType.bind(this);
        this.handleSelectOtpType = this.handleSelectOtpType.bind(this);
        this.getAvailableChallenges = this.getAvailableChallenges.bind(this);
        this.getAvailableChallenges = this.getAvailableChallenges.bind(this);
        this.sendAuthenticateChallenge = this.sendAuthenticateChallenge.bind(this);
        this.handleResponseChange = this.handleResponseChange.bind(this);
        this.reset = this.reset.bind(this);

        this.state = {
            region: "",
            regionName: "",
            regions: [],
            office: "",
            officeName: "",
            offices: [],
            deviceTypes: [],
            stock: "",
            step: 0,
            showSelectRegionAndLocation: false,
            dateOfLastBalance: i18n.t('no-data'),
            expectedBalance: this.newExpectedBalance(),
            quantityInVault: this.props.onlyStockSetter ? "0" : "",
            quantityInMachine: this.props.onlyStockSetter ? "0" : "",
            quantityInOther: this.props.onlyStockSetter ? "0" : "",
            quantityRejectedMachine: this.props.onlyStockSetter ? "0" : "",
            quantityRejectedQA: this.props.onlyStockSetter ? "0" : "",
            comment: this.props.onlyStockSetter ? "Balance que establece el stock de la sucursal." : "",
            modalIsOpen: false,
            supervisorId: "",
            supervisorPwd: "",
            insideVaultDifference: "",
            outsideVaultDifference: "",
            rejectMachineDifference: "",
            rejectQADifference: "",
            showPhase: 0,
            supervisorUsername: "",
            challengeTypes: [],
            challengeType: "",
            otpDeliveryTypes: [],
            otpDeliveryType: "",
            challengeResponse: "",
            challengeToken: "",
            showOtpType: false,
            backendActive: true,
        };
    }

    newExpectedBalance(): ExpectedBalance {
        return {
            expectedQuantityInVault: "",
            expectedTotalQuantityOutVault: "",
            expectedQuantityRejectedMachine: "",
            expectedQuantityRejectedQA: ""
        }
    }

    componentDidMount() {
        if (this.props.authUser && this.props.authUser.branchOffice) {
            if (this.props.authUser.branchOffice.code === "1") {
                this.setState({ showSelectRegionAndLocation: true }, this.getRegionsFromAPI);
            }
            else {
                this.setState({
                    office: this.props.authUser.branchOffice.code,
                    officeName: this.props.authUser.branchOffice.name,
                    regionName: this.props.authUser.branchOffice.region.regionName
                });
            }
        }
        this.getDeviceTypesFromAPI();
        if(this.props.useCardWizard) {
            this.setState({ backendActive: false }, this.isBackendActive);
        }
    }

    handleRegion(e: any) {
        e.preventDefault();
        const regionSelected = e.target.value;
        this.setState({ region: regionSelected }, this.getOfficesFromAPI);
    }

    handleBranchOffice(e: any) {
        e.preventDefault();
        const officeSelected = e.target.value;
        this.setState({ office: officeSelected }, this.callGetLastAndExpectedBalanceFromAPI);
    }

    handleStock(stockType: any) {
        this.setState({ stock: stockType.value }, this.callGetLastAndExpectedBalanceFromAPI);
    }

    refreshDifference(): void {
        var insideVaultDifference = this.getDifference(this.state.expectedBalance.expectedQuantityInVault, this.state.quantityInVault);
        var outsideVaultDifference = this.getDifference(this.state.expectedBalance.expectedTotalQuantityOutVault, this.state.quantityInMachine, this.state.quantityInOther);
        var rejectMachineDifference = this.getDifference(this.state.expectedBalance.expectedQuantityRejectedMachine, this.state.quantityRejectedMachine);
        var rejectQADifference = this.getDifference(this.state.expectedBalance.expectedQuantityRejectedQA, this.state.quantityRejectedQA);
        this.setState({ insideVaultDifference, outsideVaultDifference, rejectMachineDifference, rejectQADifference });
    }

    handleInsideVault(e: any) {
        e.preventDefault();
        const insideVault = e.target.value;
        var result = this.getDifference(this.state.expectedBalance.expectedQuantityInVault, insideVault);
        this.setState({ quantityInVault: insideVault, insideVaultDifference: result });
    }

    handleInMachine(e: any) {
        e.preventDefault();
        const inMachine = e.target.value;
        var result = this.getDifference(this.state.expectedBalance.expectedTotalQuantityOutVault, inMachine, this.state.quantityInOther);
        this.setState({ quantityInMachine: inMachine, outsideVaultDifference: result });
    }

    handleInOther(e: any) {
        e.preventDefault();
        const inOther = e.target.value;
        var result = this.getDifference(this.state.expectedBalance.expectedTotalQuantityOutVault, this.state.quantityInMachine, inOther);
        this.setState({ quantityInOther: inOther, outsideVaultDifference: result });
    }

    handleRejectedMachine(e: any) {
        e.preventDefault();
        const rejectedMachine = e.target.value;
        var result = this.getDifference(this.state.expectedBalance.expectedQuantityRejectedMachine, rejectedMachine);
        this.setState({ quantityRejectedMachine: rejectedMachine, rejectMachineDifference: result });
    }

    handleRejectedQA(e: any) {
        e.preventDefault();
        const rejectedQA = e.target.value;
        var result = this.getDifference(this.state.expectedBalance.expectedQuantityRejectedQA, rejectedQA);
        this.setState({ quantityRejectedQA: rejectedQA, rejectQADifference: result });
    }

    handleComment(e: any) {
        e.preventDefault();
        const comment = e.target.value;
        this.setState({ comment });
    }

    handleSupervisorId(e: any) {
        e.preventDefault();
        const supervisorId = e.target.value;
        this.setState({ supervisorId });
    }

    handleSupervisorPwd(e: any) {
        e.preventDefault();
        const supervisorPwd = e.target.value;
        this.setState({ supervisorPwd });
    }

    getRegionsFromAPI(): void {
        this.props.showLoading(true);
        getRegionList().then((regionArray: Region[]) => {
            this.setState({ regions: regionArray }, () => {
                this.props.showLoading(false);
            });
        })
            .catch((response: ResponseError) => {
                this.props.showLoading(false);
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
    }

    getOfficesFromAPI(): void {
        this.props.showLoading(true);
        if (this.state.region !== "" && this.state.region !== "NONE") {
            getBranchOfficeList(this.state.region).then((officeArray: BranchOffice[]) => {
                this.setState({ offices: officeArray }, () => {
                    this.props.showLoading(false);
                });
            })
                .catch((response: ResponseError) => {
                    this.props.showLoading(false);
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                });
        }
    }

    getDeviceTypesFromAPI(): void {
        this.props.showLoading(true);
        getDeviceTypeList().then((deviceTypeArray: DeviceType[]) => {
            this.setState({ deviceTypes: deviceTypeArray }, () => {
                this.props.showLoading(false);
            });
        })
            .catch((response: ResponseError) => {
                this.props.showLoading(false);
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
    }

    getLastInventoryBalanceFromAPI(): void {
        if (this.state.stock !== "NONE") {
            this.props.showLoading(true);
            const searchMovement: SearchBalance = { branchOfficeCode: this.state.office, deviceTypeCode: this.state.stock };
            getLastBalance(searchMovement).then((balance: Balance) => {
                this.props.showLoading(false);
                if (balance.date)
                    this.setState({ dateOfLastBalance: balance.date });
            })
                .catch((response: ResponseError) => {
                    this.props.showLoading(false);
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                });
        }
    }

    getExpectedInventoryBalanceFromAPI(): void {
        if (this.state.stock !== "NONE") {
            this.props.showLoading(true);
            const searchMovement: SearchBalance = { branchOfficeCode: this.state.office, deviceTypeCode: this.state.stock };
            //getExpectedBalanceCW(searchMovement).then((balance: Balance) => {
            getExpectedBalance(searchMovement).then((balance: Balance) => {
                this.setState({
                    expectedBalance: {
                        expectedQuantityInVault: balance.expectedQuantityInVault.toString(),
                        expectedTotalQuantityOutVault: balance.expectedTotalQuantityOutVault.toString(),
                        expectedQuantityRejectedMachine: balance.expectedQuantityRejectedMachine.toString(),
                        expectedQuantityRejectedQA: balance.expectedQuantityRejectedQA.toString()
                    }
                },() => {
                    this.refreshDifference();
                    this.props.showLoading(false);
                });
            })
                .catch((response: ResponseError) => {
                    this.props.showLoading(false);
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                });
        }
    }

    callGetLastAndExpectedBalanceFromAPI(): void {
        if(!this.props.onlyStockSetter){
            if (this.state.office !== "" && this.state.office !== "NONE" && this.state.stock !== "" && this.state.stock !== "NONE") {
                this.getLastInventoryBalanceFromAPI();
                this.getExpectedInventoryBalanceFromAPI();
            }
        }
    }

    sendStocktakingToAPI(): void {
        this.props.showLoading(true);
        const balanceToSend: BalanceToSend = {
            branchOffice: { code: this.state.office, name: "" },
            deviceType: { code: this.state.stock, name: "", available: true },
            userSupervisor: { group: "identity", userId: this.state.supervisorId, password: this.state.supervisorPwd },
            expectedQuantityInVault: !this.props.onlyStockSetter ? Number(this.state.expectedBalance.expectedQuantityInVault) : Number(this.state.quantityInVault),
            expectedTotalQuantityOutVault: Number(this.state.expectedBalance.expectedTotalQuantityOutVault),
            expectedQuantityRejectedMachine: Number(this.state.expectedBalance.expectedQuantityRejectedMachine),
            expectedQuantityRejectedQA: Number(this.state.expectedBalance.expectedQuantityRejectedQA),
            realQuantityInVault: Number(this.state.quantityInVault),
            realQuantityInMachine: Number(this.state.quantityInMachine),
            realQuantityInOther: Number(this.state.quantityInOther),
            realTotalQuantityOutVault: Number(this.state.quantityInMachine) + Number(this.state.quantityInOther),
            realQuantityRejectedMachine: Number(this.state.quantityRejectedMachine),
            realQuantityRejectedQA: Number(this.state.quantityRejectedQA),
            description: this.state.comment,
            insideVaultDifference: this.state.insideVaultDifference,
            outsideVaultDifference: this.state.outsideVaultDifference,
            rejectMachineDifference: this.state.rejectMachineDifference,
            rejectQADifference: this.state.rejectQADifference
        }
        const authorization: Authorization = {
            userId: this.state.supervisorId,
            password: this.state.supervisorPwd,
            username: this.state.supervisorUsername,
            challengeType: this.state.challengeType,
            otpDeliveryType: this.state.otpDeliveryType,
            group: "identity",
            response: this.state.challengeResponse,
            token: cleanProperty(this.state.challengeToken)
        }
        const model: CreateBalanceToSend = {
            balance: balanceToSend,
            authorization: authorization
        }

        sendStocktaking(model).then(() => {
            this.props.setMessage({ message: i18n.t('balance-sent-ok'), type: MESSAGE_SUCCESS });
            this.setState({
                expectedBalance: this.newExpectedBalance(),
                quantityInVault: this.props.onlyStockSetter ? "0" : "",
                quantityInMachine: this.props.onlyStockSetter ? "0" : "",
                quantityInOther: this.props.onlyStockSetter ? "0" : "",
                quantityRejectedMachine: this.props.onlyStockSetter ? "0" : "",
                quantityRejectedQA: this.props.onlyStockSetter ? "0" : "",
                comment: this.props.onlyStockSetter ? "Balance que establece el stock de la sucursal." : "",
                supervisorId: "",
                supervisorPwd: "",
                insideVaultDifference: "",
                outsideVaultDifference: "",
                rejectMachineDifference: "",
                rejectQADifference: "",
                stock: ""
            }, () => {
                this.reset();
                this.props.showLoading(false);
            });
        })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
                this.closeModal();
            });
    }

    isBackendActive(): void {
        isCardWizardActive().then((result: boolean) => {
            if(!result) this.props.setMessage({ message: i18n.t('verifying-cw-err'), type: MESSAGE_ERROR });
            this.setState({ backendActive: result });
        })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
    }

    sendButtonShouldDisabled(): boolean {
        if (this.state.stock === "" || this.state.stock === "NONE" ||
            this.state.office === "" || this.state.office === "NONE" ||
            this.state.quantityInMachine === "" || this.state.quantityInOther === "" ||
            this.state.quantityInVault === "" || this.state.quantityRejectedMachine === "" ||
            this.state.quantityRejectedQA === "" || !this.state.backendActive
        ) {
            return true;
        }
        return false;
    }

    tryToSendBalance(): void {
        if(!this.props.onlyStockSetter){
            if (this.state.insideVaultDifference == "0" &&
                this.state.outsideVaultDifference == "0" &&
                this.state.rejectMachineDifference == "0" &&
                this.state.rejectQADifference == "0") {
                this.setState({ supervisorId: "", supervisorPwd: "" }, this.sendStocktakingToAPI);
            }
            else {
                this.openModal();
            }
        }else {
            this.sendStocktakingToAPI();
        }
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    closeModal() {
        this.reset();
        this.setState({ modalIsOpen: false });
    }

    afterOpenModal() {
        if (this.subtitle)
            this.subtitle.style.color = '#f00';
    }

    showError() {
        this.myRef.current!.className = "alert alert-danger";
        setTimeout(() => {
            this.myRef.current!.className = "";
            this.myRef.current!.hidden = true;
        }, 2000);
    }

    sendDataAndCloseModal() {
        if ((!this.props.useIntellitrust && (this.state.supervisorId === "" || this.state.supervisorPwd === ""))
            || (this.props.useIntellitrust && (this.state.challengeResponse === ""))) {
            this.showError();
        }
        else {
            this.setState({ modalIsOpen: false });
            this.sendStocktakingToAPI();
        }
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    getDifference(value1: string, value2: string, value3: string = "0"): string {
        var result: string = (value1 !== "" && value2 !== "") ?
            (Number(value1) - Number(value2) - Number(value3)).toString() : "";
        return result;
    }

    getClassNameForTextbox(value: string): string {
        if (value == "0")
            return "form-control background-green";
        if (value !== "")
            return "form-control background-yellow";
        return "form-control";
    }

    getAvailableChallenges() {
        this.props.showLoading(true);
        getAuthenticatorsIntelliTrust(this.state.supervisorUsername)
            .then((response: IntelliTrustAuthenticators) => {
                this.setState({ challengeTypes: response.authenticationTypes, otpDeliveryTypes: response.availableOTPDelivery, showPhase: 1 }, () => {
                    this.props.showLoading(false);
                });
            })
            .catch((error: ResponseError) => {
                this.closeModal();
                this.props.setMessage(error.message);
                this.props.showLoading(false)
            });
    }

    sendAuthenticateChallenge() {
        this.props.showLoading(true);
        getAuthenticateIntelliTrust(this.state.supervisorUsername, this.state.challengeType, this.state.otpDeliveryType)
            .then((response: IntelliTrustChallengeRequest) => {
                this.setState({ showPhase: 2, challengeToken: response.token }, () => {
                    this.props.showLoading(false);
                });
            })
            .catch((error: ResponseError) => {
                this.closeModal();
                this.props.setMessage(error.message);
                this.props.showLoading(false);
            });
    }

    reset() {
        this.setState({ showPhase: 0, challengeResponse: '', supervisorUsername: '', supervisorId: '', supervisorPwd: '' });
    }

    handleSelectChallengeType(e: any): void {
        e.preventDefault();
        const challengeType: string = e.target.value == "NONE" ? "" : e.target.value;
        if (challengeType === "OTP") {
            this.setState({ challengeType: challengeType, showOtpType: true, showPhase: 1 });
        } else {
            this.setState({ challengeType: challengeType, showOtpType: false, showPhase: 1 });
        }
    }

    handleSelectOtpType(e: any): void {
        e.preventDefault();
        const otpType: string = e.target.value == "NONE" ? "" : e.target.value;
        this.setState({ otpDeliveryType: otpType, showPhase: 1 });
    }

    handleUsernameChange(e: any): void {
        e.preventDefault();
        const supervisorUsername: string = e.target.value;
        this.setState({ supervisorUsername, showPhase: 0 });
    }

    handleResponseChange(e: any): void {
        const response: string = e.target.value;
        this.setState({ challengeResponse: response });
    }

    render() {
        const REG: { [key: string]: string } = this.state.regions.reduce((map: { [key: string]: string }, region: Region) => {
            map[region.codeRegion] = region.regionName;
            return map;
        }, {});

        const regions: JSX.Element[] = Object.keys(REG).map((codeRegion: string) => {
            return <option key={codeRegion} value={codeRegion}>{i18n.t(REG[codeRegion])}</option>;
        });

        const OFFI: { [key: string]: string } = this.state.offices.reduce((map: { [key: string]: string }, office: BranchOffice) => {
            map[office.code] = office.name;
            return map;
        }, {});

        const branchOffices: JSX.Element[] = Object.keys(OFFI).map((codeOffice: string) => {
            return <option key={codeOffice} value={codeOffice}>{i18n.t(OFFI[codeOffice])}</option>;
        });

        const STOCK: { [key: string]: string } = this.state.deviceTypes.reduce((map: { [key: string]: string }, deviceType: DeviceType) => {
            map[deviceType.codeDeviceType] = deviceType.nameDeviceType;
            return map;
        }, {});

        const stocks : { label: string, value: string }[] = Object.keys(STOCK).map((codeStock: string) => { 
            return { label: i18n.t(STOCK[codeStock]), value: codeStock }
        });

        const customStyles: any = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            }
        };

        const CHALL: { [key: string]: string } = this.state.challengeTypes.reduce((map: { [key: string]: string }, type: string) => {
            map[type] = type;
            return map;
        }, {});

        const challenges: JSX.Element[] = Object.keys(CHALL).map((type: string) => {
            return <option key={type} value={type}>{i18n.t(CHALL[type])}</option>;
        })

        const OTPT: { [key: string]: string } = this.state.otpDeliveryTypes.reduce((map: { [key: string]: string }, type: string) => {
            map[type] = type;
            return map;
        }, {});

        const otps: JSX.Element[] = Object.keys(OTPT).map((type: string) => {
            return <option key={type} value={type}>{i18n.t(OTPT[type])}</option>;
        })

        return (
            <div className="panel-body">
                <div className="card card-sercheable-select">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('devices-balance')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        {this.state.showSelectRegionAndLocation &&
                            <div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('region')}:</label>
                                    <select className="form-control" onChange={this.handleRegion} value={this.state.region}>
                                        <option value="NONE">{i18n.t('option-select')}</option>
                                        {regions}
                                    </select>
                                </div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('branch-office')}:</label>
                                    <select className="form-control" onChange={this.handleBranchOffice} value={this.state.office}>
                                        <option value="NONE">{i18n.t('option-select')}</option>
                                        {branchOffices}
                                    </select>
                                </div>
                            </div>
                        }
                        {!this.state.showSelectRegionAndLocation &&
                            <div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('region')}:</label>
                                    <p className="form-control">{this.state.regionName}</p>
                                </div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('branch-office')}:</label>
                                    <p className="form-control">{this.state.officeName}</p>
                                </div>
                            </div>
                        }
                        <div className="form-group col-sm-4">
                            <label>{i18n.t('device-type')}:</label>
                            <Select 
                                classNamePrefix="custom-select"
                                options={stocks}
                                isSearchable={true}
                                onChange={this.handleStock}
                                placeholder={i18n.t('option-select')}
                            />
                        </div>
                    </div>
                </div>
                <div className="card margin-card-top">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('balance-last-income')}: {this.state.dateOfLastBalance.toString()}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="col-sm-12">
                            <div className="form-group col-sm-2">
                                <label>{i18n.t('balance-inside-vault')}:</label>
                                <input type="number" name="insideVault" className="form-control" id="insideVault" onChange={this.handleInsideVault}
                                    value={this.state.quantityInVault} />
                            </div>
                            <div className="form-group col-sm-2">
                                <label>{i18n.t('balance-in-machine')}:</label>
                                <input type="number" name="inMachine" className="form-control" id="inMachine" onChange={this.handleInMachine}
                                    value={this.state.quantityInMachine} disabled={this.props.onlyStockSetter} />
                            </div>
                            <div className="form-group col-sm-2">
                                <label>{i18n.t('balance-other')}:</label>
                                <input type="number" name="other" className="form-control" id="other" onChange={this.handleInOther}
                                    value={this.state.quantityInOther} disabled={this.props.onlyStockSetter} />
                            </div>
                            <div className="form-group col-sm-2">
                                <label>{i18n.t('balance-reject-in-machine')}:</label>
                                <input type="number" name="rejectMachine" className="form-control" id="rejectMachine" onChange={this.handleRejectedMachine}
                                    value={this.state.quantityRejectedMachine} disabled={this.props.onlyStockSetter} />
                            </div>
                            <div className="form-group col-sm-2">
                                <label>{i18n.t('balance-reject-qa')}:</label>
                                <input type="number" name="rejectQA" className="form-control" id="rejectQA" onChange={this.handleRejectedQA}
                                    value={this.state.quantityRejectedQA} disabled={this.props.onlyStockSetter} />
                            </div>
                            <div className="form-group col-sm-10">
                                <label>{i18n.t('comment')}:</label>
                                <textarea name="comment" className="form-control" id="comment" 
                                    onChange={this.handleComment} maxLength={250} value={this.state.comment} disabled={this.props.onlyStockSetter} />
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <button type="button" className="btn btn-info pull-right" disabled={+this.state.quantityInVault <= 0} onClick={this.tryToSendBalance}>{i18n.t('send')}</button>
                        </div>
                    </div>
                </div>

                {!this.props.onlyStockSetter && <div className="card margin-card-top">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('balance-result')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="form-group col-sm-12">
                            <div className="form-group col-sm-2"><br></br>
                            </div>
                            <div className="form-group col-sm-2 align-center">
                                <label>{i18n.t('balance-expected')}</label>
                            </div>
                            <div className="form-group col-sm-2 align-center">
                                <label>{i18n.t('balance-actual')}</label>
                            </div>
                            <div className="form-group col-sm-2 align-center">
                                <label>{i18n.t('balance-difference')}</label>
                            </div>
                        </div>
                        <div className="form-group col-sm-12">
                            <div className="form-group col-sm-2"><br></br>
                                <label>{i18n.t('balance-inside-vault')}:</label>
                            </div>
                            <div className="form-group col-sm-2">
                                <input disabled type="number" name="expectedQuantityInVault" className="form-control" id="expectedQuantityInVault" onChange={() => { }}
                                    value={this.state.expectedBalance.expectedQuantityInVault} />
                            </div>
                            <div className="form-group col-sm-2">
                                <input disabled type="number" name="insideVault" className="form-control" id="insideVault" onChange={() => { }}
                                    value={this.state.quantityInVault} />
                            </div>
                            <div className="form-group col-sm-2">
                                <input disabled type="number" name="insideVaultDifference"
                                    className={this.getClassNameForTextbox(this.state.insideVaultDifference)}
                                    id="insideVaultDifference" value={this.state.insideVaultDifference} />
                            </div>
                        </div>
                        <div className="form-group col-sm-12">
                            <div className="form-group col-sm-2"><br></br>
                                <label>{i18n.t('balance-outside-vault')}:</label>
                            </div>
                            <div className="form-group col-sm-2 align-right"><br></br>
                                <label>{i18n.t('balance-in-machine')}:</label>
                            </div>
                            <div className="form-group col-sm-2 ">
                                <input disabled type="number" name="insideVault" className="form-control" id="insideVault" onChange={() => { }}
                                    value={this.state.quantityInMachine} />
                            </div>
                            <div className="form-group col-sm-2"><br></br>
                            </div>
                        </div>
                        <div className="form-group col-sm-12">
                            <div className="form-group col-sm-2"><br></br>
                            </div>
                            <div className="form-group col-sm-2 align-right"><br></br>
                                <label>{i18n.t('balance-other')}:</label>
                            </div>
                            <div className="form-group col-sm-2">
                                <input disabled type="number" name="insideVault" className="form-control" id="insideVault" onChange={() => { }}
                                    value={this.state.quantityInOther} />
                            </div>
                            <div className="form-group col-sm-2"><br></br>
                            </div>
                        </div>
                        <div className="form-group col-sm-12">
                            <div className="form-group col-sm-2"><br></br>
                                <label>{i18n.t('balance-total-outside-vault')}:</label>
                            </div>
                            <div className="form-group col-sm-2">
                                <input disabled type="number" name="expectedTotalQuantityOutVault" className="form-control" id="expectedTotalQuantityOutVault" onChange={() => { }}
                                    value={this.state.expectedBalance.expectedTotalQuantityOutVault} />
                            </div>
                            <div className="form-group col-sm-2">
                                <input disabled type="number" name="insideVault" className="form-control" id="insideVault" onChange={() => { }}
                                    value={(this.state.quantityInMachine !== "" && this.state.quantityInOther !== "") ? Number(this.state.quantityInMachine) + Number(this.state.quantityInOther) : ""} />
                            </div>
                            <div className="form-group col-sm-2">
                                <input disabled type="number" name="outsideVault"
                                    className={this.getClassNameForTextbox(this.state.outsideVaultDifference)}
                                    id="outsideVault" onChange={() => { }} value={this.state.outsideVaultDifference} />
                            </div>
                        </div>
                        <div className="form-group col-sm-12">
                            <div className="form-group col-sm-2"><br></br>
                                <label>{i18n.t('balance-reject-in-machine')}:</label>
                            </div>
                            <div className="form-group col-sm-2">
                                <input disabled type="number" name="expectedQuantityRejectedMachine" className="form-control" id="expectedQuantityRejectedMachine" onChange={() => { }}
                                    value={this.state.expectedBalance.expectedQuantityRejectedMachine} />
                            </div>
                            <div className="form-group col-sm-2">
                                <input disabled type="number" name="insideVault" className="form-control" id="insideVault" onChange={() => { }}
                                    value={this.state.quantityRejectedMachine} />
                            </div>
                            <div className="form-group col-sm-2">
                                <input disabled type="number" name="rejectMachineDifference"
                                    className={this.getClassNameForTextbox(this.state.rejectMachineDifference)}
                                    id="rejectMachineDifference" onChange={() => { }} value={this.state.rejectMachineDifference} />
                            </div>
                        </div>
                        <div className="form-group col-sm-12">
                            <div className="form-group col-sm-2"><br></br>
                                <label>{i18n.t('balance-reject-qa')}:</label>
                            </div>
                            <div className="form-group col-sm-2">
                                <input disabled type="number" name="expectedQuantityRejectedQA" className="form-control" id="expectedQuantityRejectedQA" onChange={() => { }}
                                    value={this.state.expectedBalance.expectedQuantityRejectedQA} />
                            </div>
                            <div className="form-group col-sm-2">
                                <input disabled type="number" name="insideVault" className="form-control" id="insideVault" onChange={() => { }}
                                    value={this.state.quantityRejectedQA} />
                            </div>
                            <div className="form-group col-sm-2">
                                <input disabled type="number" name="rejectQADifference"
                                    className={this.getClassNameForTextbox(this.state.rejectQADifference)}
                                    id="rejectQADifference" onChange={() => { }} value={this.state.rejectQADifference} />
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="form-group col-sm-12">
                            <div className="form-group col-sm-2">
                                <button disabled={this.sendButtonShouldDisabled()} type="button" className="btn btn-info" onClick={this.tryToSendBalance}>{i18n.t('send')}</button>
                            </div>
                        </div>
                    </div>
                </div>}

                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.sendDataAndCloseModal}
                    style={customStyles}
                    contentLabel=""
                >
                    <h2 ref={subtitle => this.subtitle = subtitle}>{i18n.t('balance-auth-title')}</h2>
                    <div className="card-body">
                        <label>{i18n.t('balance-auth-text')}</label>
                    </div>
                    <div ref={this.myRef} hidden={true}>
                        <label>{i18n.t('balance-auth-text-required')}</label>
                    </div>
                    {!this.props.useIntellitrust &&
                        <div>
                            <div className="form-group">
                                <input autoComplete="new-password" className="form-control" onChange={this.handleSupervisorId}></input>
                                <input autoComplete="new-password" type="password" className="form-control" onChange={this.handleSupervisorPwd}></input>

                                <button className="btn btn-info" onClick={this.sendDataAndCloseModal}>{i18n.t('send')}</button>
                            </div>
                        </div>
                    }
                    {this.props.useIntellitrust &&
                        <div>
                            <div className="control">
                                <input type="text" className="form-control" placeholder={i18n.t('login-user')} onChange={this.handleUsernameChange} value={this.state.supervisorUsername} disabled={this.state.showPhase == 2} />
                            </div>


                            {this.state.showPhase == 1 &&
                                <div>
                                    <label>{i18n.t('external-challenge-type')}:</label>
                                    <select className="form-control" onChange={this.handleSelectChallengeType} value={this.state.challengeType}>
                                        <option value="NONE">{i18n.t('option-select')}</option>
                                        {challenges}
                                    </select>
                                    <div style={{ display: (this.state.showOtpType ? 'block' : 'none') }}>
                                        <label>{i18n.t('external-challenge-delivery')}:</label>
                                        <select className="form-control" onChange={this.handleSelectOtpType} value={this.state.otpDeliveryType} >
                                            <option value="NONE">{i18n.t('option-select')}</option>
                                            {otps}
                                        </select>
                                    </div>
                                </div>
                            }
                            {this.state.showPhase == 2 &&
                                <div>
                                    <div className="control">
                                        <input type="text" className="form-control" placeholder={i18n.t('sent')} value={this.state.challengeType + "/" + this.state.otpDeliveryType} disabled />
                                    </div>
                                    <div className="control">
                                        <input type="text" className="form-control" placeholder={i18n.t('external-challenge-response')} onChange={this.handleResponseChange} value={this.state.challengeResponse} />
                                    </div>
                                </div>
                            }
                            <div className="login-button text-center">
                                {this.state.showPhase != 0 &&
                                    <button onClick={this.reset} className="btn btn-default" >{i18n.t('back')}</button>}
                                {this.state.showPhase == 0 &&
                                    <button onClick={this.getAvailableChallenges} className="btn btn-primary" disabled={this.state.supervisorUsername.length === 0}>{i18n.t('login-do')}</button>}
                                {this.state.showPhase == 1 &&
                                    <button onClick={this.sendAuthenticateChallenge} className="btn btn-primary" disabled={this.state.challengeType === "" || (this.state.challengeType === "OTP" && this.state.otpDeliveryType === "")}>{i18n.t('send')}</button>}
                                {this.state.showPhase == 2 &&
                                    <button onClick={this.sendDataAndCloseModal} className="btn btn-primary" disabled={this.state.supervisorUsername.length === 0}>{i18n.t('authorize')}</button>}
                            </div>
                        </div>
                    }
                </Modal>
            </div>
        )
    }
}

export const BalanceAdd = withAppContext(BalanceAddMain);
