import * as React from "react";
import i18n from "../../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../../context/AppContext";
import { BootstrapTable, TableHeaderColumn, InsertButton, DeleteButton } from "react-bootstrap-table";
import { TABLE_ROW_SELECTION } from "../../../const/Table";
import { Movement, Region, MovementType } from "../../../interfaces/Movement";
import { getRegionList, getBranchOfficeList } from "../../../services/Stock";
import { BranchOffice, LocationInfo } from "../../../interfaces/Office";
import { ResponseError, apiHandleErrorCode } from "../../../utils/ApiBaseConfig";
import { MESSAGE_ERROR, MESSAGE_SUCCESS, MESSAGE_WARNING } from "../../../const/Message";
import { sendMovementList } from "../../../services/Movement";
import { getDeviceTypeList } from "../../../services/DeviceType";
import { DeviceType } from "../../../interfaces/DeviceType";
import { getLocationInfo } from "../../../services/Office";
import { SearchBalance, Balance } from "../../../interfaces/Balance";
import { getExpectedBalance } from "../../../services/Balance";
import { isCardWizardActive } from "../../../services/System";
import Select from "react-select";
import { findLastOfficeStatus } from "../../../services/Report";
import { ReportOfficeStatus } from "../../../interfaces/ReportOfficeStatus";

export interface MovementAddProps extends RouteComponentProps, AppContextProps { }
export interface MovementAddState {
    region: string;
    regionName: string;
    regions: Region[];
    office: string;
    officeName: string;
    offices: BranchOffice[];
    movementTypes: MovementType[];
    deviceTypes: DeviceType[];
    movement: string;
    movementDesc: string;
    stockId: string;
    stockDesc: string;
    step: number;
    cards: number;
    comment: string;
    data: Movement[];
    id: number;
    showSelectRegionAndLocation: boolean;
    maxVaultToDevice: Map<string, number>;
    backendActive: boolean;
    isOpened: boolean;
}

export class MovementAddMain extends React.Component<MovementAddProps, MovementAddState> {
    reachTheLastPage: boolean = false;
    constructor(props: MovementAddProps) {
        super(props);
        this.handleRegion = this.handleRegion.bind(this);
        this.handleBranchOffice = this.handleBranchOffice.bind(this);
        this.handleMovement = this.handleMovement.bind(this);
        this.handleStock = this.handleStock.bind(this);
        this.createCustomInsertButton = this.createCustomInsertButton.bind(this);
        this.createCustomDeleteButton = this.createCustomDeleteButton.bind(this);
        this.handleCards = this.handleCards.bind(this);
        this.handleComment = this.handleComment.bind(this);
        this.getRegionsFromAPI = this.getRegionsFromAPI.bind(this);
        this.getOfficesFromAPI = this.getOfficesFromAPI.bind(this);
        this.getDeviceTypesFromAPI = this.getDeviceTypesFromAPI.bind(this);
        this.handleInsertButtonClick = this.handleInsertButtonClick.bind(this);
        this.idKeyValidator = this.idKeyValidator.bind(this);
        this.customAfterDeleteRow = this.customAfterDeleteRow.bind(this);
        this.sendMovementsToAPI = this.sendMovementsToAPI.bind(this);
        this.isBackendActive = this.isBackendActive.bind(this);
        this.getStatusOffice = this.getStatusOffice.bind(this);
        this.getExpectedInventoryBalanceFromAPI = this.getExpectedInventoryBalanceFromAPI.bind(this);

        this.state = {
            region: "",
            regionName: "",
            regions: [],
            office: "",
            officeName: "",
            offices: [],
            movementTypes: [{ stockMovementCode: "VTOD", stockMovementDescription: "Bóveda a Impresora"}],
            movement: "VTOD",
            movementDesc: "Bóveda a Impresora",
            deviceTypes: [],
            stockId: "",
            stockDesc: "",
            comment: "",
            step: 0,
            cards: 0,
            data: [],
            id: 0,
            showSelectRegionAndLocation: false,
            maxVaultToDevice: new Map(),
            backendActive: true,
            isOpened: false,
        };
    }

    componentDidMount() {
        if (this.props.authUser && this.props.authUser.branchOffice) {
            if (this.props.authUser.branchOffice.code === "1") {
                this.setState({ showSelectRegionAndLocation: true }, this.getRegionsFromAPI);
            }
            else {
                this.setState({
                    office: this.props.authUser.branchOffice.code,
                    officeName: this.props.authUser.branchOffice.name,
                    regionName: this.props.authUser.branchOffice.region.regionName
                }, this.getStatusOffice);
            }
        }
        this.getDeviceTypesFromAPI();

        if (this.props.useCardWizard) {
            this.setState({ backendActive: false }, this.isBackendActive);
        }
    }


    handleRegion(e: any) {
        e.preventDefault();
        const regionSelected = e.target.value;
        this.setState({ region: regionSelected }, this.getOfficesFromAPI);
    }

    handleBranchOffice(e: any) {
        e.preventDefault();
        const officeSelected = e.target.value;
        this.setState({ office: officeSelected }, this.getStatusOffice);
    }

    handleMovement(e: any) {
        e.preventDefault();
        const movementSelected = e.target.value;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        this.setState({ movement: movementSelected, movementDesc: text });
    }

    handleStock(stockType: any) {
        this.setState({ stockId: stockType.value, stockDesc: stockType.label }, this.getExpectedInventoryBalanceFromAPI);
    }

    handleCards(e: any) {
        e.preventDefault();
        const cards = e.target.value;
        this.setState({ cards });
    }

    handleComment(e: any) {
        e.preventDefault();
        const comment = e.target.value;
        this.setState({ comment });
    }

    createCustomInsertButton = (onClick: any) => {
        return (
            <button disabled={ this.state.maxVaultToDevice.size == 0 || this.state.comment.length == 0 } className="btn btn-success" onClick={() => this.handleInsertButtonClick()}><i className="fa fa-plus"></i> {i18n.t('add')}</button>
        );
    }

    handleInsertButtonClick() {
        if (this.state.stockId !== "NONE" && this.state.stockId !== "" && this.state.cards > 0 &&
            this.state.movement !== "NONE" && this.state.movement !== "" && this.state.office != "NONE" && this.state.office != "") {
            if (this.checkMaxVaultToDevice(this.state.stockId, this.state.movement, this.state.cards)) {
                this.setState({ id: this.state.id + 1 });
                var currentData: Movement[] = this.state.data;
                var newData: Movement = {
                    id: this.state.id,
                    region: this.state.region,
                    office: this.state.office,
                    movement: this.state.movement,
                    movementDesc: this.state.movementDesc,
                    stockId: this.state.stockId,
                    stockDesc: this.state.stockDesc,
                    cards: this.state.cards,
                    comment: this.state.comment
                };
                currentData.push(newData);
                this.setState({ data: currentData, stockId: "", cards: 0, comment: "" });
            } else {
                this.props.setMessage({ message: i18n.t('movement-not-allowed'), type: MESSAGE_WARNING });
            }
        }
        else {
            this.props.setMessage({ message: i18n.t('movement-form-complete'), type: MESSAGE_WARNING });
        }
    }

    createCustomDeleteButton = () => {
        return (
            <DeleteButton
                btnText={i18n.t('remove')}
                btnContextual='btn-warning'
                btnGlyphicon='fa-times'
            />
        );
    }

    customAfterDeleteRow(rowKeys: number[]): void {
        var currentData: Movement[] = this.state.data;
        currentData = currentData.filter((movement: Movement) => {
            return !rowKeys.includes(movement.id);
        });
        this.setState({ data: currentData });
    }

    idKeyValidator = (value: string) => {
        if (value.trim() === "") {
            return i18n.t('field-cannot-empty');
        }
        return true;
    }

    getRegionsFromAPI(): void {
        this.props.showLoading(true);
        getRegionList().then((regionArray: Region[]) => {
            this.setState({ regions: regionArray }, () => {
                this.props.showLoading(false);
            });
        })
            .catch((response: ResponseError) => {
                this.props.showLoading(false);
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
    }

    getOfficesFromAPI(): void {
        if (this.state.region !== "" && this.state.region !== "NONE") {
            this.props.showLoading(true);
            getBranchOfficeList(this.state.region).then((officeArray: BranchOffice[]) => {
                this.setState({ offices: officeArray }, () => {
                    this.props.showLoading(false);
                });
            })
                .catch((response: ResponseError) => {
                    this.props.showLoading(false);
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                });
        }
    }

    getStatusOffice(): void {
        this.props.showLoading(true);
        findLastOfficeStatus(this.state.office).then((statusOffice: ReportOfficeStatus[]) => {
            if (statusOffice.length === 0 || !statusOffice[0].opened || statusOffice[0].closened) {
                this.props.setMessage({ message: i18n.t('movement-brach-not-opening'), type: MESSAGE_ERROR });
                this.props.showLoading(false);
            }
            else {
                this.setState({ isOpened: true }, () => {
                    this.props.showLoading(false);
                });
            }

        }).catch((response: ResponseError) => {
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
            this.props.showLoading(false);
        });
    }

    getDeviceTypesFromAPI(): void {
        this.props.showLoading(true);
        getDeviceTypeList().then((deviceTypeArray: DeviceType[]) => {
            this.setState({ deviceTypes: deviceTypeArray }, () => {
                this.props.showLoading(false);
            });
        })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    isBackendActive(): void {
        isCardWizardActive().then((result: boolean) => {
            if (!result) this.props.setMessage({ message: i18n.t('verifying-cw-err'), type: MESSAGE_ERROR });
            this.setState({ backendActive: result });
        })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
    }

    sendMovementsToAPI(): void {
        this.props.showLoading(true);
        sendMovementList(this.state.data).then(() => {
            this.props.setMessage({ message: i18n.t('movement-created'), type: MESSAGE_SUCCESS });
            this.setState({ data: [] }, () => {
                this.props.showLoading(false);
            });
            ;
        })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    customConfirm = (next: () => void) => {
        if (confirm(i18n.t('movement-delete-confirm'))) {
            next();
        }
    }

    getExpectedInventoryBalanceFromAPI(): void {
        if (this.state.stockId !== "" && this.state.stockId !== "NONE" &&
            this.state.office !== "" && this.state.office !== "NONE") {
            if (typeof this.state.maxVaultToDevice.get(this.state.stockId) === "undefined") {
                const searchBalance: SearchBalance = { branchOfficeCode: this.state.office, deviceTypeCode: this.state.stockId };
                getExpectedBalance(searchBalance).then((balance: Balance) => {
                    var maxMap = this.state.maxVaultToDevice;
                    maxMap.set(this.state.stockId, balance.expectedQuantityInVault);
                    this.setState({ maxVaultToDevice: maxMap });
                })
                    .catch((response: ResponseError) => {
                        apiHandleErrorCode(response.status, this.props.history);
                        this.props.setMessage(response.message);
                    });
            }
        }
    }

    checkMaxVaultToDevice(stockId: string, movementType: string, cards: number): boolean {
        this.props.showLoading(true);

        var movements: Movement[] = this.state.data;
        var vaultToDevice: number = 0;
        var maxVaultToDevice: number = this.state.maxVaultToDevice.get(stockId)!;

        if (movementType == "VTOD") {
            vaultToDevice = + vaultToDevice + + cards;
        }

        movements.forEach(movement => {
            if (movement.movement == "VTOD" && movement.stockId == stockId) {
                vaultToDevice = + vaultToDevice + + movement.cards;
            }
        });

        this.props.showLoading(false);
        return vaultToDevice <= maxVaultToDevice;
    }

    render() {

        const REG: { [key: string]: string } = this.state.regions.reduce((map: { [key: string]: string }, region: Region) => {
            map[region.codeRegion] = region.regionName;
            return map;
        }, {});

        const regions: JSX.Element[] = Object.keys(REG).map((codeRegion: string) => {
            return <option key={codeRegion} value={codeRegion}>{i18n.t(REG[codeRegion])}</option>;
        });

        const OFFI: { [key: string]: string } = this.state.offices.reduce((map: { [key: string]: string }, office: BranchOffice) => {
            map[office.code] = office.name;
            return map;
        }, {});

        const branchOffices: JSX.Element[] = Object.keys(OFFI).map((codeOffice: string) => {
            return <option key={codeOffice} value={codeOffice}>{i18n.t(OFFI[codeOffice])}</option>;
        });

        const MOVE: { [key: string]: string } = this.state.movementTypes.reduce((map: { [key: string]: string }, movementType: MovementType) => {
            map[movementType.stockMovementCode] = movementType.stockMovementDescription;
            return map;
        }, {})

        const movements: JSX.Element[] = Object.keys(MOVE).map((codeMovement: string) => {
            return <option key={codeMovement} value={codeMovement}>{i18n.t(MOVE[codeMovement])}</option>;
        });

        const STOCK: { [key: string]: string } = this.state.deviceTypes.reduce((map: { [key: string]: string }, deviceType: DeviceType) => {
            map[deviceType.codeDeviceType] = deviceType.nameDeviceType;
            return map;
        }, {});

        const stocks: { label: string, value: string }[] = Object.keys(STOCK).map((codeStock: string) => {
            return { label: i18n.t(STOCK[codeStock]), value: codeStock }
        });

        const options = {
            insertBtn: this.createCustomInsertButton,
            deleteBtn: this.createCustomDeleteButton,
            insertFailIndicator: i18n.t('data-errors'),
            afterDeleteRow: this.customAfterDeleteRow,
            noDataText: i18n.t('table-empty'),
            handleConfirmDeleteRow: this.customConfirm
        };

        return (
            <div className="panel-body">

                <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('devices-movements')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        {this.state.showSelectRegionAndLocation &&
                            <div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('region')}:</label>
                                    <select className="form-control" onChange={this.handleRegion} value={this.state.region}>
                                        <option value="NONE">{i18n.t('option-select')}</option>
                                        {regions}
                                    </select>
                                </div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('branch-office')}:</label>
                                    <select className="form-control" onChange={this.handleBranchOffice} value={this.state.office}>
                                        <option value="NONE">{i18n.t('option-select')}</option>
                                        {branchOffices}
                                    </select>
                                </div>
                            </div>
                        }
                        {!this.state.showSelectRegionAndLocation &&
                            <div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('region')}:</label>
                                    <p className="form-control">{this.state.regionName}</p>
                                </div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('branch-office')}:</label>
                                    <p className="form-control">{this.state.officeName}</p>
                                </div>
                            </div>
                        }
                        <div className="form-group col-sm-4">
                            <label>{i18n.t('movement')}:</label>
                            <select className="form-control" onChange={this.handleMovement} value={this.state.movement}>
                                {movements}
                            </select>
                        </div>
                    </div>
                </div>
                {this.state.isOpened && <div className="card margin-card-top">
                    <div className="card-body">
                        <div className="form-group col-sm-2">
                            <label>{i18n.t('device-type')}:</label>
                            <Select
                                options={stocks}
                                isSearchable={true}
                                onChange={this.handleStock}
                                placeholder={i18n.t('option-select')}
                            />
                        </div>
                        <div className="form-group col-sm-2">
                            <label>{i18n.t('devices-quantity')}:</label>
                            <input type="number" name="devices-quantity" className="form-control" id="devices-quantity" onChange={this.handleCards} value={this.state.cards} />
                        </div>
                        <div className="form-group col-sm-8">
                            <label>{i18n.t('comment-required')}:</label>
                            <textarea name="comment" className="form-control" id="comment" onChange={this.handleComment} maxLength={250} value={this.state.comment} />
                        </div>
                        <div className="form-group col-sm-12">
                            <BootstrapTable
                                data={this.state.data}
                                options={options}
                                selectRow={TABLE_ROW_SELECTION}
                                bordered hover insertRow deleteRow >
                                <TableHeaderColumn isKey dataField='id' hidden >id</TableHeaderColumn>
                                <TableHeaderColumn dataField='movementDesc' tdStyle={{ whiteSpace: 'normal' }} dataSort={true} dataAlign="center" width="130">{i18n.t('movement')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='stockDesc' dataSort={true} dataAlign="center" width="130" editable={{ validator: this.idKeyValidator }}>{i18n.t('stock')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='cards' dataSort={true} dataAlign="center" width="130">{i18n.t('devices-quantity')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='comment' tdStyle={{ whiteSpace: 'normal' }} dataSort={true} dataAlign="center" width="130">{i18n.t('comment')}</TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                        <div className="form-group col-sm-4">
                            <button disabled={this.state.data.length === 0 || !this.state.backendActive} type="button" className="btn btn-info" onClick={this.sendMovementsToAPI}>{i18n.t('send')}</button>
                        </div>
                    </div>
                </div>}
            </div>
        )
    }
}

export const MovementAdd = withAppContext(MovementAddMain);
