import * as React from "react";
import i18n from "../../../../i18n";
import { ShipmentEvent } from "../../../../interfaces/Event";
import { ExportCSVButton, BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { TABLE_ROW_SELECTION } from "../../../../const/Table";
import { shipmentStateFormatter } from "../../../../utils/Formatter";
import { withAppContext } from "../../../../context/AppContext";

export interface ShipmentEventsTableProps { 
    events: ShipmentEvent[];
    showShipmentEventDetails: boolean;
}

export interface ShipmentEventsTableState {
    tableOptions: any;
 }

export class ShipmentEventsTableMain extends React.Component<ShipmentEventsTableProps, ShipmentEventsTableState> {

    constructor(props: ShipmentEventsTableProps) {
        super(props);
        this.state = {
            tableOptions: {
                noDataText: i18n.t('table-empty'),
                sizePerPageList: [{
                    text: '10', value: 10
                }, {
                    text: '20', value: 20
                }, {
                    text: '50', value: 50
                }],
                sizePerPage: 10,
                paginationSize: 3,
                pageStartIndex: 1,
                exportCSVBtn: (onClick: any) => { return <ExportCSVButton btnText={i18n.t('csv-save')} btnContextual='btn-info' onClick={onClick} /> }
            }
        }
    }

    render() {

        let eventColSpan : number = this.props.showShipmentEventDetails ? 5 : 2;

        return (
            <BootstrapTable 
                data={this.props.events} 
                options={this.state.tableOptions}
                bordered={true}
                pagination hover
                exportCSV
                selectRow={TABLE_ROW_SELECTION}>
                <TableHeaderColumn headerAlign="center" row={0} colSpan={2} export={false}>{i18n.t('user')}</TableHeaderColumn>
                <TableHeaderColumn headerAlign="center" row={0} colSpan={eventColSpan} export={false}>{i18n.t('event')}</TableHeaderColumn>
                <TableHeaderColumn row={1} dataField='userId' dataSort={true} dataAlign="center" width="150">{i18n.t('id')}</TableHeaderColumn>
                <TableHeaderColumn row={1} dataField='office' dataSort={true} dataAlign="center" width="100">{i18n.t('branch-office')}</TableHeaderColumn>
                <TableHeaderColumn row={1} dataField='shipmentCorrelative' hidden={!this.props.showShipmentEventDetails} dataSort={true} dataAlign="center" width="150">{i18n.t('shipment-code')}</TableHeaderColumn>
                <TableHeaderColumn row={1} dataField='deviceType' dataSort={true} hidden={!this.props.showShipmentEventDetails} dataAlign="center" width="200">{i18n.t('device-type')}</TableHeaderColumn>
                <TableHeaderColumn row={1} dataField='deviceQuantity' dataSort={true} hidden={!this.props.showShipmentEventDetails} dataAlign="right" width="100">{i18n.t('quantity')}</TableHeaderColumn>
                <TableHeaderColumn row={1} dataField='date' isKey={true} dataSort={true} dataAlign="center" width="160">{i18n.t('date')}</TableHeaderColumn>
                <TableHeaderColumn row={1} dataField='shipmentState' dataSort={true} dataAlign="center" dataFormat={shipmentStateFormatter} width="120">{i18n.t('status')}</TableHeaderColumn>
            </BootstrapTable>
        )
    }
}

export const ShipmentEventsTable = withAppContext(ShipmentEventsTableMain);