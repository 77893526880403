import {
    StockDeviceSummaryAPI, StockDeviceSummary, DeviceSearch, StockDeviceMonthSummary, StockDeviceMonthSummaryAPI,
    StockDeviceData, StockDeviceDataAPI, StockDeviceUpload, StockSummaryAPI, StockSummary, StockMonthSummaryAPI, StockMonthSummary, StockConfigAPI, StockConfig,
    OpeningSummary,
    OpeningSummaryAPI
} from "../interfaces/Stock";
import { helperAxios, apiErrorCallback } from "../utils/ApiBaseConfig";
import { AxiosResponse } from "axios";
import { CURUS_DEVICE_TYPES } from "../const/Device";
import i18n from "../i18n";
import { Device } from "../interfaces/Device";
import { parseDevice } from "./Device";
import { cleanProperty } from "../utils/Formatter";
import { daysInMonth, daysInPreviousMonth } from "../utils/Validator";
import { Region } from "../interfaces/Movement";
import { API_PREFIX } from "../const/Routes";
import { BranchOffice } from "../interfaces/Office";
import { parseOfficeWithDeviceType } from "./Office";

export async function searchDevices(search: DeviceSearch): Promise<Device[]> {
    return helperAxios({
        method: 'POST',
        url: API_PREFIX + '/stock/search',
        data: search
    })
        .then((response: AxiosResponse) => {
            console.log("[OK] loadStock");
            return response.data.map(parseDevice);
        })
        .catch(apiErrorCallback);
}

export function getStock(officeCode: string): Promise<StockSummary[]> {
    return helperAxios({ method: 'GET', url: API_PREFIX + '/stock/office/' + officeCode })
        .then((response: AxiosResponse) => {
            console.log("[OK] getStockDevice");
            return response.data.map(parseStock);
        })
        .catch(apiErrorCallback);
}

export function getStockForOpening(officeCode: string): Promise<OpeningSummary[]> {
    return helperAxios({ method: 'GET', url: API_PREFIX + '/stock/office/' + officeCode + '/opening' })
        .then((response: AxiosResponse) => {
            console.log("[OK] getStockForOpening");
            return response.data.map(parseOpeningStock);
        })
        .catch(apiErrorCallback);
}

export function getStockMonth(officeCode: string): Promise<StockMonthSummary[][]> {
    return helperAxios({ method: 'GET', url: API_PREFIX + '/stock/office/' + officeCode + '/month' })
        .then((response: AxiosResponse) => {
            console.log("[OK] getStockDeviceMonth");
            //return response.data.map(parseStockMonth);
            return response.data.map(parseStockMonthArray);
        })
        .catch(apiErrorCallback);
}

function parseStockDevice(stockAPI: StockDeviceSummaryAPI): StockDeviceSummary {
    return {
        officeCode: cleanProperty(stockAPI.officeCode),
        branchOffice: cleanProperty(stockAPI.branchOffice),
        minStock: stockAPI.minStock,
        currentStock: stockAPI.currentStock,
        yesterdayStock: stockAPI.yesterdayStock,
        incomes: stockAPI.incomes,
        lastIncome: stockAPI.lastIncome ? new Date(stockAPI.lastIncome) : i18n.t('no-data'),
        outcomes: stockAPI.outcomes,
        updateDate: new Date(stockAPI.updateDate),
        id: cleanProperty(stockAPI.officeCode) + cleanProperty(stockAPI.branchOffice)
    }
}

function parseStock(stockAPI: StockSummaryAPI): StockSummary {
    return {
        officeCode: cleanProperty(stockAPI.officeCode),
        branchOffice: cleanProperty(stockAPI.branchOffice),
        minStock: stockAPI.minStock,
        currentStock: stockAPI.currentStock,
        yesterdayStock: stockAPI.yesterdayStock,
        incomes: stockAPI.incomes,
        lastIncome: stockAPI.lastIncome ? new Date(stockAPI.lastIncome) : i18n.t('no-data'),
        outcomes: stockAPI.outcomes,
        updateDate: new Date(stockAPI.updateDate),
        id: cleanProperty(stockAPI.officeCode) + cleanProperty(stockAPI.branchOffice),
        deviceTypeName: stockAPI.deviceTypeName,
        deviceTypeCode: stockAPI.deviceTypeCode
    }
}

function parseOpeningStock(stockopeningAPI: OpeningSummaryAPI): OpeningSummary {
    return {
        currentStock: stockopeningAPI.currentStock,
        deviceTypeCode: stockopeningAPI.deviceTypeCode,
        deviceTypeName: stockopeningAPI.deviceTypeName,
        defAperture: stockopeningAPI.defAperture,
        remainingStock: stockopeningAPI.remainingStock
    }
}

function parseStockMonthDevice(stockAPI: StockDeviceMonthSummaryAPI): StockDeviceMonthSummary {
    const days: { [key: string]: StockDeviceData } = {};
    [...Array(daysInMonth()).keys()].forEach((day: number) => {
        const dayKey: string = 'day-' + (day + 1);
        const stockData: StockDeviceDataAPI | undefined = stockAPI.stock[day];
        days[dayKey] = stockData ? { ...stockData } : { currentStock: '-', incomes: '-', outcomes: '-' };
    });

    return {
        officeCode: cleanProperty(stockAPI.officeCode),
        branchOffice: cleanProperty(stockAPI.branchOffice),
        incomes: stockAPI.incomes,
        outcomes: stockAPI.outcomes,
        ...days,
    }
}

function parseStockMonth(stockAPI: StockMonthSummaryAPI): StockMonthSummary {
    const days: { [key: string]: StockDeviceData } = {};
    [...Array(daysInMonth()).keys()].forEach((day: number) => {
        const dayKey: string = 'day-' + (day + 1);
        const stockData: StockDeviceDataAPI | undefined = stockAPI.stock[day];
        days[dayKey] = stockData ? { ...stockData } : { currentStock: '-', incomes: '-', outcomes: '-' };
    });

    return {
        officeCode: cleanProperty(stockAPI.officeCode),
        branchOffice: cleanProperty(stockAPI.branchOffice),
        incomes: stockAPI.incomes,
        outcomes: stockAPI.outcomes,
        ...days,
        deviceType: stockAPI.deviceTypeName
    }
}

function parseStockMonthArray(stock2API: StockMonthSummaryAPI[]): StockMonthSummary[] {
    //TODO: mejorar esta  función para que el indice indique realmente el mes anterior.
    let result: StockMonthSummary[] = [];

    for (let stockAPI of stock2API) {
        const days: { [key: string]: StockDeviceData } = {};
        [...Array(stockAPI.stock.length).keys()].forEach((day: number) => {
            const dayKey: string = 'day-' + (day + 1);
            const stockData: StockDeviceDataAPI | undefined = stockAPI.stock[day];
            days[dayKey] = stockData ? { ...stockData } : { currentStock: '-', incomes: '-', outcomes: '-' };
        });

        result.push({
            officeCode: cleanProperty(stockAPI.officeCode),
            branchOffice: cleanProperty(stockAPI.branchOffice),
            incomes: stockAPI.incomes,
            outcomes: stockAPI.outcomes,
            ...days,
            deviceType: stockAPI.deviceTypeName
        });

    }

    return result;
}

export function parseStockDeviceConfig(config: StockConfigAPI): StockConfig {
    return {
        deviceType: config.deviceType,
        minStock: config.minStock,
        available: config.available,
        defAperture: config.defAperture
    }
}

export function getRegionList(): Promise<Region[]> {
    return helperAxios(API_PREFIX + '/stock/regions/')
        .then((response: AxiosResponse) => {
            console.log('[OK] getRegionList');
            return response.data;
        })
        .catch(apiErrorCallback);
}

export function getBranchOfficeList(codeRegion: string): Promise<BranchOffice[]> {
    return helperAxios(API_PREFIX + '/stock/offices/region/' + codeRegion)
        .then((response: AxiosResponse) => {
            console.log('[OK] getBranchOfficeList');
            return response.data.map(parseOfficeWithDeviceType);
        })
        .catch(apiErrorCallback);
}


