
import { API_PREFIX } from "../const/Routes";
import { ReportDevice, ReportDeviceAPI, SearchFilter } from "../interfaces/ReportDevices";
import { helperAxios, apiErrorCallback } from "../utils/ApiBaseConfig";
import { AxiosResponse } from "axios";


export function getReportDeviceByFilter(searchFilter: SearchFilter): Promise<ReportDevice[]> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/report/devices/',
        data: searchFilter
    }).then((response: AxiosResponse) => {
        console.log('[OK] getReportDeviceByFilter');
        console.log('reponse: ');
        console.log(response);
        return response.data.map(parseReportDevice);
    }).catch(apiErrorCallback);
}

export function parseReportDevice(reportDevice: ReportDeviceAPI): ReportDevice {
    return {
        groupDeviceCode: reportDevice.groupDeviceCode,
        groupDeviceName: reportDevice.groupDeviceName,
        deviceName: reportDevice.deviceName,
        embossed: reportDevice.embossed,
        rejected: reportDevice.rejected,
        total: reportDevice.total
    }
}