import * as React from "react";
import i18n from "../../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../../context/AppContext";
import Modal from 'react-modal';
import { Region } from "../../../interfaces/Movement";
import { BranchOffice } from "../../../interfaces/Office";
import { getBranchOfficeList, getRegionList } from "../../../services/Stock";
import { ResponseError, TIMEOUT_RESPONSE_ERROR, apiHandleErrorCode } from "../../../utils/ApiBaseConfig";
import { CreateCloseProposalToSend, ProposalBalance, RawProposalBalance, SearchBalance } from "../../../interfaces/Balance";
import { findCloseProposal, sendCloseProposal } from "../../../services/Balance";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { MESSAGE_SUCCESS, MESSAGE_WARNING } from "../../../const/Message";
import axios from "axios";

export interface BalanceCloseProps extends RouteComponentProps, AppContextProps { }
export interface BalanceCloseState {
    region: string;
    regionName: string;
    regions: Region[];
    office: string;
    officeName: string;
    offices: BranchOffice[];
    blockRegionAndLocation: boolean;
    balances: RawProposalBalance[];
    selectedBalance: RawProposalBalance | any;
    realQuantitySuccessful: number;
    realQuantityRejectedMachine: number;
    realQuantityRejectedQA: number;
    description: string;
    editModalIsOpen: boolean;
    sendBalancesButtonDisabled: boolean;
    saveBtnDisabledOnEditModal: boolean;
    sendToAprove: boolean;
}

export class BalanceCloseMain extends React.Component<BalanceCloseProps, BalanceCloseState> {
    editModalErrorRef: React.RefObject<HTMLDivElement>;
    authModalErrorRef: React.RefObject<HTMLDivElement>;
    constructor(props: BalanceCloseProps) {
        super(props);
        this.editModalErrorRef = React.createRef();
        this.authModalErrorRef = React.createRef();
        this.handleRegion = this.handleRegion.bind(this);
        this.handleBranchOffice = this.handleBranchOffice.bind(this);
        this.getRegionsFromAPI = this.getRegionsFromAPI.bind(this);
        this.getOfficesFromAPI = this.getOfficesFromAPI.bind(this);
        this.findCloseBalancesFromAPI = this.findCloseBalancesFromAPI.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
        this.updateBalanceDataAndCloseEditModal = this.updateBalanceDataAndCloseEditModal.bind(this);
        this.handleInputRealQuantitySuccessfulChange = this.handleInputRealQuantitySuccessfulChange.bind(this);
        this.handleInputRealQuantityRejectedMachineChange = this.handleInputRealQuantityRejectedMachineChange.bind(this);
        this.handleInputRealQuantityRejectedQAChange = this.handleInputRealQuantityRejectedQAChange.bind(this);
        this.handleInputDescriptionChange = this.handleInputDescriptionChange.bind(this);
        this.showEditModalError = this.showEditModalError.bind(this);
        this.isDisabledEditModalButton = this.isDisabledEditModalButton.bind(this);
        this.sendBalancesToAPI = this.sendBalancesToAPI.bind(this);
        this.enableSaveBtnOnEditModal = this.enableSaveBtnOnEditModal.bind(this);
        this.state = {
            region: "",
            regionName: "",
            regions: [],
            office: "",
            officeName: "",
            offices: [],
            blockRegionAndLocation: true,
            balances: [],
            selectedBalance: {},
            realQuantitySuccessful: 0,
            realQuantityRejectedMachine: 0,
            realQuantityRejectedQA: 0,
            description: "",
            editModalIsOpen: false,
            sendBalancesButtonDisabled: false,
            saveBtnDisabledOnEditModal: true,
            sendToAprove: false
        }
    }

    componentDidMount() {
        if (this.props.authUser && this.props.authUser.branchOffice) {
            if (this.props.authUser.branchOffice.code === "1") {
                this.setState({ blockRegionAndLocation: false }, () => {
                    this.getRegionsFromAPI();
                });
            }
            else {
                this.setState({
                    office: this.props.authUser.branchOffice.code,
                    officeName: this.props.authUser.branchOffice.name,
                    regionName: this.props.authUser.branchOffice.region.regionName
                });
            }
        }
    }

    handleRegion(e: any) {
        e.preventDefault();
        const regionSelected = e.target.value;
        this.setState({ region: regionSelected }, this.getOfficesFromAPI);
    }

    handleBranchOffice(e: any) {
        e.preventDefault();
        const officeSelected = e.target.value;
        this.setState({ office: officeSelected });
    }

    getRegionsFromAPI(): void {
        this.props.showLoading(true);
        getRegionList().then((regionArray: Region[]) => {
            this.setState({ regions: regionArray }, () => {
                this.props.showLoading(false);
            });
        })
            .catch((response: ResponseError) => {
                this.props.showLoading(false);
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
    }

    getOfficesFromAPI(): void {
        this.props.showLoading(true);
        if (this.state.region !== "" && this.state.region !== "NONE") {
            getBranchOfficeList(this.state.region).then((officeArray: BranchOffice[]) => {
                this.setState({ offices: officeArray }, () => {
                    this.props.showLoading(false);
                });
            })
                .catch((response: ResponseError) => {
                    this.props.showLoading(false);
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                });
        }
    }

    findCloseBalancesFromAPI(): void {
        this.props.showLoading(true);
        const searchBalance: SearchBalance = { branchOfficeCode: this.state.office };
        findCloseProposal(searchBalance)
            .then((balances: RawProposalBalance[]) => {
                this.setState({ balances: balances }, () => {
                    this.props.showLoading(false);
                });
            })
            .catch((response: ResponseError) => {
                this.props.showLoading(false);
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
    }



    sendBalancesToAPI() {
        this.props.showLoading(true);
        this.setState({ sendBalancesButtonDisabled: true });
        let balancesToSend: ProposalBalance[] = [];
        for (const rawBalance of this.state.balances) {
            if (rawBalance.pending) {
                this.setState({ sendToAprove: true })
            }
            const balance: ProposalBalance = {
                correlative: 0,
                branchOffice: { code: this.state.office, name: this.state.officeName },
                deviceType: { code: rawBalance.deviceTypeCode, name: rawBalance.deviceTypeName, available: true },
                user: { group: "identity", userId: "", password: "" },
                userSupervisor: { group: "identity", userId: "", password: "" },
                description: rawBalance.description,
                vaultToMachine: rawBalance.vaultToMachine,
                expectedMachineToVault: rawBalance.expectedMachineToVault,
                expectedQuantityInVault: rawBalance.expectedQuantityInVault,
                expectedQuantityRejectedMachine: rawBalance.expectedQuantityRejectedMachine,
                expectedQuantityRejectedQA: rawBalance.expectedQuantityRejectedQA,
                expectedQuantitySuccessful: rawBalance.expectedQuantitySuccessful,
                expectedTotalQuantityOutVault: rawBalance.expectedTotalQuantityOutVault,
                realMachineToVault: rawBalance.realMachineToVault,
                realQuantityInMachine: rawBalance.realQuantityInMachine,
                realQuantityInOther: rawBalance.realQuantityInOther,
                realQuantityInVault: rawBalance.realQuantityInVault,
                realQuantityRejectedMachine: rawBalance.realQuantityRejectedMachine,
                realQuantityRejectedQA: rawBalance.realQuantityRejectedQA,
                realQuantitySuccessful: rawBalance.realQuantitySuccessful,
                realTotalQuantityOutVault: rawBalance.realTotalQuantityOutVault,
                pending: rawBalance.pending
            };
            balancesToSend.push(balance);
        }
        const model: CreateCloseProposalToSend = {
            balances: balancesToSend,
            authorization: { group: "identity", userId: "", password: "" }
        }
        sendCloseProposal(model).then(() => {
            if (this.props.authUser) {
                if (this.state.sendToAprove && this.props.authUser.role.codeRole != "SUCSP" && this.props.authUser.role.codeRole != "SUADM") {

                    this.props.setMessage({ message: i18n.t('balance-sent-close-to-aprove-ok'), type: MESSAGE_SUCCESS });
                }
                else {
                    this.props.setMessage({ message: i18n.t('balance-sent-close-ok'), type: MESSAGE_SUCCESS });
                }
            }

            this.setState({
                balances: [],
                selectedBalance: {},
                realQuantitySuccessful: 0,
                realQuantityRejectedMachine: 0,
                realQuantityRejectedQA: 0,
                description: "",
                editModalIsOpen: false,
                sendBalancesButtonDisabled: false,
                sendToAprove: false
            }, () => {
                this.props.showLoading(false);
            });
        }).catch((response: ResponseError) => {
            if (response === TIMEOUT_RESPONSE_ERROR) {
                // Error de timeout
                this.props.setMessage({ message: i18n.t('request-timeout'), type: MESSAGE_WARNING });
                this.setState({
                    balances: [],
                    selectedBalance: {},
                    realQuantitySuccessful: 0,
                    realQuantityRejectedMachine: 0,
                    realQuantityRejectedQA: 0,
                    description: "",
                    editModalIsOpen: false,
                    sendToAprove: false
                });
            } else {
                // Otros errores
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            }
            this.props.showLoading(false);
            this.setState({
                sendBalancesButtonDisabled: false,
                balances: [],
                selectedBalance: {},
                realQuantitySuccessful: 0,
                realQuantityRejectedMachine: 0,
                realQuantityRejectedQA: 0,
                description: "",
                editModalIsOpen: false,
                sendToAprove: false
            });
        });
    }

    openEditModal(record: any) {
        this.setState({
            editModalIsOpen: true,
            selectedBalance: record,
            realQuantitySuccessful: record.realQuantitySuccessful,
            realQuantityRejectedMachine: record.realQuantityRejectedMachine,
            realQuantityRejectedQA: record.realQuantityRejectedQA,
            description: record.description,
            saveBtnDisabledOnEditModal: true
        });
    }

    closeEditModal() {
        this.setState({ editModalIsOpen: false });
    }

    updateBalanceDataAndCloseEditModal() {
        let diff = this.state.selectedBalance.vaultToMachine - this.state.realQuantitySuccessful -
            this.state.realQuantityRejectedMachine - this.state.realQuantityRejectedQA;
        if (diff < 0) {
            this.showEditModalError();
        } else {
            let selected = this.state.selectedBalance;
            selected.realQuantitySuccessful = +this.state.realQuantitySuccessful;
            selected.realQuantityRejectedMachine = +this.state.realQuantityRejectedMachine;
            selected.realQuantityRejectedQA = +this.state.realQuantityRejectedQA;
            selected.description = this.state.description;

            selected.realMachineToVault = diff;
            selected.totalRejected = parseInt(selected.realQuantityRejectedMachine, 10) + parseInt(selected.realQuantityRejectedQA, 10);

            this.setState({ selectedBalance: selected, editModalIsOpen: false });
        }
    }




    handleInputRealQuantitySuccessfulChange(e: any) {
        e.preventDefault();
        this.setState({ realQuantitySuccessful: e.target.value }, this.enableSaveBtnOnEditModal);
    }

    handleInputRealQuantityRejectedMachineChange(e: any) {
        e.preventDefault();
        this.setState({ realQuantityRejectedMachine: e.target.value }, this.enableSaveBtnOnEditModal);
    }

    handleInputRealQuantityRejectedQAChange(e: any) {
        e.preventDefault();
        this.setState({ realQuantityRejectedQA: e.target.value }, this.enableSaveBtnOnEditModal);
    }

    handleInputDescriptionChange(e: any) {
        e.preventDefault();
        this.setState({ description: e.target.value }, this.enableSaveBtnOnEditModal);
    }

    showEditModalError() {
        this.editModalErrorRef.current!.className = "alert alert-danger";
        setTimeout(() => {
            if (this.state.editModalIsOpen && this.editModalErrorRef.current) {
                this.editModalErrorRef.current.className = "";
                this.editModalErrorRef.current.hidden = true;
            }
        }, 10000);
    }



    trClassName(row: RawProposalBalance, rowIndex: number) {
        var color = '';
        if(row.realQuantityRejectedMachine > 0 || row.realQuantityRejectedQA > 0)
            color = 'warning';

        if(row.realMachineToVault < 0)
            color = 'danger';

        return row.realQuantitySuccessful != row.expectedQuantitySuccessful ||
            row.realQuantityRejectedMachine != row.expectedQuantityRejectedMachine ||
            row.realQuantityRejectedQA != row.expectedQuantityRejectedQA ? 'success' : color;
    }


    isDisabledEditModalButton(): boolean {
        if (isNaN(+this.state.realQuantitySuccessful) ||
            isNaN(+this.state.realQuantityRejectedMachine) ||
            isNaN(+this.state.realQuantityRejectedQA) || this.state.saveBtnDisabledOnEditModal
        )
            return true;
        if ((this.state.realQuantitySuccessful !== this.state.selectedBalance.expectedQuantitySuccessful ||
            this.state.realQuantityRejectedMachine !== this.state.selectedBalance.expectedQuantityRejectedMachine ||
            this.state.realQuantityRejectedQA !== this.state.selectedBalance.expectedQuantityRejectedQA) &&
            this.state.description.length == 0
        )
            return true;

        return false;
    }

    enableSaveBtnOnEditModal() {
        this.setState({ saveBtnDisabledOnEditModal: false });
    }

    render() {
        const customStyles: any = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            }
        };

        const REG: { [key: string]: string } = this.state.regions.reduce((map: { [key: string]: string }, region: Region) => {
            map[region.codeRegion] = region.regionName;
            return map;
        }, {});

        const regions: JSX.Element[] = Object.keys(REG).map((codeRegion: string) => {
            return <option key={codeRegion} value={codeRegion}>{i18n.t(REG[codeRegion])}</option>;
        });

        const OFFI: { [key: string]: string } = this.state.offices.reduce((map: { [key: string]: string }, office: BranchOffice) => {
            map[office.code] = office.name;
            return map;
        }, {});

        const branchOffices: JSX.Element[] = Object.keys(OFFI).map((codeOffice: string) => {
            return <option key={codeOffice} value={codeOffice}>{i18n.t(OFFI[codeOffice])}</option>;
        });

        return (
            <>
                <div className="panel-body">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <div className="title">{i18n.t('balance-close-title')}</div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="form-group col-sm-12">
                                {!this.state.blockRegionAndLocation &&
                                    <div>
                                        <div className="form-group col-sm-3">
                                            <label>{i18n.t('region')}:</label>
                                            <select className="form-control" onChange={this.handleRegion} value={this.state.region} disabled={this.state.balances.length > 0}>
                                                <option value="NONE">{i18n.t('option-select')}</option>
                                                {regions}
                                            </select>
                                        </div>
                                        <div className="form-group col-sm-3">
                                            <label>{i18n.t('branch-office')}:</label>
                                            <select className="form-control" onChange={this.handleBranchOffice} value={this.state.office} disabled={this.state.balances.length > 0}>
                                                <option value="NONE">{i18n.t('option-select')}</option>
                                                {branchOffices}
                                            </select>
                                        </div>
                                    </div>
                                }
                                {this.state.blockRegionAndLocation &&
                                    <div>
                                        <div className="form-group col-sm-3">
                                            <label>{i18n.t('region')}:</label>
                                            <p className="form-control">{this.state.regionName}</p>
                                        </div>

                                        <div className="form-group col-sm-3">
                                            <label>{i18n.t('branch-office')}:</label>
                                            <p className="form-control">{this.state.officeName}</p>
                                        </div>
                                    </div>
                                }
                                <div className="form-group col-sm-2">
                                    <br></br>
                                    <button type="button" className="btn btn-info" onClick={this.findCloseBalancesFromAPI}
                                        disabled={this.state.office === "" || this.state.office === "NONE" || this.state.balances.length > 0}>{i18n.t('make-balance-close-generate')}</button>
                                </div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('balance-close-legend')}:</label>
                                    <ul className="list-unstyled">
                                        <li><span className="label label-danger"> </span><span style={ {marginLeft: '5px' }}>{i18n.t('balance-close-legend-danger')}</span></li>
                                        <li><span className="label label-warning"> </span><span style={ {marginLeft: '5px' }}>{i18n.t('balance-close-legend-warning')}</span></li>
                                        <li><span className="label label-success"> </span><span style={ {marginLeft: '5px' }}>{i18n.t('balance-close-legend-success')}</span></li>
                                    </ul>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.balances.length > 0 &&
                        <div className="card">
                            <div className="card-body">
                                <div className="form-group col-sm-12">
                                    <BootstrapTable
                                        data={this.state.balances}
                                        bordered
                                        hover
                                        trClassName={this.trClassName.bind(this)}>
                                        <TableHeaderColumn dataField='deviceTypeCode' isKey={true} tdStyle={ { whiteSpace: 'normal' } } dataSort={true} dataAlign="center" filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} >{i18n.t('device-code')}</TableHeaderColumn>
                                        <TableHeaderColumn dataField='deviceTypeName' tdStyle={ { whiteSpace: 'normal' } } dataSort={true} dataAlign="center" filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} >{i18n.t('device')}</TableHeaderColumn>
                                        <TableHeaderColumn dataField='vaultToMachine'>{i18n.t('balance-out-vault')} <span data-toggle="tooltip" data-placement="top" title={i18n.t('balance-out-vault-explanation')}><i className='glyphicon glyphicon-info-sign'></i></span>
                                        </TableHeaderColumn>
                                        <TableHeaderColumn dataField='realQuantitySuccessful'>{i18n.t('balance-successful-emission')} <span data-toggle="tooltip" data-placement="top" title={i18n.t('balance-successful-emission-explanation')}><i className='glyphicon glyphicon-info-sign'></i></span>
                                        </TableHeaderColumn>
                                        <TableHeaderColumn dataField='totalRejected'>{i18n.t('balance-failed-emission')} <span data-toggle="tooltip" data-placement="top" title={i18n.t('balance-failed-emission-explanation')}><i className='glyphicon glyphicon-info-sign'></i></span>
                                        </TableHeaderColumn>
                                        <TableHeaderColumn dataField='realMachineToVault'>{i18n.t('balance-expected-to-vault')} <span data-toggle="tooltip" data-placement="top" title={i18n.t('balance-expected-to-vault-explanation')}><i className='glyphicon glyphicon-info-sign'></i></span>
                                        </TableHeaderColumn>
                                        <TableHeaderColumn dataField='' tdStyle={{ textAlign: 'center' }} dataFormat={(cell, row) => (
                                            <button onClick={() => this.openEditModal(row)}>
                                                <span><i className='glyphicon glyphicon-pencil'></i></span>
                                            </button>
                                        )}>{i18n.t('balance-edit')}</TableHeaderColumn>
                                    </BootstrapTable>
                                </div>
                            </div>
                            <button className="btn btn-info pull-right" disabled={this.state.sendBalancesButtonDisabled || this.state.balances.some( balance => balance.realMachineToVault < 0)} onClick={this.sendBalancesToAPI}>{i18n.t('balance-send')}</button>
                        </div>
                    }
                </div>
                <Modal
                    isOpen={this.state.editModalIsOpen}
                    onRequestClose={this.closeEditModal}
                    style={customStyles}
                    ariaHideApp={false}
                >
                    <h2>{i18n.t('balance-adjustment')}</h2>
                    <div ref={this.editModalErrorRef} hidden={true}>
                        <label style={{ width: '350px' }}>{i18n.t('balance-edit-error-message')}</label>
                    </div>
                    <div className="card-body">
                        <div className="form-group">
                            <label>{i18n.t('device-code')}:</label>
                            <input disabled
                                type="text"
                                value={this.state.selectedBalance.deviceTypeCode}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <label>{i18n.t('device')}:</label>
                            <input disabled
                                type="text"
                                value={this.state.selectedBalance.deviceTypeName}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <label>{i18n.t('balance-successful-emission')}:</label>
                            <input
                                type="number"
                                name="realQuantitySuccessful"
                                value={this.state.realQuantitySuccessful}
                                onChange={this.handleInputRealQuantitySuccessfulChange}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <label>{i18n.t('balance-reject-in-machine')}:</label>
                            <input
                                type="number"
                                name="realQuantityRejectedMachine"
                                value={this.state.realQuantityRejectedMachine}
                                onChange={this.handleInputRealQuantityRejectedMachineChange}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <label>{i18n.t('balance-reject-qa')}:</label>
                            <input
                                type="number"
                                name="realQuantityRejectedQA"
                                value={this.state.realQuantityRejectedQA}
                                onChange={this.handleInputRealQuantityRejectedQAChange}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <label>{i18n.t('comment-required')}:</label>
                            <textarea
                                name="description"
                                maxLength={250}
                                value={this.state.description}
                                onChange={this.handleInputDescriptionChange}
                                className="form-control"
                            />
                        </div>
                        <button className="btn btn-default pull-left" onClick={this.closeEditModal}>{i18n.t('close')}</button>
                        <button className="btn btn-info pull-right" disabled={this.isDisabledEditModalButton()} onClick={this.updateBalanceDataAndCloseEditModal}>{i18n.t('update')}</button>
                    </div>

                </Modal>
            </>
        )
    }
}

export const BalanceClose = withAppContext(BalanceCloseMain);