import * as React from "react";
import i18n from "../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps } from "../../context/AppContext";
import { ResponseError, apiHandleErrorCode } from "../../utils/ApiBaseConfig";
import { Role } from "../../interfaces/Role";
import { NoticeConfParams, TYPE_USER } from "../../const/Routes";
import { withAppUserContext } from "../../context/UserContext";
import { NoticeConfiguration, SearchNotices } from "../../interfaces/Notice";
import { findNoticesConf, getNoticeConf, updateNoticeConf } from "../../services/Notice";
import { getRolesList } from "../../services/Role";
import { getAllNamesAndCodes } from "../../services/User";
import { IDENTITY_ADMIN_GROUP } from "../../const/Group";
import { getBranchOfficeList, getRegionList } from "../../services/Stock";
import { Region } from "../../interfaces/Movement";
import { getBranchOffice, updateConfNoticeOffice, updateOffice } from "../../services/Office";
import { BranchOffice, BranchOfficeAPI } from "../../interfaces/Office";
import { MESSAGE_SUCCESS } from "../../const/Message";
import { System } from "../system/System";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { formatNoticeNameForList } from "../../utils/Formatter";
import { NOTICE_CONFIGURATION_EDIT_PERM } from "../../const/Permission";
import Modal from 'react-modal';

interface NoticeFormProps extends RouteComponentProps<NoticeConfParams>, AppContextProps { }

interface NoticeFormState {
    roleList: Role[];
    assignedRoles: string[];
    userList: string[];
    assignedUsers: string[];
    mails: string;
    itemsCurrent: string[];
    itemsAvailable: string[];
    showSelectRegionAndLocation: boolean;
    office: string;
    officeName: string;
    offices: BranchOffice[];
    regionName: string;
    branchOffice: BranchOffice;
    region: string;
    regions: Region[];
    data: NoticeConfiguration[];
    tableOptions: any;
    selectedNotice: NoticeConfiguration | null;
    editModalIsOpen: boolean;
    initialAssignedRoles: string[];
    initialAssignedUsers: string[];
    initialMails: string;
    currentFilter: string;
    availableFilter: string;
}

export class NoticeFormMain extends React.Component<NoticeFormProps, NoticeFormState> {
    currentRef: any;
    availableRef: any;

    constructor(props: NoticeFormProps) {
        super(props);
        this.fetchNoticeConf = this.fetchNoticeConf.bind(this);
        this.fetchRoles = this.fetchRoles.bind(this);
        this.fetchNamesAndCodes = this.fetchNamesAndCodes.bind(this);
        this.changeRoles = this.changeRoles.bind(this);
        this.onSelectCurrent = this.onSelectCurrent.bind(this);
        this.onSelectAvailable = this.onSelectAvailable.bind(this);
        this.addUsers = this.addUsers.bind(this);
        this.removeUsers = this.removeUsers.bind(this);
        this.handleMailsChange = this.handleMailsChange.bind(this);
        this.updateAction = this.updateAction.bind(this);
        this.handleRegion = this.handleRegion.bind(this);
        this.handleBranchOffice = this.handleBranchOffice.bind(this);
        this.changeDefaultConf = this.changeDefaultConf.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
        this.handleCurrentFilterChange = this.handleCurrentFilterChange.bind(this);
        this.handleAvailableFilterChange = this.handleAvailableFilterChange.bind(this);
        this.filteredAssignedUsers = this.filteredAssignedUsers.bind(this);
        this.filteredAvailableUsers = this.filteredAvailableUsers.bind(this);

        this.state = {
            roleList: [],
            assignedRoles: [],
            userList: [],
            assignedUsers: [],
            mails: '',
            itemsCurrent: [],
            itemsAvailable: [],
            showSelectRegionAndLocation: false,
            office: "",
            officeName: "",
            offices: [],
            regionName: "",
            branchOffice: {
                code: '',
                name: '',
                enabled: true,
                address: '',
                managerUserName: '',
                notificationMail: '',
                updateDate: new Date(),
                stockConfig: [],
                region: { codeRegion: '', regionName: '', regionActive: true },
                defaultNotifyConf: true
            },
            region: "",
            regions: [],
            data: [],
            tableOptions: {
                noDataText: i18n.t('table-empty'),
                sizePerPageList: [{
                    text: '10', value: 10
                }, {
                    text: '20', value: 20
                }, {
                    text: '50', value: 50
                }],
                sizePerPage: 10,
                paginationSize: 3,
                pageStartIndex: 1,
            },
            selectedNotice: null,
            editModalIsOpen: false,
            initialAssignedRoles: [],
            initialAssignedUsers: [],
            initialMails: "",
            currentFilter: "",
            availableFilter: ""

        }

        this.currentRef = React.createRef();
        this.availableRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.authUser && this.props.authUser.branchOffice) {
            if (this.props.authUser.branchOffice.code === "1") {
                this.setState({ showSelectRegionAndLocation: true }, this.getRegionsFromAPI);
            }
            else {
                this.setState({
                    office: this.props.authUser.branchOffice.code,
                    officeName: this.props.authUser.branchOffice.name,
                    regionName: this.props.authUser.branchOffice.region.regionName
                }, this.fetchOffice);
            }
        }
        /*if (this.props.match.params.noticeConfId)
            this.fetchNoticeConf(this.props.match.params.noticeConfId);*/
    }

    fetchOffice() {
        this.props.showLoading(true);
        getBranchOffice(this.state.office)
            .then((branchOffice: BranchOffice) => {
                if (branchOffice.defaultNotifyConf) {
                    this.setState({ branchOffice }, this.props.showLoading(false));
                }
                else {
                    this.props.showLoading(false);
                    this.setState({ branchOffice }, this.findBranchNoticesConf);
                }
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    getRegionsFromAPI(): void {
        this.props.showLoading(true);
        getRegionList().then((regionArray: Region[]) => {
            this.setState({ regions: regionArray }, this.props.showLoading(false));
        })
            .catch((response: ResponseError) => {
                this.props.showLoading(false);
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
    }

    handleRegion(e: any) {
        e.preventDefault();
        this.setState({
            branchOffice: {
                ...this.state.branchOffice,
                region: {
                    codeRegion: e.target.value,
                    regionName: e.target.options[e.target.selectedIndex].text,
                    regionActive: true
                }
            }
            , region: e.target.value
        }, this.getOfficesFromAPI);
    }

    getOfficesFromAPI(): void {
        if (this.state.region !== "" && this.state.region !== "NONE") {
            this.props.showLoading(true);
            getBranchOfficeList(this.state.region).then((officeArray: BranchOffice[]) => {
                this.setState({ offices: officeArray }, () => {
                    this.props.showLoading(false);
                });
            })
                .catch((response: ResponseError) => {
                    this.props.showLoading(false);
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                });
        }
    }


    handleBranchOffice(e: any) {
        e.preventDefault();
        const officeSelected = e.target.value;
        if (officeSelected !== "" && officeSelected !== "NONE") {
            this.setState({ office: officeSelected }, this.fetchOffice);
        } else {
            this.setState({ office: officeSelected });
        }
    }

    fetchNoticeConf(noticeConfId: string) {
        this.props.showLoading(true);
        getNoticeConf(noticeConfId)
            .then((conf: NoticeConfiguration) => {
                this.setState({
                    selectedNotice: conf,
                    assignedRoles: conf.roleMails.split(",").filter(e => e),
                    assignedUsers: conf.userMails.split(",").filter(e => e),
                    mails: conf.independentMails,
                    initialAssignedRoles: conf.roleMails.split(",").filter(e => e),
                    initialAssignedUsers: conf.userMails.split(",").filter(e => e),
                    initialMails: conf.independentMails
                }, () => {
                    this.props.showLoading(false);
                    this.fetchRoles();
                    this.fetchNamesAndCodes();
                });
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
                this.props.history.goBack();
            });
    }

    fetchRoles() {
        getRolesList()
            .then((roleList: Role[]) => {
                this.setState({ roleList }, () => {
                    this.props.showLoading(false);
                });
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    changeRoles(e: any) {
        let role = e.target.value;
        if (this.state.assignedRoles.indexOf(role) == -1) {
            this.setState({ assignedRoles: this.state.assignedRoles.concat(role) });
        } else {
            this.setState({ assignedRoles: this.state.assignedRoles.filter((elem) => elem !== role) });
        }
    }

    changeDefaultConf(e: any) {
        const newValue = e.target.checked;
        const confirmationMessage = newValue
            ? i18n.t('confirm-enable-notifications')
            : i18n.t('confirm-disable-notifications');

        if (window.confirm(confirmationMessage)) {
            this.setState(
                {
                    branchOffice: {
                        ...this.state.branchOffice,
                        defaultNotifyConf: newValue,
                    },
                },
                this.update
            );
        } else {
            // Revert the checkbox to its previous state if the user cancels the action
            e.target.checked = !newValue;
        }
    }

    update() {
        const office: BranchOfficeAPI = {
            ...this.state.branchOffice,
            stockConfig: this.state.branchOffice.stockConfig,
            updateDate: 0,
            region: this.state.branchOffice.region,

        };

        this.props.showLoading(true);
        updateConfNoticeOffice(office)
            .then(() => {
                this.props.setMessage({ message: i18n.t('branch-office-updated'), type: MESSAGE_SUCCESS });
                this.props.showLoading(false);
                if (!this.state.branchOffice.defaultNotifyConf) {
                    this.findBranchNoticesConf();
                }
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }


    fetchNamesAndCodes() {
        this.props.showLoading(true);
        getAllNamesAndCodes(IDENTITY_ADMIN_GROUP, TYPE_USER)
            .then((response: string[]) => {
                this.props.showLoading(false);
                this.setState({ userList: response.filter((item) => !this.state.assignedUsers.includes(item)) });
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    onSelectCurrent(e: any) {
        const options = e.target.selectedOptions || Array.from(this.currentRef.current.options).filter((option: any) => option.selected);
        const itemsCurrent: string[] = [];
        for (let i = 0; i < options.length; i++) {
            itemsCurrent.push(options[i].value)
        }
        this.setState({ itemsCurrent });
    }

    onSelectAvailable(e: any) {
        const options = e.target.selectedOptions || Array.from(this.availableRef.current.options).filter((option: any) => option.selected);
        const itemsAvailable: string[] = [];
        for (let i = 0; i < options.length; i++) {
            itemsAvailable.push(options[i].value)
        }
        this.setState({ itemsAvailable });
    }

    addUsers() {
        let assigned = this.state.assignedUsers.concat(this.state.itemsAvailable);
        let availables = this.state.userList.filter((item) => !this.state.itemsAvailable.includes(item));
        this.setState({ itemsAvailable: [], assignedUsers: assigned, userList: availables });
    }

    removeUsers() {
        let availables = this.state.userList.concat(this.state.itemsCurrent);
        let assigned = this.state.assignedUsers.filter((item) => !this.state.itemsCurrent.includes(item));
        this.setState({ itemsCurrent: [], assignedUsers: assigned, userList: availables });
    }

    handleMailsChange(e: any) {
        const value = e.target.value;
        this.setState({ mails: e.target.value });
    }

    closeEditModal() {
        this.setState({
            editModalIsOpen: false, selectedNotice: null, assignedRoles: [], assignedUsers: [], mails: "", initialAssignedRoles: [],
            initialAssignedUsers: [], initialMails: ""
        });
    }

    checkForChanges() {
        const { selectedNotice, assignedRoles, assignedUsers, mails, initialAssignedRoles, initialAssignedUsers, initialMails } = this.state;

        const areRolesChanged = JSON.stringify(assignedRoles) !== JSON.stringify(initialAssignedRoles);
        const areUsersChanged = JSON.stringify(assignedUsers) !== JSON.stringify(initialAssignedUsers);
        const areMailsChanged = mails !== initialMails;

        return areRolesChanged || areUsersChanged || areMailsChanged;
    }

    updateAction() {
        if (this.state.selectedNotice != null) {
            const { mails } = this.state;

            // Expresión regular para validar un solo correo electrónico
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            // Separar los correos por comas
            const emails = mails.split(',');

            // Validar cada correo
            const allValid = emails.every(email => emailRegex.test(email.trim()));

            if (allValid) {
                this.props.showLoading(true);
                let conf = this.state.selectedNotice;
                conf.roleMails = this.state.assignedRoles.join(",");
                conf.userMails = this.state.assignedUsers.join(",");
                conf.independentMails = this.state.mails;
                updateNoticeConf(conf)
                    .then((response: boolean) => {
                        this.props.setMessage({ message: i18n.t('notice-update-success'), title: "", type: "alert-success" });
                        this.props.showLoading(false);
                        this.setState({
                            editModalIsOpen: false, selectedNotice: null, assignedRoles: [], assignedUsers: [], mails: "", initialAssignedRoles: [],
                            initialAssignedUsers: [], initialMails: ""
                        }, this.findBranchNoticesConf);
                    })
                    .catch((response: ResponseError) => {
                        apiHandleErrorCode(response.status, this.props.history);
                        this.props.setMessage(response.message);
                        this.setState({
                            editModalIsOpen: false, selectedNotice: null, assignedRoles: [], assignedUsers: [], mails: "", initialAssignedRoles: [],
                            initialAssignedUsers: [], initialMails: ""
                        }, this.props.showLoading(false));
                    });
            } else {
                alert('Uno o más correos no tienen el formato correcto. Por favor, revisa y corrige.');
            }


        }
    }

    openEditModal(selected: NoticeConfiguration) {
        this.setState({
            selectedNotice: selected,
            editModalIsOpen: true
        }, () => this.fetchNoticeConf(selected.correlative.toString()));
    }

    findBranchNoticesConf() {
        this.props.showLoading(true);

        const search: SearchNotices = {
            firstResult: 0,
            maxResults: 300,
            reportDate: '',
            reportOffice: this.state.branchOffice.code
        };

        findNoticesConf(search)
            .then((response: NoticeConfiguration[]) => {
                this.setState({ data: response }, () => this.props.showLoading(false));
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    handleCurrentFilterChange(event: any) {
        this.setState({ currentFilter: event.target.value });
    }

    handleAvailableFilterChange(event: any) {
        this.setState({ availableFilter: event.target.value });
    }

    filteredAssignedUsers() {
        const { assignedUsers, currentFilter } = this.state;
        if (!currentFilter) {
            return assignedUsers;
        }
        return assignedUsers.filter(user => user.toLowerCase().includes(currentFilter.toLowerCase()));
    }

    filteredAvailableUsers() {
        const { userList, availableFilter } = this.state;
        if (!availableFilter) {
            return userList;
        }
        return userList.filter(user => user.toLowerCase().includes(availableFilter.toLowerCase()));
    }

    render() {
        const isUpdateDisabled = !this.checkForChanges();

        const REG: { [key: string]: string } = this.state.regions.reduce((map: { [key: string]: string }, region: Region) => {
            map[region.codeRegion] = region.regionName;
            return map;
        }, {});

        const regions: JSX.Element[] = Object.keys(REG).map((codeRegion: string) => {
            return <option key={codeRegion} value={codeRegion}>{i18n.t(REG[codeRegion])}</option>;
        });

        const OFFI: { [key: string]: string } = this.state.offices.reduce((map: { [key: string]: string }, office: BranchOffice) => {
            map[office.code] = office.name;
            return map;
        }, {});

        const branchOffices: JSX.Element[] = Object.keys(OFFI).map((codeOffice: string) => {
            return <option key={codeOffice} value={codeOffice}>{i18n.t(OFFI[codeOffice])}</option>;
        });

        const customStyles: any = {
            content: {
                top: '10%',
                left: '10%',
                right: '10%',
                bottom: '10%',
                marginRight: 'auto',
                transform: 'none',
                maxWidth: '80%',
                maxHeight: '80%',
                overflow: 'auto'
            }
        };

        return (
            <div>
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('notice-update')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        {this.state.showSelectRegionAndLocation &&
                            <div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('region')}:</label>
                                    <select className="form-control" onChange={this.handleRegion} value={this.state.region}>
                                        <option value="NONE">{i18n.t('option-select')}</option>
                                        {regions}
                                    </select>
                                </div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('branch-office')}:</label>
                                    <select className="form-control" onChange={this.handleBranchOffice} value={this.state.office}>
                                        <option value="NONE">{i18n.t('option-select')}</option>
                                        {branchOffices}
                                    </select>
                                </div>
                            </div>
                        }
                        {!this.state.showSelectRegionAndLocation &&
                            <div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('region')}:</label>
                                    <p className="form-control">{this.state.regionName}</p>
                                </div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('branch-office')}:</label>
                                    <p className="form-control">{this.state.officeName}</p>
                                </div>
                            </div>
                        }
                        {this.state.branchOffice.code !== "" && <div className="form-group col-sm-3" >
                            <div className="checkbox">
                                <label>
                                    <input type="checkbox" onChange={this.changeDefaultConf} checked={this.state.branchOffice.defaultNotifyConf} />{i18n.t('default-config-enable')}
                                </label>
                            </div>
                        </div>}
                    </div>
                </div>

                {!this.state.branchOffice.defaultNotifyConf && <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('notices-conf')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="form-group col-sm-12">

                            <BootstrapTable
                                data={this.state.data}
                                options={this.state.tableOptions}
                                bordered={true}
                                pagination hover >

                                <TableHeaderColumn dataField='correlative' isKey hidden>{i18n.t('code')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='noticeType' dataFormat={formatNoticeNameForList} dataSort={true} dataAlign={'left'}>{i18n.t('type')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='independentMails' dataAlign={'right'}>{i18n.t('mails')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='userMails' dataAlign={'right'}>{i18n.t('users')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='roleMails' dataAlign={'right'}>{i18n.t('role-user')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='' tdStyle={{ textAlign: 'center' }} width="80" dataFormat={(cell, row) => (
                                    <button onClick={() => this.openEditModal(row)} disabled={!this.props.havePermission(NOTICE_CONFIGURATION_EDIT_PERM)}>
                                        <span><i className='glyphicon glyphicon-edit'></i></span>
                                    </button>
                                )}>{i18n.t('balance-edit')}</TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                    </div>
                </div>}
                <Modal
                    isOpen={this.state.editModalIsOpen}
                    onRequestClose={this.closeEditModal}
                    style={customStyles}
                    ariaHideApp={false}
                >
                    {this.state.selectedNotice != null &&
                        <form className="form-inline">
                            <div className="row">
                                <div className="form-group col-sm-4">
                                    <label className="control-label">{i18n.t('type')}:</label>
                                    <span className="form-control">{this.state.selectedNotice.noticeType.name}</span>
                                </div>
                                <div className="form-group col-sm-4">
                                    <label className="control-label">{i18n.t('region')}:</label>
                                    <span className="form-control">{this.state.selectedNotice.branchOffice.region.regionName}</span>
                                </div>
                                <div className="form-group col-sm-4">
                                    <label className="control-label">{i18n.t('branch-office')}:</label>
                                    <span className="form-control">{this.state.selectedNotice.branchOffice.name}</span>
                                </div>
                            </div>
                            <div className="row">
                                <hr />
                                <h4>{i18n.t('role-user')}</h4>
                                <hr />

                                {
                                    this.state.roleList.map(item => (
                                        <div className="form-group col-sm-3" key={item.codeRole}>
                                            <div className="checkbox">
                                                <label>
                                                    <input type="checkbox" value={item.codeRole} onChange={this.changeRoles} checked={this.state.assignedRoles.indexOf(item.codeRole) > -1} /> {item.roleName}
                                                </label>
                                            </div>
                                        </div>
                                    ))
                                }

                            </div>
                            <div className="row">
                                <hr />
                                <h4>{i18n.t('users')}</h4>
                                <hr />
                                <div className="col-sm-4">
                                    <div className="form-group" style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label className="control-label">{i18n.t('assigned')}:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={i18n.t('search-user')}
                                            value={this.state.currentFilter}
                                            onChange={this.handleCurrentFilterChange}
                                        />
                                        <select ref={this.currentRef} multiple={true} id="current" onChange={this.onSelectCurrent} className="form-control full-width">
                                            {this.filteredAssignedUsers().map(item => (
                                                <option key={item} value={item}>{item}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group col-sm-4" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <label className="control-label">&nbsp;</label>
                                    <button type="button" id="addModulo" className="btn btn-success btn-block" onClick={this.addUsers} disabled={this.state.itemsAvailable.length === 0}>
                                        <span className="glyphicon glyphicon-chevron-left"></span>{i18n.t('add')}
                                    </button>
                                    <button type="button" id="subModulo" className="btn btn-danger btn-block" onClick={this.removeUsers} disabled={this.state.itemsCurrent.length === 0}>
                                        {i18n.t('remove')}<span className="glyphicon glyphicon-chevron-right"></span>
                                    </button>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group" style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label className="control-label">{i18n.t('any')}:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={i18n.t('search-user')}
                                            value={this.state.availableFilter}
                                            onChange={this.handleAvailableFilterChange}
                                        />
                                        <select ref={this.availableRef} multiple={true} id="available" onChange={this.onSelectAvailable} className="form-control full-width">
                                            {this.filteredAvailableUsers().map(item => (
                                                <option key={item} value={item}>{item}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <hr />
                                <h4>{i18n.t('mails')}</h4>
                                <hr />

                                <div className="form-group col-sm-12">
                                    <label className="control-label">{i18n.t('mails-sub')}:</label>
                                    <input className="form-control full-width" value={this.state.mails} onChange={this.handleMailsChange} />
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-sm-12 text-right">
                                    <button type="button" onClick={this.closeEditModal} className="btn btn-primary">{i18n.t('close')}</button>
                                    <button type="button" onClick={this.updateAction} className="btn btn-info" disabled={isUpdateDisabled}>{i18n.t('update')}</button>
                                </div>
                            </div>
                        </form>}
                </Modal>
            </div>
        )
    }
}

export const NoticeForm = withAppUserContext(NoticeFormMain);