import * as React from "react";
import i18n from "../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../context/AppContext";
import { REPORT_DEVICES_PERM } from "../../const/Permission";
import { SearchByPeriod } from "./SearchByPeriod";
import { AdvanceSearch } from "./AdvanceSearch";


export interface ReportDevicesProps extends RouteComponentProps, AppContextProps { }

export interface ReportDevicesState {
    step: number;
}

export class ReportDevicesMain extends React.Component<ReportDevicesProps, ReportDevicesState> {
    constructor(props: ReportDevicesProps) {
        super(props);

        this.state = {
            step: this.props.havePermission(REPORT_DEVICES_PERM) ? 0 : 2
        }
    }

    setStep(step: number): any {
        this.setState({ step });
    }

    render() {
        return (
            <div className="panel-tabs">
                <div className="panel-heading">
                    <ul className="nav nav-tabs">
                        {this.props.havePermission(REPORT_DEVICES_PERM) && <li className={this.state.step === 0 ? "active" : ""}><a onClick={() => this.setStep(0)} href="#" className="nav-link" >{i18n.t('by-period')}</a></li>}
                        {this.props.havePermission(REPORT_DEVICES_PERM) && <li className={this.state.step === 1 ? "active" : ""}><a onClick={() => this.setStep(1)} href="#" className="nav-link" >{i18n.t('advanced-search')}</a></li>}
                    </ul>
                </div>
                {this.state.step === 0 && this.props.havePermission(REPORT_DEVICES_PERM) &&
                    <SearchByPeriod props={this.props} />
                }
                {this.state.step === 1 && this.props.havePermission(REPORT_DEVICES_PERM) &&
                    <AdvanceSearch props={this.props} />
                }
            </div>

        )
    }
}

export const ReportDevices = withAppContext(ReportDevicesMain);