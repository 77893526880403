import * as React from "react";
import i18n from "../../i18n";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { formatNoticeNameForList } from "../../utils/Formatter";
import { withAppContext, AppContextProps } from "../../context/AppContext";
import { SearchNotices, NoticeConfiguration } from "../../interfaces/Notice";
import { RouteComponentProps } from "react-router-dom";
import { apiHandleErrorCode, ResponseError } from "../../utils/ApiBaseConfig";
import { MESSAGE_ERROR } from "../../const/Message";
import { findNoticesConf, updateNoticeConf } from "../../services/Notice";
import { NOTICE_CONFIGURATION_EDIT_PERM } from "../../const/Permission";
import { NOTICE_EDIT_ROUTE, NOTICE_CONF_ID_PARAM } from "../../const/Routes";
import Modal from 'react-modal';
import { Role } from "../../interfaces/Role";
import { getRolesList } from "../../services/Role";

export interface NoticesConfTableProps extends RouteComponentProps, AppContextProps { }
export interface NoticesConfTableState {
    showSelectRegionAndLocation: boolean;
    data: NoticeConfiguration[];
    selectedNotice: NoticeConfiguration | null;
    tableOptions: any;
    editModalIsOpen: boolean;
    roleList: Role[];
    assignedRoles: string[];
}

export class NoticesConfTableMain extends React.Component<NoticesConfTableProps, NoticesConfTableState> {

    constructor(props: NoticesConfTableProps) {
        super(props);
        this.findNoticesConf = this.findNoticesConf.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
        this.fetchRoles = this.fetchRoles.bind(this);
        this.updateAction = this.updateAction.bind(this);
        this.changeRoles = this.changeRoles.bind(this);
        this.hasChanges = this.hasChanges.bind(this);

        this.state = {
            data: [],
            showSelectRegionAndLocation: false,

            selectedNotice: null,

            tableOptions: {
                noDataText: i18n.t('table-empty'),
                sizePerPageList: [{
                    text: '10', value: 10
                }, {
                    text: '20', value: 20
                }, {
                    text: '50', value: 50
                }],
                sizePerPage: 10,
                paginationSize: 3,
                pageStartIndex: 1,
            },
            editModalIsOpen: false,
            roleList: [],
            assignedRoles: []
        }
    }

    componentDidMount() {
        this.findNoticesConf();
    }

    fetchRoles() {
        this.props.showLoading(true);
        getRolesList()
            .then((roleList: Role[]) => {
                this.state.selectedNotice && this.setState({ roleList, assignedRoles: this.state.selectedNotice.roleMails.split(",").filter(e => e), editModalIsOpen: true }, () => {
                    this.props.showLoading(false);
                });
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }



    findNoticesConf() {
        this.props.showLoading(true);

        const search: SearchNotices = {
            firstResult: 0,
            maxResults: 300,
            reportDate: '',
            reportOffice: 'CUID'
        };

        findNoticesConf(search)
            .then((response: NoticeConfiguration[]) => {
                this.setState({ data: response }, () => this.props.showLoading(false));
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }


    openEditModal(selected: NoticeConfiguration) {
        this.setState({ selectedNotice: selected }, () => this.fetchRoles());
    }

    closeEditModal() {
        this.setState({ editModalIsOpen: false, selectedNotice: null, assignedRoles: [] });
    }

    changeRoles(e: any) {
        let role = e.target.value;
        if (this.state.assignedRoles.indexOf(role) == -1) {
            this.setState({ assignedRoles: this.state.assignedRoles.concat(role) });
        } else {
            this.setState({ assignedRoles: this.state.assignedRoles.filter((elem) => elem !== role) });
        }
    }

    updateAction() {

        if (this.state.selectedNotice != null) {
            let conf = this.state.selectedNotice;
            conf.roleMails = this.state.assignedRoles.join(",");
            updateNoticeConf(conf)
                .then((response: boolean) => {
                    this.props.setMessage({ message: i18n.t('notice-update-success'), title: "", type: "alert-success" });
                    this.props.showLoading(false);
                    this.setState({ editModalIsOpen: false, selectedNotice: null, assignedRoles: [] }, () => this.findNoticesConf());
                })
                .catch((response: ResponseError) => {
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                    this.props.showLoading(false);
                    this.setState({ editModalIsOpen: false, selectedNotice: null, assignedRoles: [] });
                });
        }
    }

    hasChanges() {
        const { assignedRoles, selectedNotice } = this.state;

        if (selectedNotice != null) {
            const initialRoles = selectedNotice.roleMails.split(",").filter(e => e);
            return (
                assignedRoles.length !== initialRoles.length ||
                assignedRoles.some((role) => !initialRoles.includes(role))
            );
        }
        return false;
    };

    render() {
        const customStyles: any = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            }
        };

        return (
            <div className="card margin-card-top">
                <div className="card-header">
                    <div className="card-title">
                        <div className="title">{i18n.t('notices-conf')}</div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="form-group col-sm-12">

                        <BootstrapTable
                            data={this.state.data}
                            options={this.state.tableOptions}
                            bordered={true}
                            pagination hover >

                            <TableHeaderColumn dataField='correlative' isKey hidden>{i18n.t('code')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='noticeType' dataFormat={formatNoticeNameForList} dataSort={true} dataAlign={'left'}>{i18n.t('type')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='roleMails' dataAlign={'right'}>{i18n.t('role-user')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='' tdStyle={{ textAlign: 'center' }} width="80" dataFormat={(cell, row) => (
                                <button onClick={() => this.openEditModal(row)} disabled={!this.props.havePermission(NOTICE_CONFIGURATION_EDIT_PERM)}>
                                    <span><i className='glyphicon glyphicon-edit'></i></span>
                                </button>
                            )}>{i18n.t('balance-edit')}</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.editModalIsOpen}
                    onRequestClose={this.closeEditModal}
                    style={customStyles}
                    ariaHideApp={false}
                >
                    {this.state.selectedNotice && <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <div className="title">{i18n.t('notice-update')}</div>
                            </div>
                        </div>
                        <div className="card-body">
                            <form className="form-inline">
                                <div className="row">
                                    <div className="form-group col-sm-4">
                                        <label className="control-label">{i18n.t('type')}:</label>
                                        <span className="form-control">{this.state.selectedNotice.noticeType.name}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <hr />
                                    <h4>{i18n.t('role-user')}</h4>
                                    <hr />

                                    {
                                        this.state.roleList.map(item => (
                                            <div className="form-group col-sm-3" key={item.codeRole}>
                                                <div className="checkbox">
                                                    <label>
                                                        <input type="checkbox" value={item.codeRole} onChange={this.changeRoles} checked={this.state.assignedRoles.indexOf(item.codeRole) > -1} /> {item.roleName}
                                                    </label>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                            </form>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 text-right">
                                <button className="btn btn-info pull-right" onClick={this.closeEditModal}>{i18n.t('close')}</button>
                                <button disabled={!this.hasChanges()} type="button" onClick={this.updateAction} className="btn btn-info ">{i18n.t('update')}</button>
                            </div>
                        </div>
                    </div>}
                </Modal>
            </div>

        )
    }
}

export const NoticesConfList = withAppContext(NoticesConfTableMain);