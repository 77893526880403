import * as React from "react";
import i18n from "../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../context/AppContext";
import { STOCK_MANAGEMENT_FIND_PERM, STOCK_MANAGEMENT_LIST_PERM, STOCK_MANAGEMENT_PENDING_PERM } from "../../const/Permission";
import { StockManagement } from "./Management";
import { FindStockManagement } from "./Find";
import { PendingStockManagement } from "./Pendings";

export interface StockManagementIndexProps extends RouteComponentProps, AppContextProps{ }
export interface StockManagementIndexState {
    step: number;
}

export class StockManagementIndexMain extends React.Component<StockManagementIndexProps, StockManagementIndexState>{
    reachTheLastPage: boolean = false;
    constructor(props: StockManagementIndexProps) {
        super(props);
        this.state = {
            step: this.props.havePermission(STOCK_MANAGEMENT_LIST_PERM) ? 0 : 1
        };
    }

    setStep(step: number): any {
        this.setState({ step });
    }

    render() {
        return (
            <div className="panel-tabs">
                <div className="panel-heading">
                    <ul className="nav nav-tabs">
                        {this.props.havePermission(STOCK_MANAGEMENT_LIST_PERM) && <li className={this.state.step === 0 ? "active" : ""}><a onClick={() => this.setStep(0)} href="#" className="nav-link" >{i18n.t('stock-management-title')}</a></li>}
                        {this.props.havePermission(STOCK_MANAGEMENT_FIND_PERM) && <li className={this.state.step === 1 ? "active" : ""}><a onClick={() => this.setStep(1)} href="#" className="nav-link" >{i18n.t('stock-management-changes')}</a></li>}
                        {this.props.havePermission(STOCK_MANAGEMENT_PENDING_PERM) && <li className={this.state.step === 2 ? "active" : ""}><a onClick={() => this.setStep(2)} href="#" className="nav-link" >{i18n.t('stock-management-pending')}</a></li>}
                    </ul>
                </div>
                {this.state.step === 0 && this.props.havePermission(STOCK_MANAGEMENT_LIST_PERM) &&
                <StockManagement/>
                }
                {this.state.step === 1 && this.props.havePermission(STOCK_MANAGEMENT_FIND_PERM) &&
                <FindStockManagement />
                }
                {this.state.step === 2 && this.props.havePermission(STOCK_MANAGEMENT_PENDING_PERM) &&
                <PendingStockManagement />
                }
            </div>
        )
    }
}

export const StockManagementIndex = withAppContext(StockManagementIndexMain);