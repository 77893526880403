import * as React from "react";
import i18n from "../../../i18n";
import Modal from 'react-modal';
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../../context/AppContext";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { SHIPMENT_CANCEL_PERM, SHIPMENT_REQUEST_PERM, SHIPMENT_SEND_PERM, SHIPMENT_RECEIVE_PERM, SHIPMENT_SHOW_PERM } from "../../../const/Permission";
import { getShipments, cancelShipments, requestShipments, sendShipments } from "../../../services/Shipment";
import { ResponseError, apiHandleErrorCode } from "../../../utils/ApiBaseConfig";
import { ShipmentSummary, ShipmentState, ShipmentAPI } from "../../../interfaces/Shipment";
import { shipmentStateFormatter } from "../../../utils/Formatter";
import { MESSAGE_SUCCESS } from "../../../const/Message";
import { SHIPMENT_STATE_CANCELED, SHIPMENT_STATE_ASSIGNED, SHIPMENT_STATE_REQUESTED, SHIPMENT_STATE_SENT, SHIPMENT_STATE_RECEIVED, CURUS_SHIPMENT_STATES } from "../../../const/Shipment";
import { DEVICES_SHIPPING_INFO_ROUTE, DEVICES_SHIPPING_ID_PARAM, DEVICES_SHIPPING_RECEIVE_ROUTE, DEVICES_SHIPPING_GENERATE_ROUTE } from "../../../const/Routes";
import { MovementType } from "../../../interfaces/Movement";

export interface ShipmentsProps extends RouteComponentProps, AppContextProps { }

export interface ShipmentsState {
    shipments: ShipmentSummary[];
    selected: ShipmentSummary[];
    tableOptions: any;
    state: string;
    states: MovementType[];
    dateFrom: string;
    dateTo: string;
    cancelModalIsOpen: boolean;
    description: string;
}

export class ShipmentsMain extends React.Component<ShipmentsProps, ShipmentsState> {

    constructor(props: ShipmentsProps) {
        super(props);
        this.fetchShipments = this.fetchShipments.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.show = this.show.bind(this);
        this.cancel = this.cancel.bind(this);
        this.request = this.request.bind(this);
        this.send = this.send.bind(this);
        this.receive = this.receive.bind(this);
        this.commonState = this.commonState.bind(this);
        this.canCancel = this.canCancel.bind(this);
        this.canSend = this.canSend.bind(this);
        this.canReceive = this.canReceive.bind(this);
        this.cleanSelectedShipments = this.cleanSelectedShipments.bind(this);
        this.handleFrom = this.handleFrom.bind(this);
        this.handleTo = this.handleTo.bind(this);
        this.handleState = this.handleState.bind(this);
        this.openCancelModal = this.openCancelModal.bind(this);
        this.closeCancelModal = this.closeCancelModal.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);

        this.state = {
            shipments: [],
            selected: [],
            tableOptions: {
                noDataText: i18n.t('table-empty'),
            },
            state: "ST",
            states : [
                { stockMovementCode: 'RV', stockMovementDescription: i18n.t(SHIPMENT_STATE_RECEIVED) },
                { stockMovementCode: 'CD', stockMovementDescription: i18n.t(SHIPMENT_STATE_CANCELED) },
                { stockMovementCode: 'ST', stockMovementDescription: i18n.t(SHIPMENT_STATE_SENT) }
            ],
            dateFrom: "",
            dateTo: "",
            cancelModalIsOpen: false,
            description: "",
        }
    }

    onSelect(shipment: ShipmentSummary, isSelected: boolean) {
        const selected: ShipmentSummary[] = this.state.selected.slice();
        if (isSelected) {
            selected.push(shipment);
        } else {
            const index: number = selected.findIndex((sm: ShipmentSummary) => sm.shipmentCorrelative === shipment.shipmentCorrelative);
            selected.splice(index, 1);
        }
        this.setState({ selected });
    }

    onSelectAll(isSelected: boolean): any {
        const all: ShipmentSummary[] = this.state.shipments.slice();
        this.setState({ selected: isSelected ? all : [] });
    }

    componentDidMount() {
        var curr = new Date();
        curr.setDate(curr.getDate() + 1);
        var tomorrow = curr.toISOString().substr(0, 10);
        curr.setDate(curr.getDate() - 7);
        var sixDaysBefore = curr.toISOString().substr(0, 10);

        this.setState({ dateFrom: sixDaysBefore, dateTo: tomorrow}, () => {
            this.fetchShipments();
        });
    }

    fetchShipments() {
        this.props.showLoading(true);
        getShipments(
            this.state.state !== "NONE" ? this.state.state : undefined,
            this.state.dateFrom !== "" ? this.state.dateFrom : undefined,
            this.state.dateTo !== "" ? this.state.dateTo : undefined
        )
            .then((shipments: ShipmentSummary[]) => {
                this.setState({ shipments }, () => this.props.showLoading(false));
                if (this.state.selected.length > 0) {
                    const selected: ShipmentSummary[] = shipments.filter((shipment: ShipmentSummary) => {
                        return this.state.selected.some((summary: ShipmentSummary) => summary.shipmentCorrelative === shipment.shipmentCorrelative)
                    });
                    this.setState({ selected });
                }
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    cleanSelectedShipments(): ShipmentAPI[] {
        const selected: ShipmentSummary[] = this.state.selected.slice();
        const clean: ShipmentAPI[] = [];
        for (let i = 0; i < selected.length; i++) {
            const shipment: ShipmentSummary = Object.assign({}, selected[i]);
            clean.push({ 
                comment: shipment.comment,
                destination: { code: shipment.destinationCode, name: shipment.destination },
                origin: { code: shipment.originCode, name: shipment.origin },
                receiver: { correlative: shipment.receiverCorrelative ? shipment.receiverCorrelative: 0, name: shipment.receiver },
                sender: { correlative: shipment.senderCorrelative, name: shipment.sender },
                shipmentCorrelative: shipment.shipmentCorrelative, 
                shipmentState: CURUS_SHIPMENT_STATES[shipment.shipmentState], 
                sendDate: null,
                receptionDate: null,
                updateDate: 0,
                deviceQuantity: 0,
                deviceTypeCode: '',
                deviceTypeName: ''
            });
        }

        return clean;
    }

    show() {
        this.props.history.push(DEVICES_SHIPPING_INFO_ROUTE.replace(DEVICES_SHIPPING_ID_PARAM, String(this.state.selected![0].shipmentCorrelative)));
    }

    cancel() {
        this.props.showLoading(true);
        this.closeCancelModal();
        cancelShipments(this.cleanSelectedShipments(), this.state.description)
            .then(() => {
                this.props.setMessage({ message: i18n.t('shipments-canceled'), type: MESSAGE_SUCCESS });
                this.props.showLoading(false);
                this.fetchShipments();
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    request() {
        this.props.showLoading(true);
        requestShipments(this.cleanSelectedShipments())
            .then(() => {
                this.props.setMessage({ message: i18n.t('shipments-requested'), type: MESSAGE_SUCCESS });
                this.props.showLoading(false);
                this.fetchShipments();
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    send() {
        this.props.showLoading(true);
        sendShipments(this.cleanSelectedShipments())
            .then(() => {
                this.props.setMessage({ message: i18n.t('shipments-sent'), type: MESSAGE_SUCCESS });
                this.props.showLoading(false);
                this.fetchShipments();
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    receive() {
        this.props.history.push(DEVICES_SHIPPING_RECEIVE_ROUTE.replace(DEVICES_SHIPPING_ID_PARAM, String(this.state.selected![0].shipmentCorrelative)));
    }

    commonState() {
        if (this.state.selected.length === 0) return SHIPMENT_STATE_CANCELED;
        const state: ShipmentState = this.state.selected[0].shipmentState;
        const equal: boolean = this.state.selected.every((shipment: ShipmentSummary) => shipment.shipmentState === state);
        return equal ? state : SHIPMENT_STATE_CANCELED;
    }

    canCancel() {
        const state: ShipmentState = this.commonState();
        const originCode: string = this.state.selected[0].originCode;
        const destinyCode: string = this.state.selected[0].destinationCode;
        return ![SHIPMENT_STATE_RECEIVED, SHIPMENT_STATE_CANCELED].includes(state) && (originCode === this.props.authUser!.branchOffice!.code || destinyCode === this.props.authUser!.branchOffice!.code);
    }

    canSend() {
        return this.state.selected.every((shipment: ShipmentSummary) => shipment.originCode === this.props.authUser!.branchOffice!.code);
    }

    canReceive() {
        return this.state.selected.every((shipment: ShipmentSummary) => shipment.destinationCode === this.props.authUser!.branchOffice!.code);
    }

    handleFrom(e: any) {
        e.preventDefault();
        const startDate = e.target.value;
        this.setState({ dateFrom :startDate });
    }

    handleTo(e: any) {
        e.preventDefault();
        const endDate = e.target.value;
        this.setState({ dateTo :endDate });
    }

    handleState(e: any) {
        e.preventDefault();
        const stateSelected = e.target.value;
        this.setState({ state: stateSelected });
    }

    handleDescriptionChange(e: any){
        e.preventDefault();
        this.setState({ description: e.target.value });
    }

    openCancelModal(){
        if(this.state.selected.length > 0)
            this.setState({ cancelModalIsOpen: true, description: "" });
    }

    closeCancelModal(){
        this.setState({ cancelModalIsOpen: false });
    }

    render() {

        const MOVE: { [key: string]: string } = this.state.states.reduce((map: { [key: string]: string }, state: MovementType) => {
            map[state.stockMovementCode] = state.stockMovementDescription;
            return map;
        }, {})

        const movements: JSX.Element[] = Object.keys(MOVE).map((codeMovement: string) => {
            return <option key={codeMovement} value={codeMovement}>{i18n.t(MOVE[codeMovement])}</option>;
        });

        const customStyles: any = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '40%'
            }
        };

        return (
            <>
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('shipments-status')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="form-group col-sm-12">
                            <div className="form-group col-sm-3">
                                <label>{i18n.t('status')}:</label>
                                <select className="form-control" onChange={this.handleState} value={this.state.state}>
                                    <option value="NONE">{i18n.t('option-select')}</option>
                                    {movements}
                                </select>
                            </div>
                            <div className="form-group col-sm-3">
                                <label>{i18n.t('from')}:</label>
                                <input type="date" name="from" className="form-control" id="from" value={this.state.dateFrom} onChange={this.handleFrom} />
                            </div>
                            <div className="form-group col-sm-3">
                                <label>{i18n.t('to')}:</label>
                                <input type="date" name="to" className="form-control" id="to" value={this.state.dateTo} onChange={this.handleTo} />
                            </div>
                            <div className="form-group col-sm-3">
                                <br></br>
                                <button type="button" className="btn btn-info" onClick={this.fetchShipments}>{i18n.t('search')}</button>
                            </div>
                        </div>

                        <div className="form-group col-sm-12">
                            {this.props.havePermission(SHIPMENT_CANCEL_PERM) && <button className="btn btn-primary" disabled={this.state.selected.length === 0 || !this.canCancel()} onClick={this.openCancelModal}>{i18n.t('cancel')}</button>}
                            {this.props.havePermission(SHIPMENT_REQUEST_PERM) && <button className="btn btn-primary" disabled={this.commonState() != SHIPMENT_STATE_ASSIGNED || !this.canSend()} onClick={this.request}>{i18n.t('request')}</button>}
                            {this.props.havePermission(SHIPMENT_SEND_PERM) && <button className="btn btn-primary" disabled={this.commonState() != SHIPMENT_STATE_REQUESTED || !this.canSend()} onClick={this.send}>{i18n.t('send')}</button>}
                            {this.props.havePermission(SHIPMENT_RECEIVE_PERM) && <button className="btn btn-primary" disabled={this.state.selected.length !== 1 || this.commonState() != SHIPMENT_STATE_SENT || !this.canReceive()} onClick={this.receive}>{i18n.t('receive')}</button>}
                            {this.props.havePermission(SHIPMENT_SHOW_PERM) && <button className="btn btn-primary" disabled={this.state.selected.length !== 1} onClick={this.show}>{i18n.t('show')}</button>}

                            <div className="panel fresh-color panel-info">
                                <div className="panel-heading">{i18n.t('shipment-select')}</div>
                                <BootstrapTable
                                    data={this.state.shipments}
                                    selectRow={{ 
                                        mode: 'checkbox',
                                        onSelect: this.onSelect, 
                                        onSelectAll: this.onSelectAll
                                    }}
                                    pagination
                                    bordered={true}
                                    options={this.state.tableOptions}>
                                    <TableHeaderColumn dataField='shipmentCorrelative' isKey={true} dataSort={true} dataAlign="center" width="60">{i18n.t('id-list')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='shipmentState' dataSort={true} dataFormat={shipmentStateFormatter} dataAlign="center" width="140">{i18n.t('status')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='sender' dataSort={true} dataAlign="center" width="160">{i18n.t('sender')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='receiver' dataSort={true} dataAlign="center" width="160">{i18n.t('receiver')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='deviceType' dataSort={true} width="300">{i18n.t('device-type')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='deviceQuantity' dataAlign="right" width="100">{i18n.t('quantity')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='origin' dataSort={true} width="220">{i18n.t('origin')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='destination' dataSort={true} width="220">{i18n.t('destination')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='sendDate' dataSort={true} dataAlign="center" width="180">{i18n.t('date-send')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='receptionDate' dataSort={true} dataAlign="center" width="180">{i18n.t('date-reception')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='updateDate' dataSort={true} dataAlign="center" width="180">{i18n.t('date-update')}</TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.cancelModalIsOpen}
                    onRequestClose={this.closeCancelModal}
                    style={customStyles}
                    ariaHideApp={false}
                >
                    <h2>{i18n.t('shipments-canceled-confirm')}</h2>
                    <div className="card-body">
                        <div className="form-group">
                                <label>{i18n.t('comment-required')}:</label>
                                <textarea
                                    name="description"
                                    maxLength={200}
                                    value={this.state.description}
                                    onChange={this.handleDescriptionChange}
                                    className="form-control"
                                />
                            </div>
                        <button className="btn btn-default pull-left" onClick={this.closeCancelModal}>{i18n.t('close')}</button>
                        <button className="btn btn-danger pull-right" onClick={this.cancel} disabled={this.state.description.trim().length === 0}>{i18n.t('cancel')}</button>
                    </div>
                </Modal>
            </>
        )
    }
}

export const Shipments = withAppContext(ShipmentsMain);