import { RouteComponentProps } from "react-router-dom";
import { AppContextProps, withAppContext } from "../../context/AppContext";
import React from "react";
import { REPORT_DEVICES_PERM } from "../../const/Permission";
import i18n from "../../i18n";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { DateRange, Period, ReportDevice, SearchFilter } from "../../interfaces/ReportDevices";
import moment from 'moment';
import { getReportDeviceByFilter } from "../../services/ReportDevices";
import { apiHandleErrorCode, ResponseError } from "../../utils/ApiBaseConfig";
import { ExcelExportButton } from "../utils/ExportExcelButton";

export interface SearchByPeriodProps extends RouteComponentProps, AppContextProps { }

export interface SearchByPeriodState {
    tableOptions: any;
    periods: Period[];
    selectedPeriod: string;
    dateRange: DateRange;
    reportDevices: ReportDevice[];
    originalData: ReportDevice[]; // Estado para datos originales
}

export class SearchByPeriodMain extends React.Component<SearchByPeriodProps, SearchByPeriodState> {
    constructor(props: SearchByPeriodProps) {
        super(props);

        this.state = {
            tableOptions: {
                noDataText: i18n.t('table-empty'),
                sizePerPageList: [
                    { text: '10', value: 10 },
                    { text: '20', value: 20 },
                    { text: '50', value: 50 }
                ],
                sizePerPage: 10,
                paginationSize: 3,
                pageStartIndex: 1
            },
            periods: [],
            selectedPeriod: '',
            dateRange: {
                from: '',
                to: ''
            },
            reportDevices: [],
            originalData: []
        }
    }

    componentDidMount() {
        const n = 6; // Número de meses que quieres mostrar
        this.generatePeriods(n);// Actualiza el estado originalData
    }

    generatePeriods = (n: number) => {
        const periods: Period[] = [];
        const today = moment();

        for (let i = 0; i < n; i++) {
            const period = today.clone().subtract(i, 'months');
            const monthIndex = period.month(); // Obtiene el índice del mes (0-11)
            const periodLabel = `${i18n.t('month-' + monthIndex)} ${period.format('YYYY')}`;
            periods.push({
                label: periodLabel,
                value: period.format('YYYY-MM')
            });
        }

        this.setState({ periods });
    }

    handlePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.props.showLoading(true);
        const selectedPeriod = e.target.value;
        const [year, month] = selectedPeriod.split('-');

        const from = moment(`${year}-${month}-01`).startOf('month').format('YYYY-MM-DD');
        const to = moment(`${year}-${month}-01`).endOf('month').format('YYYY-MM-DD');

        this.setState({
            selectedPeriod,
            dateRange: {
                from,
                to
            }
        });
        const searchFilterByPeriod: SearchFilter = {
            branchOffice: undefined,
            groupDeviceType: undefined,
            from: from,
            to: to
        };

        getReportDeviceByFilter(searchFilterByPeriod).then((response) => {
            this.setState({ reportDevices: response }, this.props.showLoading(false));
        }).catch((response: ResponseError) => {
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
            this.props.showLoading(false);
        });
    }

    updateOriginalData = () => {
        // Ordena los datos por grupo y por nombre del dispositivo
        const sortedData = this.state.reportDevices.slice().sort((a, b) => {
            if (a.groupDeviceCode === b.groupDeviceCode) {
                return a.deviceName.localeCompare(b.deviceName);
            }
            return a.groupDeviceCode.localeCompare(b.groupDeviceCode);
        });

        const groupedData: { [key: string]: ReportDevice[] } = {};

        // Agrupa los datos
        sortedData.forEach(device => {
            const key = `${device.groupDeviceCode}-${device.groupDeviceName}`;
            if (!groupedData[key]) {
                groupedData[key] = [];
            }
            groupedData[key].push(device);
        });

        // Prepara originalData asegurando que la fila total de grupo esté al final de cada grupo
        const finalData: ReportDevice[] = [];

        Object.keys(groupedData).forEach(key => {
            const group = groupedData[key];
            const devices = group.filter(device => device.deviceName !== 'Total Grupo');
            const totalRow = group.find(device => device.deviceName === 'Total Grupo');

            // Añade los dispositivos del grupo
            finalData.push(...devices);

            // Añade la fila total del grupo si está presente
            if (totalRow) {
                finalData.push(totalRow);
            }
        });

        // Filtra el dispositivo "Total Global" y lo añade al final
        const totalGlobal = finalData.find(device => device.deviceName === 'Total Global');
        const otherDevices = finalData.filter(device => device.deviceName !== 'Total Global');

        // Reemplaza finalData con los otros dispositivos seguidos por "Total Global"
        finalData.length = 0; // Limpiar finalData
        finalData.push(...otherDevices);
        if (totalGlobal) {
            finalData.push(totalGlobal);
        }

        return finalData;
    }

    prepareTableData = () => {
        const groupedData = this.state.reportDevices.reduce((acc, device) => {
            const key = `${device.groupDeviceCode}-${device.groupDeviceName}`;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(device);
            return acc;
        }, {} as { [key: string]: ReportDevice[] });

        const dataWithRowSpan: any[] = [];
        let groupColorIndex = 0; // Índice de color para alternar

        Object.keys(groupedData).forEach(key => {
            const devices = groupedData[key];
            const totalRow = devices.find(d => d.deviceName === 'Total'); // Encontrar la fila de total
            const rowsToDisplay = devices.filter(d => d.deviceName !== 'Total'); // Filtrar filas que no son total
            const rowSpan = rowsToDisplay.length;

            rowsToDisplay.forEach((device, index) => {
                dataWithRowSpan.push({
                    ...device,
                    groupDeviceCode: index === 0 ? device.groupDeviceCode : '',
                    groupDeviceName: index === 0 ? device.groupDeviceName : '',
                    rowSpanGroupCode: index === 0 ? rowSpan : 0,
                    rowSpanGroupName: index === 0 ? rowSpan : 0,
                    groupColorIndex // Asegurarse de que el índice del grupo esté definido
                });
            });

            // Añadir la fila total al final del grupo
            if (totalRow) {
                dataWithRowSpan.push({
                    ...totalRow,
                    groupDeviceCode: '',
                    groupDeviceName: '',
                    rowSpanGroupCode: 0,
                    rowSpanGroupName: 0,
                    groupColorIndex // Asegurarse de que el índice del grupo esté definido para la fila total
                });
            }

            groupColorIndex++; // Incrementar índice del grupo
        });

        return dataWithRowSpan;
    }

    getCellStyle = (groupIndex: number) => {
        const colors = ['#f9f9f9', '#e0e0e0']; // Alterna entre dos colores
        return { backgroundColor: colors[groupIndex % colors.length] };
    }

    render() {
        const { periods, selectedPeriod, dateRange } = this.state;
        const dataWithRowSpan = this.prepareTableData();
        const completeTable = this.updateOriginalData();
        const columns = [
            { dataField: 'groupDeviceCode', text: i18n.t('device-code') },
            { dataField: 'groupDeviceName', text: i18n.t('grouper') },
            { dataField: 'deviceName', text: i18n.t('device-type') },
            { dataField: 'embossed', text: i18n.t('embossed') },
            { dataField: 'rejected', text: i18n.t('rejected') },
            { dataField: 'total', text: i18n.t('total') }
        ];

        const getFormattedDate = () => {
            const today = new Date();
            const day = String(today.getDate()).padStart(2, '0');
            const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
            const year = today.getFullYear();
            return `${day}${month}${year}`;
        };

        const formattedDate = getFormattedDate();
        const filename = `ReporteCPT_${formattedDate}.xlsx`;

        return (
            <div className="panel-body">
                <div className="card">
                    <div className="card-header" style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="card-title">
                            <div className="title">{i18n.t('report-device-tittle')}</div>
                        </div>
                        <div className="form-group col-sm-3" style={{ marginTop: '10px' }}>
                            <label>{i18n.t('period')}:</label>
                            <select className="form-control" value={selectedPeriod} onChange={this.handlePeriodChange}>
                                <option value="NONE">{i18n.t('option-select')}</option>
                                {periods.map((period, index) => (
                                    <option key={index} value={period.value}>{period.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="card-body">
                        <BootstrapTable
                            data={dataWithRowSpan}
                            options={this.state.tableOptions}
                            bordered
                            hover
                            pagination
                            trStyle={(row) => {
                                // Asegúrate de que `row.groupColorIndex` esté definido
                                if (row !== undefined) {
                                    const index = row.groupColorIndex !== undefined ? row.groupColorIndex : 0;
                                    return this.getCellStyle(index);
                                }
                                return this.getCellStyle(0);
                            }}
                        >
                            <TableHeaderColumn dataField='correlative' isKey hidden></TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='groupDeviceCode'
                                tdStyle={{ whiteSpace: 'normal' }}
                                dataSort
                                dataAlign="center"
                                width="130"
                                dataFormat={(cell, row) => <span>{row.rowSpanGroupCode > 0 ? cell : ''}</span>}
                                columnClassName="groupDeviceCode"
                                filter={{ type: 'TextFilter', placeholder: i18n.t('search') }}
                            >
                                {i18n.t('device-code')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='groupDeviceName'
                                tdStyle={{ whiteSpace: 'normal' }}
                                dataSort
                                dataAlign="center"
                                width="130"
                                dataFormat={(cell, row) => <span>{row.rowSpanGroupName > 0 ? cell : ''}</span>}
                                columnClassName="groupDeviceName"
                                filter={{ type: 'TextFilter', placeholder: i18n.t('search') }}
                            >
                                {i18n.t('movement-card-name')}
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField='deviceName' tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} dataSort dataAlign="center" width="130">
                                {i18n.t('movement-card-name')}
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField='embossed' tdStyle={{ whiteSpace: 'normal' }} dataSort dataAlign="center" width="130">
                                {i18n.t('embossed')}
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField='rejected' tdStyle={{ whiteSpace: 'normal' }} dataSort dataAlign="center" width="130">
                                {i18n.t('rejected')}
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField='total' tdStyle={{ whiteSpace: 'normal' }} dataSort dataAlign="center" width="130">
                                {i18n.t('total')}
                            </TableHeaderColumn>
                        </BootstrapTable>
                        <ExcelExportButton data={completeTable} columns={columns} filename={filename} clazz="btn btn-success pull-right" />
                    </div>
                </div>
            </div>
        )
    }
}

export const SearchByPeriod = withAppContext(SearchByPeriodMain);
