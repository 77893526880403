import * as React from "react";
import { RouteComponentProps } from "react-router";
import { AssignDevice } from "../../interfaces/DeviceType";
import { AppContextProps, withAppContext } from "../../context/AppContext";
import i18n from "../../i18n";
import { MESSAGE_WARNING } from "../../const/Message";

interface AssignGroupProps extends RouteComponentProps, AppContextProps {
    assignedDevices: AssignDevice[];
    allDevices: AssignDevice[];
    addAssigns: Function;
    removeAssigns: Function;

}

interface AssignGroupState {
    availableDevices: AssignDevice[];
    itemsCurrent: string[];
    itemsAvailable: string[];
    currentSearch: string;
    availableSearch: string;
}

export class AssignGroupMain extends React.Component<AssignGroupProps, AssignGroupState> {
    currentRef: any;
    availableRef: any;

    constructor(props: AssignGroupProps) {
        super(props);
        this.setGroupItems = this.setGroupItems.bind(this);
        this.addAssigns = this.addAssigns.bind(this);
        this.removeAssigns = this.removeAssigns.bind(this);
        this.onSelectCurrent = this.onSelectCurrent.bind(this);
        this.onSelectAvailable = this.onSelectAvailable.bind(this);
        this.handleCurrentSearch = this.handleCurrentSearch.bind(this);
        this.handleAvailableSearch = this.handleAvailableSearch.bind(this);

        this.state = {
            availableDevices: [],
            itemsCurrent: [],
            itemsAvailable: [],
            currentSearch: "",
            availableSearch: "",
        }

        this.currentRef = React.createRef();
        this.availableRef = React.createRef();
    }


    componentWillReceiveProps(props: AssignGroupProps) {
        if (props.allDevices !== this.props.allDevices || props.assignedDevices !== this.props.assignedDevices) {
            this.setGroupItems(props.allDevices, props.assignedDevices);
        }
    }

    setGroupItems(allDevices: AssignDevice[], assignedDevices: AssignDevice[]) {
        const availableDevices: AssignDevice[] = allDevices.filter(assign => {
            return assignedDevices.find(cur => cur.code === assign.code) === undefined
        });
        this.setState({ availableDevices });
    }

    addAssigns() {
        const selected: AssignDevice[] = this.state.availableDevices.filter((assign: AssignDevice) => this.state.itemsAvailable.includes(assign.code));
        this.props.addAssigns(selected);
        this.setState({
            currentSearch: "",  
            availableSearch: ""  
        });
    }

    removeAssigns() {
        const selected: AssignDevice[] = this.props.assignedDevices.filter((assign: AssignDevice) => this.state.itemsCurrent.includes(assign.code));
        this.props.removeAssigns(selected);
        this.setState({
            currentSearch: "", 
            availableSearch: "" 
        });

    }

    onSelectCurrent(e: any) {
        const options = e.target.selectedOptions || Array.from(this.currentRef.current.options).filter((option: any) => option.selected);
        const itemsCurrent: string[] = [];
        for (let i = 0; i < options.length; i++) {
            itemsCurrent.push(options[i].value)
        }
        this.setState({ itemsCurrent });
    }

    onSelectAvailable(e: any) {
        const options = e.target.selectedOptions || Array.from(this.availableRef.current.options).filter((option: any) => option.selected);
        const itemsAvailable: string[] = [];
        for (let i = 0; i < options.length; i++) {
            itemsAvailable.push(options[i].value)
        }
        this.setState({ itemsAvailable });
    }

    handleCurrentSearch(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ currentSearch: e.target.value });
    }

    handleAvailableSearch(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ availableSearch: e.target.value });
    }


    render() {
        const filteredCurrentItems = this.props.assignedDevices.filter((item: AssignDevice) =>
            item.name.toLowerCase().includes(this.state.currentSearch.toLowerCase()) ||
            item.code.toLowerCase().includes(this.state.currentSearch.toLowerCase())
        );

        const filteredAvailableItems = this.state.availableDevices.filter((item: AssignDevice) =>
            item.name.toLowerCase().includes(this.state.availableSearch.toLowerCase()) ||
            item.code.toLowerCase().includes(this.state.availableSearch.toLowerCase())
        );

        const currentItems: JSX.Element[] = filteredCurrentItems.map((item: AssignDevice) => {
            return <option key={item.code} value={item.code}>{item.code} - {item.name}</option>;
        });

        const availableItems: JSX.Element[] = filteredAvailableItems.map((item: AssignDevice) => {
            return <option key={item.code} value={item.code}>{item.code} - {item.name}</option>;
        });

        return (
            <div className="form-group">
                <div className="col-md-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={i18n.t('search-assigned-devices')}
                        value={this.state.currentSearch}
                        onChange={this.handleCurrentSearch}
                    />
                    <select ref={this.currentRef} multiple={true} className="form-control" id="current" onChange={this.onSelectCurrent}>
                        {currentItems}
                    </select>
                </div>
                <div className="col-md-2">
                    <button type="button" id="addModulo" className="btn btn-success btn-block" onClick={this.addAssigns} disabled={this.state.itemsAvailable.length === 0}>
                        <span className="glyphicon glyphicon-chevron-left"></span>{i18n.t('add')}
                    </button>
                    <button type="button" id="subModulo" className="btn btn-danger btn-block" onClick={this.removeAssigns} disabled={this.state.itemsCurrent.length === 0}>
                        {i18n.t('remove')}<span className="glyphicon glyphicon-chevron-right"></span>
                    </button>
                </div>
                <div className="col-md-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={i18n.t('search-available-devices')}
                        value={this.state.availableSearch}
                        onChange={this.handleAvailableSearch}
                    />
                    <select ref={this.availableRef} multiple={true} className="form-control" id="available" onChange={this.onSelectAvailable}>
                        {availableItems}
                    </select>
                </div>
            </div>
        )
    }
}

export const AssignGroup = withAppContext(AssignGroupMain);