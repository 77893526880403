import { helperAxios, apiErrorCallback } from "../utils/ApiBaseConfig";
import i18n from "../i18n";
import { ShipmentSummary, ShipmentSummaryAPI, ShipmentAPI, ShipmentGenerated, Shipment, ShipmentGeneratedObject, ShipmentExpandedSummary } from "../interfaces/Shipment";
import { AxiosResponse } from "axios";
import { SHIPMENT_STATES } from "../const/Shipment";
import { cleanProperty, formatUserId } from "../utils/Formatter";
import { parseDocumentSource } from "./Document";
import { DocumentSource } from "../interfaces/Document";

export async function getShipments(stateCode: String | undefined, dateFrom: String| undefined, dateTo: String | undefined): Promise<ShipmentSummary[]> {
    return helperAxios({
        method: 'POST', url: '/api/v2/shipments',
        data: {
            stateCode, dateFrom, dateTo
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] getShipments');
            return response.data.map(parseShipmentSummary);
        })
        .catch(apiErrorCallback);
}

export async function getShipment(shipmentCorrelative: number): Promise<ShipmentExpandedSummary> {
    return helperAxios({
        method: 'GET', url: '/api/v2/shipments/' + shipmentCorrelative
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] getShipment');
            return response.data;
        })
        .catch(apiErrorCallback);
}

export async function getShipmentsGenerated(): Promise<ShipmentGeneratedObject> {
    return helperAxios({
        method: 'GET', url: '/api/v2/shipments/generated'
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] getShipmentsGenerated');
            return {
                shipments: response.data.shipments.map(parseShipmentGenerated),
                types: response.data.types
            }
        })
        .catch(apiErrorCallback);
}

export async function generateShipment(shipment: ShipmentAPI): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: '/api/v2/shipments/generate', data: shipment
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] generateShipment');
            return true;
        })
        .catch(apiErrorCallback);
}

export async function assignShipments(shipments: ShipmentGenerated[]): Promise<ShipmentGenerated[]> {
    return helperAxios({
        method: 'POST', url: '/api/v2/shipments/assign', data: shipments
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] assignShipments');
            return response.data.map(parseShipmentGenerated);
        })
        .catch(apiErrorCallback);
}

export async function requestShipments(shipments: ShipmentAPI[]): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: '/api/v2/shipments/request', data: shipments
    })
        .then(() => {
            console.log('[OK] requestShipments');
            return true;
        })
        .catch(apiErrorCallback);
}

export async function sendShipments(shipments: ShipmentAPI[]): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: '/api/v2/shipments/send', data: shipments
    })
        .then(() => {
            console.log('[OK] sendShipments');
            return true;
        })
        .catch(apiErrorCallback);
}

export async function receiveShipment(shipment: Shipment): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: '/api/v2/shipments/receive', data: [shipment]
    })
        .then(() => {
            console.log('[OK] receiveShipments');
            return true;
        })
        .catch(apiErrorCallback);
}

export async function cancelShipments(shipments: ShipmentAPI[], comment: String): Promise<boolean> {
    return helperAxios({
        method: 'DELETE', url: '/api/v2/shipments/cancel', data: {
            shipments, comment
        }
    })
        .then(() => {
            console.log('[OK] cancelShipments');
            return true;
        })
        .catch(apiErrorCallback);
}

export async function printShipmentVoucher(shipments: (ShipmentGenerated | Shipment)[]): Promise<DocumentSource> {
    return helperAxios({
        method: 'POST', url: '/api/v2/shipments/print', data: shipments
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] printShipmentVoucher');
            return parseDocumentSource(response.data);
        })
        .catch(apiErrorCallback);
}

function parseShipment(shipmentAPI: ShipmentAPI): Shipment {
    return {
        shipmentCorrelative: shipmentAPI.shipmentCorrelative || 0,
        shipmentState: SHIPMENT_STATES[cleanProperty(shipmentAPI.shipmentState)],
        senderCorrelative: shipmentAPI.sender.correlative,
        receiverCorrelative: shipmentAPI.receiver? shipmentAPI.receiver.correlative : 0,
        originCode: cleanProperty(shipmentAPI.origin.code),
        destinationCode: cleanProperty(shipmentAPI.destination ? shipmentAPI.destination.code : ""),
        sendDate: shipmentAPI.sendDate ? new Date(shipmentAPI.sendDate) : i18n.t('no-data'),
        receptionDate: shipmentAPI.receptionDate ? new Date(shipmentAPI.receptionDate) : i18n.t('no-data'),
        updateDate: new Date(shipmentAPI.updateDate),
        comment: shipmentAPI.comment,
        deviceQuantity: shipmentAPI.deviceQuantity,
        deviceTypeCode: shipmentAPI.deviceTypeCode,
        deviceTypeName: shipmentAPI.deviceTypeName
    }
}

function parseShipmentGenerated(shipmentAPI: ShipmentAPI): ShipmentGenerated {

    return {
        shipmentCorrelative: shipmentAPI.shipmentCorrelative || 0,
        senderCorrelative: shipmentAPI.sender.correlative,
        originCode: cleanProperty(shipmentAPI.origin.code),
        destinationCode: cleanProperty(shipmentAPI.destination ? shipmentAPI.destination.code : ""),
        updateDate: new Date(shipmentAPI.updateDate),
        deviceQuantity: shipmentAPI.deviceQuantity,
        comment: shipmentAPI.comment,
        deviceType: shipmentAPI.deviceTypeName
    }
}

function parseShipmentSummary(shipmentAPI: ShipmentSummaryAPI): ShipmentSummary {
    const cleanSender: string = cleanProperty(shipmentAPI.sender);
    const sender = formatUserId(cleanSender);
    const cleanReceiver: string = cleanProperty(shipmentAPI.receiver);
    const receiver = formatUserId(cleanReceiver);
    const originCode = cleanProperty(shipmentAPI.originCode);
    const destinationCode = cleanProperty(shipmentAPI.destinationCode);
    const deviceTypeCode = cleanProperty(shipmentAPI.deviceTypeCode);

    return {
        shipmentCorrelative: shipmentAPI.shipmentCorrelative,
        shipmentState: SHIPMENT_STATES[cleanProperty(shipmentAPI.shipmentState)],
        senderCorrelative: shipmentAPI.senderCorrelative,
        sender,
        receiverCorrelative: shipmentAPI.receiverCorrelative,
        receiver: receiver || i18n.t('no-data'),
        originCode,
        origin: originCode + ' - ' + cleanProperty(shipmentAPI.origin),
        destinationCode,
        destination: destinationCode + ' - ' + cleanProperty(shipmentAPI.destination),
        sendDate: shipmentAPI.sendDate ? new Date(shipmentAPI.sendDate) : i18n.t('no-data'),
        receptionDate: shipmentAPI.receptionDate ? new Date(shipmentAPI.receptionDate) : i18n.t('no-data'),
        updateDate: shipmentAPI.updateDate ? new Date(shipmentAPI.updateDate) : i18n.t('no-data'),
        comment: shipmentAPI.comment,
        deviceType: deviceTypeCode + ' - ' + cleanProperty(shipmentAPI.deviceTypeName),
        deviceQuantity: shipmentAPI.deviceQuantity
    }
}
