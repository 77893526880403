import * as React from "react";
import i18n from "../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps } from "./../../context/AppContext";
import { ResponseError, apiHandleErrorCode } from "../../utils/ApiBaseConfig";
import { Role } from "../../interfaces/Role";
import { getRolesList } from "../../services/Role";
import { USER_CREATE_PERM, CLIENT_CREATE_PERM, USER_UPDATE_PERM } from "../../const/Permission";
import { createUser, getUserCreate, getUser, updateUser, createUserWithOffice, updateUserWithOffice } from "../../services/User";
import { formatUserId, serviceUserId } from "../../utils/Formatter";
import { FIND_USER_ROUTE, RouteUserParams, TYPE_CLIENT, FIND_CLIENT_ROUTE, TYPE_USER, PERSON_MODIFY_ROUTE, USER_MAIN_ROUTE, USER_GROUP_PARAM, USER_ID_PARAM, PERSON_CREATE_ROUTE } from "../../const/Routes";
import { UserContextProps, withAppUserContext } from "../../context/UserContext";
import { IdentityUser, IdentityUserWithOffice } from "../../interfaces/Identity";
import { GroupAdmin, GroupClient } from "../../utils/Config";
import { Enterprise } from "../../interfaces/Enterprise";
import { getRegionList, getBranchOfficeList } from "../../services/Stock";
import { BranchOffice } from "../../interfaces/Office";
import { Region } from "../../interfaces/Balance";

interface FormUserProps extends RouteComponentProps<RouteUserParams>, AppContextProps, UserContextProps { }

interface FormUserState {
    region: string;
    regions: Region[];
    office: string;
    offices: BranchOffice[];
    blockRegionAndLocation: boolean;
    showError: boolean;
    roleList: Role[];
    prevUser: IdentityUserWithOffice | null;
    userId: string;
    userName: string;
    userActive: boolean;
    codeRole: string;
    enterprise: Enterprise | undefined;
    external: string;
    email: string;
    saveBtnDisabled: boolean;
}

export class FormUserMain extends React.Component<FormUserProps, FormUserState>{
    constructor(props: FormUserProps) {
        super(props);
        this.getRoles = this.getRoles.bind(this);
        this.handleRoleSelected = this.handleRoleSelected.bind(this);
        this.handleInputUserName = this.handleInputUserName.bind(this);
        this.handleRegion = this.handleRegion.bind(this);
        this.handleBranchOffice = this.handleBranchOffice.bind(this);
        this.getRegionsFromAPI = this.getRegionsFromAPI.bind(this);
        this.getOfficesFromAPI = this.getOfficesFromAPI.bind(this);
        this.handleInputExternalUserName = this.handleInputExternalUserName.bind(this);
        this.handleInputEmailUser = this.handleInputEmailUser.bind(this);
        this.back = this.back.bind(this);
        this.create = this.create.bind(this);
        this.update = this.update.bind(this);
        this.acceptAction = this.acceptAction.bind(this);
        this.handleUserActive = this.handleUserActive.bind(this);
        this.setDefaults = this.setDefaults.bind(this);
        this.enableSaveBtn = this.enableSaveBtn.bind(this);

        this.state = {
            region: '',
            regions: [],
            office: '',
            offices: [],
            blockRegionAndLocation: true,
            showError: false,
            roleList: [],
            prevUser: this.props.user,
            userId: '',
            userName: '',
            userActive: true,
            codeRole: '',
            enterprise: {
                id: '',
                name: '',
            },
            external: '',
            email: '',
            saveBtnDisabled: true,
        }
    }

    componentDidMount() {
        if (this.props.match.params.userType === TYPE_USER) {
            this.getRoles();
        }

        this.setState({ blockRegionAndLocation: false }, this.getRegionsFromAPI);

        if (this.props.user === null) {
            this.props.showLoading(true);
            const group = this.props.match.params.userGroup || (this.props.match.params.userType === TYPE_USER ? GroupAdmin : GroupClient)
            const method = this.props.match.path === PERSON_MODIFY_ROUTE ? getUser : getUserCreate;

            method(this.props.match.params.userId, group, this.props.match.params.userType)
                .then((prevUser: IdentityUserWithOffice) => {
                    this.setDefaults(prevUser);
                    this.props.showLoading(false);
                })
                .catch((response: ResponseError) => {
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                    this.props.showLoading(false);
                    this.props.history.push(this.props.match.params.userType === TYPE_CLIENT ? FIND_CLIENT_ROUTE : FIND_USER_ROUTE);
                });
        } else {
            this.setDefaults(this.props.user);
        }
    }

    back() {
        this.props.history.goBack();
    }

    handleRegion(e: any) {
        e.preventDefault();
        const regionSelected = e.target.value;
        this.setState({ region: regionSelected }, () => this.getOfficesFromAPI());
    }

    handleBranchOffice(e: any) {
        e.preventDefault();
        const officeSelected = e.target.value;
        this.setState({ office: officeSelected }, this.enableSaveBtn);
    }

    getRegionsFromAPI(): void {
        this.props.showLoading(true);
        getRegionList().then((regionArray: Region[]) => {
            this.setState({ regions: regionArray }, this.props.showLoading(false));
        })
            .catch((response: ResponseError) => {
                this.props.showLoading(false);
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
    }

    getOfficesFromAPI(officeCode: string = ""): void {
        this.props.showLoading(true);
        if (this.state.region !== "" && this.state.region !== "NONE") {
            getBranchOfficeList(this.state.region).then((officeArray: BranchOffice[]) => {
                if (officeCode != "") {
                    this.setState({ offices: officeArray }, () => { this.setState({ office: officeCode.trim() }, this.props.showLoading(false)) });
                } else {
                    this.setState({ offices: officeArray }, this.props.showLoading(false));
                }
            })
                .catch((response: ResponseError) => {
                    this.props.showLoading(false);
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                });
        }
    }

    setDefaults(user: IdentityUserWithOffice) {

        const role = user.role ? user.role.codeRole : '';
        const block = (role == "CENOP" || role == "CENSP");
        const off = (user.branchOffice ? user.branchOffice.code : 'NONE');
        const reg = (user.branchOffice ? (user.branchOffice.region ? user.branchOffice.region.codeRegion : 'NONE') : 'NONE');

        if (off == 'NONE') {
            this.setState(state => ({
                ...state,
                userId: formatUserId(user.userId),
                userName: user.userName,
                codeRole: (user.role ? user.role.codeRole : ''),
                enterprise: user.enterprise,
                office: off,
                region: reg,
                isAgent: this.props.match.params.userType === TYPE_CLIENT && user.enterprise !== null,
                userActive: user.userActive,
                blockRegionAndLocation: block,
                external: user.externalUserName,
                email: user.emailUser,
            }));
        } else {
            this.setState(state => ({
                ...state,
                userId: formatUserId(user.userId),
                userName: user.userName,
                codeRole: (user.role ? user.role.codeRole : ''),
                enterprise: user.enterprise,
                office: off,
                region: reg,
                isAgent: this.props.match.params.userType === TYPE_CLIENT && user.enterprise !== null,
                userActive: user.userActive,
                blockRegionAndLocation: block,
                external: user.externalUserName,
                email: user.emailUser
            }), () => { this.setSelectedLocation(user.branchOffice.region.codeRegion, user.branchOffice.code); });
        }
    }

    setSelectedLocation(region: string, office: string) {
        this.setState({ region: region }, () => this.getOfficesFromAPI(office));
    }

    create() {
        if (this.state.codeRole !== '' || this.props.match.params.userType === TYPE_CLIENT) {
            this.props.showLoading(true);
            const serviceId = serviceUserId(this.state.userId, this.props.match.params.userType === TYPE_USER ? GroupAdmin : GroupClient);
            createUserWithOffice(this.state.userName, serviceId, this.props.match.params.userType, this.state.codeRole, this.state.office, this.state.external, this.state.email)
                .then((response: boolean) => {
                    this.props.setMessage({ message: i18n.t('user-created'), title: "", type: "alert-success" });
                    this.props.history.push(FIND_USER_ROUTE);
                    this.props.showLoading(false);
                })
                .catch((response: ResponseError) => {
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                    this.props.showLoading(false);
                });
        } else {
            this.props.setMessage({ message: i18n.t('user-role-select'), title: '', type: "alert-warning" });
        }
    }

    update() {
        if (this.state.codeRole !== '') {
            this.props.showLoading(true);
            const serviceId = serviceUserId(this.state.userId, this.props.match.params.userType === TYPE_USER ? GroupAdmin : GroupClient);
            updateUserWithOffice(this.state.userName, serviceId, this.props.match.params.userType, this.state.codeRole, this.state.userActive, this.state.office, this.state.external, this.state.email)
                .then((response: boolean) => {
                    this.props.setMessage({ message: i18n.t('user-updated'), title: "", type: "alert-success" });
                    this.props.history.push(USER_MAIN_ROUTE.replace(USER_GROUP_PARAM, GroupAdmin).replace(USER_ID_PARAM, serviceId));
                    this.props.showLoading(false);
                    this.props.refreshUser();
                })
                .catch((response: ResponseError) => {
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                    this.props.showLoading(false);
                });
        } else {
            this.props.setMessage({ message: i18n.t('user-role-select'), title: '', type: "alert-warning" });
        }
    }

    acceptAction() {
        if (this.props.match.params.userGroup == undefined) {
            this.create();
        } else {
            this.update();
        }
    }

    getRoles() {
        this.props.showLoading(true);
        getRolesList()
            .then((response: Role[]) => {
                this.setState({ roleList: response }, () => { this.props.showLoading(false) });
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    handleRoleSelected(e: any) {
        e.preventDefault();
        const codeRole = e.target.value;

        if (codeRole == "CENOP" || codeRole == "CENSP") {
            this.setState({ region: "RM", blockRegionAndLocation: true }, () => this.getOfficesFromAPI("1"));
        } else {
            this.setState({ blockRegionAndLocation: false });
        }

        this.setState(state => ({ ...state, codeRole }), this.enableSaveBtn);
    }

    handleUserActive(e: any) {
        const userActive = e.target.checked;
        this.setState({ userActive }, this.enableSaveBtn);
    }

    handleInputUserName(e: any): void {
        e.preventDefault();
        const userName = e.target.value;
        this.setState({ userName }, this.enableSaveBtn);
    }

    handleInputExternalUserName(e: any): void {
        e.preventDefault();
        const external = e.target.value;
        this.setState({ external }, this.enableSaveBtn);
    }

    handleInputEmailUser(e: any): void {
        e.preventDefault();
        const email = e.target.value;
        this.setState({ email }, this.enableSaveBtn);
    }

    enableSaveBtn(){
        if(this.state.saveBtnDisabled)
            this.setState({ saveBtnDisabled: false });
    }

    render() {
        const userNameListRole: JSX.Element[] = this.state.roleList.map((item: Role) => {
            return <option key={item.codeRole} value={item.codeRole}>{item.roleName}</option>;
        })

        const REG: { [key: string]: string } = this.state.regions.reduce((map: { [key: string]: string }, region: Region) => {
            map[region.codeRegion] = region.regionName;
            return map;
        }, {});

        const regions: JSX.Element[] = Object.keys(REG).map((codeRegion: string) => {
            return <option key={codeRegion} value={codeRegion}>{i18n.t(REG[codeRegion])}</option>;
        });

        const OFFI: { [key: string]: string } = this.state.offices.reduce((map: { [key: string]: string }, office: BranchOffice) => {
            map[office.code] = office.name;
            return map;
        }, {});

        const branchOffices: JSX.Element[] = Object.keys(OFFI).map((codeOffice: string) => {
            return <option key={codeOffice} value={codeOffice}>{i18n.t(OFFI[codeOffice])}</option>;
        });
        return (
            <div>
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t(this.props.match.params.userGroup == undefined ? 'create' : 'modify')} {i18n.t(this.state.enterprise ? 'agent' : this.props.match.params.userType).toLowerCase()}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <form className="form-horizontal">
                            <div className="form-group">
                                <label className="col-sm-2 control-label">{i18n.t('id')}:</label>
                                <div className="col-sm-4">
                                    <input type="text" readOnly={true} name="userid" className="form-control" id="userid" defaultValue={this.state.userId} />
                                </div>
                            </div>
                            <br />
                            <div className="form-group">
                                <label className="col-sm-2 control-label">{i18n.t('name')}:</label>
                                <div className="col-sm-4">
                                    <input type="text" readOnly={this.props.match.path != PERSON_CREATE_ROUTE} onChange={this.handleInputUserName} name="username" className="form-control" id="username" defaultValue={this.state.userName} />
                                </div>
                            </div>
                            <br />
                            {this.props.match.params.userType === TYPE_USER && <div className="form-group">
                                <label className="col-sm-2 control-label">{i18n.t('role')}:</label>
                                <div className="col-sm-4">
                                    <select className="form-control" onChange={this.handleRoleSelected} value={this.state.codeRole} disabled={this.props.useActiveDirectory}>
                                        <option value="" disabled hidden>{i18n.t('user-role-select')}</option>
                                        {userNameListRole}
                                    </select>
                                </div>
                                <br />
                            </div>}
                            <div className="form-group">
                                <label className="col-sm-2 control-label">{i18n.t('region')}:</label>
                                <div className="col-sm-4">
                                    <select disabled={this.state.blockRegionAndLocation} className="form-control" onChange={this.handleRegion} value={this.state.region}>
                                        <option value="NONE">{i18n.t('option-select')}</option>
                                        {regions}
                                    </select>
                                </div>
                            </div>
                            <br />
                            <div className="form-group">
                                <label className="col-sm-2 control-label">{i18n.t('branch-office')}:</label>
                                <div className="col-sm-4">
                                    <select disabled={this.state.blockRegionAndLocation} className="form-control" onChange={this.handleBranchOffice} value={this.state.office}>
                                        <option value="NONE">{i18n.t('option-select')}</option>
                                        {branchOffices}
                                    </select>
                                </div>
                            </div>
                            <br />
                            {this.props.useIntellitrust && <div className="form-group">
                                <label className="col-sm-2 control-label">{i18n.t('external-name')}:</label>
                                <div className="col-sm-4">
                                    <input type="text" onChange={this.handleInputExternalUserName} name="externalusername" className="form-control" id="externalusername" defaultValue={this.state.external} />
                                </div>
                            </div>}
                            <br />
                            <div className="form-group">
                                <label className="col-sm-2 control-label">{i18n.t('email')}:</label>
                                <div className="col-sm-4">
                                    <input type="text" onChange={this.handleInputEmailUser} name="email" className="form-control" id="email" defaultValue={this.state.email} disabled={this.props.useActiveDirectory}/>
                                </div>
                            </div>
                            <br />
                            {this.props.match.params.userType === TYPE_USER && this.props.match.path === PERSON_MODIFY_ROUTE && (this.props.user === null || this.props.match.params.userId !== this.props.authUser!.username) && <div className="form-group">
                                <label className="col-sm-2 control-label">{i18n.t('active')}:</label>
                                <div className="col-sm-4">
                                    <div className="form-control">
                                        <input type="checkbox" name="userActive" id="userActive" onChange={this.handleUserActive} checked={this.state.userActive} disabled={this.props.useActiveDirectory}></input> {this.state.userActive ? i18n.t('yes') : i18n.t('no')}
                                    </div>
                                </div>
                                <br />
                            </div>}
                            {this.state.enterprise && <div>
                                <div className="card-header">
                                    <div className="card-title">
                                        <div className="title">{i18n.t('enterprise-data')}</div>
                                    </div>
                                </div>
                                <br />
                                <div className="form-group">
                                    <label className="col-sm-2 control-label">{i18n.t('id')}:</label>
                                    <div className="col-sm-4">
                                        <input type="text" readOnly={true} name="entId" className="form-control" id="entId" defaultValue={this.state.enterprise.id} />
                                    </div>
                                </div>
                                <br />
                                <div className="form-group">
                                    <label className="col-sm-2 control-label">{i18n.t('name')}:</label>
                                    <div className="col-sm-4">
                                        <input type="text" readOnly={true} name="entName" className="form-control" id="entName" defaultValue={this.state.enterprise.name} />
                                    </div>
                                </div>
                                <br />

                            </div>}
                            <div className="form-group">
                                <div className="col-sm-offset-2 col-sm-10">
                                    <button type="button" onClick={this.back} className="btn btn-primary">{i18n.t('back')}</button>
                                    {(
                                      (this.props.match.params.userType === TYPE_USER && (this.props.havePermission(USER_CREATE_PERM) || this.props.havePermission(USER_UPDATE_PERM)) ) || 
                                      (this.props.match.params.userType === TYPE_CLIENT && this.props.havePermission(CLIENT_CREATE_PERM))
                                     ) &&
                                        <button type="button" onClick={this.acceptAction} className="btn btn-info " disabled={this.state.saveBtnDisabled}>
                                            {this.props.match.params.userGroup === undefined ? i18n.t('create') : i18n.t('update')}
                                        </button>}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export const FormUser = withAppUserContext(FormUserMain);