import * as React from "react";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../../context/AppContext";
import { RouteEquivalenceParams, SYSTEM_ROUTE } from "../../../const/Routes";
import i18n from "../../../i18n";
import { getEquivalence, updateEquivalence, getEquivalenceTypes, createEquivalence } from "../../../services/Equivalence";
import { Equivalence } from "../../../interfaces/Equivalence";
import { ResponseError, apiHandleErrorCode } from "../../../utils/ApiBaseConfig";
import { MESSAGE_SUCCESS } from "../../../const/Message";

interface FormEquivalenceProps extends RouteComponentProps<RouteEquivalenceParams>, AppContextProps { }
interface FormEquivalenceState {
    equivalenceTypes : string[];
    equivalenceType: string;
    stockcardValue: string;
    externalValue: string;
    saveBtnDisabled: boolean;
}

export class FormEquivalenceMain extends React.Component<FormEquivalenceProps, FormEquivalenceState>{
    constructor(props: FormEquivalenceProps) {
        super(props);
        this.back = this.back.bind(this);
        this.create = this.create.bind(this);
        this.update = this.update.bind(this);
        this.handleEquivalenceType = this.handleEquivalenceType.bind(this);
        this.handleCardwizardValue = this.handleCardwizardValue.bind(this);
        this.handleStockcardValue = this.handleStockcardValue.bind(this);
        this.enableSaveBtn = this.enableSaveBtn.bind(this);
        
        this.state = {
            equivalenceTypes : [],
            equivalenceType: "",
            stockcardValue: "",
            externalValue: "",
            saveBtnDisabled: true,
        }
    }

    componentDidMount() {
        this.props.showLoading(true);
        if(this.props.match.params.equivalenceId) {
            getEquivalence(Number(this.props.match.params.equivalenceId))
            .then((equivalence: Equivalence) => {
                this.setState({ 
                    equivalenceType: equivalence.equivalenceType,
                    stockcardValue: equivalence.stockcardValue,
                    externalValue: equivalence.externalValue
                });
                this.props.showLoading(false);
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
        }
        else {
            getEquivalenceTypes()
            .then((equivalenceTypes: string[]) => {
                this.setState( { equivalenceTypes});
                this.props.showLoading(false);
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
        }
            
    }

    back() {
        this.props.history.push(SYSTEM_ROUTE);
    }

    handleCardwizardValue(e: any): void {
        e.preventDefault();
        const externalValue = e.target.value;
        this.setState({ externalValue }, this.enableSaveBtn);
    }

    handleStockcardValue(e: any): void {
        e.preventDefault();
        const stockcardValue = e.target.value;
        this.setState({ stockcardValue}, this.enableSaveBtn);
    }

    handleEquivalenceType(e: any): void {
        e.preventDefault();
        const equivalenceType = e.target.value;
        this.setState({ equivalenceType}, this.enableSaveBtn);
    }

    create() {
        if(this.state.equivalenceType !== "" && this.state.stockcardValue !== "" && this.state.externalValue) {
            const equivalence: Equivalence = {
                id: 0,
                equivalenceType: this.state.equivalenceType,
                stockcardValue: this.state.stockcardValue,
                externalValue: this.state.externalValue
            };
            this.props.showLoading(true);
            createEquivalence(equivalence)
            .then(() => {
                this.props.showLoading(false);
                this.props.setMessage({ message: i18n.t('equivalence-create-ok'), type: MESSAGE_SUCCESS });
                this.props.history.push(SYSTEM_ROUTE);
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
        }
    }

    update() {
        const equivalence: Equivalence = {
            id: Number(this.props.match.params.equivalenceId),
            equivalenceType: this.state.equivalenceType,
            stockcardValue: this.state.stockcardValue,
            externalValue: this.state.externalValue
        };
        this.props.showLoading(true);
        updateEquivalence(equivalence)
        .then(() => {
            this.props.showLoading(false);
            this.props.setMessage({ message: i18n.t('equivalence-update-ok'), type: MESSAGE_SUCCESS });
            this.props.history.push(SYSTEM_ROUTE);
        })
        .catch((response: ResponseError) => {
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
            this.props.showLoading(false);
        });
    }

    enableSaveBtn(){
        if(this.state.saveBtnDisabled)
            this.setState({ saveBtnDisabled: false });
    }

    render() {
        const TYPES: { [key: string]: string } = this.state.equivalenceTypes.reduce((map: { [key: string]: string }, equivalenceType: string) => {
            map[equivalenceType] = equivalenceType;
            return map;
        }, {});

        const equivalenceTypes: JSX.Element[] = Object.keys(TYPES).map((equivalenceType: string) => {
            return <option key={equivalenceType} value={equivalenceType}>{i18n.t(TYPES[equivalenceType])}</option>;
        });

        return (
            <div>
                <div className="card">
                    <div className="card-body form-horizontal">
                        <div className="form-group">
                            <label className="col-sm-3 control-label">{i18n.t('equivalence')}: </label>
                            {this.props.match.params.equivalenceId && <input type="text" disabled={this.state.equivalenceType !== undefined} name="equivalenceType" className="col-sm-3" 
                            id="equivalenceType" defaultValue={this.state.equivalenceType ? this.state.equivalenceType : ''}>
                            </input>}
                            {!this.props.match.params.equivalenceId && <select className="col-sm-3" onChange={this.handleEquivalenceType} value={this.state.equivalenceType}>
                                        <option value="NONE">{i18n.t('option-select')}</option>
                                        {equivalenceTypes}
                                    </select>}
                        </div>
                        <div className="form-group">
                            <label className="col-sm-3 control-label">{i18n.t('equivalence-stockcard')}: </label>
                            <input type="text" disabled={this.props.match.params.equivalenceId !== undefined} name="equivalenceStockcard" className="col-sm-3" id="equivalenceStockcard"
                                defaultValue={this.state.stockcardValue ? this.state.stockcardValue : ''} onChange={this.handleStockcardValue}>
                            </input>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-3 control-label">{i18n.t('equivalence-cw')}: </label>
                            <input type="text" name="equivalenceCardWizard" className="col-sm-3" id="equivalenceCardWizard" onChange={this.handleCardwizardValue}
                                defaultValue={this.state.externalValue ? this.state.externalValue : ''}>
                            </input>
                        </div>
                        <div className="col-sm-4">
                            <button type="button" onClick={this.back} className="btn btn-primary">{i18n.t('back')}</button>
                            {!this.props.match.params.equivalenceId && <button type="button" onClick={this.create} className="btn btn-info" disabled={this.state.saveBtnDisabled}>{i18n.t('save')}</button>}
                            {this.props.match.params.equivalenceId && <button type="button" onClick={this.update} className="btn btn-warning" disabled={this.state.saveBtnDisabled}>{i18n.t('update')}</button>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export const FormEquivalence = withAppContext(FormEquivalenceMain);