import "../styles/lib/css/bootstrap.min.css";
import "../styles/lib/css/animate.min.css";
import "../styles/lib/css/font-awesome.min.css";
import "../styles/style.css";
import "../styles/flat-blue.css";
import "../styles/basic.css";
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'fast-text-encoding';

import * as React from "react";
import { Route, Switch, RouteComponentProps, withRouter, Redirect } from "react-router-dom";
import { MainContainer } from "./Main";
import { Login } from "./login/Index";
import { AppContextProvider, AppContextProps } from '../context/AppContext';
import { MessageModel } from "../utils/Message";
import { ResponseError, getToken } from "../utils/ApiBaseConfig";
import { IdentityAuthUser } from "../interfaces/Identity";
import { getUserSessionInfo } from "../services/Session";
import { Loading } from "../components/loading/Index";
import { LOGIN_ROUTE, MAIN_ROUTE } from "../const/Routes";
import ProtectedRoute from "../utils/ProtectedRoute";
import { isActiveDirectoryEnabled, isCardWizardEnabled, isIntelliTrustEnabled } from "../services/System";
import { LoginIntelliTrust } from "./login/IntelliTrust";

export interface IdentityProps extends RouteComponentProps { }

export interface IdentityState {
    loading: boolean;
    loadingCount: number;
    appContext: AppContextProps;
    isIntelliTrustEnabled: boolean;
    isCardWizardEnabled: boolean;
}

export class IdentityAppMain extends React.Component<IdentityProps, IdentityState> {
    constructor(props: IdentityProps) {
        super(props);

        this.isAuthenticated = this.isAuthenticated.bind(this);
        this.getAuthUser = this.getAuthUser.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.setMessage = this.setMessage.bind(this);
        this.hideMessage = this.hideMessage.bind(this);
        this.getParamFromURL = this.getParamFromURL.bind(this);
        this.havePermission = this.havePermission.bind(this);
        this.clearPermission = this.clearPermission.bind(this);
        this.getIntelliTrustStatus = this.getIntelliTrustStatus.bind(this);
        this.getCardWizardStatus = this.getCardWizardStatus.bind(this);
        this.getActiveDirectoryStatus = this.getActiveDirectoryStatus.bind(this);

        this.state = {
            loading: false,
            loadingCount: 0,
            isIntelliTrustEnabled: false,
            isCardWizardEnabled: false,
            appContext: {
                showLoading: this.showLoading,
                message: {},
                setMessage: this.setMessage,
                hideMessage: this.hideMessage,
                showMessage: false,
                authUser: null,
                authenticated: this.isAuthenticated,
                havePermission: this.havePermission,
                clearPermission: this.clearPermission,
                useIntellitrust: false,
                useCardWizard: false,
                useActiveDirectory: false,
            }
        }
    }

    componentWillMount() {
        if (this.isAuthenticated()) {
            this.getAuthUser();
        }
        this.getIntelliTrustStatus();
        this.getCardWizardStatus();
        this.getActiveDirectoryStatus();
    }

    isAuthenticated() {
        const token = getToken();
        return token !== null && token.length > 0;
    }

    getAuthUser() {
        this.showLoading(true);
        getUserSessionInfo()
            .then((authUser: IdentityAuthUser) => {
                this.setState(state => ({ ...state, appContext: { ...state.appContext, authUser } }), () => this.showLoading(false));
            })
            .catch((response: ResponseError) => {
                this.showLoading(false);
                this.props.history.push(LOGIN_ROUTE);
            });
    }

    getIntelliTrustStatus() {
        this.showLoading(true);
        isIntelliTrustEnabled()
            .then((isIntelliTrustEnabled: boolean) => {
                this.setState(state => ({
                    ...state,
                    appContext:{
                        ...state.appContext,
                        useIntellitrust: isIntelliTrustEnabled
                    }
                }), () => this.showLoading(false));
            })
            .catch((response: ResponseError) => {
                this.showLoading(false);
                this.props.history.push(LOGIN_ROUTE);
            });
    }

    getActiveDirectoryStatus() {
        this.showLoading(true);
        isActiveDirectoryEnabled()
            .then((isActiveDirectoryEnabled: boolean) => {
                this.setState(state => ({
                    ...state,
                    appContext:{
                        ...state.appContext,
                        useActiveDirectory: isActiveDirectoryEnabled
                    }
                }), () => this.showLoading(false));
            })
            .catch((response: ResponseError) => {
                this.showLoading(false);
                this.props.history.push(LOGIN_ROUTE);
            });
    }

    getCardWizardStatus(){
        this.showLoading(true);
        isCardWizardEnabled()
            .then((isCardWizardEnabled: boolean) => {
                this.setState(state => ({
                    ...state,
                    appContext:{
                        ...state.appContext,
                        useCardWizard: isCardWizardEnabled
                    }
                }), () => this.showLoading(false));
            })
            .catch((response: ResponseError) => {
                this.showLoading(false);
                this.props.history.push(LOGIN_ROUTE);
            });
    }

    getParamFromURL(data: string) {
        let param: string[];
        let pathData: string = data.replace("?", "");
        let paramsArray: string[] = pathData.split("&");

        let i: number = 0;
        for (i = 0; i < paramsArray.length; i++) {
            param = paramsArray[i].split("=");
        }
    }

    showLoading(loading: boolean) {
        this.setState(state => ({ ...state, loadingCount: state.loadingCount + (loading ? 1 : -1) }), () => this.setState(state => ({ ...state, loading: state.loadingCount > 0 })));
    }

    setMessage(message: MessageModel) {
        window.scrollTo(0, 0);
        this.setState(state => ({ ...state, appContext: { ...state.appContext, showMessage: true, message } }));
    }

    hideMessage() {
        this.setState(state => ({ ...state, appContext: { ...state.appContext, showMessage: false } }));
    }

    havePermission(...permissions: string[]): boolean {
        if (this.state.appContext.authUser) {
            for (let i = 0; i < permissions.length; i++) {
                if (this.state.appContext.authUser.permissionIds.some(current => current === permissions[i])) return true;
            }
        }
        return false;
    }

    clearPermission() {
        this.setState(state => ({ ...state, appContext: { ...state.appContext, authUser: null } }));
    }

    render() {
        const style = process.env.REACT_APP_STYLE ? require('../styles/' + process.env.REACT_APP_STYLE) : null;
        if (!this.state.appContext.useIntellitrust) {
            return (
                <div className="flat-blue body-class">
                    {process.env.REACT_APP_STYLE && <link rel="stylesheet" type="text/css" href={style} />}
                    <AppContextProvider value={this.state.appContext}>
                        <Switch>
                            <Route path={LOGIN_ROUTE} render={() => <Login getAuthUser={this.getAuthUser} getParamFromURL={this.getParamFromURL} />} />
                            <ProtectedRoute path={MAIN_ROUTE} component={MainContainer}/>
                        </Switch>
                        {this.state.loading && <Loading />}
                    </AppContextProvider>
                </div>
            )
        } else {
            return (
                <div className="flat-blue body-class">
                    {process.env.REACT_APP_STYLE && <link rel="stylesheet" type="text/css" href={style} />}
                    <AppContextProvider value={this.state.appContext}>
                        <Switch>
                            <Route path={LOGIN_ROUTE} render={() => <LoginIntelliTrust getAuthUser={this.getAuthUser} getParamFromURL={this.getParamFromURL} />} />
                            <ProtectedRoute path={MAIN_ROUTE} component={MainContainer} />
                        </Switch>
                        {this.state.loading && <Loading />}
                    </AppContextProvider>
                </div>
            )
        }
    }
}

export const IdentityApp = withRouter(IdentityAppMain);


