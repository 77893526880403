import * as React from "react";
import i18n from "../../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../../context/AppContext";
import { BootstrapTable, TableHeaderColumn, ExportCSVButton } from "react-bootstrap-table";
import { Movements, Region} from "../../../interfaces/Movement";
import { BranchOffice } from "../../../interfaces/Office";
import { getRegionList, getBranchOfficeList } from "../../../services/Stock";
import { ResponseError, apiHandleErrorCode } from "../../../utils/ApiBaseConfig";
import { getTimestamp } from "../../../utils/Formatter";
import { Balance, SearchBalance } from "../../../interfaces/Balance";
import { findBalances, BALANCES_AMOUNT_PER_REQUEST } from "../../../services/Balance";
import { getDeviceTypeList } from "../../../services/DeviceType";
import { DeviceType } from "../../../interfaces/DeviceType";
import Select from "react-select";
import Modal from 'react-modal';
import { findMovementsOfBalance } from "../../../services/Movement";

export interface BalanceListProps extends RouteComponentProps, AppContextProps{ }
export interface BalanceListState {
    region: string;
    regionName: string;
    regions: Region[];
    office: string;
    officeName: string;
    offices: BranchOffice[];
    deviceTypes: DeviceType[];
    stock: string;
    step: number;
    dateFrom: string;
    dateTo: string;
    blockRegionAndLocation: boolean;
    dateOfLastBalance: string;
    data: Balance[];
    tableOptions: any;
    comment: string;
    detailModalIsOpen: boolean;
    selectedBalance: Balance | any;
    movements: Movements[];
 }

export class BalanceListMain extends React.Component<BalanceListProps, BalanceListState>{
    reachTheLastPage: boolean = false;
    constructor(props: BalanceListProps) {
        super(props);
        this.handleRegion = this.handleRegion.bind(this);
        this.handleBranchOffice = this.handleBranchOffice.bind(this);
        this.handleStock = this.handleStock.bind(this);
        this.handleFrom = this.handleFrom.bind(this);
        this.handleTo = this.handleTo.bind(this);
        this.getRegionsFromAPI = this.getRegionsFromAPI.bind(this);
        this.getOfficesFromAPI = this.getOfficesFromAPI.bind(this);
        this.getDeviceTypesFromAPI = this.getDeviceTypesFromAPI.bind(this);
        this.findBalancesFromAPI = this.findBalancesFromAPI.bind(this);
        this.createSearchBalance = this.createSearchBalance.bind(this);
        this.openDetailModal = this.openDetailModal.bind(this);
        this.closeDetailModal = this.closeDetailModal.bind(this);
        this.state = {
            region: "",
            regionName: "",
            regions: [],
            office: "",
            officeName: "",
            offices: [],
            deviceTypes: [],
            stock: "",
            step: 0,
            blockRegionAndLocation: true,
            dateOfLastBalance: i18n.t('no-data'),
            dateFrom: "",
            dateTo: "",
            data: [],
            comment: "",
            tableOptions: {
                noDataText: i18n.t('table-empty'),
                sizePerPageList: [{
                    text: '10', value: 10
                }, {
                    text: '20', value: 20
                }, {
                    text: '50', value: 50
                }],
                sizePerPage: 10,
                paginationSize: 3,
                pageStartIndex: 1,
                exportCSVBtn: (onClick: any) => { return <ExportCSVButton btnText={i18n.t('csv-save')} btnContextual='btn-info' onClick={onClick} /> }
            },
            detailModalIsOpen: false,
            movements: [],
            selectedBalance: {}
        };
    }

    componentDidMount() {
        if(this.props.authUser && this.props.authUser.branchOffice) {
            //setear la fecha de  busqueda por defecto
            var curr = new Date();
            var date = curr.toISOString().substr(0, 10);
            curr.setDate(curr.getDate()+1);
            var tomorrow = curr.toISOString().substr(0, 10);

            if(this.props.authUser.branchOffice.code === "1") {
                this.setState({ blockRegionAndLocation: false, dateFrom: date, dateTo: tomorrow }, () => {
                    this.getRegionsFromAPI();
                    this.getDeviceTypesFromAPI();
                    this.findBalancesFromAPI();
                });
            }
            else {
                this.setState({
                    office: this.props.authUser.branchOffice.code, 
                    officeName: this.props.authUser.branchOffice.name, 
                    regionName: this.props.authUser.branchOffice.region.regionName,
                    dateFrom: date, 
                    dateTo: tomorrow
                }, () => {
                    this.getDeviceTypesFromAPI();
                    this.findBalancesFromAPI();
                });
            }
        }
        
    }

    handleRegion(e: any) {
        e.preventDefault();
        const regionSelected = e.target.value;
        this.setState({ region: regionSelected }, this.getOfficesFromAPI);
    }

    handleBranchOffice(e: any) {
        e.preventDefault();
        const officeSelected = e.target.value;
        this.setState({ office: officeSelected });
    }

    handleStock(stockType: any) {
        this.setState({ stock: stockType.value });
        
    }

    handleFrom(e: any) {
        e.preventDefault();
        const startDate = e.target.value;
        this.setState({ dateFrom :startDate });
    }

    handleTo(e: any) {
        e.preventDefault();
        const endDate = e.target.value;
        this.setState({ dateTo :endDate });
    }


    getRegionsFromAPI(): void {
        this.props.showLoading(true);
        getRegionList().then((regionArray: Region[]) =>{
            this.setState({ regions: regionArray }, () => {
                this.props.showLoading(false);
            });
        })
        .catch((response: ResponseError) => {
            this.props.showLoading(false);
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
        });
    }

    getOfficesFromAPI(): void {
        this.props.showLoading(true);
        if(this.state.region !== "" && this.state.region !== "NONE") {
            getBranchOfficeList(this.state.region).then((officeArray: BranchOffice[]) =>{
                this.setState({ offices: officeArray }, () => {
                    this.props.showLoading(false);
                });
            })
            .catch((response: ResponseError) => {
                this.props.showLoading(false);
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
        }
    }

    getDeviceTypesFromAPI(): void {
        this.props.showLoading(true);
        getDeviceTypeList().then((deviceTypeArray: DeviceType[]) =>{
            this.setState({ deviceTypes: deviceTypeArray }, () => {
                this.props.showLoading(false);
            });
        })
        .catch((response: ResponseError) => {
            this.props.showLoading(false);
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
        });
    }

    createSearchBalance(firstResult: number): SearchBalance {
        const searchBalance: SearchBalance = {};
        if (this.state.office && this.state.office !== "NONE")
            searchBalance.branchOfficeCode = this.state.office;
        if(this.state.dateFrom && this.state.dateFrom !== "")
            searchBalance.movementDateFrom = this.state.dateFrom;
        if(this.state.dateTo && this.state.dateTo !== "")
            searchBalance.movementDateTo = this.state.dateTo;
        if(this.state.stock && this.state.stock !== "NONE")
            searchBalance.deviceTypeCode = this.state.stock;
        searchBalance.firstResult = firstResult;
        return searchBalance;
    }

    findBalancesFromAPI(): void {
        this.props.showLoading(true);
            findBalances(this.createSearchBalance(0))
                .then((balance: Balance[]) => {
                    this.setState({ data: balance }, () => {
                        this.props.showLoading(false);
                    });
                })
                .catch((response: ResponseError) => {
                    this.props.showLoading(false);
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                });
    }


    onPageChange(page: number, sizePerPage: number) {
        const pages = Math.floor(this.state.data.length / sizePerPage) + (this.state.data.length % BALANCES_AMOUNT_PER_REQUEST === 0 ? 0 : 1);
        if (page === pages && !this.reachTheLastPage) {
            this.props.showLoading(this.reachTheLastPage = true);
            findBalances(this.createSearchBalance(this.state.data.length))
                .then((registries: Balance[]) => {
                    if (registries.length > 0) {
                        this.setState(state => {
                            return { data: [...state.data, ...registries] }
                        }, () => { 
                            if (registries.length === BALANCES_AMOUNT_PER_REQUEST)
                                this.reachTheLastPage = false;
                            this.props.showLoading(false);
                        });
                    } else {
                        this.props.showLoading(false);
                    }
                })
                .catch((response: ResponseError) => {
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                    this.props.showLoading(false);
                });
        }
    }

    getFileName() {
        return 'balances_' + getTimestamp() + '.csv';
    }

    openDetailModal(record: any){
        this.setState({ selectedBalance: record }, () => this.findMovements(record.correlative));
    }

    closeDetailModal(){
        this.setState({ detailModalIsOpen: false, movements: [], selectedBalance: {} });
    }
    
    findMovements(balanceCorrelative: number){
        this.props.showLoading(true);
        findMovementsOfBalance(balanceCorrelative).then((movements: Movements[]) => {
            this.setState({ movements: movements, detailModalIsOpen: true }, () => {
                this.props.showLoading(false);
            });
        })
        .catch((response: ResponseError) => {
            this.props.showLoading(false);
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
        });
    }

    render() {
        const customStyles: any = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            }
        };

        const REG : { [key: string]: string } = this.state.regions.reduce((map: { [key: string]: string }, region: Region) => {
            map[region.codeRegion] = region.regionName;
            return map;
        }, {});

        const regions: JSX.Element[] = Object.keys(REG).map((codeRegion: string) => {
            return <option key={codeRegion} value={codeRegion}>{i18n.t(REG[codeRegion])}</option>;
        });
        
        const OFFI : { [key: string]: string } = this.state.offices.reduce((map: { [key: string]: string }, office: BranchOffice) => {
            map[office.code] = office.name;
            return map;
        }, {});

        const branchOffices: JSX.Element[] = Object.keys(OFFI).map((codeOffice: string) => {
            return <option key={codeOffice} value={codeOffice}>{i18n.t(OFFI[codeOffice])}</option>;
        });

        const STOCK : { [key: string]: string } = this.state.deviceTypes.reduce((map: { [key: string]: string }, deviceType: DeviceType) => {
            map[deviceType.codeDeviceType] = deviceType.nameDeviceType;
            return map;
        }, {});

        const stocks : { label: string, value: string }[] = Object.keys(STOCK).map((codeStock: string) => { 
            return { label: i18n.t(STOCK[codeStock]), value: codeStock }
        });

        return (
            <>
                <div className="panel-body">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <div className="title">{i18n.t('filters')}</div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="form-group col-sm-12">
                            {!this.state.blockRegionAndLocation &&
                                <div>
                                    <div className="form-group col-sm-4">
                                        <label>{i18n.t('region')}:</label>
                                        <select className="form-control" onChange={this.handleRegion} value={this.state.region}>
                                            <option value="NONE">{i18n.t('option-select')}</option>
                                            {regions}
                                        </select>
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label>{i18n.t('branch-office')}:</label>
                                        <select className="form-control" onChange={this.handleBranchOffice} value={this.state.office}>
                                            <option value="NONE">{i18n.t('option-select')}</option>
                                            {branchOffices}
                                        </select>
                                    </div>
                                </div>
                                }
                                {this.state.blockRegionAndLocation &&
                                <div>
                                    <div className="form-group col-sm-4">
                                        <label>{i18n.t('region')}:</label>
                                        <p className="form-control">{this.state.regionName}</p>
                                    </div>

                                    <div className="form-group col-sm-4">
                                        <label>{i18n.t('branch-office')}:</label>
                                        <p className="form-control">{this.state.officeName}</p>
                                    </div>
                                </div>
                                }
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('device-type')}:</label>
                                    <Select 
                                        options={stocks}
                                        isSearchable={true}
                                        onChange={this.handleStock}
                                        placeholder={i18n.t('option-select')}
                                    />
                                </div>
                            </div>
                            <div className="form-group col-sm-12">
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('from')}:</label>
                                    <input type="date" name="from" className="form-control" id="from" value={this.state.dateFrom} onChange={this.handleFrom} />
                                </div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('to')}:</label>
                                    <input type="date" name="to" className="form-control" id="to" value={this.state.dateTo} onChange={this.handleTo} />
                                </div>
                                <div className="form-group col-sm-4">
                                    <br></br>
                                    <button type="button" className="btn btn-info" onClick={this.findBalancesFromAPI}>{i18n.t('search')}</button>
                                </div>
                            </div>
                            <div className="form-group col-sm-12">
                                <BootstrapTable 
                                    data={this.state.data}
                                    options={{ 
                                        ...this.state.tableOptions, 
                                        onPageChange: this.onPageChange.bind(this) 
                                    }}
                                    bordered hover pagination={this.state.data.length > 0} exportCSV={this.state.data.length > 0}
                                    csvFileName={this.getFileName()}
                                    >
                                    <TableHeaderColumn dataField='correlative' isKey={true} hidden={true}></TableHeaderColumn>
                                    <TableHeaderColumn dataField='regionName' tdStyle={ { whiteSpace: 'normal' } } dataSort={true} dataAlign="center" filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} width="130">{i18n.t('region')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='branchOfficeName' tdStyle={ { whiteSpace: 'normal' } } dataSort={true} dataAlign="center" filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} width="130">{i18n.t('branch-office')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='deviceTypeName' tdStyle={ { whiteSpace: 'normal' } } dataSort={true} dataAlign="center" filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} width="200" >{i18n.t('stock')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='date' tdStyle={ { whiteSpace: 'normal' } } dataSort={true} dataAlign="center" width="130">{i18n.t('date')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='vaultToMachine' thStyle={ { whiteSpace: 'normal', wordWrap: 'break-word'} } dataAlign="center"  width="100">{i18n.t('balance-out-vault')} <span data-toggle="tooltip" data-placement="top" title={i18n.t('balance-out-vault-explanation')}><i className='glyphicon glyphicon-info-sign'></i></span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='realQuantitySuccessful' thStyle={ { whiteSpace: 'normal', wordWrap: 'break-word'} } dataAlign="center"  width="100">{i18n.t('balance-successful-emission')} <span data-toggle="tooltip" data-placement="top" title={i18n.t('balance-successful-emission-explanation')}><i className='glyphicon glyphicon-info-sign'></i></span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='totalRejected' thStyle={ { whiteSpace: 'normal', wordWrap: 'break-word'} } dataAlign="center"  width="100">{i18n.t('balance-failed-emission')} <span data-toggle="tooltip" data-placement="top" title={i18n.t('balance-failed-emission-explanation')}><i className='glyphicon glyphicon-info-sign'></i></span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='realMachineToVault' thStyle={ { whiteSpace: 'normal', wordWrap: 'break-word'} } dataAlign="center"  width="100">{i18n.t('balance-expected-to-vault')} <span data-toggle="tooltip" data-placement="top" title={i18n.t('balance-expected-to-vault-explanation')}><i className='glyphicon glyphicon-info-sign'></i></span></TableHeaderColumn>
                                    <TableHeaderColumn dataField='userName' dataSort={true} dataAlign="center" width="130">{i18n.t('user')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='userSupervisorName' dataSort={true} dataAlign="center" width="130">{i18n.t('supervisor')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='' tdStyle={{ textAlign: 'center' }} width="80" dataFormat={(cell, row) => (
                                        <button onClick={() => this.openDetailModal(row)}>
                                            <span><i className='glyphicon glyphicon-search'></i></span>
                                        </button>
                                    )}>{i18n.t('details')}</TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.detailModalIsOpen}
                    onRequestClose={this.closeDetailModal}
                    style={customStyles}
                    ariaHideApp={false}
                >
                    <h2>{i18n.t('balance-details-title')}</h2>
                    <div className="card-body">
                        <div className="form-group col-sm-6">
                            <label>{i18n.t('device-code')}:</label>
                            <input disabled
                                type="text"
                                value={this.state.selectedBalance.deviceTypeCode}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group col-sm-6">
                            <label>{i18n.t('device')}:</label>
                            <input disabled
                                type="text"
                                value={this.state.selectedBalance.deviceTypeName}
                                className="form-control"
                            />
                        </div>
                        <label>{i18n.t('see-movements')}</label>
                        <div className="form-group" style={ { maxWidth: '1000px' } }>
                            <BootstrapTable 
                                data={this.state.movements}
                                bordered hover
                                >
                                <TableHeaderColumn dataField='movementTypeDescription' dataAlign="center" width="130">{i18n.t('movement')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='movementDate' isKey={true} dataAlign="center" width="130">{i18n.t('date')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='movementQuantity' dataAlign="center" width="130">{i18n.t('devices')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='userName' dataAlign="center" width="130">{i18n.t('user')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='movementDescription' tdStyle={ { whiteSpace: 'normal' } } dataAlign="center" width="200">{i18n.t('comment')}</TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                        <label>{i18n.t('binnacle')}</label>
                        <div className="form-group" style={ { maxWidth: '1000px' } }>
                            <BootstrapTable 
                                data={[this.state.selectedBalance]}
                                bordered hover 
                                >
                                <TableHeaderColumn dataField='correlative' isKey={true} hidden={true}></TableHeaderColumn>
                                <TableHeaderColumn dataField='realQuantitySuccessful' thStyle={ { whiteSpace: 'normal', wordWrap: 'break-word'} } dataAlign="center"  width="100">{i18n.t('balance-successful-emission')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='realQuantityRejectedMachine' thStyle={ { whiteSpace: 'normal', wordWrap: 'break-word'} } dataAlign="center"  width="100">{i18n.t('balance-reject-in-machine')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='realQuantityRejectedQA' thStyle={ { whiteSpace: 'normal', wordWrap: 'break-word'} } dataAlign="center"  width="100">{i18n.t('balance-reject-qa')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='totalRejected' thStyle={ { whiteSpace: 'normal', wordWrap: 'break-word'} } dataAlign="center"  width="100">{i18n.t('balance-failed-emission')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='userSupervisorName' dataSort={true} dataAlign="center" width="130">{i18n.t('supervisor')}</TableHeaderColumn>
                                <TableHeaderColumn dataField='description' dataSort={true} dataAlign="center" width="130">{i18n.t('comment')}</TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                        <button className="btn btn-info pull-right" onClick={this.closeDetailModal}>{i18n.t('close')}</button>
                    </div>
                </Modal>
            </>
        )
    }
}

export const BalanceList = withAppContext(BalanceListMain);
