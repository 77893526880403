import { RouteComponentProps } from "react-router-dom";
import { AppContextProps, withAppContext } from "../../context/AppContext";
import React from "react";
import { REPORT_DEVICES_PERM } from "../../const/Permission";
import i18n from "../../i18n";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { DateRange, ReportDevice, SearchFilter } from "../../interfaces/ReportDevices";
import { Region } from "../../interfaces/Movement";
import { BranchOffice } from "../../interfaces/Office";
import { getBranchOfficeList, getRegionList } from "../../services/Stock";
import { apiHandleErrorCode, ResponseError } from "../../utils/ApiBaseConfig";
import { getBranchOffice } from "../../services/Office";
import { StockConfig, StockDeviceType } from "../../interfaces/Stock";
import { getReportDeviceByFilter } from "../../services/ReportDevices";
import { getDeviceTypeList } from "../../services/DeviceType";
import { DeviceType } from "../../interfaces/DeviceType";
import { ExcelExportButton } from "../utils/ExportExcelButton";

export interface AdvanceSearchProps extends RouteComponentProps, AppContextProps { }

export interface AdvanceSearchState {
    tableOptions: any;
    dateRange: DateRange;
    showSelectRegionAndLocation: boolean;
    region: string;
    regions: Region[];
    branchOffice: BranchOffice;
    offices: BranchOffice[];
    office: string;
    officeName: string;
    regionName: string;
    devicesType: StockDeviceType[];
    deviceType: string;
    groupDeviceType: string;
    groupDeviceTypes: string[];
    toDateDisabled: boolean;
    reportDevices: ReportDevice[];
    originalData: ReportDevice[]; // Estado para datos originales
}

export class AdvanceSearchMain extends React.Component<AdvanceSearchProps, AdvanceSearchState> {
    constructor(props: AdvanceSearchProps) {
        super(props);
        this.handleRegion = this.handleRegion.bind(this);
        this.handleBranchOffice = this.handleBranchOffice.bind(this);
        this.getRegionsFromAPI = this.getRegionsFromAPI.bind(this);
        this.handleDeviceType = this.handleDeviceType.bind(this);
        this.handleFromDateChange = this.handleFromDateChange.bind(this);
        this.handleToDateChange = this.handleToDateChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);

        this.state = {
            tableOptions: {
                noDataText: i18n.t('table-empty'),
                sizePerPageList: [{
                    text: '10', value: 10
                }, {
                    text: '20', value: 20
                }, {
                    text: '50', value: 50
                }],
                sizePerPage: 10,
                paginationSize: 3,
                pageStartIndex: 1
            },
            dateRange: {
                from: '',
                to: ''
            },
            showSelectRegionAndLocation: false,
            region: "",
            regions: [],
            branchOffice: {
                code: '',
                name: '',
                enabled: true,
                address: '',
                managerUserName: '',
                notificationMail: '',
                updateDate: new Date(),
                stockConfig: [],
                region: { codeRegion: '', regionName: '', regionActive: true },
                defaultNotifyConf: true
            },
            offices: [],
            office: '',
            officeName: '',
            regionName: '',
            groupDeviceType: '',
            groupDeviceTypes: [],
            devicesType: [],
            deviceType: '',
            toDateDisabled: true,
            reportDevices: [],
            originalData: []

        }
    }
    componentDidMount() {
        if (this.props.authUser && this.props.authUser.branchOffice) {
            if (this.props.authUser.branchOffice.code === "1") {
                this.setState({ showSelectRegionAndLocation: true }, this.getRegionsFromAPI);

            }
            else {
                this.setState({
                    office: this.props.authUser.branchOffice.code,
                    officeName: this.props.authUser.branchOffice.name,
                    regionName: this.props.authUser.branchOffice.region.regionName
                }, this.fetchOffice);
                this.getRegionsFromAPI();
            }
            this.getDeviceTypesFromAPI();
        }
    }

    getRegionsFromAPI(): void {
        this.props.showLoading(true);
        getRegionList().then((regionArray: Region[]) => {
            this.setState({ regions: regionArray }, this.props.showLoading(false));
        })
            .catch((response: ResponseError) => {
                this.props.showLoading(false);
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
    }

    // Función para extraer el prefijo numérico del código del dispositivo
    extractNumericPrefix(code: string): string {
        // Usa una expresión regular para obtener la parte numérica antes del primer carácter alfabético
        const match = code.match(/^\d+/);
        return match ? match[0] : '';
    }

    getDeviceTypesFromAPI(): void {
        this.props.showLoading(true);
        getDeviceTypeList()
            .then((deviceTypeArray: DeviceType[]) => {
                // Crear una lista para almacenar los códigos de grupo y nombres de grupo
                const stockDeviceTypes: StockDeviceType[] = [];

                // Procesar cada dispositivo
                deviceTypeArray.forEach(deviceType => {
                    // Extraer el código del grupo
                    const groupCode = this.extractNumericPrefix(deviceType.codeDeviceType);

                    // Extraer el nombre del grupo
                    const lastDashIndex = deviceType.nameDeviceType.lastIndexOf('-');
                    const groupName = lastDashIndex !== -1
                        ? deviceType.nameDeviceType.substring(0, lastDashIndex)
                        : deviceType.nameDeviceType;

                    // Crear un objeto StockDeviceType y agregarlo a la lista
                    stockDeviceTypes.push({
                        code: groupCode,
                        name: groupName
                    });
                });

                // Actualizar el estado con la lista de StockDeviceType
                this.setState({ devicesType: stockDeviceTypes }, this.props.showLoading(false));
            })
            .catch((response: ResponseError) => {
                this.props.showLoading(false);
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
    }


    handleRegion(e: any) {
        e.preventDefault();
        this.setState({
            branchOffice: {
                ...this.state.branchOffice,
                region: {
                    codeRegion: e.target.value,
                    regionName: e.target.options[e.target.selectedIndex].text,
                    regionActive: true
                }
            }
            , region: e.target.value
        }, this.getOfficesFromAPI);
    }

    getOfficesFromAPI(): void {
        if (this.state.region !== "" && this.state.region !== "NONE") {
            this.props.showLoading(true);
            getBranchOfficeList(this.state.region).then((officeArray: BranchOffice[]) => {
                this.setState({ offices: officeArray }, () => {
                    this.props.showLoading(false);
                });
            })
                .catch((response: ResponseError) => {
                    this.props.showLoading(false);
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                });
        }
    }

    handleBranchOffice(e: any) {
        e.preventDefault();
        const officeSelected = e.target.value;
        if (officeSelected !== "" && officeSelected !== "NONE") {
            this.setState({ office: officeSelected }, this.fetchOffice);
        } else {
            this.setState({ office: officeSelected });
        }
    }



    fetchOffice() {
        this.props.showLoading(true);
        getBranchOffice(this.state.office)
            .then((branchOffice: BranchOffice) => {

                // Procesar dispositivos y crear stockDeviceTypes
                const stockDeviceTypes: StockDeviceType[] = (branchOffice.stockConfig || [])
                    .filter(deviceType => deviceType.available)
                    .map(deviceType => {
                        // Extraer el código del grupo
                        const groupCode = this.extractNumericPrefix(deviceType.deviceType.code);

                        // Extraer el nombre del grupo
                        const lastDashIndex = deviceType.deviceType.name.lastIndexOf('-');
                        const groupName = lastDashIndex !== -1
                            ? deviceType.deviceType.name.substring(0, lastDashIndex)
                            : deviceType.deviceType.name;

                        return {
                            code: groupCode,
                            name: groupName
                        };
                    });

                this.setState({
                    branchOffice,
                    devicesType: stockDeviceTypes
                });
                this.props.showLoading(false);
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    handleDeviceType(e: any) {
        e.preventDefault();
        const challengeType = e.target.value;
        this.setState({ deviceType: challengeType });
    }

    handleFromDateChange(e: any) {
        const fromDate = e.target.value;
        this.setState({
            dateRange: {
                ...this.state.dateRange,
                from: fromDate
            },
            toDateDisabled: !fromDate // Habilitar el campo 'to' si 'from' tiene un valor
        });
    }

    handleToDateChange(e: any) {
        const toDate = e.target.value;
        const { from } = this.state.dateRange;

        if (new Date(toDate) < new Date(from)) {
            alert(i18n.t('alert-date'));
        } else {
            this.setState({
                dateRange: {
                    ...this.state.dateRange,
                    to: toDate
                }
            });
        }
    }

    handleSearch(e: any) {
        this.props.showLoading(true)
        e.preventDefault();
        const { from, to } = this.state.dateRange;

        if (new Date(to) < new Date(from)) {
            alert(i18n.t('alert-date'));
            this.props.showLoading(false);
            return;
        }

        const searchFilterByPeriod: SearchFilter = {
            branchOffice: this.state.branchOffice,
            groupDeviceType: this.state.deviceType,
            from: from,
            to: to
        };

        getReportDeviceByFilter(searchFilterByPeriod).then((response) => {
            this.updateOriginalData();
            this.setState({ reportDevices: response }, this.props.showLoading(false));
        }).catch((response: ResponseError) => {
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
            this.props.showLoading(false);
        });;

    }


    updateOriginalData = () => {
        // Ordena los datos por grupo y por nombre del dispositivo
        const sortedData = this.state.reportDevices.slice().sort((a, b) => {
            if (a.groupDeviceCode === b.groupDeviceCode) {
                return a.deviceName.localeCompare(b.deviceName);
            }
            return a.groupDeviceCode.localeCompare(b.groupDeviceCode);
        });

        const groupedData: { [key: string]: ReportDevice[] } = {};

        // Agrupa los datos
        sortedData.forEach(device => {
            const key = `${device.groupDeviceCode}-${device.groupDeviceName}`;
            if (!groupedData[key]) {
                groupedData[key] = [];
            }
            groupedData[key].push(device);
        });

        // Prepara originalData asegurando que la fila total de grupo esté al final de cada grupo
        const finalData: ReportDevice[] = [];

        Object.keys(groupedData).forEach(key => {
            const group = groupedData[key];
            const devices = group.filter(device => device.deviceName !== 'Total Grupo');
            const totalRow = group.find(device => device.deviceName === 'Total Grupo');

            // Añade los dispositivos del grupo
            finalData.push(...devices);

            // Añade la fila total del grupo si está presente
            if (totalRow) {
                finalData.push(totalRow);
            }
        });

        // Filtra el dispositivo "Total Global" y lo añade al final
        const totalGlobal = finalData.find(device => device.deviceName === 'Total Global');
        const otherDevices = finalData.filter(device => device.deviceName !== 'Total Global');

        // Reemplaza finalData con los otros dispositivos seguidos por "Total Global"
        finalData.length = 0; // Limpiar finalData
        finalData.push(...otherDevices);
        if (totalGlobal) {
            finalData.push(totalGlobal);
        }

        return finalData;
    }

    prepareTableData = () => {
        const groupedData = this.state.reportDevices.reduce((acc, device) => {
            const key = `${device.groupDeviceCode}-${device.groupDeviceName}`;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(device);
            return acc;
        }, {} as { [key: string]: ReportDevice[] });

        const dataWithRowSpan: any[] = [];
        let groupColorIndex = 0; // Índice de color para alternar

        Object.keys(groupedData).forEach(key => {
            const devices = groupedData[key];
            const totalRow = devices.find(d => d.deviceName === 'Total'); // Encontrar la fila de total
            const rowsToDisplay = devices.filter(d => d.deviceName !== 'Total'); // Filtrar filas que no son total
            const rowSpan = rowsToDisplay.length;

            rowsToDisplay.forEach((device, index) => {
                dataWithRowSpan.push({
                    ...device,
                    groupDeviceCode: index === 0 ? device.groupDeviceCode : '',
                    groupDeviceName: index === 0 ? device.groupDeviceName : '',
                    rowSpanGroupCode: index === 0 ? rowSpan : 0,
                    rowSpanGroupName: index === 0 ? rowSpan : 0,
                    groupColorIndex // Asegurarse de que el índice del grupo esté definido
                });
            });

            // Añadir la fila total al final del grupo
            if (totalRow) {
                dataWithRowSpan.push({
                    ...totalRow,
                    groupDeviceCode: '',
                    groupDeviceName: '',
                    rowSpanGroupCode: 0,
                    rowSpanGroupName: 0,
                    groupColorIndex // Asegurarse de que el índice del grupo esté definido para la fila total
                });
            }

            groupColorIndex++; // Incrementar índice del grupo
        });

        return dataWithRowSpan;
    }

    getCellStyle = (groupIndex: number) => {
        const colors = ['#f9f9f9', '#e0e0e0']; // Alterna entre dos colores
        return { backgroundColor: colors[groupIndex % colors.length] };
    }

    areDatesSelected = (): boolean => {
        const { from, to } = this.state.dateRange;
        return from !== '' && to !== '';
    }

    render() {

        const dataWithRowSpan = this.prepareTableData();
        const completeTable = this.updateOriginalData();

        const REG: { [key: string]: string } = this.state.regions.reduce((map: { [key: string]: string }, region: Region) => {
            map[region.codeRegion] = region.regionName;
            return map;
        }, {});

        const regions: JSX.Element[] = Object.keys(REG).map((codeRegion: string) => {
            return <option key={codeRegion} value={codeRegion}>{i18n.t(REG[codeRegion])}</option>;
        });

        const OFFI: { [key: string]: string } = this.state.offices.reduce((map: { [key: string]: string }, office: BranchOffice) => {
            map[office.code] = office.name;
            return map;
        }, {});

        const branchOffices: JSX.Element[] = Object.keys(OFFI).map((codeOffice: string) => {
            return <option key={codeOffice} value={codeOffice}>{i18n.t(OFFI[codeOffice])}</option>;
        });

        const DEVICES: { [key: string]: string } = this.state.devicesType.reduce((map: { [key: string]: string }, deviceType: StockDeviceType) => {
            map[deviceType.code] = deviceType.name;
            return map;
        }, {});

        const devicesType: JSX.Element[] = Object.keys(DEVICES).map((codeDevice: string) => {
            return <option key={codeDevice} value={codeDevice}>{i18n.t(DEVICES[codeDevice])}</option>;
        });

        const columns = [
            { dataField: 'groupDeviceCode', text: i18n.t('device-code') },
            { dataField: 'groupDeviceName', text: i18n.t('grouper') },
            { dataField: 'deviceName', text: i18n.t('device-type') },
            { dataField: 'embossed', text: i18n.t('embossed') },
            { dataField: 'rejected', text: i18n.t('rejected') },
            { dataField: 'total', text: i18n.t('total') }
        ];

        const getFormattedDate = () => {
            const today = new Date();
            const day = String(today.getDate()).padStart(2, '0');
            const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
            const year = today.getFullYear();
            return `${day}${month}${year}`;
        };

        const formattedDate = getFormattedDate();
        const filename = `ReporteCPT_${formattedDate}.xlsx`;

        return (
            <div className="panel-body">
                <div className="card">
                    <div className="card-header" style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="card-title">
                            <div className="title">{i18n.t('report-device-tittle')}</div>
                        </div>
                        <div className="form-group" style={{ marginTop: '10px' }}>
                            {this.state.showSelectRegionAndLocation &&
                                <div className="row" style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-end' }}>
                                    <div className="form-group col-sm-3" style={{ margin: '0 10px' }}>
                                        <label>{i18n.t('region')}:</label>
                                        <select className="form-control" onChange={this.handleRegion} value={this.state.region}>
                                            <option value="NONE">{i18n.t('option-select')}</option>
                                            {regions}
                                        </select>
                                    </div>
                                    <div className="form-group col-sm-3" style={{ margin: '0 10px' }}>
                                        <label>{i18n.t('branch-office')}:</label>
                                        <select className="form-control" onChange={this.handleBranchOffice} value={this.state.office}>
                                            <option value="NONE">{i18n.t('option-select')}</option>
                                            {branchOffices}
                                        </select>
                                    </div>
                                    <div className="form-group col-sm-3" style={{ margin: '0 10px' }}>
                                        <label>{i18n.t('device')}:</label>
                                        <select className="form-control" onChange={this.handleDeviceType} value={this.state.deviceType}>
                                            <option value="NONE">{i18n.t('option-select')}</option>
                                            {devicesType}
                                        </select>
                                    </div>
                                </div>
                            }
                            <div className="row" style={{ marginTop: '10px', display: 'flex', alignItems: 'flex-end' }}>
                                <div className="form-group col-sm-3" style={{ margin: '0 10px' }}>
                                    <label>{i18n.t('from')}:</label>
                                    <input type="date" className="form-control" onChange={this.handleFromDateChange} value={this.state.dateRange.from} />
                                </div>
                                <div className="form-group col-sm-3" style={{ margin: '0 10px' }}>
                                    <label>{i18n.t('to')}:</label>
                                    <input type="date" className="form-control" onChange={this.handleToDateChange} value={this.state.dateRange.to} disabled={this.state.toDateDisabled} />
                                </div>
                                <div className="form-group col-sm-3 d-flex justify-content-end" style={{ margin: '0 10px' }}>
                                    <button className="btn btn-info" disabled={!this.areDatesSelected()} onClick={this.handleSearch}>{i18n.t('search')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">

                        <BootstrapTable
                            data={dataWithRowSpan}
                            options={this.state.tableOptions}
                            bordered
                            hover
                            pagination
                            trStyle={(row) => {
                                // Asegúrate de que `row.groupColorIndex` esté definido
                                if (row !== undefined) {
                                    const index = row.groupColorIndex !== undefined ? row.groupColorIndex : 0;
                                    return this.getCellStyle(index);
                                }
                                return this.getCellStyle(0);
                            }}
                        >
                            <TableHeaderColumn dataField='correlative' isKey hidden></TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='groupDeviceCode'
                                tdStyle={{ whiteSpace: 'normal' }}
                                dataSort
                                dataAlign="center"
                                width="130"
                                dataFormat={(cell, row) => <span>{row.rowSpanGroupCode > 0 ? cell : ''}</span>}
                                columnClassName="groupDeviceCode"
                                filter={{ type: 'TextFilter', placeholder: i18n.t('search') }}
                            >
                                {i18n.t('device-code')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='groupDeviceName'
                                tdStyle={{ whiteSpace: 'normal' }}
                                dataSort
                                dataAlign="center"
                                width="130"
                                dataFormat={(cell, row) => <span>{row.rowSpanGroupName > 0 ? cell : ''}</span>}
                                columnClassName="groupDeviceName"
                                filter={{ type: 'TextFilter', placeholder: i18n.t('search') }}
                            >
                                {i18n.t('device')}
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField='deviceName' tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', placeholder: i18n.t('search') }} dataSort dataAlign="center" width="130">
                                {i18n.t('chip-type')}
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField='embossed' tdStyle={{ whiteSpace: 'normal' }} dataSort dataAlign="center" width="130">
                                {i18n.t('embossed')}
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField='rejected' tdStyle={{ whiteSpace: 'normal' }} dataSort dataAlign="center" width="130">
                                {i18n.t('rejected')}
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField='total' tdStyle={{ whiteSpace: 'normal' }} dataSort dataAlign="center" width="130">
                                {i18n.t('total')}
                            </TableHeaderColumn>
                        </BootstrapTable>
                        <ExcelExportButton data={completeTable} columns={columns} filename={filename} clazz="btn btn-success pull-right" />

                    </div>
                </div>
            </div>
        )
    }
}

export const AdvanceSearch = withAppContext(AdvanceSearchMain);