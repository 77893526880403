import * as React from "react";
import i18n from "../../../i18n";
import { RouteComponentProps } from "react-router";
import { formatUserId } from "../../../utils/Formatter";
import { AppContextProps } from "../../../context/AppContext";
import { UserContextProps, withAppUserContext } from "../../../context/UserContext";
import { RouteUserParams, TYPE_USER, TYPE_CLIENT } from "../../../const/Routes";

export interface UserInfoProps extends RouteComponentProps<RouteUserParams>, AppContextProps, UserContextProps { }
export interface UserInfoState { }

export class UserInfoMain extends React.Component<UserInfoProps, UserInfoState>{
    constructor(props: UserInfoProps) {
        super(props);
    }

    render() {
        return (this.props.user &&
            <div className="">
                <div className=""></div>
                <table className="table table-custom">
                    <tbody className="data">
                        <tr>
                            <th className="col-lg-3">{i18n.t('id')}:</th>
                            <td colSpan={3}>{formatUserId(this.props.user.userId, this.props.user.group)}</td>
                        </tr>
                        <tr>
                            <th className="col-lg-3"> {i18n.t('name')}:</th>
                            <td colSpan={3}>{this.props.user.userName}</td>
                        </tr>
                        {this.props.match.params.userType === TYPE_USER && this.props.user.role && <tr>
                            <th className="col-lg-3">{i18n.t('role')}:</th>
                            <td colSpan={3}>{this.props.user.role.roleName}</td>
                        </tr>}
                        {this.props.match.params.userType === TYPE_USER && this.props.user.branchOffice && <tr>
                            <th className="col-lg-3">{i18n.t('branch-office')}:</th>
                            <td colSpan={3}>{this.props.user.branchOffice.code != 'CUID' ? this.props.user.branchOffice.name : ''}</td>
                        </tr>}
                        {this.props.match.params.userType === TYPE_USER && this.props.user.branchOffice.region && <tr>
                            <th className="col-lg-3">{i18n.t('region')}:</th>
                            <td colSpan={3}>{this.props.user.branchOffice.region.regionName}</td>
                        </tr>}
                        {this.props.match.params.userType === TYPE_CLIENT && <tr>
                            <th className="col-lg-3">{i18n.t('agent')}:</th>
                            <td colSpan={3}>{i18n.t(this.props.user.enterprise ? 'yes' : 'no')}</td>
                        </tr>}
                        {this.props.match.params.userType === TYPE_CLIENT && this.props.user.enterprise && <tr>
                            <th className="col-lg-3">{i18n.t('enterprise')}:</th>
                            <td colSpan={3}>{this.props.user.enterprise.name}</td>
                        </tr>}
                        {this.props.match.params.userType === TYPE_USER && this.props.user.externalUserName != '' && <tr>
                            <th className="col-lg-3"> {i18n.t('external-name')}:</th>
                            <td colSpan={3}>{this.props.user.externalUserName}</td>
                        </tr>}
                        {this.props.match.params.userType === TYPE_USER && this.props.user.emailUser != '' && <tr>
                            <th className="col-lg-3"> {i18n.t('email')}:</th>
                            <td colSpan={3}>{this.props.user.emailUser}</td>
                        </tr>}
                        <tr>
                            <th className="col-lg-3">{i18n.t('status-user')}:</th>
                            <td colSpan={3}>
                                {this.props.user.userActive && <span className="label label-success">{i18n.t('active')}</span>}
                                {!this.props.user.userActive && <span className="label label-danger">{i18n.t('inactive')}</span>}
                            </td>
                        </tr>
                        {this.props.user.lastAuth && <tr>
                            <th className="col-lg-3">{i18n.t('last-access-success')}:</th>
                            <td className="col-lg-4">{this.props.user.lastAuth.date.toString()}</td>
                            <th className="col-lg-1">{i18n.t('device')}:</th>
                            <td className="col-lg-4">{i18n.t(this.props.user.lastAuth.type)}</td>
                        </tr>}
                        {this.props.user.lastFailedAuth && <tr>
                            <th className="col-lg-3">{i18n.t('last-access-failed')}:</th>
                            <td className="col-lg-4">{this.props.user.lastFailedAuth.date.toString()}</td>
                            <th className="col-lg-1">{i18n.t('device')}:</th>
                            <td className="col-lg-4">{i18n.t(this.props.user.lastFailedAuth.type)}</td>
                        </tr>}
                    </tbody>
                </table>
            </div>
        )
    }
}

export const UserInfo = withAppUserContext(UserInfoMain);