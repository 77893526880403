import * as React from "react";
import i18n from "../../i18n";
import { ExportCSVButton, BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { formatBranchOfficeForList, formatNameForList, noticeStatusFormatter, formatNoticeNameForList } from "../../utils/Formatter";
import { withAppContext, AppContextProps } from "../../context/AppContext";
import { Notice, SearchNotices } from "../../interfaces/Notice";
import { Region } from "../../interfaces/ReportOfficeStatus";
import { BranchOffice } from "../../interfaces/Office";
import { RouteComponentProps } from "react-router-dom";
import { getRegionList, getBranchOfficeList } from "../../services/Stock";
import { apiHandleErrorCode, ResponseError } from "../../utils/ApiBaseConfig";
import { MESSAGE_WARNING } from "../../const/Message";
import { findNotices } from "../../services/Notice";

export interface NoticesTableProps extends RouteComponentProps, AppContextProps { }
export interface NoticesTableState {
    region: string;
    regionName: string;
    regions: Region[];
    office: string;
    officeName: string;
    offices: BranchOffice[];
    showSelectRegionAndLocation: boolean;
    data: Notice[];
    dateSearch: string;
    tableOptions: any;
}

export class NoticesTableMain extends React.Component<NoticesTableProps, NoticesTableState> {

    constructor(props: NoticesTableProps) {
        super(props);

        this.handleDateSearch = this.handleDateSearch.bind(this);
        this.handleRegion = this.handleRegion.bind(this);
        this.handleBranchOffice = this.handleBranchOffice.bind(this);
        this.getRegionsFromAPI = this.getRegionsFromAPI.bind(this);
        this.getOfficesFromAPI = this.getOfficesFromAPI.bind(this);
        this.findNotices = this.findNotices.bind(this);

        this.state = {
            data: [],
            region: "",
            regionName: "",
            regions: [],
            office: "",
            officeName: "",
            offices: [],
            showSelectRegionAndLocation: false,
            dateSearch: "",

            tableOptions: {
                noDataText: i18n.t('table-empty'),
                sizePerPageList: [{
                    text: '10', value: 10
                }, {
                    text: '20', value: 20
                }, {
                    text: '50', value: 50
                }],
                sizePerPage: 5,
                paginationSize: 3,
                pageStartIndex: 1,
            }
        }
    }

    componentDidMount() {
        if (this.props.authUser && this.props.authUser.branchOffice) {
            //setear la fecha por primera vez
            var curr = new Date();
            var date = curr.toISOString().substr(0, 10);

            if (this.props.authUser.branchOffice.code === "1") {
                this.setState({ showSelectRegionAndLocation: true, dateSearch: date }, () => { 
                    this.getRegionsFromAPI();
                    this.findNotices();
                });
            }
            else {
                this.setState({
                    office: this.props.authUser.branchOffice.code,
                    officeName: this.props.authUser.branchOffice.name,
                    regionName: this.props.authUser.branchOffice.region.regionName,
                    dateSearch: date
                }, this.findNotices);
            }
        }
    }

    handleDateSearch(e: any) {
        e.preventDefault();
        this.setState({ dateSearch: e.target.value });
    }

    handleRegion(e: any) {
        e.preventDefault();
        const regionSelected = e.target.value == "NONE" ? "" : e.target.value;
        this.setState({ region: regionSelected }, this.getOfficesFromAPI);
    }

    handleBranchOffice(e: any) {
        e.preventDefault();
        const officeSelected = e.target.value == "NONE" ? "" : e.target.value;
        this.setState({ office: officeSelected });
    }

    getRegionsFromAPI(): void {
        this.props.showLoading(true);
        getRegionList().then((regionArray: Region[]) => {
            this.setState({ regions: regionArray }, this.props.showLoading(false));
        })
            .catch((response: ResponseError) => {
                this.props.showLoading(false);
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
    }

    getOfficesFromAPI(): void {
        if (this.state.region !== "" && this.state.region !== "NONE") {
            this.props.showLoading(true);
            getBranchOfficeList(this.state.region).then((officeArray: BranchOffice[]) => {
                this.setState({ offices: officeArray }, this.props.showLoading(false));
            })
                .catch((response: ResponseError) => {
                    this.props.showLoading(false);
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                });
        }
    }

    findNotices() {
        this.props.showLoading(true);

        if (this.state.region !== "" && this.state.office == "") {
            this.props.setMessage({ message: i18n.t('branch-office-select'), type: MESSAGE_WARNING });
            this.props.showLoading(false);
            return;
        }

        const search: SearchNotices = {
            firstResult: 0,
            maxResults: 300,
            reportDate: this.state.dateSearch,
            reportOffice: this.state.office
        };

        findNotices(search)
            .then((response: Notice[]) => {
                this.setState({ data: response }, () => this.props.showLoading(false));
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    render() {

        const REG: { [key: string]: string } = this.state.regions.reduce((map: { [key: string]: string }, region: Region) => {
            map[region.codeRegion] = region.regionName;
            return map;
        }, {});

        const regions: JSX.Element[] = Object.keys(REG).map((codeRegion: string) => {
            return <option key={codeRegion} value={codeRegion}>{i18n.t(REG[codeRegion])}</option>;
        });

        const OFFI: { [key: string]: string } = this.state.offices.reduce((map: { [key: string]: string }, office: BranchOffice) => {
            map[office.code] = office.name;
            return map;
        }, {});

        const branchOffices: JSX.Element[] = Object.keys(OFFI).map((codeOffice: string) => {
            return <option key={codeOffice} value={codeOffice}>{i18n.t(OFFI[codeOffice])}</option>;
        });

        return (
            <div className="card">
                <div className="card-header">
                    <div className="card-title">
                        <div className="title">{i18n.t('notices')}</div>
                    </div>
                </div>
                <div className="card-body">
                    {this.state.showSelectRegionAndLocation &&
                        <div>
                            <div className="form-group col-sm-3">
                                <label>{i18n.t('region')}:</label>
                                <select className="form-control" onChange={this.handleRegion} value={this.state.region}>
                                    <option value="NONE">{i18n.t('option-select')}</option>
                                    {regions}
                                </select>
                            </div>
                            <div className="form-group col-sm-3">
                                <label>{i18n.t('branch-office')}:</label>
                                <select className="form-control" onChange={this.handleBranchOffice} value={this.state.office}>
                                    <option value="NONE">{i18n.t('option-select')}</option>
                                    {branchOffices}
                                </select>
                            </div>
                        </div>
                    }
                    {!this.state.showSelectRegionAndLocation &&
                        <div>
                            <div className="form-group col-sm-3">
                                <label>{i18n.t('region')}:</label>
                                <p className="form-control">{this.state.regionName}</p>
                            </div>
                            <div className="form-group col-sm-3">
                                <label>{i18n.t('branch-office')}:</label>
                                <p className="form-control">{this.state.officeName}</p>
                            </div>
                        </div>
                    }
                    <div className="form-group col-sm-3">
                        <label>{i18n.t('date')}:</label>
                        <input type="date" name="dateSearch" className="form-control" id="dateSearch" value={this.state.dateSearch} onChange={this.handleDateSearch} />
                    </div>
                    <div className="form-group col-sm-3">
                        <br></br>
                        <button type="button" className="btn btn-info" onClick={this.findNotices}>{i18n.t('search')}</button>
                    </div>
                    <div className="form-group col-sm-12">
                        <BootstrapTable
                            data={this.state.data}
                            options={this.state.tableOptions}
                            bordered={true}
                            pagination hover
                        >
                            <TableHeaderColumn dataField='correlative' isKey>{i18n.t('code')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='noticeType' dataFormat={formatNoticeNameForList} dataSort={true} dataAlign={'left'}>{i18n.t('type')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='branchOffice' dataFormat={formatBranchOfficeForList} dataSort={true} dataAlign={'left'}>{i18n.t('branch-office')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='noticeStatus' dataFormat={noticeStatusFormatter} dataSort={true} dataAlign={'center'}>{i18n.t('status')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='noticeDate' dataSort={true} dataAlign={'right'}>{i18n.t('date')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='updateDate' dataSort={true} dataAlign={'right'}>{i18n.t('date-update')}</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </div>
            </div>
        )
    }
}

export const NoticesList = withAppContext(NoticesTableMain);