import * as React from "react";
import i18n from "../../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../../context/AppContext";
import { BootstrapTable, TableHeaderColumn, DeleteButton } from "react-bootstrap-table";
import { Movement, Region, MovementType } from "../../../interfaces/Movement";
import { getRegionList, getBranchOfficeList, getStockForOpening } from "../../../services/Stock";
import { BranchOffice } from "../../../interfaces/Office";
import { ResponseError, apiHandleErrorCode } from "../../../utils/ApiBaseConfig";
import { MESSAGE_ERROR, MESSAGE_SUCCESS, MESSAGE_WARNING } from "../../../const/Message";
import { sendMovementList, sendMovementListCW } from "../../../services/Movement";
import { DeviceType } from "../../../interfaces/DeviceType";
import { isCardWizardActive } from "../../../services/System";
import { OpeningSummary } from "../../../interfaces/Stock";

export interface MovementOpeningProps extends RouteComponentProps, AppContextProps { }
export interface MovementOpeningState {
    region: string;
    regionName: string;
    regions: Region[];
    office: string;
    officeName: string;
    offices: BranchOffice[];
    movementTypes: MovementType[];
    deviceTypes: DeviceType[];
    deviceTypesOpening: OpeningSummary[];
    movement: string;
    movementDesc: string;
    stockId: string;
    stockDesc: string;
    step: number;
    cards: number;
    comment: string;
    data: Movement[];
    id: number;
    showSelectRegionAndLocation: boolean;
    maxVaultToDevice: Map<string, number>;
    backendActive: boolean;
    isClosing: boolean;
    isOpeningToday: boolean;
    openingNotZero: boolean;
}

export class MovementOpeningMain extends React.Component<MovementOpeningProps, MovementOpeningState> {
    reachTheLastPage: boolean = false;
    constructor(props: MovementOpeningProps) {
        super(props);
        this.handleRegion = this.handleRegion.bind(this);
        this.handleBranchOffice = this.handleBranchOffice.bind(this);
        this.getRegionsFromAPI = this.getRegionsFromAPI.bind(this);
        this.getOfficesFromAPI = this.getOfficesFromAPI.bind(this);
        this.sendMovementsToAPI = this.sendMovementsToAPI.bind(this);
        this.isBackendActive = this.isBackendActive.bind(this);

        this.state = {
            region: "",
            regionName: "",
            regions: [],
            office: "",
            officeName: "",
            offices: [],
            movementTypes: [],
            movement: "",
            movementDesc: "",
            deviceTypes: [],
            deviceTypesOpening: [],
            stockId: "",
            stockDesc: "",
            comment: "",
            step: 0,
            cards: 0,
            data: [],
            id: 0,
            showSelectRegionAndLocation: false,
            maxVaultToDevice: new Map(),
            backendActive: true,
            isClosing: true,
            isOpeningToday: false,
            openingNotZero: true,
        };
    }

    componentDidMount() {
        if (this.props.authUser && this.props.authUser.branchOffice) {
            if (this.props.authUser.branchOffice.code === "1") {
                this.setState({ showSelectRegionAndLocation: true }, this.getRegionsFromAPI);
            }
            else {
                this.setState({
                    office: this.props.authUser.branchOffice.code,
                    officeName: this.props.authUser.branchOffice.name,
                    regionName: this.props.authUser.branchOffice.region.regionName
                }, this.getDevicesForOpeningFromAPI);
            }
        }

        if (this.props.useCardWizard) {
            this.setState({ backendActive: false }, this.isBackendActive);
        }
    }


    handleRegion(e: any) {
        e.preventDefault();
        const regionSelected = e.target.value;
        this.setState({ region: regionSelected }, this.getOfficesFromAPI);
    }

    handleBranchOffice(e: any) {
        e.preventDefault();
        const officeSelected = e.target.value;
        if (officeSelected !== "" && officeSelected !== "NONE") {
            this.setState({ office: officeSelected, deviceTypesOpening: [] }, this.getDevicesForOpeningFromAPI);
        } else {
            this.setState({ office: officeSelected, deviceTypesOpening: [] });
        }
    }

    handleMovement(e: any) {
        e.preventDefault();
        const movementSelected = e.target.value;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        this.setState({ movement: movementSelected, movementDesc: text });
    }

    handleCards(e: any) {
        e.preventDefault();
        const cards = e.target.value;
        this.setState({ cards });
    }

    handleComment(e: any) {
        e.preventDefault();
        const comment = e.target.value;
        this.setState({ comment });
    }

    createCustomDeleteButton = () => {
        return (
            <DeleteButton
                btnText={i18n.t('remove')}
                btnContextual='btn-warning'
                btnGlyphicon='fa-times'
            />
        );
    }

    customAfterDeleteRow(rowKeys: number[]): void {
        var currentData: Movement[] = this.state.data;
        currentData = currentData.filter((movement: Movement) => {
            return !rowKeys.includes(movement.id);
        });
        this.setState({ data: currentData });
    }

    idKeyValidator = (value: string) => {
        if (value.trim() === "") {
            return i18n.t('field-cannot-empty');
        }
        return true;
    }

    getRegionsFromAPI(): void {
        this.props.showLoading(true);
        getRegionList().then((regionArray: Region[]) => {
            this.setState({ regions: regionArray }, () => {
                this.props.showLoading(false);
            });
        })
            .catch((response: ResponseError) => {
                this.props.showLoading(false);
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
    }

    getOfficesFromAPI(): void {
        if (this.state.region !== "" && this.state.region !== "NONE") {
            this.props.showLoading(true);
            getBranchOfficeList(this.state.region).then((officeArray: BranchOffice[]) => {
                this.setState({ offices: officeArray }, () => {
                    this.props.showLoading(false);
                });
            })
                .catch((response: ResponseError) => {
                    this.props.showLoading(false);
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                });
        }
    }

    getDevicesForOpeningFromAPI(): void {
        this.props.showLoading(true);
        getStockForOpening(this.state.office).then((stockOpening: OpeningSummary[]) => {
            const newMovements: Movement[] = stockOpening.map((stock, index) => ({
                id: index,
                region: this.state.region,
                office: this.state.office,
                movement: "VTOD",
                movementDesc: this.state.movementDesc,
                stockId: stock.deviceTypeCode,
                stockDesc: "Apertura SYS",
                cards: stock.defAperture,
                comment: "Apertura (SYS)"
            }));

            const hasValidCards = newMovements.some(
                movement => movement.cards > 0
            ); 

            if (!hasValidCards){
                this.props.setMessage({ message: i18n.t('movement-opening-zero-devices'), type: MESSAGE_WARNING });
            }

            this.setState({ data: newMovements, deviceTypesOpening: stockOpening, openingNotZero: hasValidCards  }, () => {
                this.props.showLoading(false);
            });
        })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    isBackendActive(): void {
        isCardWizardActive().then((result: boolean) => {
            if (!result) this.props.setMessage({ message: i18n.t('verifying-cw-err'), type: MESSAGE_ERROR });
            this.setState({ backendActive: result });
        })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
    }

    sendMovementsToAPI(): void {
        this.props.showLoading(true);
        sendMovementList(this.state.data).then(() => {
            this.props.setMessage({ message: i18n.t('movement-brach-opening-ready'), type: MESSAGE_SUCCESS });
            this.setState({ data: [], deviceTypesOpening: [] }, () => {
                this.props.showLoading(false);
            });
        })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    render() {

        const REG: { [key: string]: string } = this.state.regions.reduce((map: { [key: string]: string }, region: Region) => {
            map[region.codeRegion] = region.regionName;
            return map;
        }, {});

        const regions: JSX.Element[] = Object.keys(REG).map((codeRegion: string) => {
            return <option key={codeRegion} value={codeRegion}>{i18n.t(REG[codeRegion])}</option>;
        });

        const OFFI: { [key: string]: string } = this.state.offices.reduce((map: { [key: string]: string }, office: BranchOffice) => {
            map[office.code] = office.name;
            return map;
        }, {});

        const branchOffices: JSX.Element[] = Object.keys(OFFI).map((codeOffice: string) => {
            return <option key={codeOffice} value={codeOffice}>{i18n.t(OFFI[codeOffice])}</option>;
        });

        const options = {
            noDataText: i18n.t('table-empty'),
        };


        return (
            <div className="panel-body">
                <p></p>
                {!this.state.isClosing &&
                    <div className="alert alert-bold alert-danger">
                        <i className="fa fa-times-circle" style={{ fontSize: '24px' ,marginRight: '10px' }}></i>
                        <strong style={{ fontSize: '24px' }}>{i18n.t('movement-brach-not-closing').toUpperCase()}</strong>
                    </div>
                }
                {this.state.isOpeningToday &&
                    <div className="alert alert-bold alert-success">
                        <i className="fa fa-check-circle" style={{ fontSize: '24px' ,marginRight: '10px' }}></i>
                        <strong style={{ fontSize: '24px' }}>{i18n.t('movement-brach-opening-ready').toUpperCase()}</strong>
                    </div>
                }
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('movement-opening')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        {this.state.showSelectRegionAndLocation &&
                            <div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('region')}:</label>
                                    <select className="form-control" onChange={this.handleRegion} value={this.state.region}>
                                        <option value="NONE">{i18n.t('option-select')}</option>
                                        {regions}
                                    </select>
                                </div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('branch-office')}:</label>
                                    <select className="form-control" onChange={this.handleBranchOffice} value={this.state.office}>
                                        <option value="NONE">{i18n.t('option-select')}</option>
                                        {branchOffices}
                                    </select>
                                </div>
                            </div>
                        }
                        {!this.state.showSelectRegionAndLocation &&
                            <div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('region')}:</label>
                                    <p className="form-control">{this.state.regionName}</p>
                                </div>
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('branch-office')}:</label>
                                    <p className="form-control">{this.state.officeName}</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {this.state.deviceTypesOpening.length > 0 && <div className="card margin-card-top">
                    <div className="card-body">
                        <BootstrapTable data={this.state.deviceTypesOpening} bordered={true} options={options}>
                            <TableHeaderColumn dataField='deviceTypeCode' dataSort={true} isKey={true}>{i18n.t('movement-card-code')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='deviceTypeName' dataSort={true}>{i18n.t('movement-card-name')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='currentStock' dataAlign={"center"} dataSort={true}>{i18n.t('devices-quantity-current')} <span data-toggle="tooltip" data-placement="top" title={i18n.t('devices-quantity-current-explanation')}><i className='glyphicon glyphicon-info-sign'></i></span></TableHeaderColumn>
                            <TableHeaderColumn dataField='defAperture' dataAlign={"center"} dataSort={true} editable={{ type: 'textarea' }}>{i18n.t('movement-opening')} <span data-toggle="tooltip" data-placement="top" title={i18n.t('movement-opening-explanation')}><i className='glyphicon glyphicon-info-sign'></i></span></TableHeaderColumn>
                            <TableHeaderColumn dataField='remainingStock' dataAlign={"center"} dataSort={true}>{i18n.t('movement-remnant')} <span data-toggle="tooltip" data-placement="top" title={i18n.t('movement-remnant-explanation')}><i className='glyphicon glyphicon-info-sign'></i></span></TableHeaderColumn>
                        </BootstrapTable>
                    </div>

                    <div className="col-sm-12">
                        <button disabled={this.state.deviceTypesOpening.length === 0 || !this.state.backendActive || !this.state.openingNotZero } type="button" className="btn btn-info pull-right" onClick={this.sendMovementsToAPI}>{i18n.t('movement-opening-send')}</button>
                    </div>
                </div>}
            </div>
        )
    }
}

export const MovementOpening = withAppContext(MovementOpeningMain);