export const BASE_PERM = "base"; // Utilizar sólo para dev

export const BIOMETRIC_FINGERPRINTS_PERM: string = "userFingerprintsGet";
export const BIOMETRIC_FINGERPRINT_DELETE_PERM: string = "userFingerprintDelete";
export const BIOMETRIC_FACE_ENROLLMENT_PERM: string = "userFaceEnrollmentGet";
export const BIOMETRIC_FACE_ENROLLMENT_DELETE_PERM: string = "userFaceEnrollmentDelete";

export const CLIENT_SHOW_PERM: string = "users";
export const CLIENT_CREATE_PERM: string = "usersCreate";
export const CLIENT_UNLOCK_PERM: string = "userUnlock";
export const CLIENT_PASSWORD_PERM: string = "userPassword";
export const CLIENT_PASSWORD_UNLOCK_PERM: string = "userPasswordUnlock";
export const CLIENT_PASSWORD_SERVICE_PERM: string = "userPasswordService";
export const CLIENT_PASSWORD_SERVICE_DISABLE_PERM: string = "userPasswordServiceDisable";
export const CLIENT_PASSWORD_SERVICE_ENABLE_PERM: string = "userPasswordServiceEnable";
export const CLIENT_PASSWORD_EVENTS_PERM: string = "userPasswordEvent";
export const CLIENT_EVENTS_PERM: string = "userEvent";

export const DEVICES_MANAGE_PERM: string = "devicesManagement";

export const DEVICE_TYPE_SECTION_ACCESS: string = "deviceTypesView";
export const DEVICE_TYPE_LIST_PERM: string = "deviceTypes";
export const DEVICE_TYPE_CONF_PERM: string = "configurationsByDeviceType";
export const DEVICE_TYPE_CREATE_PERM: string = "deviceTypeCreate";
export const DEVICE_TYPE_UPDATE_PERM: string = "deviceTypeUpdate";
export const DEVICE_TYPE_DELETE_PERM: string = "deviceTypeDelete";
export const DEVICE_TYPE_DO_ASIGN_PERM: string = "deviceTypeAssignOffice";
export const DEVICE_TYPE_ASIGN_PERM: string = "deviceTypeAssignedOffice";

export const EVENTS_SECTION_ACCESS: string = "systemEventView";
export const EVENTS_GENERAL_PERM: string = "userAllEvent";
export const EVENTS_SYSTEM_PERM: string = "systemAllEvent";

export const GRID_LIST_PERM: string = "userGrid";
export const GRID_ASSIGN_PERM: string = "userGridAssign";
export const GRID_UNASSIGN_PERM: string = "userGridUnassign";
export const GRID_CANCEL_PERM: string = "userGridCancel";
export const GRID_DELETE_PERM: string = "userGridDelete";
export const GRID_UPDATE_PERM: string = "userGridUpdate";
export const GRID_EVENTS_PERM: string = "userGridEvent";
export const GRID_EVENTS_EXTERNAL_PERM: string = "userGridEventExternal";

export const LICENSE_LIST_PERM: string = "license";

export const SOFTTOKEN_LIST_PERM: string = "userSoftToken";
export const SOFTTOKEN_ACTIVATION_PERM: string = "userSoftTokenActivation";
export const SOFTTOKEN_ACTIVATION_CONFIRM_PERM: string = "userSoftTokenActivationConfirm";
export const SOFTTOKEN_LOCK_PERM: string = "userSoftTokenLock";
export const SOFTTOKEN_UNLOCK_PERM: string = "userSoftTokenUnlock";
export const SOFTTOKEN_DELETE_PERM: string = "userSoftTokenDelete";
export const SOFTTOKEN_EVENTS_PERM: string = "userSoftTokenEvent";

export const ROLE_SECTION_ACCESS: string = "roleView";
export const ROLE_LIST_PERM: string = "roles";
export const ROLE_SHOW_PERM: string = "roleGet";
export const ROLE_MODIFY_PERM: string = "roleUpdate";
export const ROLE_DELETE_PERM: string = "roleDelete";
export const ROLE_CREATE_PERM: string = "roleCreate";

export const OFFICE_SECTION_ACCESS: string = "branchOfficesView";
export const OFFICE_LIST_PERM: string = "branchOffices";
export const OFFICE_CREATE_PERM: string = "branchOfficeCreate";
export const OFFICE_SHOW_PERM: string = "branchOfficeGet";
export const OFFICE_MODIFY_PERM: string = "branchOfficeUpdate";
export const OFFICE_CHANGE_PERM: string = "branchOfficeChange";
export const OFFICE_SYNC_PERM: string = "branchOfficeSync";
export const OFFICE_NOTICE_CONF_PERM: string = "branchOfficeNoticeConf";

export const USER_SECTION_ACCESS: string = "userView";
export const USER_SHOW_PERM: string = "userAdmin";
export const USER_LIST_PERM: string = "userAdminList";
export const USER_SHOW_CREATE_PERM: string = "userAdminCreate";
export const USER_CREATE_PERM: string = "userAdminGetCreate";
export const USER_LOCK_PERM: string = "userAdminLock";
export const USER_UPDATE_PERM: string = "userAdminUpdate";
export const USER_DOCUMENT_PERM: string = "userDocument";
export const USER_DOCUMENT_PRINT_PERM: string = "userDocumentPrint";
export const USER_DOCUMENT_SIGN_PERM: string = "userDocumentSign";
export const USER_DOCUMENT_EVENTS_PERM: string = "userDocumentEvent";
export const USER_EVENTS_PERM: string = "userEvent";

export const DOCUMENT_LIST_PERM: string = "documents";
export const DOCUMENT_SHOW_PERM: string = "documentGet";
export const DOCUMENT_UPLOAD_PERM: string = "documentUpload";

export const STOCK_DEVICE_SECTION_ACCESS: string = "deviceView";
export const STOCK_DEVICE_STOCK_SECTION_ACCESS: string = "deviceStockView";
export const STOCK_DEVICE_STOCK_OPENING_ACCESS: string = "deviceOpeningStock";
export const STOCK_DEVICE_SHIPMENT_SECTION_ACCESS: string = "deviceShipmentView";
export const STOCK_DEVICE_ASSIGN_SECTION_ACCESS: string = "deviceAsignView";
export const STOCK_DEVICE_EVENT_SECTION_ACCESS: string = "deviceEventView";
export const STOCK_DEVICE_MOVEMENT_SECTION_ACCESS: string = "deviceMovementView";
export const STOCK_DEVICE_BALANCE_SECTION_ACCESS: string = "deviceBalanceView";
export const STOCK_DEVICE_STATE_SECTION_ACCESS: string = "deviceStateView";

export const STOCK_DEVICES_SUMMARY_PERM: string = "deviceStock";
export const STOCK_DEVICES_SUMMARY_MONTH_PERM: string = "deviceMonthStock";

export const STOCK_DEVICES_LOAD_PERM: string = "stockLoad";
export const STOCK_DEVICES_LOADED_PERM: string = "stockLoadList";
export const STOCK_DEVICES_ASSIGN_PERM: string = "stockAssign";
export const STOCK_DEVICES_SEARCH_PERM: string = "stockDeviceSearch";

export const REPORT_DEVICES_PERM: string = "reportDevices";

export const SHIPMENTS_SUMMARY_PERM: string = "shipmentsSummary";
export const SHIPMENT_SHOW_PERM: string = "shipmentShow";
export const SHIPMENT_EVENTS_PERM: string = "shipmentEvents";
export const SHIPMENT_ASSIGN_PERM: string = "shipmentAssign";
export const SHIPMENT_REQUEST_PERM: string = "shipmentRequest";
export const SHIPMENT_SEND_PERM: string = "shipmentSend";
export const SHIPMENT_RECEIVE_PERM: string = "shipmentReceive";
export const SHIPMENT_CANCEL_PERM: string = "shipmentCancel";
export const SHIPMENT_SUMMARY_PRINT_PERM: string = "shipmentSummaryPrint";
export const SHIPMENT_GENERATE_PERM: string = "shipmentGenerate";
export const SHIPMENTS_EVENTS_PERM: string = "shipmentsEvents";

export const SYSTEM_PARAMETERS_SECTION_ACCESS: string = "systemParametersView";
export const SYSTEM_PARAMETERS_LIST_PERM: string = "systemParameters";
export const SYSTEM_PARAMETERS_SYNC_PERM: string = "systemParametersSync";
export const EQUIVALENCE_PERM: string = "equivalence";
export const EQUIVALENCE_ADMIN_PERM: string = "equivalenceAdmin";


export const DEVICE_MANAGE_MENU_PERM: string = "device_manage_menu";

export const REPORTS_AND_CONTROL_PERM: string = "reportAndControl";
export const REPORTS_AND_CONTROL_MENU_PERM: string = "reports_control_menu";
export const STATE_OFFICE_REPORT_PERM: string = "stateOffice";
export const STATE_OFFICE_LAST_REPORT_PERM: string = "lastStateOffice";

export const NOTICE_SECTION_ACCESS: string = "noticeView";
export const NOTICE_LIST_PERM: string = "noticeList";
export const NOTICE_CONFIGURATION_PERM: string = "noticeConf";
export const NOTICE_CONFIGURATION_EDIT_PERM: string = "noticeConfEdit";
export const NOTICE_ASSING_PERM: string = "noticeAssign";

export const BALANCE_CREATE_PERM: string = "balanceCreate";
export const BALANCE_CLOSE_PERM: string = "balanceClose";
export const BALANCE_FIND_PERM: string = "balanceFind";
export const BALANCE_PENDING_APPROVAL: string = "balancePending";
export const MOVEMENT_CREATE_PERM: string = "movementsCreate";
export const MOVEMENT_FIND_PERM: string = "movementsFind";

export const STOCK_MANAGEMENT_SECTION_ACCESS: string = "stockManagementView";
export const STOCK_MANAGEMENT_FIND_PERM: string = "stockManagementFind";
export const STOCK_MANAGEMENT_LIST_PERM: string = "stockManagementList";
export const STOCK_MANAGEMENT_LOAD_PERM: string = "stockManagementLoad";
export const STOCK_MANAGEMENT_ADD_PERM: string = "stockManagementAdd";
export const STOCK_MANAGEMENT_SEND_PERM: string = "stockManagementSend";
export const STOCK_MANAGEMENT_REDUCE_PERM: string = "stockManagementReduce";
export const STOCK_MANAGEMENT_PENDING_PERM: string = "stockManagementPendingList";
export const STOCK_MANAGEMENT_RESOLVE_PERM: string = "stockManagementPendingResolve";