import * as React from "react";
import i18n from "../../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../../context/AppContext";
import { BootstrapTable, TableHeaderColumn, ExportCSVButton } from "react-bootstrap-table";
import { Region, MovementType, SearchMovement, Movements } from "../../../interfaces/Movement";
import { getRegionList, getBranchOfficeList} from "../../../services/Stock";
import { BranchOffice } from "../../../interfaces/Office";
import { ResponseError, apiHandleErrorCode } from "../../../utils/ApiBaseConfig";
import { getTimestamp } from "../../../utils/Formatter";
import { getMovementTypeList, findMovements, MOVEMENTS_AMOUNT_PER_REQUEST } from "../../../services/Movement";
import { getDeviceTypeList } from "../../../services/DeviceType";
import { DeviceType } from "../../../interfaces/DeviceType";

export interface MovementListProps extends RouteComponentProps, AppContextProps{ }
export interface MovementListState {
    region: string;
    regionName: string;
    regions: Region[];
    office: string;
    officeName: string;
    offices: BranchOffice[];
    movementTypes: MovementType[];
    deviceTypes: DeviceType[];
    movement: string;
    stockId: string;
    stockDesc: string;
    dateFrom: string;
    dateTo: string;
    movements: Movements[];
    blockRegionAndLocation: boolean;
    tableOptions: any;
 }

export class MovementListMain extends React.Component<MovementListProps, MovementListState>{
    reachTheLastPage: boolean = false;
    constructor(props: MovementListProps) {
        super(props);
        this.handleRegion = this.handleRegion.bind(this);
        this.handleBranchOffice = this.handleBranchOffice.bind(this);
        this.handleMovement = this.handleMovement.bind(this);
        this.handleStock = this.handleStock.bind(this);
        this.handleFrom = this.handleFrom.bind(this);
        this.handleTo = this.handleTo.bind(this);
        this.getRegionsFromAPI = this.getRegionsFromAPI.bind(this);
        this.getOfficesFromAPI = this.getOfficesFromAPI.bind(this);
        this.getMovementTypesFromAPI = this.getMovementTypesFromAPI.bind(this);
        this.getDeviceTypesFromAPI = this.getDeviceTypesFromAPI.bind(this);
        this.findMovementsFromAPI = this.findMovementsFromAPI.bind(this);
        this.createSearchMovement = this.createSearchMovement.bind(this);
        this.state = {
            region: "",
            regionName: "",
            regions: [],
            office: "",
            officeName: "",
            offices: [],
            movementTypes: [],
            movement: "",
            deviceTypes: [],
            stockId: "",
            stockDesc: "",
            movements: [],
            dateFrom: "",
            dateTo: "",
            blockRegionAndLocation: true,
            tableOptions: {
                noDataText: i18n.t('table-empty'),
                sizePerPageList: [{
                    text: '10', value: 10
                }, {
                    text: '20', value: 20
                }, {
                    text: '50', value: 50
                }],
                sizePerPage: 10,
                paginationSize: 3,
                pageStartIndex: 1,
                exportCSVBtn: (onClick: any) => { return <ExportCSVButton btnText={i18n.t('csv-save')} btnContextual='btn-info' onClick={onClick} /> }
            }
        };
    }

    componentDidMount() {
        if(this.props.authUser && this.props.authUser.branchOffice) {
            //setear la fecha de  busqueda por defecto
            var curr = new Date();
            var date = curr.toISOString().substr(0, 10);
            curr.setDate(curr.getDate()+1);
            var tomorrow = curr.toISOString().substr(0, 10);

            if(this.props.authUser.branchOffice.code === "1") {
                this.setState({ blockRegionAndLocation: false, dateFrom: date, dateTo: tomorrow }, () => { 
                    this.getRegionsFromAPI() 
                    this.getMovementTypesFromAPI();
                    this.getDeviceTypesFromAPI();
                    this.findMovementsFromAPI();
                });
            }
            else {
                this.setState({
                    office: this.props.authUser.branchOffice.code, 
                    officeName: this.props.authUser.branchOffice.name, 
                    regionName: this.props.authUser.branchOffice.region.regionName,
                    dateFrom: date, 
                    dateTo: tomorrow
                }, () => { 
                    this.getMovementTypesFromAPI();
                    this.getDeviceTypesFromAPI();
                    this.findMovementsFromAPI();
                });
            }
        }
        
    }


    handleRegion(e: any) {
        e.preventDefault();
        const regionSelected = e.target.value;
        this.setState({ region: regionSelected }, this.getOfficesFromAPI);
    }

    handleBranchOffice(e: any) {
        e.preventDefault();
        const officeSelected = e.target.value;
        this.setState({ office: officeSelected });
    }

    handleMovement(e: any) {
        e.preventDefault();
        const movementSelected = e.target.value;
        this.setState({ movement: movementSelected });
    }

    handleStock(e: any) {
        e.preventDefault();
        const index = e.target.selectedIndex;
        this.setState({ stockId: e.target.value, stockDesc: e.target[index].text });
    }

    handleFrom(e: any) {
        e.preventDefault();
        const startDate = e.target.value;
        this.setState({ dateFrom: startDate });
    }

    handleTo(e: any) {
        e.preventDefault();
        const endDate = e.target.value;
        this.setState({ dateTo: endDate });
    }

    getRegionsFromAPI(): void {
        this.props.showLoading(true);
        getRegionList().then((regionArray: Region[]) =>{
            this.setState({ regions: regionArray }, () => {
                this.props.showLoading(false);
            });
        })
        .catch((response: ResponseError) => {
            this.props.showLoading(false);
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
        });
    }

    getOfficesFromAPI(): void {
        if(this.state.region !== "" && this.state.region !== "NONE") {
            this.props.showLoading(true);
            getBranchOfficeList(this.state.region).then((officeArray: BranchOffice[]) =>{
                this.setState({ offices: officeArray }, () => {
                    this.props.showLoading(false);
                });
            })
            .catch((response: ResponseError) => {
                this.props.showLoading(false);
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
        }
    }

    getMovementTypesFromAPI(): void {
        this.props.showLoading(true);
        getMovementTypeList().then((movementTypeArray: MovementType[]) => {
            this.setState({movementTypes: movementTypeArray}, () => {
                this.props.showLoading(false);
            });
        })
        .catch((response: ResponseError) => {
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
            this.props.showLoading(false);
        });
    }

    getDeviceTypesFromAPI(): void {
        this.props.showLoading(true);
        getDeviceTypeList().then((deviceTypeArray: DeviceType[]) =>{
            this.setState({ deviceTypes: deviceTypeArray }, () => {
                this.props.showLoading(false);
            });
        })
        .catch((response: ResponseError) => {
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
            this.props.showLoading(false);
        });
    }

    createSearchMovement(firstResult: number): SearchMovement {
        const searchMovement: SearchMovement = {};

        if (this.state.office && this.state.office !== "NONE")
            searchMovement.branchOfficeCode = this.state.office;
        if(this.state.movement && this.state.movement !== "NONE")
            searchMovement.stockMovementTypeCode = this.state.movement;
        if(this.state.dateFrom && this.state.dateFrom !== "")
            searchMovement.movementDateFrom = this.state.dateFrom;
        if(this.state.dateTo && this.state.dateTo !== "")
            searchMovement.movementDateTo = this.state.dateTo;
        if(this.state.stockId && this.state.stockId !== "NONE")
            searchMovement.deviceTypeCode = this.state.stockId;
        searchMovement.firstResult = firstResult;
        return searchMovement;
    }

    findMovementsFromAPI(): void {
        this.props.showLoading(true);
        findMovements(this.createSearchMovement(0))
        .then((movements: Movements[]) => {
            this.setState({ movements }, () => {
                this.props.showLoading(false);
            });
        })
        .catch((response: ResponseError) => {
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
            this.props.showLoading(false);
        });
    }

    onPageChange(page: number, sizePerPage: number) {
        const pages = Math.floor(this.state.movements.length / sizePerPage) + (this.state.movements.length % MOVEMENTS_AMOUNT_PER_REQUEST === 0 ? 0 : 1);
        if (page === pages && !this.reachTheLastPage) {
            this.props.showLoading(this.reachTheLastPage = true);
            findMovements(this.createSearchMovement(this.state.movements.length))
                .then((registries: Movements[]) => {
                    if (registries.length > 0) {
                        this.setState(state => {
                            return { movements: [...state.movements, ...registries] }
                        }, () => { 
                            if (registries.length === MOVEMENTS_AMOUNT_PER_REQUEST) this.reachTheLastPage = false;
                            this.props.showLoading(false);
                        });
                    } else {
                        this.props.showLoading(false);
                    }
                })
                .catch((response: ResponseError) => {
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                    this.props.showLoading(false);
                });
        }
    }

    getFileName() {
        return 'movimientos_' + getTimestamp() + '.csv';
    }


    render() {

        const REG : { [key: string]: string } = this.state.regions.reduce((map: { [key: string]: string }, region: Region) => {
            map[region.codeRegion] = region.regionName;
            return map;
        }, {});

        const regions: JSX.Element[] = Object.keys(REG).map((codeRegion: string) => {
            return <option key={codeRegion} value={codeRegion}>{i18n.t(REG[codeRegion])}</option>;
        });
        
        const OFFI : { [key: string]: string } = this.state.offices.reduce((map: { [key: string]: string }, office: BranchOffice) => {
            map[office.code] = office.name;
            return map;
        }, {});

        const branchOffices: JSX.Element[] = Object.keys(OFFI).map((codeOffice: string) => {
            return <option key={codeOffice} value={codeOffice}>{i18n.t(OFFI[codeOffice])}</option>;
        });

        const MOVE : { [key: string]: string } = this.state.movementTypes.reduce((map: { [key: string]: string }, movementType: MovementType) => {
            map[movementType.stockMovementCode] = movementType.stockMovementDescription;
            return map;
        }, {})

        const movements: JSX.Element[] = Object.keys(MOVE).map((codeMovement: string) => {
            return <option key={codeMovement} value={codeMovement}>{i18n.t(MOVE[codeMovement])}</option>;
        });

        const STOCK : { [key: string]: string } = this.state.deviceTypes.reduce((map: { [key: string]: string }, deviceType: DeviceType) => {
            map[deviceType.codeDeviceType] = deviceType.nameDeviceType;
            return map;
        }, {});

        const stocks: JSX.Element[] = Object.keys(STOCK).map((codeStock: string) => {
            return <option key={codeStock} value={codeStock}>{i18n.t(STOCK[codeStock])}</option>;
        });

        
        return (
                <div className="panel-body">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <div className="title">{i18n.t('filters')}</div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="form-group col-sm-12">
                            {!this.state.blockRegionAndLocation &&
                                <div>
                                    <div className="form-group col-sm-4">
                                        <label>{i18n.t('region')}:</label>
                                        <select className="form-control" onChange={this.handleRegion} value={this.state.region}>
                                            <option value="NONE">{i18n.t('option-select')}</option>
                                            {regions}
                                        </select>
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label>{i18n.t('branch-office')}:</label>
                                        <select className="form-control" onChange={this.handleBranchOffice} value={this.state.office}>
                                            <option value="NONE">{i18n.t('option-select')}</option>
                                            {branchOffices}
                                        </select>
                                    </div>
                                </div>
                                }
                                {this.state.blockRegionAndLocation &&
                                <div>
                                    <div className="form-group col-sm-4">
                                        <label>{i18n.t('region')}:</label>
                                        <p className="form-control">{this.state.regionName}</p>
                                    </div>

                                    <div className="form-group col-sm-4">
                                        <label>{i18n.t('branch-office')}:</label>
                                        <p className="form-control">{this.state.officeName}</p>
                                    </div>
                                </div>
                                }
                                <div className="form-group col-sm-4">
                                    <label>{i18n.t('movement')}:</label>
                                    <select className="form-control" onChange={this.handleMovement} value={this.state.movement}>
                                        <option value="NONE">{i18n.t('option-select')}</option>
                                        {movements}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group col-sm-12">
                                <div className="form-group col-sm-3">
                                    <label>{i18n.t('device-type')}:</label>
                                    <select className="form-control" onChange={this.handleStock} value={this.state.stockId}>
                                        <option value="NONE">{i18n.t('option-select')}</option>
                                        {stocks}
                                    </select>
                                </div>
                                <div className="form-group col-sm-3">
                                    <label>{i18n.t('from')}:</label>
                                    <input type="date" name="from" className="form-control" id="from" value={this.state.dateFrom} onChange={this.handleFrom} />
                                </div>
                                <div className="form-group col-sm-3">
                                    <label>{i18n.t('to')}:</label>
                                    <input type="date" name="to" className="form-control" id="to" value={this.state.dateTo} onChange={this.handleTo} />
                                </div>
                                <div className="form-group col-sm-3">
                                    <br></br>
                                    <button type="button" className="btn btn-info" onClick={this.findMovementsFromAPI}>{i18n.t('search')}</button>
                                </div>
                            </div>
                            <div className="form-group col-sm-12">
                                <BootstrapTable 
                                    data={this.state.movements}
                                    options={{ 
                                        ...this.state.tableOptions, 
                                        onPageChange: this.onPageChange.bind(this) 
                                    }}
                                    bordered hover pagination={this.state.movements.length > 0} exportCSV={this.state.movements.length > 0}
                                    csvFileName={this.getFileName()}
                                    >
                                    <TableHeaderColumn dataField='regionName' dataSort={true} dataAlign="center" width="130">{i18n.t('region')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='officeName' dataSort={true} dataAlign="center" width="130">{i18n.t('branch-office')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='movementTypeDescription' dataSort={true} dataAlign="center" width="130">{i18n.t('movement')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='deviceTypeName' dataSort={true} dataAlign="center" width="130" isKey={true}>{i18n.t('device-type')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='movementQuantity' dataSort={true} dataAlign="center" width="130">{i18n.t('devices')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='movementDescription' tdStyle={ { whiteSpace: 'normal' } } dataSort={true} dataAlign="center" width="130">{i18n.t('comment')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='movementDate' dataSort={true} dataAlign="center" width="130">{i18n.t('date')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='userName' dataSort={true} dataAlign="center" width="130">{i18n.t('user')}</TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export const MovementList = withAppContext(MovementListMain);
