import * as React from "react";
import { RouteComponentProps } from "react-router";
import { DeviceTypeParams } from "../../const/Routes";
import { AppContextProps, withAppContext } from "../../context/AppContext";
import i18n from "../../i18n";
import { AssignDevice, AssignOffice, DeviceType, ResultDeviceAssign } from "../../interfaces/DeviceType";
import { BranchOffice } from "../../interfaces/Office";
import { Region } from "../../interfaces/Movement";
import { getBranchOffice } from "../../services/Office";
import { apiHandleErrorCode, ResponseError } from "../../utils/ApiBaseConfig";
import { getBranchOfficeList, getRegionList } from "../../services/Stock";
import { AssignGroup } from "./AssignGroup";
import { assignDevicesToOffice, checkSincro, getAssignedDeviceByOffice } from "../../services/DeviceType";


export interface DeviceAssignProps extends RouteComponentProps<DeviceTypeParams>, AppContextProps { }
export interface DeviceAssignState {
  remainingDevices: AssignDevice[];
  assignedDevices: AssignDevice[];
  wasModificated: boolean;
  regionName: string;
  branchOffice: BranchOffice;
  region: string;
  regions: Region[];
  office: string;
  showSelectRegionAndLocation: boolean;
  officeName: string;
  offices: BranchOffice[];
}

export class DeviceAssignMain extends React.Component<DeviceAssignProps, DeviceAssignState> {
  constructor(props: DeviceAssignProps) {
    super(props);
    this.update = this.update.bind(this);

    this.removeAssigns = this.removeAssigns.bind(this);
    this.addAssigns = this.addAssigns.bind(this);
    this.handleRegion = this.handleRegion.bind(this);
    this.handleBranchOffice = this.handleBranchOffice.bind(this);

    this.checkSincronization = this.checkSincronization.bind(this);

    this.state = {
      remainingDevices: [],
      wasModificated: false,
      branchOffice: {
        code: '',
        name: '',
        enabled: true,
        address: '',
        managerUserName: '',
        notificationMail: '',
        updateDate: new Date(),
        stockConfig: [],
        region: { codeRegion: '', regionName: '', regionActive: true },
        defaultNotifyConf: true
      },
      regionName: "",
      regions: [],
      office: "",
      showSelectRegionAndLocation: false,
      officeName: "",
      offices: [],
      region: "",
      assignedDevices: []
    };
  }

  componentDidMount() {
    if (this.props.authUser && this.props.authUser.branchOffice) {
      if (this.props.authUser.branchOffice.code === "1") {
        this.setState({ showSelectRegionAndLocation: true }, this.getRegionsFromAPI);
      }
      else {
        this.setState({
          office: this.props.authUser.branchOffice.code,
          officeName: this.props.authUser.branchOffice.name,
          regionName: this.props.authUser.branchOffice.region.regionName
        }, this.fetchDeviceByOffice);
      }
    }
  }

  fetchDeviceByOffice() {
    this.props.showLoading(true)
    getAssignedDeviceByOffice(this.state.office).then((resultDeviceAssign: ResultDeviceAssign) => {
      this.setState({
        remainingDevices: resultDeviceAssign.remmainingDevices,
        assignedDevices: resultDeviceAssign.assignedDevices
      }, () => {
        this.checkSincronization();
        this.props.showLoading(false);
      })

    })
      .catch((response: ResponseError) => {
        apiHandleErrorCode(response.status, this.props.history);
        this.props.setMessage(response.message);
        this.props.showLoading(false);
      });
  }

  checkSincronization(){
    this.props.showLoading(true);
  
    const { office, assignedDevices, remainingDevices } = this.state;
  
    const resultDeviceAssign: ResultDeviceAssign = {
      assignedDevices: assignedDevices,
      remmainingDevices: remainingDevices
    };

    checkSincro(office, resultDeviceAssign)
    .then((response) => {
      if(!response) {
        this.props.setMessage({ message: i18n.t('devices-not-sincro'), type: 'alert-danger' });
        this.setState({ wasModificated: true });
      }
    })
    .catch((error: ResponseError) => {
      apiHandleErrorCode(error.status, this.props.history);
      this.props.setMessage({ message: error.message.message, type: error.message.type });
    })
    .finally(() => {
      this.props.showLoading(false);
    });
  }

  fetchOffice() {
    this.props.showLoading(true);
    getBranchOffice(this.state.office)
      .then((branchOffice: BranchOffice) => {
        if (branchOffice.defaultNotifyConf) {
          this.setState({ branchOffice }, this.props.showLoading(false));
        }
        else {
          this.props.showLoading(false);
          this.setState({ branchOffice });
        }
      })
      .catch((response: ResponseError) => {
        apiHandleErrorCode(response.status, this.props.history);
        this.props.setMessage(response.message);
        this.props.showLoading(false);
      });
  }

  getRegionsFromAPI(): void {
    this.props.showLoading(true);
    getRegionList().then((regionArray: Region[]) => {
      this.setState({ regions: regionArray }, this.props.showLoading(false));
    })
      .catch((response: ResponseError) => {
        this.props.showLoading(false);
        apiHandleErrorCode(response.status, this.props.history);
        this.props.setMessage(response.message);
      });
  }

  fetchDeviceTypeAssign(deviceTypeId: string) {
    this.props.showLoading(true);

  }

  update() {
    this.props.showLoading(true);
  
    const { office, assignedDevices, remainingDevices } = this.state;
  
    const resultDeviceAssign: ResultDeviceAssign = {
      assignedDevices: assignedDevices,
      remmainingDevices: remainingDevices
    };
  
    // Llama al servicio para enviar los datos al backend
    assignDevicesToOffice(office, resultDeviceAssign)
      .then((response) => {
        this.props.setMessage({ message: i18n.t('devices-updated-successfully'), type: 'alert-success' });
        this.setState({ wasModificated: false });
      })
      .catch((error: ResponseError) => {
        apiHandleErrorCode(error.status, this.props.history);
        this.props.setMessage({ message: error.message.message, type: error.message.type });
      })
      .finally(() => {
        this.props.showLoading(false);
      });
  }


  handleRegion(e: any) {
    e.preventDefault();
    this.setState({
      branchOffice: {
        ...this.state.branchOffice,
        region: {
          codeRegion: e.target.value,
          regionName: e.target.options[e.target.selectedIndex].text,
          regionActive: true
        }
      }
      , region: e.target.value
    }, this.getOfficesFromAPI);
  }

  handleBranchOffice(e: any) {
    e.preventDefault();
    const officeSelected = e.target.value;
    if (officeSelected !== "" && officeSelected !== "NONE") {
      this.setState({ office: officeSelected }, this.fetchDeviceByOffice);
    } else {
      this.setState({ office: officeSelected });
    }
  }

  getOfficesFromAPI(): void {
    if (this.state.region !== "" && this.state.region !== "NONE") {
      this.props.showLoading(true);
      getBranchOfficeList(this.state.region).then((officeArray: BranchOffice[]) => {
        this.setState({ offices: officeArray }, () => {
          this.props.showLoading(false);
        });
      })
        .catch((response: ResponseError) => {
          this.props.showLoading(false);
          apiHandleErrorCode(response.status, this.props.history);
          this.props.setMessage(response.message);
        });
    }
  }


  addAssigns(selected: AssignDevice[]) {
    // Combinar los dispositivos seleccionados con los ya asignados
    const updatedAssignedDevices = [...this.state.assignedDevices, ...selected];

    // Filtrar los dispositivos seleccionados de la lista de dispositivos restantes
    const updatedRemainingDevices = this.state.remainingDevices.filter(device =>
      !selected.some(selectedDevice => selectedDevice.code === device.code)
    );

    // Actualizar el estado con las nuevas listas
    this.setState({
      assignedDevices: updatedAssignedDevices,
      remainingDevices: updatedRemainingDevices,
      wasModificated: true
    });
  }

  removeAssigns(selected: AssignDevice[]) {
    // Filtrar los dispositivos seleccionados de la lista de dispositivos asignados
    const updatedAssignedDevices = this.state.assignedDevices.filter(device =>
      !selected.some(selectedDevice => selectedDevice.code === device.code)
    );

    // Combinar los dispositivos seleccionados con los dispositivos restantes
    const updatedRemainingDevices = [...this.state.remainingDevices, ...selected];

    // Actualizar el estado con las nuevas listas
    this.setState({
      assignedDevices: updatedAssignedDevices,
      remainingDevices: updatedRemainingDevices,
      wasModificated: true
    });
  }

  render() {

    const REG: { [key: string]: string } = this.state.regions.reduce((map: { [key: string]: string }, region: Region) => {
      map[region.codeRegion] = region.regionName;
      return map;
    }, {});

    const regions: JSX.Element[] = Object.keys(REG).map((codeRegion: string) => {
      return <option key={codeRegion} value={codeRegion}>{i18n.t(REG[codeRegion])}</option>;
    });

    const OFFI: { [key: string]: string } = this.state.offices.reduce((map: { [key: string]: string }, office: BranchOffice) => {
      map[office.code] = office.name;
      return map;
    }, {});

    const branchOffices: JSX.Element[] = Object.keys(OFFI).map((codeOffice: string) => {
      return <option key={codeOffice} value={codeOffice}>{i18n.t(OFFI[codeOffice])}</option>;
    });

    const form: JSX.Element =
      <AssignGroup
        assignedDevices={this.state.assignedDevices}
        allDevices={this.state.remainingDevices}
        addAssigns={this.addAssigns}
        removeAssigns={this.removeAssigns} />

    return (

      <div>
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <div className="title">{i18n.t('branch-office-config-devices')}</div>
            </div>
          </div>
          <div className="card-body">
            {this.state.showSelectRegionAndLocation &&
              <div>
                <div className="form-group col-sm-4">
                  <label>{i18n.t('region')}:</label>
                  <select className="form-control" onChange={this.handleRegion} value={this.state.region}>
                    <option value="NONE">{i18n.t('option-select')}</option>
                    {regions}
                  </select>
                </div>
                <div className="form-group col-sm-4">
                  <label>{i18n.t('branch-office')}:</label>
                  <select className="form-control" onChange={this.handleBranchOffice} value={this.state.office}>
                    <option value="NONE">{i18n.t('option-select')}</option>
                    {branchOffices}
                  </select>
                </div>
              </div>
            }
            {!this.state.showSelectRegionAndLocation &&
              <div>
                <div className="form-group col-sm-4">
                  <label>{i18n.t('region')}:</label>
                  <p className="form-control">{this.state.regionName}</p>
                </div>
                <div className="form-group col-sm-4">
                  <label>{i18n.t('branch-office')}:</label>
                  <p className="form-control">{this.state.officeName}</p>
                </div>
              </div>
            }
          </div>
        </div>

        <div className="card margin-card-top">
          <div className="card-header">
            <div className="card-title">
              <div className="title">{i18n.t('devices')}</div>
            </div>
          </div>
          <div className="card-body">
            <div className="form-horizontal">
              {form}
              <br />
              <div className="form-group">
                <div className="col-sm-offset-2 col-sm-10">
                  <button type="button" onClick={this.update} className="btn btn-info" disabled={!this.state.wasModificated}>
                    { this.props.useCardWizard ?  i18n.t('sync') : i18n.t('update')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export const DeviceAssign = withAppContext(DeviceAssignMain);
