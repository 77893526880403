import { SearchBalance, Balance, BalanceAPI, CreateBalanceToSend, ProposalBalance, RawProposalBalance, CreateCloseProposalToSend } from "../interfaces/Balance";
import { helperAxios, apiErrorCallback } from "../utils/ApiBaseConfig";
import { API_PREFIX } from "../const/Routes";
import { AxiosResponse } from "axios";
import i18n from "../i18n";

export const BALANCES_AMOUNT_PER_REQUEST = 300;

export function getLastBalance(searchBalance: SearchBalance): Promise<Balance> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/balance/last',
        data: {
            ...searchBalance
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] getLastBalance');
            return parseBalance(response.data);
        })
        .catch(apiErrorCallback);
}

export function getExpectedBalance(searchBalance: SearchBalance): Promise<Balance> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/balance/expected',
        data: {
            ...searchBalance
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] getExpectedBalance');
            return parseBalance(response.data);
        })
        .catch(apiErrorCallback);
}

export function parseBalance(balanceAPI: BalanceAPI): Balance {
    return {
        correlative: balanceAPI.correlative,
        date: balanceAPI.date ? new Date(balanceAPI.date) : i18n.t('no-data'),
        userCorrelative: balanceAPI.userCorrelative,
        userName: balanceAPI.userName,
        userSupervisorCorrelative: balanceAPI.userSupervisorCorrelative,
        userSupervisorName: (balanceAPI.userSupervisorName) ? balanceAPI.userSupervisorName : i18n.t('no-data'),
        regionCode: balanceAPI.regionCode,
        regionName: balanceAPI.regionName,
        branchOfficeCode: balanceAPI.branchOfficeCode,
        branchOfficeName: balanceAPI.branchOfficeName,
        deviceTypeCode: balanceAPI.deviceTypeCode,
        deviceTypeName: balanceAPI.deviceTypeName,
        expectedQuantityInVault: balanceAPI.expectedQuantityInVault,
        expectedQuantityInMachine: balanceAPI.expectedQuantityInMachine,
        expectedQuantityInOther: balanceAPI.expectedQuantityInOther,
        expectedTotalQuantityOutVault: balanceAPI.expectedTotalQuantityOutVault,
        expectedQuantityRejectedMachine: balanceAPI.expectedQuantityRejectedMachine,
        expectedQuantityRejectedQA: balanceAPI.expectedQuantityRejectedQA,
        realQuantityInVault: balanceAPI.realQuantityInVault,
        realQuantityInMachine: balanceAPI.realQuantityInMachine,
        realQuantityInOther: balanceAPI.realQuantityInOther,
        realTotalQuantityOutVault: balanceAPI.realTotalQuantityOutVault,
        realQuantityRejectedMachine: balanceAPI.realQuantityRejectedMachine,
        realQuantityRejectedQA: balanceAPI.realQuantityRejectedQA,
        description: (balanceAPI.description) ? balanceAPI.description : '',
        vaultToMachine: balanceAPI.vaultToMachine,
        expectedQuantitySuccessful: balanceAPI.expectedQuantitySuccessful,
        realQuantitySuccessful: balanceAPI.realQuantitySuccessful,
        expectedMachineToVault: balanceAPI.expectedMachineToVault,
        realMachineToVault: balanceAPI.realMachineToVault,
        totalRejected: balanceAPI.realQuantityRejectedMachine + balanceAPI.realQuantityRejectedQA,
        pending: balanceAPI.pending
    }
}

export function sendStocktaking(model: CreateBalanceToSend): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/balance/create',
        data: model
    })
        .then(() => {
            console.log('[OK] sendStocktaking');
            return true;
        })
        .catch(apiErrorCallback);
}

export function findBalances(searchBalance: SearchBalance): Promise<Balance[]> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/balance/find',
        data: {
            ...searchBalance,
            maxResults: searchBalance.maxResults || BALANCES_AMOUNT_PER_REQUEST
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] findBalances');
            return response.data.map(parseBalance);
        })
        .catch(apiErrorCallback);
}

export function findPendingBalances(officeCode: string): Promise<Balance[]> {
    return helperAxios({
        method: 'GET', url: API_PREFIX + '/balance/pending/'+officeCode        
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] findPendingBalances');
            return response.data.map(parseBalance);
        })
        .catch(apiErrorCallback);
}

export function findCloseProposal(searchBalance: SearchBalance): Promise<RawProposalBalance[]> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/balance/proposal',
        data: {
            ...searchBalance,
            maxResults: searchBalance.maxResults || BALANCES_AMOUNT_PER_REQUEST
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] findCloseProposal');
            return response.data.map(parseProposalBalance);
        })
        .catch(apiErrorCallback);
}

export function sendCloseProposal(model: CreateCloseProposalToSend): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/balance/close',
        data: model,
        timeout: 90000 //Este request es pesado, ya que involucra comunicación con CW
    })
        .then(() => {
            console.log('[OK] sendCloseProposal');
            return true;
        })
        .catch(apiErrorCallback);
}

export function sendApprovalClose(balances: ProposalBalance[],): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/balance/approvalPendingClose',
        data: balances,
        timeout: 90000 //Este request es pesado, ya que involucra comunicación con CW
    })
        .then(() => {
            console.log('[OK] sendCloseProposal');
            return true;
        })
        .catch(apiErrorCallback);
}

export function rejectBalances(balances: ProposalBalance[],): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/balance/reject',
        data: balances,
        timeout: 90000
    })
        .then(() => {
            console.log('[OK] rejectBalances');
            return true;
        })
        .catch(apiErrorCallback);
}

export function parseProposalBalance(proposalBalance: ProposalBalance): RawProposalBalance {
    return {
        branchOfficeCode: proposalBalance.branchOffice.code,
        branchOfficeName: (proposalBalance.branchOffice.name) ? proposalBalance.branchOffice.name : i18n.t('no-data'),
        deviceTypeCode: proposalBalance.deviceType.code,
        deviceTypeName: proposalBalance.deviceType.name,
        user: (proposalBalance.user) ? proposalBalance.userSupervisor.userId : i18n.t('no-data'),
        userSupervisor: (proposalBalance.userSupervisor) ? proposalBalance.userSupervisor.userId : i18n.t('no-data'),
        expectedQuantityInVault: proposalBalance.expectedQuantityInVault,
        expectedTotalQuantityOutVault: proposalBalance.expectedTotalQuantityOutVault,
        expectedQuantityRejectedMachine: proposalBalance.expectedQuantityRejectedMachine,
        expectedQuantityRejectedQA: proposalBalance.expectedQuantityRejectedQA,
        realQuantityInVault: proposalBalance.realQuantityInVault,
        realQuantityInMachine: proposalBalance.realQuantityInMachine,
        realQuantityInOther: proposalBalance.realQuantityInOther,
        realTotalQuantityOutVault: proposalBalance.realTotalQuantityOutVault,
        realQuantityRejectedMachine: proposalBalance.realQuantityRejectedMachine,
        realQuantityRejectedQA: proposalBalance.realQuantityRejectedQA,
        description: (proposalBalance.description) ? proposalBalance.description : '',
        vaultToMachine: proposalBalance.vaultToMachine,
        expectedQuantitySuccessful: proposalBalance.expectedQuantitySuccessful,
        realQuantitySuccessful: proposalBalance.realQuantitySuccessful,
        expectedMachineToVault: proposalBalance.expectedMachineToVault,
        realMachineToVault: proposalBalance.realMachineToVault,
        totalRejected: proposalBalance.realQuantityRejectedMachine + proposalBalance.realQuantityRejectedQA,
        pending: proposalBalance.pending
    }
}