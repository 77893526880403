import {
    SearchStockManagementAction,
    StockManagementAction,
    StockSummary,
    StockSummaryAPI
} from "../interfaces/Stock";
import { helperAxios, apiErrorCallback } from "../utils/ApiBaseConfig";
import { AxiosResponse } from "axios";
import { API_PREFIX } from "../const/Routes";
import { cleanProperty } from "../utils/Formatter";
import i18n from "../i18n";

export function getCurrentStock(officeCode: string): Promise<StockSummary[]> {
    return helperAxios({ method: 'GET', url: API_PREFIX + '/stockmanagement/office/' + officeCode })
        .then((response: AxiosResponse) => {
            console.log("[OK] getCurrentStock");
            return response.data.map(parseStock);
        })
        .catch(apiErrorCallback);
}

export function getFileLoadStock(officeCode: string): Promise<void> {
    return helperAxios({ method: 'GET', url: API_PREFIX + '/stockmanagement/load/' + officeCode, responseType: 'blob' })
        .then((response: AxiosResponse) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'loadStock_Office_' + officeCode + '.xlsx'); 
            document.body.appendChild(link);
            link.click();
            if (link.parentNode) {
                link.parentNode.removeChild(link);
            }
            console.log("[OK] getFileLoadStock");
            })
        .catch(apiErrorCallback);
}

export function uploadStockFile(file: string, officeCode: string) : Promise<void> {
    return helperAxios({ 
        method: 'POST', 
        url: API_PREFIX + '/stockmanagement/load/',  
        data: { file, officeCode },
        timeout: 90000 //Este request es pesado, ya que involucra procesamiento del archivo
    })
    .then((response: AxiosResponse) => {
        console.log("[OK] uploadStockFile");
    })
    .catch(apiErrorCallback);
}

export function addStock(income: number, officeCode: string, deviceTypeCode: string) : Promise<void> {
    return helperAxios({ 
        method: 'POST', 
        url: API_PREFIX + '/stockmanagement/add/',  
        data: { income, officeCode, deviceTypeCode }
    })
    .then((response: AxiosResponse) => {
        console.log("[OK] addStock");
    })
    .catch(apiErrorCallback);
}

export function reduceStock(outcome: number, officeCode: string, deviceTypeCode: string, comment: string) : Promise<void> {
    return helperAxios({ 
        method: 'POST', 
        url: API_PREFIX + '/stockmanagement/reduce/',  
        data: { outcome, officeCode, deviceTypeCode, comment }
    })
    .then((response: AxiosResponse) => {
        console.log("[OK] reduceStock");
    })
    .catch(apiErrorCallback);
}

export function sendStock(quantity: number, originOfficeCode: string, destinyOfficeCode: string, deviceTypeCode: string) : Promise<void> {
    return helperAxios({ 
        method: 'POST', 
        url: API_PREFIX + '/stockmanagement/send/',  
        data: { quantity, originOfficeCode, destinyOfficeCode, deviceTypeCode }
    })
    .then((response: AxiosResponse) => {
        console.log("[OK] sendStock");
    })
    .catch(apiErrorCallback);
}

export function findActions(searchAction: SearchStockManagementAction): Promise<StockManagementAction[]> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/stockmanagement/find',
        data: {
            ...searchAction,
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] findActions');
            return response.data;
        })
        .catch(apiErrorCallback);
}

export function pendingActions(officeCode: string): Promise<StockManagementAction[]> {
    return helperAxios({
        method: 'GET', url: API_PREFIX + '/stockmanagement/pendings/' + officeCode
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] pendingActions');
            return response.data;
        })
        .catch(apiErrorCallback);
}

export function resolveAction(actionCorrelative: number, approve: boolean): Promise<StockManagementAction[]> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/stockmanagement/resolve',
        data: {
            actionCorrelative,
            approve
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] resolveAction');
            return response.data;
        })
        .catch(apiErrorCallback);
}

function parseStock(stockAPI: StockSummaryAPI): StockSummary {
    return {
        officeCode: cleanProperty(stockAPI.officeCode),
        branchOffice: cleanProperty(stockAPI.branchOffice),
        minStock: stockAPI.minStock,
        currentStock: stockAPI.currentStock,
        yesterdayStock: stockAPI.yesterdayStock,
        incomes: stockAPI.incomes,
        lastIncome: stockAPI.lastIncome ? new Date(stockAPI.lastIncome) : i18n.t('no-data'),
        outcomes: stockAPI.outcomes,
        updateDate: new Date(stockAPI.updateDate),
        id: cleanProperty(stockAPI.officeCode) + cleanProperty(stockAPI.deviceTypeCode),
        deviceTypeName: stockAPI.deviceTypeName,
        deviceTypeCode: stockAPI.deviceTypeCode
    }
}