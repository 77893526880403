import * as React from "react";
import i18n from "../../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../../context/AppContext";
import { Shipment, ShipmentExpandedSummary, ShipmentState } from "../../../interfaces/Shipment";
import { BootstrapTable, TableHeaderColumn, ExportCSVButton } from "react-bootstrap-table";
import { getShipment, receiveShipment } from "../../../services/Shipment";
import { RouteShipmentParams, DEVICES_SHIPPING_RECEIVE_ROUTE, DEVICES_SHIPPING_ROUTE } from "../../../const/Routes";
import { ResponseError, apiHandleErrorCode } from "../../../utils/ApiBaseConfig";
import { shipmentStateFormatter, getTimestamp, shipmentStateCodeFormatter, newDateFormatter } from "../../../utils/Formatter";
import { SHIPMENT_STATE_RECEIVED, SHIPMENT_STATE_SENT, SHIPMENT_STATES } from "../../../const/Shipment";
import { MESSAGE_ERROR, MESSAGE_SUCCESS, MESSAGE_WARNING } from "../../../const/Message";
import { SHIPMENT_RECEIVE_PERM, SHIPMENT_EVENTS_PERM, SHIPMENT_SUMMARY_PRINT_PERM } from "../../../const/Permission";
import { ShipmentEventsTable } from "./event/List";
import { ShipmentEvent } from "../../../interfaces/Event";
import { getShipmentEvents } from "../../../services/Event";
import { isCardWizardActive } from "../../../services/System";

export interface ShipmentInfoProps extends RouteComponentProps<RouteShipmentParams>, AppContextProps { }
export interface ShipmentInfoState {
    shipment: ShipmentExpandedSummary | null;
    events: ShipmentEvent[];
    tableOptions: any;
    backendActive: boolean;
}

export class ShipmentInfoMain extends React.Component<ShipmentInfoProps, ShipmentInfoState>{
    constructor(props: ShipmentInfoProps) {
        super(props);
        this.back = this.back.bind(this);
        this.fetchShipment = this.fetchShipment.bind(this);
        this.fetchShipmentEvents = this.fetchShipmentEvents.bind(this);
        this.showDefectiveDevices = this.showDefectiveDevices.bind(this);
        this.receive = this.receive.bind(this);
        this.csvFileName = this.csvFileName.bind(this);
        this.isBackendActive = this.isBackendActive.bind(this);

        this.state = {
            shipment: null,
            events: [],
            tableOptions: {
                noDataText: i18n.t('table-empty'),
                exportCSVBtn: (onClick: any) => { return <ExportCSVButton btnText={i18n.t('shipment-summary-export')} btnContextual='btn-info' onClick={onClick} /> }
            },
            backendActive: true,
        }
    }
    
    componentDidMount() {
        
        if (this.props.match.params.shipmentId) {
            this.fetchShipment();
            this.fetchShipmentEvents();

            if (this.props.match.path === DEVICES_SHIPPING_RECEIVE_ROUTE 
                && this.props.havePermission(SHIPMENT_RECEIVE_PERM)
                && this.props.useCardWizard){
                    this.setState({ backendActive: false }, this.isBackendActive);
            }

            return;
        }

        this.back();
    }

    back() {
        this.props.history.goBack();
    }

    fetchShipment() {
        this.props.showLoading(true);
        getShipment(Number(this.props.match.params.shipmentId))
            .then((shipment: ShipmentExpandedSummary) => {
                console.log(shipment);
                if (this.props.match.path === DEVICES_SHIPPING_RECEIVE_ROUTE && (SHIPMENT_STATES[shipment.shipmentState] !== SHIPMENT_STATE_SENT || 
                    this.props.authUser!.branchOffice!.code !== shipment.destinationCode)) {
                        this.props.showLoading(false);
                        this.props.history.replace(DEVICES_SHIPPING_ROUTE);
                    return;
                }

                this.setState({ shipment }, () => this.props.showLoading(false));
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    fetchShipmentEvents() {

        if (!this.props.havePermission(SHIPMENT_EVENTS_PERM)) return;

        this.props.showLoading(true);
        getShipmentEvents(Number(this.props.match.params.shipmentId))
            .then((events: ShipmentEvent[]) => {
                this.setState({ events }, () => this.props.showLoading(false));
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    showDefectiveDevices() {
        const state: ShipmentState = this.state.shipment!.shipmentState;
        return this.props.match.path === DEVICES_SHIPPING_RECEIVE_ROUTE && (state === SHIPMENT_STATE_RECEIVED || state === SHIPMENT_STATE_SENT);
    }

    receive() {
        const shipmentE: ShipmentExpandedSummary = Object.assign({}, this.state.shipment);
        const shipment: Shipment = {
            shipmentCorrelative: shipmentE.shipmentCorrelative,
            shipmentState: shipmentE.shipmentState,
            deviceTypeCode: shipmentE.deviceTypeCode,
            deviceTypeName: shipmentE.deviceTypeName,
            deviceQuantity: shipmentE.deviceQuantity,
            sendDate: shipmentE.sendDate,
            updateDate: shipmentE.updateDate,
            receptionDate: shipmentE.receptionDate,
            comment: shipmentE.comment,
            destinationCode: shipmentE.destinationCode,
            originCode: shipmentE.originCode,
            receiverCorrelative: shipmentE.receiverCorrelative,
            senderCorrelative: shipmentE.senderCorrelative
        }
        var total: number = 0;

        delete shipment['shipmentState'];
        delete shipment['receptionDate'];
        
        this.props.showLoading(true);
        receiveShipment(shipment)
            .then(() => {
                this.props.setMessage({ message: i18n.t('shipments-received'), type: MESSAGE_SUCCESS });
                this.props.showLoading(false);
                this.back();
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    csvFileName() {
        return 'RESUMEN_ENVIO_' + this.state.shipment!.originCode + '_' + this.state.shipment!.destinationCode + '_' + getTimestamp() + '.csv';
    }

    isBackendActive(): void {
        isCardWizardActive().then((result: boolean) => {
            if(!result) this.props.setMessage({ message: i18n.t('verifying-cw-err'), type: MESSAGE_ERROR });
            this.setState({ backendActive: result });
        })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
    }

    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('shipment-info')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        {this.state.shipment && <table className="table table-custom">
                            <tbody className="data">
                                <tr>
                                    <th className="col-lg-3">{i18n.t('shipment-code')}:</th>
                                    <td className="col-lg-3">{this.state.shipment.shipmentCorrelative}</td>
                                    <th className="col-lg-3"> {i18n.t('actual-status')}:</th>
                                    <td className="col-lg-3">{shipmentStateCodeFormatter(this.state.shipment.shipmentState)}</td>
                                </tr>
                                <tr>
                                    <th className="col-lg-3"> {i18n.t('origin')}:</th>
                                    <td className="col-lg-3">{this.state.shipment.originName}</td>
                                    <th className="col-lg-3"> {i18n.t('date-send')}:</th>
                                    <td className="col-lg-3">{this.state.shipment.sendDate ? newDateFormatter(this.state.shipment.sendDate) : ""}</td>
                                </tr>
                                <tr>
                                    <th className="col-lg-3">{i18n.t('destination')}:</th>
                                    <td className="col-lg-3">{this.state.shipment.destinationName}</td>
                                    <th className="col-lg-3"> {i18n.t('date-reception')}:</th>
                                    <td className="col-lg-3">{this.state.shipment.receptionDate ? newDateFormatter(this.state.shipment.receptionDate) : ""}</td>
                                </tr>
                                <tr>
                                    <th className="col-lg-3">{i18n.t('device-type')}:</th>
                                    <td className="col-lg-3">{this.state.shipment.deviceTypeName}</td>
                                    <th className="col-lg-3">{i18n.t('quantity')}:</th>
                                    <td className="col-lg-3">{this.state.shipment.deviceQuantity}</td>
                                </tr>
                            </tbody>
                        </table>}
                    </div>
                </div>
                {this.props.havePermission(SHIPMENT_EVENTS_PERM) && <div className="card margin-card-top">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t('recent-activity')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <ShipmentEventsTable events={this.state.events} showShipmentEventDetails={false}  />
                    </div>
                    <div>
                        {this.props.match.path === DEVICES_SHIPPING_RECEIVE_ROUTE && this.props.havePermission(SHIPMENT_RECEIVE_PERM) && <button type="button"  disabled={!this.state.backendActive} onClick={this.receive} className="btn btn-info pull-right">{i18n.t('receive')}</button>}
                        <button type="button" onClick={this.back} className="btn btn-default pull-right">{i18n.t('back')}</button>
                    </div>
                </div>}
            </div>
        )
    }
}

export const ShipmentInfo = withAppContext(ShipmentInfoMain);