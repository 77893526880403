import * as React from "react";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../../context/AppContext";
import { DeviceTypeParams, DEVICES_TYPE_DEVICE_CREATE_ROUTE, DEVICES_TYPE_EDIT_ROUTE, DEVICES_TYPE_DEVICE_ROUTE } from "../../../const/Routes";
import i18n from "../../../i18n";
import { DeviceType, DeviceTypeConfiguration } from "../../../interfaces/DeviceType";
import { getDeviceTypeConfigurationsByType, updateDeviceType, createDeviceType } from "../../../services/DeviceType";
import { ResponseError, apiHandleErrorCode } from "../../../utils/ApiBaseConfig";



export interface DeviceTypeFormProps extends RouteComponentProps<DeviceTypeParams>, AppContextProps{ }
export interface DeviceTypeFormState {
    deviceType: DeviceType;
    saveBtnDisabled: boolean;
 }

export class DeviceTypeFormMain extends React.Component<DeviceTypeFormProps, DeviceTypeFormState>{
    constructor(props: DeviceTypeFormProps) {
        super(props);
        this.handleInputCode = this.handleInputCode.bind(this);
        this.handleInputName = this.handleInputName.bind(this);
        this.fetchDeviceType = this.fetchDeviceType.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.back = this.back.bind(this);
        this.update = this.update.bind(this);
        this.create = this.create.bind(this);
        this.enableSaveBtn = this.enableSaveBtn.bind(this);
        this.state = {
            deviceType: { codeDeviceType:"", nameDeviceType: "", available: true},
            saveBtnDisabled: true,
        }
    }

    componentDidMount() {
        if(this.props.match.params.deviceTypeId)
            this.fetchDeviceType(this.props.match.params.deviceTypeId);
    }

    fetchDeviceType(deviceTypeId: string) {
        this.props.showLoading(true);
        getDeviceTypeConfigurationsByType(deviceTypeId)
        .then((config: DeviceTypeConfiguration) => {
            this.setState( {
                deviceType: { codeDeviceType: deviceTypeId, nameDeviceType: config.type.name, available: config.type.available}
            }, this.props.showLoading(false) );
        })
        .catch((response: ResponseError) => {
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
            this.props.showLoading(false);
            this.props.history.goBack();
        });
    }

    back() {
        this.props.history.push(DEVICES_TYPE_DEVICE_ROUTE);
    }

    update() {
        this.props.showLoading(true);
        updateDeviceType(this.state.deviceType)
        .then(() => {
             this.props.showLoading(false);
             this.props.history.goBack();
        })
        .catch((response: ResponseError) => {
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
            this.props.showLoading(false);
            this.props.history.goBack();
        });
    }

    create() {
        this.props.showLoading(true);
        createDeviceType(this.state.deviceType)
        .then(() => {
             this.props.showLoading(false);
             this.props.history.goBack();
        })
        .catch((response: ResponseError) => {
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
            this.props.showLoading(false);
            this.props.history.goBack();
        });
    }
    
    handleInputCode(e: any): void {
        e.preventDefault();
        const code = e.target.value;
        this.setState(state => ({ ...state, deviceType: { ...state.deviceType, codeDeviceType: code }}), this.enableSaveBtn);
    }

    handleInputName(e: any): void {
        e.preventDefault();
        const name = e.target.value;
        this.setState(state => ({ ...state, deviceType: { ...state.deviceType, nameDeviceType: name }}), this.enableSaveBtn);
    }

    handleCheckboxChange() {
        this.setState({ deviceType: {...this.state.deviceType, available: !this.state.deviceType.available}}, this.enableSaveBtn)
    }

    enableSaveBtn(){
        if(this.state.saveBtnDisabled)
            this.setState({ saveBtnDisabled: false });
    }

    render() {
        return (
            <div className="panel-tabs">
                <div className="panel-heading">
                </div>
                <div className="panel-body">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <div className="title">{i18n.t('device-type-form')}</div>
                            </div>
                        </div>
                        <div className="card-body">
                            <form className="form-horizontal">
                                <div className="form-group">
                                    <label className="col-sm-2 control-label">{i18n.t('code')}: </label>
                                    <div className="col-sm-4">
                                        <input type="text" maxLength={15} disabled={this.props.match.path !== DEVICES_TYPE_DEVICE_CREATE_ROUTE} name="code" 
                                        className="form-control" id="code" onChange={this.handleInputCode} value={this.state.deviceType.codeDeviceType} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-2 control-label">{i18n.t('name')}: </label>
                                    <div className="col-sm-4">
                                        <input type="text" name="name" maxLength={30} 
                                        className="form-control" id="name" onChange={this.handleInputName} value={this.state.deviceType.nameDeviceType} />
                                    </div>
                                </div>
                                {this.props.match.path === DEVICES_TYPE_EDIT_ROUTE &&
                                    <div className="form-group">
                                        <label className="col-sm-2 control-label">{i18n.t('status')}: </label>
                                        <div className="col-sm-4">
                                            <input type="checkbox" checked={this.state.deviceType.available} onChange={this.handleCheckboxChange}></input>
                                        </div>
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                    <div className="card margin-card-top">
                        <div className="card-body">
                            <div className="col-sm-offset-0 col-sm-10">
                                <button type="button" onClick={this.back} className="btn btn-info">{i18n.t('back')}</button>
                                {this.props.match.path === DEVICES_TYPE_DEVICE_CREATE_ROUTE &&
                                    <button type="button" onClick={this.create} className="btn btn-info" >
                                        {i18n.t('create')}
                                    </button>}
                                {this.props.match.path === DEVICES_TYPE_EDIT_ROUTE &&
                                    <button type="button" onClick={this.update} className="btn btn-info" disabled={this.state.saveBtnDisabled}>
                                        {i18n.t('update')}
                                    </button>}
                            </div>
                            <br/><br/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export const DeviceTypeForm = withAppContext(DeviceTypeFormMain);
