import React from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { AppContextProps, withAppContext } from '../../context/AppContext';
import { RouteComponentProps } from 'react-router-dom';
import i18n from '../../i18n';

interface ExcelExportButtonProps extends RouteComponentProps, AppContextProps {
  data: any[];
  columns: { dataField: string; text: string }[];
  filename?: string;
  clazz?: string;
  formatters?: { [key: string]: (cell: any, row: any) => string };
}
interface ExcelExportButtonState {}

export class ExcelExportButtonMain extends React.Component<ExcelExportButtonProps, ExcelExportButtonState> {
    constructor(props: ExcelExportButtonProps) {
        super(props);
        this.exportToExcel = this.exportToExcel.bind(this);
    }

    componentDidMount() {}

    async exportToExcel() : Promise<void> {
        const { data, columns, filename, formatters } = this.props;
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Data');

        // Agregar encabezados de columna
        worksheet.columns = columns.map(col => ({
            header: col.text,
            key: col.dataField,
            width: 20
        }));

        // Agregar filas de datos con formateo
        data.forEach((row) => {
            let rowData = columns.map(col => {
              const value = row[col.dataField];
              // Aplicar formateo si existe
              return formatters && formatters[col.dataField] ? formatters[col.dataField](value, row) : value;
            });
            worksheet.addRow(rowData);
        });
    
        // Generar buffer de Excel
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        saveAs(blob, filename || 'data.xlsx');
    }

    render() {
        return (
          <button className={this.props.clazz || 'btn btn-default'} disabled={this.props.data.length === 0 } onClick={this.exportToExcel}>{ i18n.t('excel-export')}</button>
        );
    }
    
}

export const ExcelExportButton = withAppContext(ExcelExportButtonMain);
