
import { helperAxios, apiErrorCallback } from "../utils/ApiBaseConfig";
import { AxiosResponse } from "axios";
import { API_PREFIX } from "../const/Routes";
import { BranchOffice, BranchOfficeAPI, LocationInfo } from "../interfaces/Office";
import { parseStockDeviceConfig } from "./Stock";
import i18n from "../i18n";
import { cleanProperty } from "../utils/Formatter";
import { AssignOffice } from "../interfaces/DeviceType";

export function getBranchOffice(officeId: string): Promise<BranchOffice> {
    return helperAxios({
        method: 'GET', url: API_PREFIX + '/admin/offices/' + officeId
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] getBranchOffice');
            return parseOfficeWithDeviceType(response.data);
        })
        .catch(apiErrorCallback);
}

export function syncOfficeList(): Promise<boolean> {
    return helperAxios(API_PREFIX + '/admin/offices/sync')
        .then(() => {
            console.log('[OK] syncOffice');
            return true;
        })
        .catch(apiErrorCallback);
}

export function getOfficesList(): Promise<BranchOffice[]> {
    return helperAxios(API_PREFIX + '/admin/offices')
        .then((response: AxiosResponse) => {
            console.log('[OK] getOfficesList');
            return response.data.map(parseOfficeWithDeviceType);
        })
        .catch(apiErrorCallback);
}

export function getOfficesAllList(): Promise<AssignOffice[]> {
    return getOfficesList()
        .then((offices: BranchOffice[]) => {
            return offices.map(parseOfficeToAssignOffice);
        })
}

export function parseOfficeToAssignOffice(branchOffice: BranchOffice): AssignOffice {
    return {
        code: cleanProperty(branchOffice.code),
        name: branchOffice.name
    }
}

export function updateOffice(branchOffice: BranchOfficeAPI): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/admin/offices/' + branchOffice.code,
        data: branchOffice
    })
        .then(() => {
            console.log('[OK] updateOffice');
            return true;
        })
        .catch(apiErrorCallback);
}

export function updateConfNoticeOffice(branchOffice: BranchOfficeAPI): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/admin/offices/confnotices',
        data: branchOffice
    })
        .then(() => {
            console.log('[OK] updateConfNoticeOffice');
            return true;
        })
        .catch(apiErrorCallback);
}

export function createOffice(branchOffice: BranchOfficeAPI): Promise<boolean> {
    return helperAxios({
        method: 'POST', url: API_PREFIX + '/admin/offices',
        data: branchOffice
    })
        .then(() => {
            console.log('[OK] createOffice');
            return true;
        })
        .catch(apiErrorCallback);
}

export function getLocationInfo(branchOfficeCode: string): Promise<LocationInfo> {
    return helperAxios(API_PREFIX + '/stock/offices/location/' + branchOfficeCode)
        .then((response: AxiosResponse) => {
            console.log('[OK] getLocationInfo');
            return response.data;
        })
        .catch(apiErrorCallback);
}


export function parseOfficeWithDeviceType(officeAPI: BranchOfficeAPI): BranchOffice {
    return {
        code: cleanProperty(officeAPI.code),
        name: officeAPI.name,
        address: officeAPI.address ? officeAPI.address : "",
        managerUserName: officeAPI.managerUserName ? officeAPI.managerUserName : "",
        updateDate: officeAPI.updateDate ? new Date(officeAPI.updateDate) : i18n.t('no-data'),
        enabled: officeAPI.enabled,
        notificationMail: officeAPI.notificationMail ? officeAPI.notificationMail : "",
        stockConfig: officeAPI.stockConfig ? officeAPI.stockConfig.map(parseStockDeviceConfig) : undefined,
        region: officeAPI.region,        
        defaultNotifyConf: officeAPI.defaultNotifyConf,
    }
}