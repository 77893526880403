import * as React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RouteComponentProps } from "react-router";
import { Link } from 'react-router-dom'
import { AppContextProps, withAppContext } from "../../../context/AppContext";
import { EQUIVALENCE_ADMIN_PERM } from "../../../const/Permission";
import i18n from "../../../i18n";
import { EQUIVALENCE_ROUTE, EQUIVALENCE_MODIFY_ROUTE, RouteEquivalenceParams, EQUIVALENCE_ID_PARAM } from "../../../const/Routes";
import { ToggleLink } from "../../../utils/ToggleLink";
import { ResponseError, apiHandleErrorCode } from "../../../utils/ApiBaseConfig";
import { Equivalence } from "../../../interfaces/Equivalence";
import { getEquivalencesList, deleteEquivalence } from "../../../services/Equivalence";
import { MESSAGE_SUCCESS } from "../../../const/Message";

interface EquivalenceListProps extends RouteComponentProps<RouteEquivalenceParams>, AppContextProps { }
interface EquivalenceListState {
    showModify: boolean;
    showCreateNew: boolean;
    equivalenceList: Equivalence[];
    selected: Equivalence | null;
}

export class EquivalenceListMain extends React.Component<EquivalenceListProps, EquivalenceListState>{
    bootstrapTableRef: any;
    constructor(props: EquivalenceListProps) {
        super(props);
        this.onSelect = this.onSelect.bind(this);
        this.delete = this.delete.bind(this);
        this.getEquivalences = this.getEquivalences.bind(this);
        this.state = {
            showCreateNew: false,
            showModify: false,
            equivalenceList: [],
            selected: null,
        }
    }

    componentDidMount() {
        this.getEquivalences();
    }

    getEquivalences() {
        this.props.showLoading(true);
        getEquivalencesList()
            .then((equivalenceList: Equivalence[]) => {
                this.setState({ equivalenceList, selected: null }, () => this.props.showLoading(false));
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    onSelect(selected: Equivalence) {
        this.setState({ selected });
    }

    delete() {
        this.props.showLoading(true);
        deleteEquivalence(this.state.selected!.id)
        .then(() => {
            this.props.setMessage({ message: i18n.t('equivalence-delete-ok'), type: MESSAGE_SUCCESS });
            this.getEquivalences();
            this.props.showLoading(false);
        })
        .catch((response: ResponseError) => {
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
            this.props.showLoading(false);
        });
    }

    render() {
        return (
            <div className="card">
                <div className="card-header">
                    <div className="card-title">
                        <div className="title">{i18n.t('equivalences')}</div>
                    </div>
                </div>
                <div className="card-body">
                    {this.props.havePermission(EQUIVALENCE_ADMIN_PERM) && <Link to={EQUIVALENCE_ROUTE} className="btn btn-info" >{i18n.t('create')}</Link>}
                    {this.props.havePermission(EQUIVALENCE_ADMIN_PERM) && <ToggleLink 
                        to={EQUIVALENCE_MODIFY_ROUTE
                            .replace(EQUIVALENCE_ID_PARAM, this.state.selected ? this.state.selected.id.toString() : '')
                        }
                        className="btn btn-primary" disabled={this.state.selected === null}>{i18n.t('modify')}</ToggleLink>}
                    {this.props.havePermission(EQUIVALENCE_ADMIN_PERM) && <button type="button" onClick={this.delete} className="btn btn-danger" 
                        disabled={this.state.selected === null}>{i18n.t('delete')}</button>}

                    <div className="panel fresh-color panel-info">
                        <div className="panel-heading">{i18n.t('equivalences')}</div>
                        <BootstrapTable
                            data={this.state.equivalenceList}
                            selectRow={{ mode: 'radio', onSelect: this.onSelect }}
                            bordered={false} >
                            <TableHeaderColumn dataField='id' isKey={true} dataSort={true} dataAlign="center">{i18n.t('code')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='equivalenceType' dataSort={true} dataAlign="center">{i18n.t('equivalence')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='stockcardValue' dataSort={true} dataAlign="center">{i18n.t('equivalence-stockcard')}</TableHeaderColumn>
                            <TableHeaderColumn dataField='externalValue' dataSort={true} dataAlign="center">{i18n.t('equivalence-cw')}</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </div>
            </div>
        )
    }
}

export const EquivalenceList = withAppContext(EquivalenceListMain);