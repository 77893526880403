import * as React from "react";
import { Switch, Route, RouteComponentProps } from "react-router-dom";
import { ClientContainer } from "./sub/Client";
import { UserContainer } from "./sub/User";
import { Message } from "../utils/Message";
import { NavBar } from "./NavBar";
import { AppContextProps, withAppContext } from "../context/AppContext";
import { Audit } from "../components/event/List";
import { MainMenu } from "../components/MainMenu";
import { MAIN_MENU_ITEMS, ROLE_FORM_CREATE_MENU, ROLE_FORM_MENU, OFFICE_FORM_MENU, USER_FORM_CREATE_MENU, USER_FORM_MENU, USER_MENU, CLIENT_MENU, DEVICE_MANAGE_SIDE_MENU, DEFAULT_MENU, REPORTS_AND_CONTROL_MENU, DEVICE_TYPE_CREATE_MENU, DEVICE_TYPE_UPDATE_MENU, DEVICE_TYPE_ASSIGN_MENU } from "../const/Menu";
import { RoleContainer } from "./sub/Role";
import { SideMenu } from "./SideMenu";
import { Forbidden } from "./Forbidden";
import { ReportDevices } from "../components/reportDevices/ReportDevices";
import { OfficeContainer } from "./sub/Office";
import { DocumentContainer } from "./sub/Document";
import { ROLE_CREATE_ROUTE, ROLE_MODIFY_ROUTE, ROLE_SHOW_ROUTE, OFFICE_MODIFY_ROUTE, OFFICE_SHOW_ROUTE, REPORTS_DEVICES_ROUTE, USER_CREATE_ROUTE, USER_MODIFY_ROUTE, USER_MAIN_ROUTE, CLIENT_MAIN_ROUTE, DEVICES_ROUTE, FIND_USER_ROUTE, FIND_CLIENT_ROUTE, ROLES_ROUTE, OFFICES_ROUTE, DOCUMENTS_ROUTE, AUDIT_ROUTE, SYSTEM_ROUTE, FORBIDDEN_ROUTE, MAIN_ROUTE, PERSON_SELF_PROFILE_ROUTE, EQUIVALENCE_ROUTE, RouteEquivalenceParams, EQUIVALENCE_MODIFY_ROUTE, CONTROL_REPORTS_ROUTE, DEVICES_TYPE_DEVICE_ROUTE, DEVICES_TYPE_ASSIGN_ROUTE, DEVICES_TYPE_EDIT_ROUTE, DEVICES_TYPE_DEVICE_CREATE_ROUTE, NOTICE_ROUTE, NOTICE_EDIT_ROUTE, STOCK_MANAGEMENT_ROUTE } from "../const/Routes";
import { CLIENT_SHOW_PERM, ROLE_LIST_PERM, OFFICE_LIST_PERM, DOCUMENT_LIST_PERM, REPORT_DEVICES_PERM, EVENTS_GENERAL_PERM, EVENTS_SYSTEM_PERM, SYSTEM_PARAMETERS_LIST_PERM, LICENSE_LIST_PERM, BASE_PERM, DEVICES_MANAGE_PERM, EQUIVALENCE_ADMIN_PERM, EQUIVALENCE_PERM, REPORTS_AND_CONTROL_PERM, USER_SECTION_ACCESS, ROLE_SECTION_ACCESS, OFFICE_SECTION_ACCESS, DEVICE_TYPE_SECTION_ACCESS, EVENTS_SECTION_ACCESS, SYSTEM_PARAMETERS_SECTION_ACCESS, STOCK_DEVICE_SECTION_ACCESS, STOCK_DEVICE_STATE_SECTION_ACCESS, NOTICE_SECTION_ACCESS, NOTICE_CONFIGURATION_EDIT_PERM, STOCK_MANAGEMENT_SECTION_ACCESS } from "../const/Permission";
import { System } from "../components/system/System";
import { DeviceContainer } from "./sub/Device";
import { UserSelfProfile } from "../components/user/profile/SelfProfile";
import { FormEquivalence } from "../components/system/equivalence/Form";
import { ReportContainer } from "./sub/Reports";
import { DeviceTypeList } from "../components/devices/type/List";
import { DeviceTypeAssign } from "../components/devices/type/Assign";
import { DeviceTypeForm } from "../components/devices/type/Form";
import { Notice } from "../components/notice/Notice";
import { FormConf } from "../components/notice/ConfForm";
import i18n from "../i18n";
import { StockManagementIndex } from "../components/stock/Index";

interface MainContainerProps extends RouteComponentProps, AppContextProps { }
interface MainContainerState {
    containterCollapsed: boolean;
}

export class MainContainerMain extends React.Component<MainContainerProps, MainContainerState> {
    constructor(props: MainContainerProps) {
        super(props);
        this.collapseSideMenu = this.collapseSideMenu.bind(this);
        this.props.showLoading(true);
        this.state = {
            containterCollapsed: true,
        }
    }

    collapseSideMenu() {
        this.setState(state => {
            return { ...state, containterCollapsed: !state.containterCollapsed }
        });
    }

    componentDidMount() {
        this.props.showLoading(false);
    }

    render() {
        return (
            <div className={this.state.containterCollapsed ? "app-container" : "app-container expanded"}>
                <div className="row content-container">
                    <NavBar collapseSideMenu={this.collapseSideMenu} />
                    {this.props.authUser && this.props.authUser.branchOffice && this.props.authUser.branchOffice.code !== 'CUID' &&
                        <>
                            <Switch>
                                <Route path={ROLE_CREATE_ROUTE} render={() => <SideMenu items={ROLE_FORM_CREATE_MENU} />} />
                                <Route path={ROLE_MODIFY_ROUTE} render={() => <SideMenu items={ROLE_FORM_MENU} />} />
                                <Route path={ROLE_SHOW_ROUTE} render={() => <SideMenu items={ROLE_FORM_MENU} />} />

                                <Route path={OFFICE_MODIFY_ROUTE} render={() => <SideMenu items={OFFICE_FORM_MENU} />} />
                                <Route path={OFFICE_SHOW_ROUTE} render={() => <SideMenu items={OFFICE_FORM_MENU} />} />

                                <Route path={USER_CREATE_ROUTE} render={() => <SideMenu items={USER_FORM_CREATE_MENU} />} />
                                <Route path={USER_MODIFY_ROUTE} render={() => <SideMenu items={USER_FORM_MENU} />} />

                                <Route path={DEVICES_TYPE_ASSIGN_ROUTE} render={() => <SideMenu items={DEVICE_TYPE_ASSIGN_MENU} />} />
                                <Route path={DEVICES_TYPE_EDIT_ROUTE} render={() => <SideMenu items={DEVICE_TYPE_UPDATE_MENU} />} />
                                <Route path={DEVICES_TYPE_DEVICE_CREATE_ROUTE} render={() => <SideMenu items={DEVICE_TYPE_CREATE_MENU} />} />
                                <Route path={DEVICES_TYPE_DEVICE_ROUTE} render={() => <SideMenu items={DEFAULT_MENU} />} />

                                <Route path={USER_MAIN_ROUTE} render={() => <SideMenu items={USER_MENU} />} />
                                <Route path={CLIENT_MAIN_ROUTE} render={() => <SideMenu items={CLIENT_MENU} />} />
                                <Route path={DEVICES_ROUTE} render={() => <SideMenu items={DEVICE_MANAGE_SIDE_MENU} />} />

                                <Route path={CONTROL_REPORTS_ROUTE} render={() => <SideMenu items={DEVICE_MANAGE_SIDE_MENU} />} />

                                <Route path='/' render={() => <SideMenu items={DEFAULT_MENU} />} />
                            </Switch>
                            <div className="container-fluid">
                                <div className="side-body padding-top">
                                    <Message message={this.props.message} hide={this.props.hideMessage} show={this.props.showMessage} closable={true} />
                                    {this.props.authUser && <Switch>
                                        {this.props.havePermission(USER_SECTION_ACCESS) && <Route path={FIND_USER_ROUTE} component={UserContainer} />}
                                        {
                                            //this.props.havePermission(CLIENT_SHOW_PERM) && <Route path={FIND_CLIENT_ROUTE} component={ClientContainer} />
                                        }
                                        {this.props.havePermission(ROLE_SECTION_ACCESS) && <Route path={ROLES_ROUTE} component={RoleContainer} />}
                                        {this.props.havePermission(OFFICE_SECTION_ACCESS) && <Route path={OFFICES_ROUTE} component={OfficeContainer} />}
                                        {this.props.havePermission(DEVICE_TYPE_SECTION_ACCESS) && <Route path={DEVICES_TYPE_ASSIGN_ROUTE} component={DeviceTypeAssign} />}
                                        {this.props.havePermission(DEVICE_TYPE_SECTION_ACCESS) && <Route path={DEVICES_TYPE_EDIT_ROUTE} component={DeviceTypeForm} />}
                                        {this.props.havePermission(DEVICE_TYPE_SECTION_ACCESS) && <Route path={DEVICES_TYPE_DEVICE_CREATE_ROUTE} component={DeviceTypeForm} />}
                                        {this.props.havePermission(DEVICE_TYPE_SECTION_ACCESS) && <Route path={DEVICES_TYPE_DEVICE_ROUTE} component={DeviceTypeList} />}
                                        {this.props.havePermission(DOCUMENT_LIST_PERM) && <Route path={DOCUMENTS_ROUTE} component={DocumentContainer} />}
                                        {this.props.havePermission(STOCK_DEVICE_SECTION_ACCESS) && <Route path={DEVICES_ROUTE} component={DeviceContainer} />}
                                        {this.props.havePermission(EVENTS_SECTION_ACCESS) && <Route path={AUDIT_ROUTE} component={Audit} />}
                                        {this.props.havePermission(EQUIVALENCE_ADMIN_PERM) && <Route path={EQUIVALENCE_MODIFY_ROUTE} exact component={FormEquivalence} />}
                                        {this.props.havePermission(EQUIVALENCE_PERM) && <Route path={EQUIVALENCE_ROUTE} exact component={FormEquivalence} />}
                                        {this.props.havePermission(SYSTEM_PARAMETERS_SECTION_ACCESS) && <Route path={SYSTEM_ROUTE} component={System} />}
                                        {this.props.havePermission(BASE_PERM) && <Route path={PERSON_SELF_PROFILE_ROUTE} component={UserSelfProfile} />}
                                        {this.props.havePermission(STOCK_DEVICE_STATE_SECTION_ACCESS) && <Route path={CONTROL_REPORTS_ROUTE} component={ReportContainer} />}
                                        {this.props.havePermission(NOTICE_CONFIGURATION_EDIT_PERM) && <Route path={NOTICE_EDIT_ROUTE} component={FormConf} />}
                                        {this.props.havePermission(NOTICE_SECTION_ACCESS) && <Route path={NOTICE_ROUTE} component={Notice} />}
                                    {this.props.havePermission(STOCK_MANAGEMENT_SECTION_ACCESS) && <Route path={STOCK_MANAGEMENT_ROUTE} component={StockManagementIndex} />}
                                        {this.props.havePermission(REPORT_DEVICES_PERM) && <Route path={REPORTS_DEVICES_ROUTE} component={ReportDevices} />}
                                        <Route path={FORBIDDEN_ROUTE} component={Forbidden} />
                                        <Route path={MAIN_ROUTE} render={() => <MainMenu menuItems={MAIN_MENU_ITEMS} />} />
                                    </Switch>}
                                </div>
                            </div>
                        </>
                    }
                    {this.props.authUser && this.props.authUser.branchOffice && this.props.authUser.branchOffice.code === 'CUID' &&
                        <>
                            <SideMenu items={[]} />
                            <div className="container-fluid">
                                <div className="side-body padding-top">
                                    <h1>{i18n.t('no-branch-office-asigned')}</h1>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        )
    }
}

export const MainContainer = withAppContext(MainContainerMain);