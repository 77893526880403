import * as React from "react";
import i18n from "../i18n";
import { RouteComponentProps, Switch, Route } from "react-router-dom";
import { cleanToken, ResponseError, apiHandleErrorCode } from "../utils/ApiBaseConfig";
import { AppContextProps, withAppContext } from "../context/AppContext";
import {
    USER_MAIN_ROUTE, FIND_CLIENT_ROUTE, LOGIN_ROUTE, ROLES_ROUTE, AUDIT_ROUTE, SOFTTOKEN_ACTIVATION_ROUTE,
    CLIENT_GRID_ASSIGN_ROUTE, DEVICES_ROUTE, CLIENT_MAIN_ROUTE, DEVICES_STOCK_ROUTE, RouteAllParams, CLIENT_EVENTS_ROUTE,
    CLIENT_BIOMETRIC_ROUTE, CLIENT_DOCUMENTS_ROUTE, CLIENT_GRIDS_ROUTE, CLIENT_PASSWORD_ROUTE, CLIENT_SOFTTOKEN_ROUTE, USER_MODIFY_ROUTE,
    USER_CREATE_ROUTE, SYSTEM_ROUTE, DOCUMENTS_ROUTE, DEVICES_SHIPPING_ROUTE, DEVICES_SHIPPING_ASSIGN_ROUTE, DEVICES_FIND_DEVICE_ROUTE,
    DEVICES_EVENTS_ROUTE, DEVICES_SHIPPING_GENERATE_ROUTE, DEVICES_BALANCE_MOVEMENT_ROUTE, DEVICES_BALANCE_STOCKTAKING_ROUTE, DEVICES_TYPE_ASSIGN_ROUTE, PERSON_SELF_PROFILE_ROUTE, DEVICES_TYPE_DEVICE_ROUTE
} from "../const/Routes";
import { capitalize, formatUserId } from "../utils/Formatter";
import { getUserLogout } from "../services/Session";
import { Notification } from "../interfaces/Notification";
import { getNotifications, markNotificationAsRead } from "../services/Notification";
import { getWebSocket } from "../utils/StompBaseConfig";
import { CompatClient, IMessage } from "@stomp/stompjs";
import { GET_NOTIFICATIONS_LAPSE } from "../const/System";

export interface NavBarProps extends RouteComponentProps<RouteAllParams>, AppContextProps {
    collapseSideMenu: Function;
}
export interface NavBarState {
    notifications: Notification[];
    rotateIcon: boolean,
    showUserPanel: boolean,
    showNotificationsPanel: boolean,
}

export class NavBarMain extends React.Component<NavBarProps, NavBarState>{
    notificationsPanelRef: any;
    userPanelRef: any;
    intervalID!: NodeJS.Timeout;

    constructor(props: NavBarProps) {
        super(props);
        this.hideSideMenu = this.hideSideMenu.bind(this);
        this.logoutAction = this.logoutAction.bind(this);
        this.setNotificationsPanelRef = this.setNotificationsPanelRef.bind(this);
        this.setUserPanelRef = this.setUserPanelRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.userSelfProfile = this.userSelfProfile.bind(this);
        this.markAsRead = this.markAsRead.bind(this);
        this.getUserNotifications = this.getUserNotifications.bind(this);
        this.state = {
            rotateIcon: false,
            showUserPanel: false,
            showNotificationsPanel: false,
            notifications: []
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        this.getUserNotifications();
        this.intervalID = setInterval(() => {
            this.getUserNotifications()
        }, GET_NOTIFICATIONS_LAPSE);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        clearInterval(this.intervalID);
    }

    setNotificationsPanelRef(node: any) {
        this.notificationsPanelRef = node;
    }

    setUserPanelRef(node: any) {
        this.userPanelRef = node;
    }

    handleClickOutside(event: any) {
        if (this.userPanelRef && !this.userPanelRef.contains(event.target)) {
            this.setState({ showUserPanel: false });
        }

        if (this.notificationsPanelRef && !this.notificationsPanelRef.contains(event.target)) {
            this.setState({ showNotificationsPanel: false });
        }
    }

    hideSideMenu() {
        this.setState(state => { return { ...state, rotateIcon: !state.rotateIcon } });
        this.props.collapseSideMenu();
    }

    logoutAction() {
        this.props.showLoading(true);
        getUserLogout()
            .then(() => {
                this.props.clearPermission();
                this.props.showLoading(false);
                cleanToken();
                this.props.history.replace(LOGIN_ROUTE);
            })
            .catch(() => {
                this.props.showLoading(false);
                cleanToken();
                this.props.history.replace(LOGIN_ROUTE);
            });

    }

    userSelfProfile(e: any) {
        e.preventDefault();
        this.props.history.push(
            PERSON_SELF_PROFILE_ROUTE
        );
        this.setState({ showUserPanel: false });
    }

    markAsRead(noticeId: number){
        markNotificationAsRead(noticeId)
        .then((result: boolean) => {
            this.getUserNotifications();
            this.setState({ showNotificationsPanel: false });
        })
        .catch((response: ResponseError) => {
            apiHandleErrorCode(response.status, this.props.history);
            this.props.setMessage(response.message);
            this.props.showLoading(false);
        });
    }

    getUserNotifications(){
        getNotifications()
            .then((notifications: Notification[]) => {
                this.setState({ notifications });
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    render() {
        const notifications: JSX.Element[] = this.state.notifications.map((notification: Notification) => {
            return (
                <li className="list-group-item notification" key={notification.notificationCorrelative}>
                    <div className="notification-body">
                        <div className="notification-message">{notification.message}</div>
                    </div>
                    <div>
                        <div className="notification-date">{notification.notificationDate.toString()}</div>
                        <button onClick={ () => this.markAsRead(notification.notificationCorrelative) } className="btn-link notification-option">{i18n.t('notification-mark-as-read')}</button>
                    </div>
                </li>
            )
        })

        return (
            <nav className="navbar navbar-default navbar-fixed-top navbar-top">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <button type="button" onClick={this.hideSideMenu} className={this.state.rotateIcon ? 'navbar-expand-toggle fa-rotate-90' : 'navbar-expand-toggle'}>
                            <i className="fa fa-bars icon"></i>
                        </button>
                        <ol className="breadcrumb navbar-breadcrumb">
                            <Switch>
                                <Route path={FIND_CLIENT_ROUTE} exact component={() => <li className="active">{i18n.t('client-manage')}</li>} />
                                <Route path={CLIENT_MAIN_ROUTE} render={() => ([
                                    <li key="a-0-0">{i18n.t('client-manage')}</li>,
                                    <Switch key="a-0-0">
                                        <Route path={CLIENT_SOFTTOKEN_ROUTE} exact component={() => <li className="active">{i18n.t('soft-token-manager')}</li>} />
                                        <Route path={CLIENT_SOFTTOKEN_ROUTE} render={() => ([
                                            <li key="a-1-0">{i18n.t('soft-token-manager')}</li>,
                                            <Switch key="a-1-1">
                                                <Route path={SOFTTOKEN_ACTIVATION_ROUTE} exact component={() => <li className="active">{i18n.t('enroll')}</li>} />
                                            </Switch>
                                        ])} />

                                        <Route path={CLIENT_PASSWORD_ROUTE} exact component={() => <li className="active">{i18n.t('passwords')}</li>} />
                                        <Route path={CLIENT_DOCUMENTS_ROUTE} exact component={() => <li className="active">{i18n.t('documents')}</li>} />
                                        <Route path={CLIENT_BIOMETRIC_ROUTE} exact component={() => <li className="active">{i18n.t('biometrics')}</li>} />
                                        <Route path={CLIENT_GRIDS_ROUTE} exact component={() => <li className="active">{i18n.t('grid-cards')}</li>} />
                                        <Route path={CLIENT_EVENTS_ROUTE} exact component={() => <li className="active">{i18n.t('events')}</li>} />
                                        <Route path={CLIENT_GRID_ASSIGN_ROUTE} exact component={() => <li className="active">{i18n.t('assign')} {i18n.t('grid-card').toLowerCase()}</li>} />
                                    </Switch>
                                ])} />

                                <Route path={USER_MAIN_ROUTE} exact component={() => <li className="active">{i18n.t('user-manage')}</li>} />
                                <Route path={USER_MAIN_ROUTE} render={() => ([
                                    <li key="b-0-0">{i18n.t('user-manage')}</li>,
                                    <Switch key="b-0-1">
                                        <Route path={USER_CREATE_ROUTE} exact component={() => <li className="active">{i18n.t('create')}</li>} />
                                        <Route path={USER_MODIFY_ROUTE} exact component={() => <li className="active">{i18n.t('modify')}</li>} />
                                    </Switch>
                                ])} />

                                <Route path={DEVICES_ROUTE} render={() => ([
                                    <li key="c-0-0">{i18n.t('devices-manage')}</li>,
                                    <Switch key="c-0-1">
                                        <Route path={DEVICES_STOCK_ROUTE} exact component={() => <li className="active">{i18n.t('stock-summary')}</li>} />
                                        <Route path={DEVICES_SHIPPING_ASSIGN_ROUTE} exact component={() => <li className="active">{i18n.t('shipments-assign')}</li>} />
                                        <Route path={DEVICES_FIND_DEVICE_ROUTE} exact component={() => <li className="active">{i18n.t('devices-consult')}</li>} />
                                        <Route path={DEVICES_TYPE_DEVICE_ROUTE} exact component={() => <li className="active">{i18n.t('device-type-office-assign')}</li>} />
                                        <Route path={DEVICES_EVENTS_ROUTE} exact component={() => <li className="active">{i18n.t('events')}</li>} />
                                        <Route path={DEVICES_BALANCE_MOVEMENT_ROUTE} exact component={() => <li className="active">{i18n.t('devices-movements')}</li>} />
                                        <Route path={DEVICES_BALANCE_STOCKTAKING_ROUTE} exact component={() => <li className="active">{i18n.t('devices-balance')}</li>} />
                                        <Route path={DEVICES_SHIPPING_ROUTE} exact component={() => <li className="active">{i18n.t('shipments')}</li>} />
                                        <Route path={DEVICES_SHIPPING_ROUTE} render={() => ([
                                            <li key="c-1-0">{i18n.t('shipments')}</li>,
                                            <Switch key="c-1-1">
                                                <Route path={DEVICES_SHIPPING_GENERATE_ROUTE} exact component={() => <li className="active">{i18n.t('shipment-generate')}</li>} />
                                            </Switch>
                                        ])} />
                                    </Switch>
                                ])} />

                                <Route path={ROLES_ROUTE} exact component={() => <li className="active">{i18n.t('roles-manage')}</li>} />
                                <Route path={DOCUMENTS_ROUTE} exact component={() => <li className="active">{i18n.t('documents')}</li>} />
                                <Route path={SYSTEM_ROUTE} exact component={() => <li className="active">{i18n.t('system')}</li>} />
                                <Route path={DEVICES_ROUTE} exact component={() => <li className="active">{i18n.t('devices-manage')}</li>} />
                                <Route path={AUDIT_ROUTE} exact component={() => <li className="active">{i18n.t('events-platform')}</li>} />
                                <Route component={() => <li className="active">Stock Card</li>} />
                            </Switch>
                        </ol>
                        <button type="button" className="navbar-right-expand-toggle pull-right visible-xs">
                            <i className="fa fa-th icon"></i>
                        </button>
                    </div>
                    <ul className="nav navbar-nav navbar-right">
                        <button type="button" className="navbar-right-expand-toggle pull-right visible-xs">
                            <i className="fa fa-times icon"></i>
                        </button>
                        {this.props.authUser && <li className="dropdown">
                            <a className="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false"><span className="label label-success">{this.props.authUser.role.roleName}</span></a>
                        </li>}
                        {this.props.authUser && this.props.authUser.branchOffice && <li className="dropdown">
                            <a className="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false"><span className="label label-warning">
                                {i18n.t('branch-office')}: {this.props.authUser.branchOffice.name}
                                </span>
                            </a>
                        </li>}
                        <li ref={this.setNotificationsPanelRef} className="dropdown danger">
                            <a className="dropdown-toggle" data-toggle="dropdown" role="button" onClick={() => this.setState(state => ({ ...state, showNotificationsPanel: !state.showNotificationsPanel }))} aria-expanded={this.state.showNotificationsPanel}>
                                <i className="fa fa-star-half-o"></i> {this.state.notifications.length}
                            </a>
                            <ul className={"dropdown-menu notifications animated " + (this.state.showNotificationsPanel ? "fadeInDown" : "fadeOutUp")}>
                                <li className="title">
                                    {i18n.t('notifications')} <span className="badge pull-right">{this.state.notifications.length}</span>
                                </li>
                                <li>
                                    <ul className="list-group notifications">
                                        {notifications}
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        {this.props.authUser && <li ref={this.setUserPanelRef} className="dropdown profile">
                            <a className="dropdown-toggle" onClick={() => this.setState(state => ({ ...state, showUserPanel: !state.showUserPanel }))} data-toggle="dropdown" role="button" aria-expanded={this.state.showUserPanel} >
                                {this.props.authUser.fullName} <i className={this.state.showUserPanel ? "fa fa-angle-up" : "fa fa-angle-down"}></i>
                            </a>
                            <ul className={"dropdown-menu animated " + (this.state.showUserPanel ? "fadeInDown" : "fadeOutUp")}>
                                <li>
                                    <div className="profile-info">
                                        <h4 className="username">{this.props.authUser.fullName}</h4>
                                        <p>{formatUserId(this.props.authUser.username, this.props.authUser.group)}</p>
                                        {this.props.authUser.alias && this.props.authUser.alias != "" && <p>{this.props.authUser.alias}</p>}
                                        <p>
                                            <span className="label label-primary">{capitalize(this.props.authUser.role.roleName)}</span>
                                        </p>
                                        {this.props.authUser.branchOffice && <p>{i18n.t('branch-office')}: {"(" + this.props.authUser.branchOffice.code + ") " + this.props.authUser.branchOffice.name}</p>}

                                        <div className="btn-group margin-bottom-2x" role="group">
                                            <a onClick={this.userSelfProfile} className="btn btn-default"><i className="fa fa-user"></i>{i18n.t('profile')}</a>
                                            <a onClick={this.logoutAction} className="btn btn-default" style={{ marginLeft: '12px' }}><i className="fa fa-sign-out"></i>{i18n.t('logout')}</a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </li>}
                    </ul>
                </div>
            </nav>
        )
    }
}

export const NavBar = withAppContext(NavBarMain);
