import { ReportOfficeStatus, ReportOfficeStatusAPI, SearchOfficeStatusReport } from "../interfaces/ReportOfficeStatus";
import { AxiosResponse } from "axios";
import { apiErrorCallback, helperAxios } from "../utils/ApiBaseConfig";
import { API_PREFIX } from "../const/Routes";

export async function findOfficeStatus(search: SearchOfficeStatusReport): Promise<ReportOfficeStatus[]> {
    return helperAxios({
        method: 'POST', url: '/api/v2/reports/stateoffices',
        data: {
            ...search
        }
    })
        .then((response: AxiosResponse) => {
            console.log('[OK] findOfficeStatus');
            return response.data.map(parseOfficeStatus);
        })
        .catch(apiErrorCallback);
}

export async function findLastOfficeStatus(officeCode: string): Promise<ReportOfficeStatus[]> {
    console.log("officecode: ",officeCode)
    return helperAxios(API_PREFIX + '/reports/stateoffices/' + officeCode)
        .then((response: AxiosResponse) => {
            console.log('[OK] findLastOfficeStatus');
            return response.data.map(parseOfficeStatus);
        })
        .catch(apiErrorCallback);
}


export function parseOfficeStatus(officeStatus: ReportOfficeStatusAPI): ReportOfficeStatus {
    return {
        officeCode: officeStatus.branchOffice.code,
        officeName: officeStatus.branchOffice.name,
        date: new Date(officeStatus.date).toLocaleString(),
        opened: officeStatus.opened ? officeStatus.opened : false,
        openedAt: officeStatus.openedAt ? new Date(officeStatus.openedAt).toLocaleString() : '',
        closened: officeStatus.closened ? officeStatus.closened : false,
        closedAt: officeStatus.closedAt ? new Date(officeStatus.closedAt).toLocaleString() : '',
        openedBy: officeStatus.openedBy ? officeStatus.openedBy.userName : '',
        closedBy: officeStatus.closenedBy ? officeStatus.closenedBy.userName : '',
        pendingApproval: officeStatus.pendingApproval ? officeStatus.pendingApproval : false
    }
}