import * as React from "react";
import { RouteComponentProps } from "react-router";
import { AppContextProps } from "./../../context/AppContext";
import { ResponseError, apiHandleErrorCode } from "../../utils/ApiBaseConfig";
import i18n from "../../i18n";
import { RouteUserParams, PERSON_MODIFY_ROUTE, USER_GROUP_PARAM, USER_ID_PARAM, USER_TYPE_PARAM, TYPE_CLIENT, TYPE_USER, PERSON_SOFTTOKEN_ROUTE, PERSON_GRIDS_ROUTE, PERSON_PASSWORD_ROUTE } from "../../const/Routes";
import { UserInfo } from "./profile/Info";
import { SoftTokenList } from "./soft-token/List";
import { USER_UPDATE_PERM, SOFTTOKEN_LIST_PERM, CLIENT_UNLOCK_PERM, GRID_LIST_PERM, CLIENT_PASSWORD_PERM, CLIENT_PASSWORD_EVENTS_PERM, GRID_EVENTS_PERM, SOFTTOKEN_EVENTS_PERM } from "../../const/Permission";
import { UserContextProps, withAppUserContext } from "../../context/UserContext";
import { getUser, unlockUser, updatePassword } from "../../services/User";
import { IdentityUser } from "../../interfaces/Identity";
import { HashLink as Link } from 'react-router-hash-link';
import { CardList } from "./grid/List";
import { MESSAGE_SUCCESS, MESSAGE_ERROR } from "../../const/Message";
import { PasswordList } from "./password/List";
import Modal from 'react-modal';

export interface UserProfileProps extends RouteComponentProps<RouteUserParams>, AppContextProps, UserContextProps { }
export interface UserProfileState {
    userId: string,
    userPwd: string;
    userPwdRepeat: string;
    modalIsOpen: boolean;
    showError: boolean;
    textError: string;
}

export class UserProfileMain extends React.Component<UserProfileProps, UserProfileState>{
    subtitle: HTMLHeadingElement | null | undefined;
    constructor(props: UserProfileProps) {
        super(props);
        this.modify = this.modify.bind(this);
        this.unlock = this.unlock.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.sendDataAndCloseModal = this.sendDataAndCloseModal.bind(this);
        this.showError = this.showError.bind(this);
        this.handleUserPwdRepeat = this.handleUserPwdRepeat.bind(this);
        this.handleUserPwd = this.handleUserPwd.bind(this);

        this.state = {
            userId: this.props.user != null ? this.props.user.userId : "",
            userPwd: "",
            userPwdRepeat: "",
            modalIsOpen: false,
            showError: false,
            textError: ""
        }
    }

    componentWillMount() {
        if (this.props.user === null) {
            this.props.showLoading(true);
            getUser(this.props.match.params.userId, this.props.match.params.userGroup, this.props.match.params.userType)
                .then((response: IdentityUser) => {
                    this.props.setUser(response, () => this.props.showLoading(false));
                })
                .catch((response: ResponseError) => {
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                    this.props.showLoading(false);
                });
        }
    }

    modify() {
        this.props.history.push(PERSON_MODIFY_ROUTE
            .replace(USER_TYPE_PARAM, this.props.match.params.userType)
            .replace(USER_GROUP_PARAM, this.props.match.params.userGroup)
            .replace(USER_ID_PARAM, this.props.match.params.userId));
    }

    unlock() {
        this.props.showLoading(true);
        unlockUser(this.props.match.params.userId, this.props.match.params.userGroup, this.props.match.params.userType)
            .then(() => {
                this.props.setMessage({ message: i18n.t('user-unlocked'), type: MESSAGE_SUCCESS });
                this.props.showLoading(false);
                this.props.refreshUser();
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    handleUserPwd(e: any) {
        e.preventDefault();
        const userPwd = e.target.value;
        this.setState({ userPwd });
    }

    handleUserPwdRepeat(e: any) {
        e.preventDefault();
        const userPwdRepeat = e.target.value;
        this.setState({ userPwdRepeat });
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        if (this.subtitle)
            this.subtitle.style.color = '#f00';
    }

    showError(text: string) {

        this.setState({ showError: true, textError: text }, () => setTimeout(() => {
            this.setState({ showError: false, textError: '' });
        }, 2000));

    }

    sendDataAndCloseModal() {
        if (this.state.userPwd === "" || this.state.userPwdRepeat === "") {
            this.showError(i18n.t('password-update-text-required'));
        }
        else {
            if (this.state.userPwd !== this.state.userPwdRepeat) {
                this.showError(i18n.t('password-update-text-notmatch'));
            } else {
                this.props.showLoading(true);
                this.setState({ modalIsOpen: false });
                updatePassword(this.state.userId, '', this.state.userPwd)
                    .then((response: Boolean) => {
                        this.props.showLoading(false);
                        this.props.setMessage({ message: i18n.t('password-updated'), type: MESSAGE_SUCCESS });
                    })
                    .catch((response: ResponseError) => {
                        this.props.showLoading(false);
                        this.props.setMessage({ message: i18n.t('password-updated-failed'), type: MESSAGE_ERROR });
                    });
            }
        }
    }

    closeModal() {
        this.setState({ userPwd: '', userPwdRepeat: '', modalIsOpen: false });
    }

    render() {

        const customStyles: any = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            }
        };

        return (
            <div>
                {this.props.user && <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{i18n.t(this.props.match.params.userType + '-data')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <UserInfo client={this.props.user} />
                        <button className="btn btn-info" disabled={this.props.useActiveDirectory} onClick={this.openModal}>{i18n.t('password-update')}</button>
                        {this.props.user.lockoutInfo && this.props.user.lockoutInfo.failureCount === 0 && this.props.havePermission(CLIENT_UNLOCK_PERM) &&
                            <button type="button" id="blockButton" className="btn btn-primary" onClick={this.unlock}>{i18n.t('unlock')}</button>}
                        {/** this.props.match.params.userType === TYPE_USER && this.props.havePermission(USER_LOCK_PERM) && <button type="button" id="blockButton" className="btn btn-primary" >Bloquear</button>} */}
                        {this.props.match.params.userType === TYPE_USER && this.props.havePermission(USER_UPDATE_PERM) && this.props.match.params.userId !== this.props.authUser!.username && <button type="button" onClick={this.modify} id="blockButton" className="btn btn-primary" >{i18n.t('modify')}</button>}
                    </div>
                </div>}
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.sendDataAndCloseModal}
                    style={customStyles}
                    contentLabel=""
                >
                    <h2 ref={subtitle => this.subtitle = subtitle}>{i18n.t('password-update')}</h2>
                    <div className="card-body">
                        <label>{i18n.t('password-update-text')}</label>
                    </div>
                    <div className="form-group">
                        <div className="form-group">
                            <label className="control-label">{i18n.t('password-new')}:</label>
                            <input autoComplete="new-password" type="password" className="form-control" onChange={this.handleUserPwd}></input>
                        </div>
                        <div className="form-group">
                            <label className="control-label">{i18n.t('password-new-repeat')}:</label>
                            <input autoComplete="new-password" type="password" className="form-control" onChange={this.handleUserPwdRepeat}></input>
                        </div>
                        <div className={this.state.showError ? "alert alert-danger" : "alert alert-danger hidden"}>
                            <label>{this.state.textError}</label>
                        </div>
                        <button className="btn btn-info" onClick={this.sendDataAndCloseModal}>{i18n.t('update')}</button>
                        <button className="btn btn-default" onClick={this.closeModal}>{i18n.t('close')}</button>
                    </div>
                </Modal>

                {this.props.user && (this.props.havePermission(SOFTTOKEN_LIST_PERM) || this.props.havePermission(SOFTTOKEN_EVENTS_PERM)) && this.props.match.params.userType === TYPE_CLIENT &&
                    <div className="card margin-card-top" >
                        <div className="card-header">
                            <div className="card-title">
                                <div className="title">{i18n.t('soft-token-manager')}</div>
                            </div>
                        </div>
                        <div className="card-body">
                            {this.props.havePermission(SOFTTOKEN_LIST_PERM) && <div className="panel fresh-color panel-info">
                                <div className="panel-heading"></div>
                                <SoftTokenList />
                            </div>}
                            {this.props.havePermission(SOFTTOKEN_LIST_PERM) &&
                                <Link to={PERSON_SOFTTOKEN_ROUTE.replace(USER_TYPE_PARAM, this.props.match.params.userType)
                                    .replace(USER_GROUP_PARAM, this.props.match.params.userGroup)
                                    .replace(USER_ID_PARAM, this.props.match.params.userId) + '#list'} className="btn btn-primary">{i18n.t('manage')}</Link>}
                            {this.props.havePermission(SOFTTOKEN_EVENTS_PERM) &&
                                <Link to={PERSON_SOFTTOKEN_ROUTE.replace(USER_TYPE_PARAM, this.props.match.params.userType)
                                    .replace(USER_GROUP_PARAM, this.props.match.params.userGroup)
                                    .replace(USER_ID_PARAM, this.props.match.params.userId) + '#events'} className="btn btn-primary">{i18n.t('events')}</Link>}
                        </div>
                    </div>}
                {this.props.user && (this.props.havePermission(GRID_LIST_PERM) || this.props.havePermission(GRID_EVENTS_PERM)) && this.props.match.params.userType === TYPE_CLIENT &&
                    <div className="card margin-card-top" >
                        <div className="card-header">
                            <div className="card-title">
                                <div className="title">{i18n.t('grid-cards')}</div>
                            </div>
                        </div>
                        <div className="card-body">
                            {this.props.havePermission(GRID_LIST_PERM) && <div className="panel fresh-color panel-info">
                                <div className="panel-heading"></div>
                                <CardList />
                            </div>}
                            {this.props.havePermission(GRID_LIST_PERM) &&
                                <Link to={PERSON_GRIDS_ROUTE.replace(USER_TYPE_PARAM, this.props.match.params.userType)
                                    .replace(USER_GROUP_PARAM, this.props.match.params.userGroup)
                                    .replace(USER_ID_PARAM, this.props.match.params.userId) + '#list'} className="btn btn-primary">{i18n.t('manage')}</Link>}
                            {this.props.havePermission(GRID_EVENTS_PERM) &&
                                <Link to={PERSON_GRIDS_ROUTE.replace(USER_TYPE_PARAM, this.props.match.params.userType)
                                    .replace(USER_GROUP_PARAM, this.props.match.params.userGroup)
                                    .replace(USER_ID_PARAM, this.props.match.params.userId) + '#events'} className="btn btn-primary">{i18n.t('events')}</Link>}
                        </div>
                    </div>}
                {this.props.user && (this.props.havePermission(CLIENT_PASSWORD_PERM) || this.props.havePermission(CLIENT_PASSWORD_EVENTS_PERM)) && this.props.match.params.userType === TYPE_CLIENT &&
                    <div className="card margin-card-top" >
                        <div className="card-header">
                            <div className="card-title">
                                <div className="title">{i18n.t('passwords')}</div>
                            </div>
                        </div>
                        <div className="card-body">
                            {this.props.havePermission(CLIENT_PASSWORD_PERM) && <div className="panel fresh-color panel-info">
                                <div className="panel-heading"></div>
                                <PasswordList />
                            </div>}
                            {this.props.havePermission(CLIENT_PASSWORD_PERM) &&
                                <Link to={PERSON_PASSWORD_ROUTE.replace(USER_TYPE_PARAM, this.props.match.params.userType)
                                    .replace(USER_GROUP_PARAM, this.props.match.params.userGroup)
                                    .replace(USER_ID_PARAM, this.props.match.params.userId) + '#list'} className="btn btn-primary">{i18n.t('manage')}</Link>}
                            {this.props.havePermission(CLIENT_PASSWORD_EVENTS_PERM) &&
                                <Link to={PERSON_PASSWORD_ROUTE.replace(USER_TYPE_PARAM, this.props.match.params.userType)
                                    .replace(USER_GROUP_PARAM, this.props.match.params.userGroup)
                                    .replace(USER_ID_PARAM, this.props.match.params.userId) + '#events'} className="btn btn-primary">{i18n.t('events')}</Link>}
                        </div>
                    </div>}
            </div>
        )
    }
}

export const UserProfile = withAppUserContext(UserProfileMain);