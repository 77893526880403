import * as React from "react";
import i18n from "../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../context/AppContext";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { ReportOfficeStatus, SearchOfficeStatusReport, Region } from "../../interfaces/ReportOfficeStatus";
import { findOfficeStatus } from "../../services/Report";
import { apiHandleErrorCode, ResponseError } from "../../utils/ApiBaseConfig";
import { idForReport, activeFormatterOpenedAtForReport, activeFormatterClosedAtForReport, officeFormatterForReport, onlyDatePart, activeFormatterPendingReport } from "../../utils/Formatter";
import { BranchOffice } from "../../interfaces/Office";
import { getRegionList, getBranchOfficeList } from "../../services/Stock";
import { MESSAGE_WARNING } from "../../const/Message";

export interface StateOfficeProps extends RouteComponentProps, AppContextProps { }
export interface StateOfficeState {
    region: string;
    regionName: string;
    regions: Region[];
    office: string;
    officeName: string;
    offices: BranchOffice[];
    showSelectRegionAndLocation: boolean;
    data: ReportOfficeStatus[];
    dateSearch: string;
    tableOptions: any;
}

const customFilter = {
    true: 'Si',
    false: 'No'
};

export class StateOfficeMain extends React.Component<StateOfficeProps, StateOfficeState> {
    constructor(props: StateOfficeProps) {
        super(props);
        this.handleDateSearch = this.handleDateSearch.bind(this);
        this.handleRegion = this.handleRegion.bind(this);
        this.handleBranchOffice = this.handleBranchOffice.bind(this);
        this.getRegionsFromAPI = this.getRegionsFromAPI.bind(this);
        this.getOfficesFromAPI = this.getOfficesFromAPI.bind(this);
        this.findOfficeStatusReport = this.findOfficeStatusReport.bind(this);
        this.state = {
            data: [],
            region: "",
            regionName: "",
            regions: [],
            office: "",
            officeName: "",
            offices: [],
            showSelectRegionAndLocation: false,
            dateSearch: "",
            tableOptions: {
                noDataText: i18n.t('table-empty')
            }
        }
    }

    componentDidMount() {
        if (this.props.authUser && this.props.authUser.branchOffice) {
            if (this.props.authUser.branchOffice.code === "1") {
                this.setState({ showSelectRegionAndLocation: true }, this.getRegionsFromAPI);
                this.findOfficeStatusReport();
            }
            else {
                this.setState({
                    office: this.props.authUser.branchOffice.code,
                    officeName: this.props.authUser.branchOffice.name,
                    regionName: this.props.authUser.branchOffice.region.regionName
                }, this.findOfficeStatusReport);
            }
        }
    }

    handleDateSearch(e: any) {
        e.preventDefault();
        this.setState({ dateSearch: e.target.value });
    }

    handleRegion(e: any) {
        e.preventDefault();
        const regionSelected = e.target.value == "NONE" ? "" : e.target.value;
        this.setState({ region: regionSelected }, this.getOfficesFromAPI);
    }

    handleBranchOffice(e: any) {
        e.preventDefault();
        const officeSelected = e.target.value == "NONE" ? "" : e.target.value;
        this.setState({ office: officeSelected });
    }

    getRegionsFromAPI(): void {
        this.props.showLoading(true);
        getRegionList().then((regionArray: Region[]) => {
            this.setState({ regions: regionArray }, this.props.showLoading(false));
        })
            .catch((response: ResponseError) => {
                this.props.showLoading(false);
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
            });
    }

    getOfficesFromAPI(): void {
        if (this.state.region !== "" && this.state.region !== "NONE") {
            this.props.showLoading(true);
            getBranchOfficeList(this.state.region).then((officeArray: BranchOffice[]) => {
                this.setState({ offices: officeArray }, this.props.showLoading(false));
            })
                .catch((response: ResponseError) => {
                    this.props.showLoading(false);
                    apiHandleErrorCode(response.status, this.props.history);
                    this.props.setMessage(response.message);
                });
        }
    }

    findOfficeStatusReport() {
        this.props.showLoading(true);
        if (this.state.dateSearch == "") {
            var curr = new Date();
            var date = curr.toISOString().substr(0, 10);
            this.setState({ dateSearch: date })
        }

        if (this.state.region !== "" && this.state.office == "") {
            this.props.setMessage({ message: i18n.t('branch-office-select'), type: MESSAGE_WARNING });
            this.props.showLoading(false);
            return;
        }

        const search: SearchOfficeStatusReport = {
            firstResult: 0,
            maxResults: 300,
            reportDate: this.state.dateSearch,
            reportOffice: this.state.office
        };

        findOfficeStatus(search)
            .then((report: ReportOfficeStatus[]) => {
                console.log("report en componente");
                console.log(report);
                this.setState({ data: report }, () => this.props.showLoading(false));
            })
            .catch((response: ResponseError) => {
                apiHandleErrorCode(response.status, this.props.history);
                this.props.setMessage(response.message);
                this.props.showLoading(false);
            });
    }

    render() {

        const REG: { [key: string]: string } = this.state.regions.reduce((map: { [key: string]: string }, region: Region) => {
            map[region.codeRegion] = region.regionName;
            return map;
        }, {});

        const regions: JSX.Element[] = Object.keys(REG).map((codeRegion: string) => {
            return <option key={codeRegion} value={codeRegion}>{i18n.t(REG[codeRegion])}</option>;
        });

        const OFFI: { [key: string]: string } = this.state.offices.reduce((map: { [key: string]: string }, office: BranchOffice) => {
            map[office.code] = office.name;
            return map;
        }, {});

        const branchOffices: JSX.Element[] = Object.keys(OFFI).map((codeOffice: string) => {
            return <option key={codeOffice} value={codeOffice}>{i18n.t(OFFI[codeOffice])}</option>;
        });

        return (
            <div className="panel-tabs">
                <div className="panel-heading">
                </div>
                <div className="panel-body">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <div className="title">{i18n.t('report-status-office')}</div>
                            </div>
                        </div>
                        <div className="card-body">
                            {this.state.showSelectRegionAndLocation &&
                                <div>
                                    <div className="form-group col-sm-3">
                                        <label>{i18n.t('region')}:</label>
                                        <select className="form-control" onChange={this.handleRegion} value={this.state.region}>
                                            <option value="NONE">{i18n.t('option-select')}</option>
                                            {regions}
                                        </select>
                                    </div>
                                    <div className="form-group col-sm-3">
                                        <label>{i18n.t('branch-office')}:</label>
                                        <select className="form-control" onChange={this.handleBranchOffice} value={this.state.office}>
                                            <option value="NONE">{i18n.t('option-select')}</option>
                                            {branchOffices}
                                        </select>
                                    </div>
                                </div>
                            }
                            {!this.state.showSelectRegionAndLocation &&
                                <div>
                                    <div className="form-group col-sm-3">
                                        <label>{i18n.t('region')}:</label>
                                        <p className="form-control">{this.state.regionName}</p>
                                    </div>
                                    <div className="form-group col-sm-3">
                                        <label>{i18n.t('branch-office')}:</label>
                                        <p className="form-control">{this.state.officeName}</p>
                                    </div>
                                </div>
                            }
                            <div className="form-group col-sm-3">
                                <label>{i18n.t('date')}:</label>
                                <input type="date" name="dateSearch" className="form-control" id="dateSearch" value={this.state.dateSearch} onChange={this.handleDateSearch} />
                            </div>
                            <div className="form-group col-sm-3">
                                <br></br>
                                <button type="button" className="btn btn-info" onClick={this.findOfficeStatusReport}>{i18n.t('search')}</button>
                            </div>
                            <div className="form-group col-sm-12">
                                <BootstrapTable
                                    data={this.state.data}
                                    options={this.state.tableOptions}
                                    pagination
                                >
                                    <TableHeaderColumn dataField='officeCode' isKey={true} dataFormat={idForReport} hidden={true} >#</TableHeaderColumn>
                                    <TableHeaderColumn dataField='officeName' dataFormat={officeFormatterForReport} dataSort={true} filter={{ type: 'TextFilter', placeholder: i18n.t('search') }}>{i18n.t('branch-office')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='date' dataFormat={onlyDatePart} dataSort={true} filter={{ type: 'TextFilter', placeholder: i18n.t('date') }}>{i18n.t('date')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='opened' filter={{ type: 'SelectFilter', options: customFilter, selectText: i18n.t('was') }} dataFormat={activeFormatterOpenedAtForReport} dataAlign="center" >{i18n.t('opened')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='closened' filter={{ type: 'SelectFilter', options: customFilter, selectText: i18n.t('was') }} dataFormat={activeFormatterClosedAtForReport} dataAlign="center" >{i18n.t('closened')}</TableHeaderColumn>
                                    <TableHeaderColumn dataField='pendingApproval' filter={{ type: 'SelectFilter', options: customFilter, selectText: i18n.t('was') }} dataAlign="center" dataFormat={activeFormatterPendingReport}>{i18n.t('pending')}</TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export const StateOffice = withAppContext(StateOfficeMain);
