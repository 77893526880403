import * as React from "react";
import i18n from "../../i18n";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "../../context/AppContext";
import { NOTICE_LIST_PERM, NOTICE_CONFIGURATION_PERM } from "../../const/Permission";
import { NoticesList } from "./List";
import { NoticesConfList } from "./Conf";


export interface NoticeProps extends RouteComponentProps, AppContextProps { }

export interface NoticeState {
    step: number;
}

export class NoticeMain extends React.Component<NoticeProps, NoticeState> {
    lockGeneralRequests: boolean = false;
    lockSystemRequests: boolean = false;

    constructor(props: NoticeProps) {
        super(props);

        this.state = {
            step: this.props.havePermission(NOTICE_LIST_PERM) ? 0 : 2
        }
    }

    setStep(step: number): any {
        this.setState({ step });
    }

    render() {
        return (
            <div className="panel-tabs">
                <div className="panel-heading">
                    <ul className="nav nav-tabs">
                        {this.props.havePermission(NOTICE_LIST_PERM) && <li className={this.state.step === 0 ? "active" : ""}><a onClick={() => this.setStep(0)} href="#" className="nav-link" >{i18n.t('notices')}</a></li>}
                        {this.props.havePermission(NOTICE_CONFIGURATION_PERM) && <li className={this.state.step === 1 ? "active" : ""}><a onClick={() => this.setStep(1)} href="#" className="nav-link" >{i18n.t('notices-conf')}</a></li>}
                     </ul>
                </div>
                {this.state.step === 0 && this.props.havePermission(NOTICE_LIST_PERM) &&
                    <NoticesList props={this.props} />
                }
                {this.state.step === 1 && this.props.havePermission(NOTICE_CONFIGURATION_PERM) &&
                    <NoticesConfList props={this.props} />
                }
            </div>

        )
    }
}

export const Notice = withAppContext(NoticeMain);